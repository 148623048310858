export const MARK_HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+shift+9': 'code',
  'mod+option+9': 'code',
  'mod+shift+\\': 'code',
}

export const BLOCK_HOTKEYS = {
  // 'mod+shift+q': 'block-quote',
}

export const LIST_TYPES = ['numbered-list', 'bulleted-list']
export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']
