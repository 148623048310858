import React, { FC, useContext } from 'react'
import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { DeleteModal, useDeleteWithConfirm, useTranslate } from 'chakra-admin'
import { FiMoreVertical } from 'react-icons/fi'
import { FaTrash } from 'react-icons/fa'
import { DownloadButton } from '../../resources/Project/DownloadButton'
import { ProjectIdContext } from './ProjectIdContext'

type Props = {
  onDelete?: () => void
  id?: string
  title?: string
}

export const LaneHeaderMenu: FC<Props> = ({ onDelete, ...props }) => {
  const t = useTranslate()
  const { deleting, onDeleteItem, isOpen, onClose, onOpen } = useDeleteWithConfirm({
    onDelete,
  })
  const projectId = useContext(ProjectIdContext)

  return (
    <>
      <Menu isLazy>
        <MenuButton
          as={IconButton}
          borderRadius="full"
          size="sm"
          aria-label={t('components.ProjectBoard.laneMenuAriaLabel')}
          // color="blackAlpha.700"
          icon={<Icon as={FiMoreVertical} />}
          fontSize="sm"
        />
        <MenuList>
          <MenuItem onClick={onOpen} color="red.500" icon={<Icon color="red.400" as={FaTrash} />}>
            {t('components.ProjectBoard.deleteLane')}
          </MenuItem>
          <MenuItem
            as={DownloadButton}
            projectId={projectId}
            statusId={props.id}
            filename={`List of ${props.title}`}
            w="100%"
            justifyContent="flex-start"
            borderRadius="none"
            _hover={{ cursor: 'pointer' }}
          />
        </MenuList>
      </Menu>
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        onDeleteItem={onDeleteItem}
        deleting={deleting}
        title={t('components.ProjectBoard.deleteLane')}
        description={t('components.ProjectBoard.deleteLaneConfirm')}
        confirmDeleteButtonLabel={t('components.ProjectBoard.deleteLane')}
      />
    </>
  )
}
