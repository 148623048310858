import React from 'react'
import { Box, Button, Checkbox, Stack, Tag, useToast } from '@chakra-ui/react'
import { isEqual, eachDayOfInterval, parseISO, differenceInDays, isPast } from 'date-fns'
import { useDebounce } from 'react-use'
import { useApolloClient } from '@apollo/client'
import {
  gql_EditTaskMutation,
  gql_EditTaskMutationVariables,
  gql_Task,
  useEditTaskCompletionDateMutation,
} from '../../graphql'
import { MUTATION_UPDATE_TASK } from '../../queries'

import 'react-datepicker/dist/react-datepicker.css'
import { DatetimePicker } from '../datetime-picker'
import { CalendarContainer } from 'react-datepicker'

interface CompletableDateInputProps {
  task: Pick<gql_Task, 'id' | 'startDate' | 'endDate' | 'completionDate'>
  highlightInterval?: {
    start: Date | string
    end: Date | string
  }
}

const shouldUpdate = (current: Date | null, initial: string | null) => {
  if (!current) {
    return false
  }

  if (!initial) {
    return true
  }

  return !isEqual(current, parseISO(initial))
}

const parseDate = (date: string | null) => (date ? parseISO(date) : null)

export const CompletableDateInput: React.FC<CompletableDateInputProps> = ({
  task,
  // task.id,
  // date: initialEndDate,
  highlightInterval,
}) => {
  const client = useApolloClient()
  const [endDate, setEndDate] = React.useState(parseDate(task.endDate))
  const [complete, completeResult] = useEditTaskCompletionDateMutation()

  const toast = useToast()

  const endDateRef = React.useRef(endDate)

  useDebounce(
    () => {
      if (shouldUpdate(endDate, task.endDate)) {
        handleSubmit(endDate!)
      }
    },
    1000,
    [endDate]
  )

  // reset
  React.useEffect(() => {
    if (shouldUpdate(endDateRef.current, task.endDate)) {
      setEndDate(parseDate(task.endDate))
    }
  }, [task.endDate])

  React.useEffect(() => {
    endDateRef.current = endDate
  }, [endDate])

  const handleSubmit = React.useCallback(
    async (endDate?: Date | null) => {
      if (!task.id) {
        return false
      }

      try {
        await client.mutate<gql_EditTaskMutation, gql_EditTaskMutationVariables>({
          mutation: MUTATION_UPDATE_TASK,
          variables: {
            id: task.id,
            data: {
              endDate: endDate?.toISOString() || null,
            },
          },
        })
      } catch (error) {
        toast({
          title: 'Error',
          description: (error as any).message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    },
    [task.id, client, toast]
  )

  const handleComplete = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      complete({
        variables: {
          id: task.id,
          data: {
            completionDate: event.target.checked ? new Date().toISOString() : null,
          },
        },
      })
    },
    [complete, task.id]
  )

  const highlightDates = React.useMemo(
    () =>
      highlightInterval
        ? eachDayOfInterval({
            start: new Date(highlightInterval.start),
            end: new Date(highlightInterval.end),
          })
        : [],
    [highlightInterval]
  )

  const renderTag = React.useCallback(() => {
    if (!task.endDate) {
      return null
    }

    const isCompleted = !!task.completionDate

    if (isCompleted) {
      return (
        <Tag size="sm" colorScheme="green">
          Completato
        </Tag>
      )
    }

    const isOverdue = isPast(new Date(task.endDate))

    if (isOverdue) {
      return (
        <Tag size="sm" colorScheme="red">
          Scaduto
        </Tag>
      )
    }

    const closingSoon = differenceInDays(new Date(task.endDate), new Date()) === 0

    if (closingSoon) {
      return (
        <Tag size="sm" colorScheme="orange">
          Scadenza imminente
        </Tag>
      )
    }

    return null
  }, [task.completionDate, task.endDate])

  const isEmpty = !task.endDate

  return (
    <Stack isInline>
      {!isEmpty && (
        <Checkbox
          isChecked={!!task.completionDate}
          onChange={handleComplete}
          isDisabled={completeResult.loading}
        />
      )}
      <Stack isInline bg="gray.100" p="1" borderRadius="lg">
        <DatetimePicker
          selected={endDate}
          onChange={setEndDate}
          showTimeInput
          showTimeSelect
          timeIntervals={60}
          dateFormat="Pp"
          placeholderText="Aggiungi data di scadenza"
          inputProps={{
            variant: 'unstyled',
            textAlign: 'center',
            width: isEmpty ? '10rem' : '7rem',
            size: 'xs',
            color: 'gray.500',
            fontWeight: '500',
          }}
          highlightDates={highlightDates}
          calendarContainer={({ className, children }) => (
            <CalendarContainer className={className}>
              <Box>{children}</Box>
              <Box w="100%" p="2">
                <Button isDisabled={!endDate} w="100%" onClick={() => handleSubmit(null)}>
                  Rimuovi la data scadenza
                </Button>
              </Box>
            </CalendarContainer>
          )}
        />

        {renderTag()}
      </Stack>
    </Stack>
  )
}
