/* eslint-disable react/require-default-props */
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  Text,
  Icon,
  BoxProps,
  useDisclosure,
  useOutsideClick,
  PopoverContent,
  Popover,
  PopoverTrigger,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Circle,
} from '@chakra-ui/react'
import { FieldValues, useController } from 'react-hook-form'
import { HexColorPicker } from 'react-colorful'
import { BsChevronDown } from 'react-icons/bs'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { CAInputProps, useTranslate } from 'chakra-admin'

type ColorInputProps<TItem extends FieldValues> = {
  placeholder?: string
} & CAInputProps<TItem> &
  BoxProps

/**
 *
 * WIP: Component for choosing a color.
 *
 * @example
 *
 * <ColorInput source="color" />
 */
export function ColorInput<TItem extends FieldValues>({
  source,
  label,
  resource,
  required,
  min,
  max,
  maxLength,
  minLength,
  pattern,
  validate,
  valueAsNumber,
  valueAsDate,
  value: propValue,
  setValueAs,
  shouldUnregister,
  onChange: propOnChange,
  onBlur: propOnBlur,
  disabled,
  deps,
  placeholder,
  ...rest
}: ColorInputProps<TItem>) {
  const t = useTranslate({ keyPrefix: 'ca.input.single_image' })

  const {
    field: { onChange, onBlur, name, value, ref },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name: source as any,
    control: (rest as any).control,
    rules: { required, min, max, maxLength, minLength, pattern, validate },
    shouldUnregister,
  })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialRef = useRef(null)
  const pickerRef = useRef(null)

  useOutsideClick({
    ref: pickerRef,
    handler: onClose,
    enabled: isOpen,
  })

  return (
    <Popover
      placement="bottom"
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
      isLazy
    >
      <PopoverTrigger>
        <Box onClick={onOpen} ref={initialRef} flex="1" {...rest}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Circle size="18px" bgColor={(value as string) || 'gray.200'} />
            </InputLeftElement>
            <Input
              placeholder={placeholder}
              name={name}
              color={value as string}
              ref={ref}
              value={(value as string) || ''}
              onChange={onChange}
              _placeholder={{ color: 'gray.500' }}
              borderRadius="lg"
            />
            <InputRightElement
              cursor="pointer"
              children={<Icon as={ChevronDownIcon} boxSize="20px" />}
            />
          </InputGroup>
        </Box>
      </PopoverTrigger>

      <PopoverContent
        p={0}
        w="min-content"
        border="none"
        outline="none"
        _focus={{ boxShadow: 'none' }}
        ref={pickerRef}
      >
        <HexColorPicker onChange={onChange} color={(value as string) || ''} />
      </PopoverContent>
    </Popover>
  )
}
