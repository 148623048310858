import * as React from 'react'
import {
  Checkbox,
  Flex,
  Icon,
  IconButton,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react'
import { FiMoreVertical, FiTrash } from 'react-icons/fi'
import { MdDragIndicator } from 'react-icons/md'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { gql_TaskTodo } from '../../../graphql'
import { useTaskTodo } from './TaskTodoContext'
import { EditableDescription } from './EditableDescription'

interface TodoItemProps {
  todo: gql_TaskTodo
  sortable?: boolean
}

export const TaskTodoItem: React.FC<TodoItemProps> = ({ todo, sortable = false }) => {
  const { handleDelete, handleDone, handleUpdateDescription } = useTaskTodo()
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: todo.id,
  } as any)

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Flex
      alignItems="center"
      as={ListItem}
      ref={setNodeRef}
      paddingX="2"
      borderRadius="5px"
      {...style}
      boxShadow={isDragging ? 'lg' : undefined}
      zIndex={isDragging ? 1 : 0}
      my={4}
    >
      <Checkbox isChecked={todo.isDone} colorScheme="red" onChange={() => handleDone(todo)} />
      <EditableDescription
        initialValue={todo.description}
        onSubmit={(value) => handleUpdateDescription(todo.id, value)}
        editableProps={{
          marginLeft: 2,
          flex: 1,
        }}
        editablePreviewProps={{ color: todo.isDone ? 'primary.500' : 'gray.500' }}
      />
      <Menu isLazy>
        <MenuButton
          as={IconButton}
          aria-label="actions options"
          icon={<Icon as={FiMoreVertical} fontSize="lg" />}
          mr={sortable ? 0 : 2}
          size="sm"
          variant="ghost"
          colorScheme="blackAlpha"
        />
        <Portal>
          <MenuList>
            <MenuItem
              onClick={() => handleDelete(todo)}
              color="red.500"
              icon={<Icon color="red.400" as={FiTrash} />}
            >
              Elimina
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
      {sortable && (
        <IconButton
          variant="ghost"
          aria-label="drag and sort"
          size="sm"
          color="gray.300"
          icon={<Icon as={MdDragIndicator} />}
          marginX="2"
          {...attributes}
          {...listeners}
        />
      )}
    </Flex>
  )
}
