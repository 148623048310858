import React, { FC } from 'react'
import { ca, TreeRenderer } from 'chakra-admin'
import { MUTATION_UPDATE_ROLE, QUERY_GET_ROLE } from '../../queries'
import {
  Alert,
  AlertIcon,
  Box,
  EditableInput,
  EditablePreview,
  Stat,
  StatArrow,
  StatGroup,
  Text,
} from '@chakra-ui/react'
import { gql_Role } from '../../graphql'
import { Edit } from '../../components/details/Edit'
import { BaseForm } from '../../components/forms/BaseForm'
import { RoleForm } from './RoleForm'

type Role = Omit<gql_Role, 'users'>

export const RoleEdit: FC = (props) => {
  return (
    <Edit query={QUERY_GET_ROLE} mutation={MUTATION_UPDATE_ROLE} {...props}>
      <RoleForm {...props} />
    </Edit>
  )
}
