import React, { FC, useMemo, useCallback, useEffect, useState } from 'react'
import {
  AvatarGroup,
  Avatar,
  HStack,
  Button,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Portal,
  AvatarProps,
  IconButton,
  useToast,
} from '@chakra-ui/react'
import {
  gql_Project,
  gql_User,
  useAddUserToProjectMutation,
  useGetUsersQuery,
  useRemoveUserFromProjectMutation,
} from '../../graphql'
import { useParams, useSearchParams } from 'react-router-dom'
import { UserCard } from '../user-select/UserCard'
import { motion, Transition } from 'framer-motion'
import { useVersionStateValue } from 'chakra-admin'
import { FiPlus } from 'react-icons/fi'
import { BoardUserListSelect } from './BoardUserListSelect'
import { WithPermission } from '../auth/WithPermission'
import { useHasPermission } from '../../utils/auth/permissions'

const defaultExpandedAvatarsSpacing = -1
const defaultGroupedAvatarsSpacing = -2

type Props = {
  record?: gql_Project
  expandedAvatarsSpacing?: number
  groupedAvatarsSpacing?: number
}

export const BoardUsersFilter: FC<Props> = ({
  record,
  expandedAvatarsSpacing = defaultExpandedAvatarsSpacing,
  groupedAvatarsSpacing = defaultGroupedAvatarsSpacing,
  ...rest
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const version = useVersionStateValue()
  const toast = useToast()

  const filteredUserIdsParam = searchParams.get('f_userIds')

  const filteredUserIds = useMemo(() => {
    return filteredUserIdsParam ? filteredUserIdsParam.split(',').filter((item) => !!item) : []
  }, [filteredUserIdsParam])

  const [spacing, setSpacing] = useState<number | undefined>(groupedAvatarsSpacing)

  const { data, loading, refetch } = useGetUsersQuery({
    variables: {
      filters: {
        projectId: record?.id,
      },
    },
    skip: !record?.id,
  })

  const hasPermissionFn = useHasPermission()
  const canAddUser = hasPermissionFn('addUserToProject')

  const handleMouseEnter = useCallback(() => {
    setSpacing(expandedAvatarsSpacing)
  }, [expandedAvatarsSpacing])
  const handleMouseLeave = useCallback(() => {
    setSpacing(groupedAvatarsSpacing)
  }, [groupedAvatarsSpacing])

  const [addMutation] = useAddUserToProjectMutation()
  const [removeMutation] = useRemoveUserFromProjectMutation()

  const handleSelect = React.useCallback(
    async (userId: string, shouldRemove: boolean) => {
      const mutation = shouldRemove ? removeMutation : addMutation

      try {
        const { data } = await mutation({
          variables: {
            projectId: record?.id!,
            userId,
          },
        })
        refetch()
      } catch (e) {
        toast({
          title: 'Errore',
          // eslint-disable-next-line @typescript-eslint/quotes
          description: (e as any).message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    },
    [addMutation, record?.id, refetch, removeMutation, toast]
  )

  const handleRemoveUserFromProject = useCallback(
    (userId: string) => {
      handleSelect(userId, true)
    },
    [handleSelect]
  )

  const handleResetFilters = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.delete('f_userIds')
    setSearchParams(newSearchParams)
  }, [searchParams, setSearchParams])

  useEffect(() => {
    if (!loading && data?.users) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version])

  return (
    <HStack>
      {filteredUserIds.length > 0 && (
        <Button size="xs" variant="ghost" colorScheme="red" onClick={handleResetFilters}>
          Rimuovi filtri
        </Button>
      )}
      {data?.users?.data?.length && (
        <AvatarGroup
          role="group"
          //   onMouseEnter={handleMouseEnter}
          //   onMouseLeave={handleMouseLeave}
          //   onBlur={handleMouseLeave}
          spacing={defaultGroupedAvatarsSpacing}
        >
          {data.users.data.map((user) => (
            <UserAvatarFilter
              key={user.id}
              id={user.id}
              size="sm"
              name={`${user.firstName || ''} ${user.lastName || ''}`}
              src={user?.picture?.urlSmall}
              isSelected={filteredUserIds.includes(user.id)}
              onDelete={handleRemoveUserFromProject}
              user={user}
              onClick={() => {
                const newSearchParams = new URLSearchParams(searchParams)
                if (filteredUserIds.includes(user.id)) {
                  if (filteredUserIds.length === 1) {
                    newSearchParams.delete('f_userIds')
                  } else {
                    newSearchParams.set(
                      'f_userIds',
                      filteredUserIds.filter((id) => id !== user.id).join(',')
                    )
                  }
                } else {
                  newSearchParams.set('f_userIds', `${filteredUserIds.join(',')},${user.id}`)
                }
                setSearchParams(newSearchParams.toString())
              }}
            />
          ))}
        </AvatarGroup>
      )}

      {canAddUser && (
        <Popover>
          <PopoverTrigger>
            <IconButton
              aria-label="add new user"
              icon={<Icon as={FiPlus} fontSize="sm" color="gray.300" />}
              size="sm"
              variant="outline"
              borderRadius="50%"
              borderStyle="dashed"
              borderColor="gray.300"
              colorScheme="blackAlpha"
            />
          </PopoverTrigger>

          <Portal>
            <BoardUserListSelect
              selected={data?.users?.data || []}
              onSelect={handleSelect}
              projectId={record?.id!}
            />
          </Portal>
        </Popover>
      )}
    </HStack>
  )
}

export const MotionAvatar = motion(Avatar)

const animationTransition: Transition = {
  type: 'spring',
  stiffness: 400,
  damping: 30,
}

export type UserAvatarFilterProps = {
  id: string | number
  isSelected?: boolean
  user: gql_User
  onDelete?: (userId: string) => void
} & AvatarProps

export const UserAvatarFilter: FC<UserAvatarFilterProps> = ({
  isSelected,
  id,
  user,
  onDelete,
  ...props
}) => {
  const hasPermissionFn = useHasPermission()

  const hasEdit = useMemo(() => {
    return hasPermissionFn('updateUser')
  }, [hasPermissionFn])

  const hasRemove = useMemo(() => {
    return hasPermissionFn('removeUserFromProject')
  }, [hasPermissionFn])

  const handleKeyUp = useCallback(
    (e) => {
      if (e.keyCode === 32 || e.keyCode === 13) {
        e.preventDefault()
        if (props?.onClick) {
          props.onClick(e)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props?.onClick, id]
  )

  const handleDelete = useCallback(() => {
    if (onDelete) {
      onDelete(id)
    }
  }, [onDelete, id])

  return (
    <Popover key={user.id} trigger="hover">
      <PopoverTrigger>
        <MotionAvatar
          _hover={{
            borderColor: 'primary.500',
            borderWidth: '3px',
          }}
          borderWidth={isSelected ? '3px' : undefined}
          borderColor={isSelected ? 'primary.400' : undefined}
          role="button"
          tabIndex={0}
          whileHover={{
            bottom: '5px',
          }}
          layout
          transition={animationTransition}
          onKeyUp={handleKeyUp}
          {...props}
        />
      </PopoverTrigger>
      <Portal>
        <UserCard user={user} onDelete={handleDelete} hasEdit={hasEdit} hasRemove={hasRemove} />
      </Portal>
    </Popover>
  )
}
