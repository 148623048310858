import React, { FC } from 'react'
import { ca, SelectInput, useTranslate } from 'chakra-admin'
import { VStack, Flex, Box, Heading, Text } from '@chakra-ui/react'
import { gql_MutationCreateProjectArgs } from '../../graphql'
import { useWatch } from 'react-hook-form'
import { ColorInput } from '../../components/inputs/ColorInput'
import { BaseForm } from '../../components/forms/BaseForm'
import { SingleImageInput } from '../../components/inputs/SingleImageInput'

type TInput = gql_MutationCreateProjectArgs['data']

type Props = {
  //
}

const ShowFormData = (props: any) => {
  const form = useWatch({ control: props.control })
  return <pre>{JSON.stringify(form, null, 2)}</pre>
}

export const ProjectForm: FC<Props> = (props) => {
  const t = useTranslate()

  return (
    <BaseForm {...props}>
      <VStack align="stretch" spacing={5}>
        <Flex
          w="100%"
          flexDir={{ base: 'column', md: 'row' }}
          alignItems="flex-start"
          justifyContent="flex-start"
          pt={4}
        >
          <Box w={{ base: '100%', md: 'unset' }}>
            <Heading size="sm" mb={{ base: 5, md: 3 }}>
              {t('resources.Project.fields.picture')}
            </Heading>
            <SingleImageInput w={{ base: '100%', md: '130px' }} h="100%" source="picture.file" />
          </Box>
          <VStack
            spacing={4}
            alignItems="flex-start"
            justifyContent="center"
            mt={{ base: 5, md: 0 }}
            ml={{ base: 0, md: 4 }}
            w="100%"
            flex="1"
          >
            <Heading size="sm" mb={{ base: 1, md: 3 }}>
              Informazioni Bacheca
            </Heading>
            <ca.FormControl<TInput> source="name">
              <ca.Input<TInput>
                source="name"
                required={`${t('ca.message.required_field')}`}
                placeholder={t('resources.Project.placeholders.name')}
                _placeholder={{ color: 'gray.500' }}
                borderRadius="lg"
                autoFocus
              />
              <ca.FormErrorMessage />
            </ca.FormControl>

            <ca.FormControl<TInput> source="color">
              <ColorInput source="color" placeholder={t('resources.Project.placeholders.color')} />
            </ca.FormControl>
          </VStack>
        </Flex>

        <Heading size="sm" mt={4} mb={3}>
          Visiblità Bacheca
        </Heading>

        <ca.FormControl source="visibility" mb={4}>
          <SelectInput<TInput>
            source="visibility"
            required={`${t('ca.message.required_field')}`}
            borderRadius="lg"
          >
            <option value="PRIVATE">Privata</option>
            <option value="PUBLIC">Pubblica</option>
          </SelectInput>
          <VisibilityInfo />
        </ca.FormControl>
        {/* <ShowFormData /> */}
      </VStack>
    </BaseForm>
  )
}

const VisibilityInfo = () => {
  return (
    <Box px={1}>
      <Text fontSize="sm" color="gray.400" mt={3}>
        Se la visibilità è impostata su{' '}
        <Text as="span" fontWeight="600" color="gray.500">
          pubblica
        </Text>{' '}
        tutti gli utenti potranno accedere alla bacheca e modificarla.
      </Text>
      <Text fontSize="sm" color="gray.400" mb={4}>
        Se la visibilità è impostata su{' '}
        <Text as="span" fontWeight="600" color="gray.500">
          privata
        </Text>{' '}
        solo gli utenti aggiunti alla bacheca potranno accedervi.
      </Text>
    </Box>
  )
}
