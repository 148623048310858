import React from 'react'

import { Avatar, Flex, Icon, IconButton } from '@chakra-ui/react'
import { useInteractions } from './InteractionContext'
import { MdKeyboardReturn } from 'react-icons/md'
import { RichText } from '../rich-text/RichText'

export const CommentInput: React.FC = (_, ref) => {
  const { handleCreate, user } = useInteractions()

  if (!user) {
    return null
  }

  return (
    <Flex position="relative" alignItems="center">
      <Avatar
        size="sm"
        src={user?.picture?.urlSmall}
        name={`${user.firstName} ${user.lastName}`}
        mr="2"
      />
      <RichText
        hideToolbar
        boxContainer
        onSubmit={(value) => {
          handleCreate(value)
        }}
      />
      <IconButton
        position="absolute"
        top="1"
        right="1"
        variant="unstyled"
        display="flex"
        justifyContent="center"
        type="submit"
        aria-label="submit"
        size="sm"
        icon={<Icon as={MdKeyboardReturn} />}
      />
    </Flex>
  )
}
