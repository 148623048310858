import { gql } from '@apollo/client'

export const tagFragment = gql`
  fragment TagFragment on Tag {
    id
    name
    color
  }
`

export const QUERY_GET_TAGS = gql`
  query GetTags(
    $projectId: ID!
    $pagination: PaginationInputType
    $sort: TagSortInput
    $filters: TagFilterInput
  ) {
    tags(projectId: $projectId, pagination: $pagination, sort: $sort, filters: $filters) {
      total
      data {
        ...TagFragment
      }
    }
  }

  ${tagFragment}
`

export const MUTATION_ADD_TAG_TO_TASK = gql`
  mutation AddTagToTask($tagId: ID!, $taskId: ID!) {
    addTagToTask(taskId: $taskId, tagId: $tagId) {
      id
      tags {
        ...TagFragment
      }
    }
  }

  ${tagFragment}
`

export const MUTATION_REMOVE_TAG_FROM_TASK = gql`
  mutation RemoveTagFromTask($taskId: ID!, $tagId: ID!) {
    removeTagFromTask(taskId: $taskId, tagId: $tagId) {
      id
      tags {
        ...TagFragment
      }
    }
  }

  ${tagFragment}
`

export const MUTATION_CREATE_TAG = gql`
  ${tagFragment}
  mutation CreateTag($data: TagCreateInput!) {
    createTag(data: $data) {
      ...TagFragment
    }
  }
`

export const MUTATION_UPDATE_TAG = gql`
  ${tagFragment}
  mutation UpdateTag($id: ID!, $data: TagUpdateInput!) {
    updateTag(id: $id, data: $data) {
      ...TagFragment
    }
  }
`

export const MUTATION_DELETE_TAG = gql`
  mutation DeleteTag($id: ID!) {
    deleteTag(id: $id)
  }
`
