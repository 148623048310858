import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

export const taskUploadAttachmentState = atom<boolean>({
  key: 'taskUploadAttachmentState',
  default: false,
})

export const useTaskUploadAttachmentState = () => useRecoilState(taskUploadAttachmentState)

export const useSetTaskUploadAttachmentState = () => {
  return useSetRecoilState(taskUploadAttachmentState)
}

export const useTaskUploadAttachmentStateValue = () => {
  return useRecoilValue(taskUploadAttachmentState)
}
