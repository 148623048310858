import React from 'react'
import { BoxProps } from '@chakra-ui/react'
import { TaskTodoProvider } from './TaskTodoContext'
import { TaskTodoListComponent } from './TaskTodoList'

interface TaskTodoListProps {
  taskId: string
  containerProps?: BoxProps
}

export const TaskTodoList: React.FC<TaskTodoListProps> = ({ taskId, ...props }) => {
  return (
    <TaskTodoProvider taskId={taskId}>
      <TaskTodoListComponent {...props} />
    </TaskTodoProvider>
  )
}
