import React, { FC } from 'react'
import { MotionBox } from 'chakra-admin'
import { AnimatePresence } from 'framer-motion'

type Props = {
  isExpanderHover: boolean
}

export const ToggleSizeHoverBorder: FC<Props> = ({ isExpanderHover }) => {
  return (
    <AnimatePresence>
      {isExpanderHover && (
        <MotionBox
          pos="absolute"
          right="-1px"
          top="0px"
          bottom="0px"
          bgColor="primary.400"
          initial={{ width: 0 }}
          animate={{ width: 2 }}
          exit={{ width: 0 }}
        />
      )}
    </AnimatePresence>
  )
}
