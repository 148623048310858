import React, { FC, useCallback } from 'react'
import { IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react'
import { useSlate } from 'slate-react'
import { isBlockActive, toggleBlock } from './helpers'
import { TEXT_ALIGN_TYPES } from './constants'

export type BlockButtonProps = {
  format: string
  label: string
} & Omit<IconButtonProps, 'aria-label'>

export const BlockButton: FC<BlockButtonProps> = ({ format, label, ...props }) => {
  const editor = useSlate()

  const handleClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault()

      toggleBlock(editor, format)
    },
    [editor, format]
  )

  return (
    <Tooltip label={label}>
      <IconButton
        size="xs"
        isActive={isBlockActive(
          editor,
          format,
          TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
        )}
        aria-label={label}
        onClick={handleClick}
        {...props}
      ></IconButton>
    </Tooltip>
  )
}
