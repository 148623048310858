import { useLocalStorage } from 'chakra-admin'
import produce from 'immer'
import React, { createContext, ReactNode, useContext, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  gql_Interaction,
  gql_GetInteractionsQuery,
  gql_GetInteractionsQueryVariables,
  gql_SortDirection,
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useGetUserMeQuery,
  useUpdateCommentMutation,
  gql_InteractableType,
  gql_GetUserMeQuery,
} from '../../graphql'
import { QUERY_GET_INTERACTIONS } from '../../queries'

type HandleCreate = (comment: string, doneCallback?: Function) => void
type HandleUpdate = (id: string, comment: string) => void
type HandleDelete = (action: Pick<gql_Interaction, 'id' | '__typename'>) => void

interface InteractionsContextData {
  variables: gql_GetInteractionsQueryVariables
  setSearchString: (searchString?: string) => void
  type: gql_InteractableType
  id: string
  user: gql_GetUserMeQuery['userMe']
  handleDelete: HandleDelete
  handleCreate: HandleCreate
  handleUpdate: HandleUpdate
  showDetails: boolean
  setShowDetails: (value: boolean) => void
  handleClickOutside: (id: string) => void
}

interface InteractionProviderProps {
  children: ReactNode
  type: gql_InteractableType
  id: string
}

const InteractionsContext = createContext({} as InteractionsContextData)

export const sort = {
  createdAt: gql_SortDirection.Desc,
}

export const InteractionProvider = ({ type, id, children }: InteractionProviderProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [searchString, setSearchString] = React.useState<string | undefined>()
  const [showDetails, setShowDetails] = useLocalStorage<boolean>(
    'activity-interaction-type-filter',
    false
  )

  const variables = useMemo(() => {
    return {
      on: {
        type,
        id,
      },
      sort,
      filters: {
        types: showDetails ? undefined : ['COMMENT'],
        comment: searchString,
      },
    } as gql_GetInteractionsQueryVariables
  }, [id, searchString, showDetails, type])

  const { data: userData } = useGetUserMeQuery({
    fetchPolicy: 'cache-first',
  })

  const [createMutation] = useCreateCommentMutation()
  const [deleteMutation] = useDeleteCommentMutation()
  const [updateMutation] = useUpdateCommentMutation()

  const handleDelete = React.useCallback<HandleDelete>(
    (comment) => {
      deleteMutation({
        variables: {
          id: comment.id,
        },
        update: (cache) => {
          cache.evict({
            id: cache.identify(comment),
          })
        },
      })
    },
    [deleteMutation]
  )

  const handleUpdate = React.useCallback<HandleUpdate>(
    (id: string, comment: string) => {
      updateMutation({
        variables: {
          id,
          comment,
        },
      })
    },
    [updateMutation]
  )

  const handleCreate = React.useCallback<HandleCreate>(
    (comment: string, done) => {
      if (!comment) {
        return
      }

      createMutation({
        variables: {
          on: {
            type,
            id,
          },
          comment,
        },
        update: (cache, { data }) => {
          const createComment = data?.createComment

          if (!createComment) {
            return
          }

          const currentData = cache.readQuery<gql_GetInteractionsQuery>({
            query: QUERY_GET_INTERACTIONS,
            variables,
          })

          cache.writeQuery({
            query: QUERY_GET_INTERACTIONS,
            variables,
            data: produce(currentData, (draftState) => {
              draftState?.interactions?.data.unshift(createComment)
            }),
          })

          done?.()
        },
      })
    },
    [createMutation, id, type, variables]
  )

  const handleClickOutside = React.useCallback(
    (id) => {
      if (window.location.hash && window.location.hash.includes(id)) {
        navigate(
          {
            hash: '',
            search: location.search,
            pathname: location.pathname,
          },
          { replace: true }
        )
      }
    },
    [navigate, location.pathname, location.search]
  )

  const value = useMemo(
    () => ({
      type,
      id,
      variables,
      setSearchString,
      handleDelete,
      handleCreate,
      handleUpdate,
      user: userData?.userMe,
      showDetails,
      setShowDetails,
      handleClickOutside,
    }),
    [
      handleCreate,
      handleDelete,
      handleUpdate,
      id,
      setShowDetails,
      showDetails,
      type,
      userData?.userMe,
      variables,
      handleClickOutside,
    ]
  )

  return <InteractionsContext.Provider value={value}>{children}</InteractionsContext.Provider>
}

export const useInteractions = () => {
  return useContext(InteractionsContext)
}
