import {
  DocumentNode,
  gql,
  OperationVariables,
  QueryResult,
  TypedDocumentNode,
} from '@apollo/client'
import { generateFields, DefaultShowStrategy } from 'chakra-admin'
import { query } from 'gql-query-builder'
import omit from 'lodash.omit'
import { gql_Task, gql_Project, gql_User } from '../graphql'
import { formatDate, resolvePicture } from './utils'

export class EditStrategy extends DefaultShowStrategy {
  getQuery(
    resource: string,
    operation: string,
    variables?: OperationVariables,
    fields?: string[]
  ): DocumentNode | TypedDocumentNode<any, OperationVariables> {
    const result = query({
      operation,
      variables: {
        id: {
          value: variables?.id,
          type: 'ID',
          required: true,
        },
      },
      fields: generateFields(fields),
    })

    return gql`
      ${result.query}
    `
  }

  getItem = (({ data }: QueryResult<any, OperationVariables>): any => {
    const toReturnData =
      data && Object.keys(data).length > 0 && (data as any)[Object.keys(data)[0]]
        ? (data as any)[Object.keys(data)[0]]
        : {}

    switch (toReturnData.__typename) {
      case 'Project': {
        const { ...rest } = toReturnData as gql_Project
        return {
          ...rest,
          startDate: rest.startDate ? new Date(rest.startDate) : undefined,
          endDate: rest.endDate ? new Date(rest.endDate) : undefined,
        }
      }
      case 'Task': {
        const { ...rest } = toReturnData as gql_Task
        return {
          ...rest,
          startDate: formatDate(rest.startDate || ''),
          endDate: formatDate(rest.endDate || ''),
        }
      }

      case 'User': {
        const { ...rest } = toReturnData as gql_User

        return {
          ...rest,
          roleIds: rest.roles?.map((role) => role.id),
        }
      }

      default:
        return toReturnData
    }
  }) as any

  getItemVariables = (id: string): OperationVariables => {
    return { id }
  }

  getMutationVariables = (id: string, values: Record<string, any>): OperationVariables => {
    switch (values.__typename) {
      case 'Project': {
        const {
          pictureId,
          picture,
          createdAt,
          updatedAt,
          tasksCount,
          __typename,
          id: iId,
          ...data
        } = values as gql_Project
        return {
          id,
          data: {
            ...data,
            picture: resolvePicture(picture),
          },
        }
      }

      case 'Task': {
        const {
          createdAt,
          updatedAt,
          __typename,
          // taskCategory,
          project,
          id: iId,
          ...data
        } = values as gql_Task
        return {
          id,
          data: {
            ...data,
            startDate: new Date(data.startDate || '').toISOString(),
            endDate: new Date(data.endDate || '').toISOString(),
          },
        }
      }

      case 'User': {
        const { __typename, picture, ...rest } = values as gql_User

        return {
          id,
          data: {
            ...omit(rest, 'id', 'roles'),
            picture: resolvePicture(picture),
          },
        }
      }

      default:
        const { id: iId, __typename, createdAt, updatedAt, ...data } = values as any
        return {
          id,
          data,
        }
    }
  }
}
