import React, { FC } from 'react'
import { Spinner, Text, Flex, HStack } from '@chakra-ui/react'
import { useTranslate } from 'chakra-admin'

const Loading: FC<any> = () => {
  const t = useTranslate()

  return (
    <Flex width="100%" height="calc(100vh - 89px)" alignItems="center" justifyContent="center">
      <HStack spacing={6} position="relative" bottom={12} left={6}>
        <Spinner color="primary" size="xl" />
        <Text fontSize="2xl">Loading</Text>
      </HStack>
    </Flex>
  )
}

export default Loading
