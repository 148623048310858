import * as React from 'react'

import {
  Checkbox,
  FormControl,
  HStack,
  StackProps,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  ListItem,
  Divider,
} from '@chakra-ui/react'

import { MdKeyboardReturn } from 'react-icons/md'
import { useForm, SubmitHandler } from 'react-hook-form'
import { gql_GetTaskTodosQuery, useCreateTaskTodoMutation } from '../../../graphql'
import { QUERY_GET_TASK_TODOS } from '../../../queries'
import produce from 'immer'
import { useTaskTodo } from './TaskTodoContext'

interface TaskTodoCreateProps {
  containerProps?: StackProps
}

interface FormInputs {
  description: string
}

export const TaskTodoCreate: React.FC<TaskTodoCreateProps> = ({ containerProps }) => {
  const { variables, taskId } = useTaskTodo()
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormInputs>({})

  const [createMutation] = useCreateTaskTodoMutation()

  const onSubmit: SubmitHandler<FormInputs> = async (values) => {
    await createMutation({
      variables: {
        data: {
          description: values.description,
          taskId,
        },
      },
      update: (cache, { data }) => {
        const createTodo = data?.createTaskTodo

        if (!createTodo) {
          return
        }

        const currentData = cache.readQuery<gql_GetTaskTodosQuery>({
          query: QUERY_GET_TASK_TODOS,
          variables,
        })

        cache.writeQuery({
          query: QUERY_GET_TASK_TODOS,
          variables,
          data: produce(currentData, (draftState) => {
            draftState?.taskTodos?.data.unshift(createTodo)
          }),
        })

        reset()
      },
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HStack as={ListItem} {...containerProps}>
          <Checkbox disabled />
          <FormControl isInvalid={!!errors.description}>
            <InputGroup size="sm">
              <Input
                paddingLeft="2"
                size="sm"
                variant="unstyled"
                placeholder="Aggiungi nuovo to do list..."
                _placeholder={{ color: 'gray.300' }}
                {...register('description', { required: true })}
              />
              <InputRightElement>
                <IconButton
                  isLoading={isSubmitting}
                  variant="unstyled"
                  display="flex"
                  justifyContent="center"
                  type="submit"
                  aria-label="submit"
                  size="sm"
                  mr={3}
                  icon={<Icon as={MdKeyboardReturn} />}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </HStack>
      </form>

      <Divider borderBottom="1px solid" opacity={1} borderBottomColor="gray.200" mb={6} mt={4} />
    </>
  )
}
