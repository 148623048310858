import React, { FC } from 'react'
import { Link, useMatch } from 'react-router-dom'
import { chakra, Button, ButtonProps, Tooltip, useBreakpointValue } from '@chakra-ui/react'

type Props = {
  to: string
  label: string
  icon: React.ReactElement
  isCollapsed: boolean
} & ButtonProps

export const MenuItemLink: FC<Props> = ({ to, label, icon, isCollapsed, ...rest }) => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })
  const match = useMatch({
    path: to,
    end: false,
  })

  return (
    <chakra.div as="li" userSelect="none" listStyleType="none">
      <Tooltip
        label={label}
        isDisabled={isMobile || !isCollapsed ? true : false}
        portalProps={{ appendToParentPortal: true }}
      >
        <Button
          h="48px"
          w="100%"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          variant="unstyled"
          as={Link}
          to={to}
          color="gray.900"
          fontWeight="normal"
          isActive={!!match}
          _hover={{
            bgColor: 'primary.100',
            color: 'primary.500',
          }}
          _active={{
            bgColor: 'primary.100',
            color: 'primary.500',
          }}
          pl={3.5}
          pr={3.5}
          borderRadius="xl"
        >
          {icon
            ? React.cloneElement(icon, {
                boxSize: '20px',
                // color: 'gray.900',
                mr: 3.5,
              })
            : undefined}
          {isMobile ? label : isCollapsed ? null : label}
        </Button>
      </Tooltip>
    </chakra.div>
  )
}
