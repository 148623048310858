import React, { FC, useCallback, useMemo, useState } from 'react'
import {
  Avatar,
  AvatarGroup,
  Text,
  Box,
  Flex,
  Icon,
  IconButton,
  Progress,
  HStack,
  useToast,
  CircularProgress,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import produce from 'immer'
import { useTranslate, useVersion } from 'chakra-admin'
import { FiEdit } from 'react-icons/fi'
import { CardTag } from './CardTag'

import { getUserName } from '../../utils/user/getUserName'
import { CardDate } from './CardDate'
import { BsTextParagraph } from 'react-icons/bs'
import { IoIosAttach, IoMdCheckboxOutline } from 'react-icons/io'
import { gql_GetTaskQuery, gql_Task, useCreateTaskAttachmentMutation } from '../../graphql'
import { useDropzone } from 'react-dropzone'
import { QUERY_GET_TASK } from '../../queries'
import { ChatBadge } from '../badges/ChatBadge'
import { CompletableDate } from '../task/CompletableDate'

type Props = {
  showDeleteButton?: boolean
  onDelete?: () => void
  onClick?: () => void
  style?: Record<string, any>
  tagStyle?: Record<string, any>
  className?: string
  id: string
  title: string
  label?: string
  description?: string
  tags?: any[]
  index: number
  cardDraggable: boolean
  editable: boolean
  order: number
  laneId: number
  metadata: gql_Task
}

export const TaskCard: FC<Props> = ({
  showDeleteButton = false,
  onDelete = () => {},
  onClick = () => {},
  style,
  tagStyle,
  className,
  id,
  title,
  label,
  description,
  tags,
  index,
  cardDraggable,
  editable,
  order,
  laneId,
  metadata,
  ...props
}) => {
  const t = useTranslate()
  const toast = useToast()
  const [isUploading, setIsUploading] = useState(false)
  const nextVersion = useVersion()
  const [uploadAttachmentMutation] = useCreateTaskAttachmentMutation()

  const [state, setState] = useState<'idle' | 'hover' | 'editing'>('idle')

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        try {
          setIsUploading(true)

          const results = await Promise.all(
            acceptedFiles.map(async (item) => {
              const { data } = await uploadAttachmentMutation({
                variables: {
                  data: {
                    taskId: id,
                    name: item.name,
                    file: item,
                  },
                },
                update: (cache, { data }) => {
                  const attachment = data?.createTaskAttachment

                  if (!attachment) {
                    return
                  }

                  const currentData = cache.readQuery<gql_GetTaskQuery>({
                    query: QUERY_GET_TASK,
                    variables: { id },
                  })

                  cache.writeQuery({
                    query: QUERY_GET_TASK,
                    variables: { id },
                    data: produce(currentData, (draftState) => {
                      draftState?.task?.attachments?.push(attachment)
                    }),
                  })
                },
              })

              if (data?.createTaskAttachment?.id) {
                return true
              } else {
                return false
              }
            })
          )

          if (results.filter((item) => item === false).length === 0) {
            nextVersion()
            toast({
              title: 'Allegati caricati',
              description: 'I file sono stati caricati correttamente',
              status: 'success',
              duration: 5000,
              isClosable: true,
            })
          } else {
            toast({
              title: 'Errore',
              description: 'Si è verificato un errore durante il caricamento dei file',
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          }
        } catch (e) {
          toast({
            title: 'Errore',
            // eslint-disable-next-line @typescript-eslint/quotes
            description: "Errore nell'upload dei file",
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        } finally {
          setIsUploading(false)
        }
      }
    },
    [setIsUploading, uploadAttachmentMutation, id, nextVersion, toast]
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: handleDrop,
    noClick: true,
  })

  const handleMouseEnter = useCallback(() => {
    setState('hover')
  }, [])

  const handleMouseLeave = useCallback(() => {
    setState((prevState) => (prevState === 'hover' ? 'idle' : prevState))
  }, [])

  const completedTodosCount = useMemo(() => {
    if (metadata?.todos?.length) {
      return metadata?.todos.filter((todo) => todo.isDone).length
    }

    return 0
  }, [metadata?.todos])

  const todosCompletitionPercentage = useMemo(() => {
    if (metadata?.todos?.length) {
      return (completedTodosCount / metadata?.todos.length) * 100
    }

    return 0
  }, [completedTodosCount, metadata?.todos])

  const coverImage = useMemo(() => {
    return metadata?.attachments?.find((attachment) => attachment.isCover)
  }, [metadata?.attachments])

  return (
    <Box
      {...getRootProps()}
      as="article"
      bgColor="white"
      borderRadius="lg"
      //   boxShadow="main"
      pos="relative"
      border="1px solid"
      borderColor="gray.200"
      w="100%"
      boxSizing="border-box"
      mb={1.5}
      px={2}
      py={1}
      cursor="pointer"
      onClick={onClick}
      //   onMouseEnter={handleMouseEnter}
      //   onMouseLeave={handleMouseLeave}
      display="flex"
      flexDir="column"
    >
      <Flex alignItems="center" justifyContent="space-between" mb={2}>
        <Wrap maxW="180px">
          {metadata?.tags?.map((tag) => (
            <WrapItem key={`tag-${tag.id}`}>
              <CardTag value={tag.name} bgColor={tag.color} />
            </WrapItem>
          ))}
        </Wrap>

        {!!metadata?.endDate && <CompletableDate task={metadata} />}
      </Flex>

      {coverImage?.picture?.urlMedium && (
        <Box
          as="img"
          src={coverImage?.picture?.urlMedium}
          alt={coverImage?.name}
          width="100%"
          maxH="100%"
          objectFit="cover"
          objectPosition="center"
          mb={2}
          borderRadius="lg"
        />
      )}

      <Box>{`${metadata?.id ? `#${metadata.id} ` : ''}${title}`}</Box>

      <Flex alignItems="center" mt={2} justifyContent="space-between">
        <HStack spacing={2} flex="1">
          {!!metadata?.commentCount && <ChatBadge count={metadata.commentCount} size="xs" />}
          {metadata?.description?.length ? <Icon as={BsTextParagraph} /> : null}
          {metadata?.attachments?.length ? (
            <HStack spacing={1}>
              <Icon as={IoIosAttach} mr={-1} />
              <Text fontSize="xs" as="span" color="blackAlpha.700" fontWeight="bold">
                {metadata?.attachments?.length || 0}
              </Text>
            </HStack>
          ) : null}

          {metadata?.todos?.length ? (
            <Flex
              alignItems="center"
              flex={todosCompletitionPercentage < 100 ? '1 1 auto' : '0 0 auto'}
              maxW="120px"
              bgColor={todosCompletitionPercentage >= 100 ? 'green.500' : undefined}
              color={todosCompletitionPercentage >= 100 ? 'white' : undefined}
              borderRadius="md"
              px={todosCompletitionPercentage >= 100 ? 1 : undefined}
            >
              {todosCompletitionPercentage > 0 && todosCompletitionPercentage < 100 ? (
                <Progress
                  //   minW="70px"
                  //   w="100%"
                  flex="1"
                  size="sm"
                  borderRadius="full"
                  value={todosCompletitionPercentage}
                  colorScheme="primary"
                  mx={1}
                  mr={2}
                />
              ) : (
                <Icon as={IoMdCheckboxOutline} mr={1} />
              )}
              <Text as="span" fontSize="xs" fontWeight="bold" mr={1}>{`${completedTodosCount}/${
                metadata?.todos.length || 0
              }`}</Text>
            </Flex>
          ) : null}
        </HStack>

        {metadata?.assignees?.length ? (
          <AvatarGroup size="xs" max={2}>
            {metadata?.assignees?.map((assignee) => {
              return (
                <Avatar
                  size="xs"
                  key={`asssignee-${assignee.id}`}
                  name={getUserName(assignee)}
                  src={assignee.picture?.urlSmall}
                />
              )
            })}
          </AvatarGroup>
        ) : null}
      </Flex>

      {state === 'hover' && (
        <IconButton
          pos="absolute"
          size="xs"
          variant="ghost"
          top="2px"
          right="2px"
          borderRadius="full"
          aria-label={t('ca.action.edit')}
          icon={<Icon as={FiEdit} />}
        />
      )}

      <input {...getInputProps()} />
      {isDragActive && (
        <Box
          pos="absolute"
          top="0"
          right="0"
          left="0"
          bottom="0"
          // minH={isModal || isTablet ? '100vh' : '100%'}
          display="flex"
          bgColor="whiteAlpha.800"
          zIndex="10"
          alignItems="center"
          justifyContent="center"
          fontSize="md"
          fontWeight="bold"
        >
          Rilascia i file per caricare.
        </Box>
      )}

      {isUploading && (
        <Box
          pos="absolute"
          top="0"
          right="0"
          left="0"
          bottom="0"
          border="1px solid"
          borderColor="gray.200"
          boxShadow="md"
          borderRadius="md"
          // minH={isModal || isTablet ? '100vh' : '100%'}
          display="flex"
          bgColor="whiteAlpha.800"
          zIndex="10"
          alignItems="center"
          justifyContent="center"
          p={2}
          fontSize="sm"
          fontWeight="bold"
        >
          <CircularProgress size="30px" isIndeterminate color="primary.500" /> Caricamento in
          corso...
        </Box>
      )}
    </Box>
  )
}
