import React, { FC, useCallback, useMemo } from 'react'
import { Box, Skeleton } from '@chakra-ui/react'
import { gql_Project, gql_SortDirection, gql_Tag, useGetTagsQuery } from '../../graphql'
import { useSearchParams } from 'react-router-dom'
import { isDark } from '@chakra-ui/theme-tools'
import { useVersionStateValue } from 'chakra-admin'
import { ActionMeta, GetOptionLabel, MultiValue, Select } from 'chakra-react-select'

type Props = {
  record?: gql_Project
}

const getOptionLabel: GetOptionLabel<Partial<gql_Tag>> = (option) => option?.name!

export const BoardTagsFilter: FC<Props> = ({ record, ...rest }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const version = useVersionStateValue()

  const { data, loading } = useGetTagsQuery({
    variables: {
      projectId: record?.id!,
      pagination: {
        offset: 0,
        limit: 100,
      },
      sort: {
        name: gql_SortDirection.Asc,
      },
    },
    skip: !record?.id,
  })

  const filteredTagIdsParam = searchParams.get('f_tagIds')

  const filteredTagIds = useMemo(() => {
    return filteredTagIdsParam ? filteredTagIdsParam.split(',').filter((item) => !!item) : []
  }, [filteredTagIdsParam])

  const filteredTags = useMemo(
    () =>
      filteredTagIds
        .map((item) => data?.tags?.data?.find((t) => t.id === item))
        .filter((item) => !!item),
    [data?.tags?.data, filteredTagIds]
  )

  const handleChange = useCallback(
    (newValue: MultiValue<Partial<gql_Tag>>, actionMeta: ActionMeta<Partial<gql_Tag>>) => {
      const newSearchParams = new URLSearchParams(searchParams)
      for (let value of newValue) {
        if (filteredTagIds.includes(value.id!)) {
          newSearchParams.set('f_tagIds', filteredTagIds.filter((id) => id !== value.id!).join(','))
        } else {
          newSearchParams.set('f_tagIds', [...filteredTagIds, value.id!].join(','))
        }
      }

      if (newValue.length === 0) {
        newSearchParams.delete('f_tagIds')
      }

      setSearchParams(newSearchParams.toString())
    },
    [filteredTagIds, searchParams, setSearchParams]
  )

  return (
    <Box minW="300px" px={4}>
      <Skeleton w="100%" isLoaded={!loading}>
        <Select
          isMulti
          menuPortalTarget={document?.body}
          isLoading={loading}
          options={data?.tags?.data}
          getOptionLabel={getOptionLabel}
          getOptionValue={(option) => option?.id!}
          value={filteredTags as any[]}
          onChange={handleChange}
          placeholder="Filtra per tag..."
          size="sm"
          chakraStyles={{
            multiValue: (provided, state) => ({
              ...provided,
              backgroundColor: state.data.color,
              color: isDark(state.data.color!)({}) ? 'white' : 'gray.800',
            }),
            menuList: (provided, state) => ({
              ...provided,
              p: 2,
              pr: 8,
            }),
            option: (provided, state) => ({
              ...provided,
              mt: 2,
              borderRadius: 'md',
              bgColor: state.data.color,
              color: isDark(state.data.color!)({}) ? 'white' : 'gray.800',
              ':after': {
                content: state.isFocused ? '"<"' : '""',
                position: 'absolute',
                right: 3,
                color: 'gray.800',
                fontSize: 'lg',
              },
            }),
          }}
        />
      </Skeleton>
    </Box>
  )
}
