import React from 'react'
import {
  Box,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverAnchor,
  PopoverTrigger,
  Portal,
  Tag,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { isDark } from '@chakra-ui/theme-tools'
import { FiTag } from 'react-icons/fi'
import { TagListSelect } from './TagListSelect'
import { gql_Tag, useAddTagToTaskMutation, useRemoveTagFromTaskMutation } from '../../graphql'

export type Task = Pick<gql_Tag, 'id' | 'name' | 'color'>

interface TagSelectProps {
  taskId: string
  projectId: string
  selected?: Task[]
}

export const TagSelect: React.FC<TagSelectProps> = ({ taskId, projectId, selected = [] }) => {
  const [addMutation] = useAddTagToTaskMutation()
  const [removeMutation] = useRemoveTagFromTaskMutation()

  const handleSelect = React.useCallback(
    async (tagId: string, shouldRemove: boolean) => {
      const mutation = shouldRemove ? removeMutation : addMutation

      return mutation({
        variables: {
          taskId,
          tagId,
        },
      })
    },
    [taskId, addMutation, removeMutation]
  )

  return (
    <HStack spacing={2} flex={1} justifyContent="flex-end" alignItems="center">
      <Box>
        <Popover>
          <Wrap as={PopoverTrigger} align="center" spacing="1">
            {selected?.map((tag) => (
              <WrapItem key={tag.id}>
                <Tag bg={tag.color} color={isDark(tag.color)({}) ? 'white' : 'gray.800'} size="sm">
                  {tag.name}
                </Tag>
              </WrapItem>
            ))}
            <PopoverAnchor>
              <WrapItem>
                <IconButton
                  aria-label="add new user"
                  icon={<Icon as={FiTag} />}
                  variant="solid"
                  borderRadius="full"
                  colorScheme="purple"
                  size="xs"
                />
              </WrapItem>
            </PopoverAnchor>
          </Wrap>
          <Portal>
            <TagListSelect projectId={projectId} selected={selected} onSelect={handleSelect} />
          </Portal>
        </Popover>
      </Box>
    </HStack>
  )
}
