import React, { FC } from 'react'
import { BoxProps, IconButton, Icon, Button, ListItem, useDisclosure } from '@chakra-ui/react'

import { Task } from '.'
import { FiEdit2 } from 'react-icons/fi'
import { isDark } from '@chakra-ui/theme-tools'
import { FaCheck, FaChevronRight } from 'react-icons/fa'

type ItemProps = {
  record: Task
  isSelected: boolean
  onClick: (id: string, isIn: boolean) => void
  onEdit: (record: Task) => void
} & Omit<BoxProps, 'onClick'>

export const Item: FC<ItemProps> = ({ onClick, record, isSelected, onEdit }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isBgDark = isDark(record.color)({})
  const color = isBgDark ? 'white' : 'gray.800'

  return (
    <ListItem
      display="flex"
      onMouseEnter={() => onOpen()}
      onMouseLeave={() => onClose()}
      alignItems="center"
      position="relative"
    >
      <Button
        display="flex"
        flex={1}
        bg={record.color}
        color={color}
        _hover={{ opacity: 0.9 }}
        size="sm"
        borderRadius="xl"
        onClick={() => onClick(record.id, isSelected)}
        justifyContent="space-between"
        rightIcon={isSelected ? <Icon as={FaCheck} /> : undefined}
        px="4"
      >
        {record.name}
      </Button>
      <IconButton
        aria-label="edit"
        icon={<Icon as={FiEdit2} />}
        size="sm"
        variant="ghost"
        ml="1"
        onClick={() => onEdit(record)}
      />
      {isOpen && (
        <Icon position="absolute" as={FaChevronRight} color={color} left="1" fontSize="sm" />
      )}
    </ListItem>
  )
}
