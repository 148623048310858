import React, { forwardRef } from 'react'
import { Box, ButtonGroup, Icon } from '@chakra-ui/react'
import { BlockButton } from './BlockButton'
import { GrBlockQuote, GrUnorderedList } from 'react-icons/gr'
import { BiBold, BiHeading, BiItalic, BiUnderline } from 'react-icons/bi'
import { AiOutlineAlignCenter, AiOutlineAlignLeft, AiOutlineAlignRight } from 'react-icons/ai'
import { MarkButton } from './MarkButton'

type Props = {
  children?: React.ReactNode
}

export const Toolbar = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <Box {...props} mb={0} mt={4} ref={ref}>
      <ButtonGroup isAttached mr={2} borderRight="1px solid" borderColor="gray.200" pr={2}>
        <MarkButton label="Grassetto" icon={<Icon as={BiBold} />} format="bold" bg="white" />
        <MarkButton label="Corsivo" icon={<Icon as={BiItalic} />} format="italic" bg="white" />
        <MarkButton
          label="Sottolineato"
          icon={<Icon as={BiUnderline} />}
          format="underline"
          bg="white"
        />
      </ButtonGroup>

      <ButtonGroup isAttached borderRight="1px solid" borderColor="gray.200" pr={2}>
        <BlockButton
          icon={<Icon as={BiHeading} boxSize="15px" />}
          format="heading-one"
          label="Titolo 1"
          bg="white"
        />
        <BlockButton
          icon={<Icon as={BiHeading} boxSize="10px" />}
          format="heading-two"
          label="Titolo 2"
          bg="white"
        />
      </ButtonGroup>

      <ButtonGroup isAttached mx={2} borderRight="1px solid" borderColor="gray.200" pr={2}>
        {/* <BlockButton
              icon={<Icon as={GrOrderedList} />}
              format="ordered-list"
              label="Lista numerata"
            /> */}
        <BlockButton
          icon={<Icon as={GrUnorderedList} />}
          format="bulleted-list"
          label="Elenco puntato"
          bg="white"
        />
      </ButtonGroup>

      <ButtonGroup borderRight="1px solid" borderColor="gray.200" pr={2}>
        <BlockButton
          icon={<Icon as={GrBlockQuote} />}
          format="block-quote"
          label="Citazione"
          bg="white"
        />
      </ButtonGroup>

      <ButtonGroup isAttached ml={2}>
        <BlockButton
          icon={<Icon as={AiOutlineAlignLeft} />}
          format="left"
          label="Allinea a sinistra"
          bg="white"
        />
        <BlockButton
          icon={<Icon as={AiOutlineAlignCenter} />}
          format="center"
          label="Allinea al centro"
          bg="white"
        />
        <BlockButton
          icon={<Icon as={AiOutlineAlignRight} />}
          format="right"
          label="Allinea a destra"
          bg="white"
        />
      </ButtonGroup>
    </Box>
  )
})
