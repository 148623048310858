import React from 'react'
import {
  Box,
  BoxProps,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  List,
  Stack,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react'
import { gql_InteractionType, useGetInteractionsQuery } from '../../graphql'
import { InteractionItem } from './InteractionItem'
import { CommentItem } from './CommentItem'
import { useInteractions } from './InteractionContext'
import { SearchBox } from '../search-box/SearchBox'
import { FiRefreshCw } from 'react-icons/fi'
import { NetworkStatus } from '@apollo/client'
import { CommentInput } from './CommentInput'
import { BiHide, BiShow } from 'react-icons/bi'
import { InteractionSubscription } from './InteractionSubscription'

export const InteractionList: React.FC<BoxProps & { subscribable?: boolean }> = (props) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { variables, type, id, showDetails, setShowDetails, setSearchString } = useInteractions()
  const scrolled = React.useRef(false)
  const { data, networkStatus, refetch } = useGetInteractionsQuery({
    variables,
    skip: !id,
    // it could generate a loop
    onCompleted: (data) => {
      const commentId =
        window.location.hash.startsWith('#comment-') &&
        window.location.hash.replace('#comment-', '')
      if (
        scrolled.current ||
        !commentId ||
        !data.interactions?.data?.some((i) => i.id === commentId)
      ) {
        return
      }

      scrolled.current = true

      setTimeout(() => {
        const element = document.getElementById(window.location.hash.replace('#', ''))

        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
          })
        }
      }, 100)
    },
  })

  return (
    <Box {...props}>
      <Flex
        marginBottom="4"
        flexDir={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems={{ base: 'flex-start', md: 'center' }}
        w={{ base: '100%', md: 'unset' }}
      >
        <Stack isInline alignItems="center" gap="2">
          <Heading size="md">Interazioni</Heading>
          {props.subscribable && <InteractionSubscription type={type} id={id} minimal />}
        </Stack>
        <Stack
          isInline
          // flex={1}
          justifyContent={{ base: 'unset', md: 'flex-end' }}
          w={{ base: '100%', md: 'unset' }}
          mt={{ base: 3, md: 0 }}
        >
          <Box w={{ base: '100%', md: 'unset' }}>
            <SearchBox
              doSearch={setSearchString}
              m={0}
              bgColor="white"
              borderWidth="1px"
              size="sm"
              marginRight="2"
              _focus={{ bgColor: 'white' }}
            />
          </Box>

          <Box>
            {isMobile ? (
              <Tooltip label={showDetails ? 'Nascondi Dettagli' : 'Mostra Dettagli'}>
                <IconButton
                  size="sm"
                  borderRadius="xl"
                  bgColor="gray.50"
                  aria-label="Show/hide details"
                  icon={<Icon as={showDetails ? BiHide : BiShow} boxSize="18px" color="gray.700" />}
                  onClick={() => setShowDetails(!showDetails)}
                />
              </Tooltip>
            ) : (
              <Button
                size="sm"
                borderRadius="xl"
                bgColor="gray.50"
                _hover={{ bgColor: 'gray.100' }}
                onClick={() => setShowDetails(!showDetails)}
              >
                {showDetails ? 'Nascondi dettagli' : 'Mostra dettagli'}
              </Button>
            )}
          </Box>

          <IconButton
            size="sm"
            borderRadius="xl"
            bgColor="gray.50"
            marginLeft="2"
            _hover={{ bgColor: 'gray.100' }}
            aria-label="Refresh todos list"
            onClick={() => refetch()}
            icon={<Icon as={FiRefreshCw} />}
            isLoading={networkStatus === NetworkStatus.refetch}
          />
        </Stack>
      </Flex>

      <CommentInput />

      <Stack as={List} marginTop="4">
        {data?.interactions?.data.map((interaction) =>
          interaction.type === gql_InteractionType.Comment ? (
            <CommentItem key={interaction.id} interaction={interaction} />
          ) : (
            <InteractionItem key={interaction.id} interaction={interaction} />
          )
        )}
      </Stack>
    </Box>
  )
}
