import React, { FC, useCallback, useMemo } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  IconButtonProps,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Skeleton,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'

import {
  ImFileEmpty,
  ImFileExcel,
  ImFilePdf,
  ImFilePicture,
  ImFilePlay,
  ImFileWord,
  ImFileZip,
} from 'react-icons/im'
import { format } from 'date-fns'
import { FiMoreVertical, FiUpload } from 'react-icons/fi'
import { FaFileImage } from 'react-icons/fa'
import { useTaskAttachments } from './TaskAttachmentsDropzone'
import { gql_Task, gql_TaskAttachment, useDeleteTaskAttachmentMutation } from '../../graphql'
import { useTaskUploadAttachmentStateValue } from '../../utils/task/taskUploadAttachmentsState'
import { MUTATION_UPDATE_TASK_ATTACHMENT } from '../../queries'
import { GenericMoreMenuButton } from 'chakra-admin'
import { acceptedImageTypes } from '../../utils/task/attachment-types'

type Props = {
  task?: gql_Task
  showAlways?: boolean
  isMobile?: boolean
}

const ITEM_WIDTH = '250px'
const ITEM_HEIGHT = '60px'

export const TaskAttachmentsList: FC<Props> = ({
  task,
  showAlways = false,
  isMobile,
  ...props
}) => {
  const isUploading = useTaskUploadAttachmentStateValue()
  const open = useTaskAttachments()
  if (task?.attachments?.length === 0 && !isUploading && !showAlways) {
    return null
  }

  const render = () => {
    /* if (!task?.attachments?.length) {
      return (
        <Text fontSize="sm" color="gray.500" p={4}>
          Rilascia qui i file da allegare
        </Text>
      )
    } */

    return (
      <>
        {task?.attachments && task?.attachments?.length > 0 && (
          <Wrap direction="row" p={4}>
            {task?.attachments?.map((item) => {
              return <TaskAttachmentItem key={`task-attachment-${item.id}`} record={item} />
            })}

            {isUploading && <Skeleton w={ITEM_WIDTH} h={ITEM_HEIGHT} borderRadius="md" />}
          </Wrap>
        )}
      </>
    )
  }

  return (
    <Flex flexDir="column" px={{ base: 4, lg: 8 }}>
      <Flex justifyContent="space-between">
        <Heading size="md" color="gray.600" fontWeight="400">
          Allegati
        </Heading>
        {task?.attachments && task?.attachments.length > 0 && (
          <Button size="sm" leftIcon={<Icon as={FiUpload} />} onClick={open}>
            Upload
          </Button>
        )}
      </Flex>
      {task?.attachments && task?.attachments.length === 0 && (
        <Flex mt={4} flexDir="column" justifyContent="space-between" alignItems="center">
          <Flex
            onClick={open}
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            border="1px dashed"
            borderColor="gray.300"
            bgColor="gray.50"
            w="100%"
            borderRadius="xl"
            py={4}
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              p={3}
              borderRadius="3xl"
              bg="gray.200"
              w="fit-content"
            >
              <Icon as={FaFileImage} color="gray.500" boxSize={5} />
            </Flex>
            <Text color="gray.500" fontSize="sm" mt={4}>
              <Text as="span" color="primary.500">
                Click to browse
              </Text>{' '}
              {!isMobile && 'or drag and drop'}
            </Text>
            <Text color="gray.300" fontSize="xs" mt={1}>
              JPG, PNG, GIF or PDF (max. 50MB)
            </Text>
          </Flex>
        </Flex>
      )}

      {render()}
    </Flex>
  )
}

export const TaskAttachmentItem: FC<{ record: gql_TaskAttachment }> = ({
  children,
  record,
  ...props
}) => {
  return (
    <WrapItem>
      <Box
        w={ITEM_WIDTH}
        h={ITEM_HEIGHT}
        overflow="hidden"
        borderRadius="md"
        border="1px solid"
        borderColor="gray.200"
        p={2}
        display="flex"
        pos="relative"
      >
        <MimeTypeIcon mimeType={record.mimeType!} />
        <Box>
          <Link isExternal href={record.url} noOfLines={1} fontSize="sm" maxW="170px">
            {record.name}
          </Link>
          <Text color="gray.300" noOfLines={1} fontSize="xs">
            Caricato il: {format(new Date(record.createdAt), 'dd/MM/yyyy HH:mm')}
          </Text>
        </Box>
        <TaskAttachmentMenu record={record} pos="absolute" right="1px" top="1px" />
      </Box>
    </WrapItem>
  )
}

const getIconComponentByMimeType = (mimeType: string) => {
  switch (mimeType) {
    case 'application/pdf':
      return ImFilePdf
    // Word Documents
    case 'application/vnd.oasis.opendocument.text':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/msword':
      return ImFileWord
    // PowerPoint Documents
    case 'application/vnd.oasis.opendocument.presentation':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.ms-powerpoint':
      return ImFilePlay
    // Excel Document
    case 'application/vnd.oasis.opendocument.spreadsheet':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.ms-excel':
      return ImFileExcel
    // Archives
    case 'application/x-tar':
    case 'application/x-bzip':
    case 'application/x-bzip2':
    case 'application/x-rar-compressed':
    case 'application/zip':
    case 'application/x-7z-compressed':
      return ImFileZip
    // Images
    case 'image/jpeg':
    case 'image/gif':
    case 'image/png':
    case 'image/svg+xml':
      return ImFilePicture
    case 'video/quicktime':
      return ImFilePlay
    default:
      return ImFileEmpty
  }
}

export const MimeTypeIcon: FC<{ mimeType: string }> = ({ mimeType, ...props }) => {
  const FoundedIcon = useMemo(
    () => (mimeType ? getIconComponentByMimeType(mimeType) : ImFileEmpty),
    [mimeType]
  )

  return (
    <Box
      minW="40px"
      h="40px"
      overflow="hidden"
      border="1px solid"
      borderColor="gray.200"
      display="flex"
      alignItems="center"
      justifyContent="center"
      mr={2}
    >
      {FoundedIcon && <Icon as={FoundedIcon} />}
    </Box>
  )
}

export const TaskAttachmentMenu: FC<
  { record: gql_TaskAttachment } & Omit<IconButtonProps, 'aria-label'>
> = ({ record, ...props }) => {
  const handleStopPropagation = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
  }, [])

  const [deleteAttachmentMutation] = useDeleteTaskAttachmentMutation()

  const handleDelete = React.useCallback(() => {
    deleteAttachmentMutation({
      variables: {
        id: record!.id!,
      },
      update: (cache) => {
        cache.evict({
          id: cache.identify(record),
        })
      },
    })
  }, [deleteAttachmentMutation, record])

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        variant="ghost"
        // ml={2}
        aria-label=""
        onClick={handleStopPropagation}
        icon={<Icon as={FiMoreVertical} boxSize="12px" />}
        size="xs"
        borderRadius="full"
        {...props}
      />

      <Portal>
        <MenuList>
          <MenuItem disabled fontSize="xs">
            Commento
          </MenuItem>
          {acceptedImageTypes.includes(record.mimeType!) && (
            <MenuItem disabled fontSize="xs">
              Imposta come copertina
            </MenuItem>
          )}
          <MenuItem fontSize="xs" onClick={handleDelete}>
            Elimina
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
}
