import React, { useMemo } from 'react'
import {
  Avatar,
  AvatarGroup,
  Box,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverTrigger,
  Portal,
  useBreakpointValue,
} from '@chakra-ui/react'
import { UserCard } from './UserCard'
import { FiPlus } from 'react-icons/fi'
import { UserListSelect } from './UserListSelect'
import { useAddAssigneeToTaskMutation, useRemoveAssigneeFromTaskMutation } from '../../graphql'
import { getUserName } from '../../utils/user/getUserName'
import { useHasPermission } from '../../utils/auth/permissions'

export interface User {
  id: string
  firstName?: string
  lastName?: string
  picture?: {
    urlSmall?: string
  }
}

interface UserSelectProps {
  taskId: string
  users?: User[]
}

export const UserSelect: React.FC<UserSelectProps> = ({ taskId, users = [] }) => {
  const [addMutation] = useAddAssigneeToTaskMutation()
  const [removeMutation] = useRemoveAssigneeFromTaskMutation()

  const hasPermissionFn = useHasPermission()

  const hasRemoveUserPermission = useMemo(() => {
    return hasPermissionFn('removeAssigneeFromTask')
  }, [hasPermissionFn])

  const handleSelect = React.useCallback(
    async (userId: string, shouldRemove: boolean) => {
      const mutation = shouldRemove ? removeMutation : addMutation

      return mutation({
        variables: {
          taskId,
          userId,
        },
      })
    },
    [taskId, addMutation, removeMutation]
  )

  const isUserVisible = useBreakpointValue({
    base: false,
    md: true,
  })

  return (
    <HStack spacing={2} flex={1} justifyContent="flex-end" alignItems="center">
      <Box>
        <Popover>
          <PopoverTrigger>
            <IconButton
              aria-label="add new user"
              icon={<Icon as={FiPlus} fontSize={{ base: 'xs', md: 'sm' }} color="gray.300" />}
              size={isUserVisible ? 'xs' : 'xxs'}
              variant="outline"
              borderRadius="50%"
              borderStyle="dashed"
              borderColor="gray.300"
              colorScheme="blackAlpha"
            />
          </PopoverTrigger>
          <Portal>
            <UserListSelect selected={users} onSelect={handleSelect} taskId={taskId} />
          </Portal>
        </Popover>
      </Box>
      <Box>
        <AvatarGroup size={isUserVisible ? 'sm' : 'xs'} max={isUserVisible ? undefined : 1}>
          {users.map((user) => (
            <Box key={user.id}>
              <Popover trigger="hover">
                <PopoverTrigger>
                  <Avatar
                    size={isUserVisible ? 'sm' : 'xs'}
                    name={getUserName(user)}
                    src={user.picture?.urlSmall}
                  />
                </PopoverTrigger>
                <UserCard
                  user={user}
                  onDelete={(id: string) => {
                    handleSelect(id, true)
                  }}
                  hasRemove={hasRemoveUserPermission}
                />
              </Popover>
            </Box>
          ))}
        </AvatarGroup>
      </Box>
    </HStack>
  )
}
