import * as React from 'react'

import { Input, InputProps } from '@chakra-ui/react'
import { useDebounce } from 'react-use'

interface SearchProps extends InputProps {
  doSearch: (searchString?: string) => void
  onEnter?: (searchString?: string) => void
}

export interface SearchBoxHandle {
  clear: () => void
}

export const SearchBoxComponent: React.ForwardRefRenderFunction<SearchBoxHandle, SearchProps> = (
  { doSearch, placeholder, onEnter, ...rest },
  ref
) => {
  const [searchString, setSearchString] = React.useState<string | undefined>()

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value)
  }

  const [, cancel] = useDebounce(
    () => {
      doSearch(searchString)
    },
    500,
    [doSearch, searchString]
  )

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (ev) => {
    if (ev.key === 'Enter') {
      if (onEnter) {
        onEnter(searchString)
      } else {
        cancel()
        doSearch(searchString)
      }
    }
  }

  React.useImperativeHandle(
    ref,
    () => ({
      clear: () => setSearchString(''),
    }),
    []
  )

  return (
    <Input
      placeholder={placeholder ?? 'Cerca...'}
      _placeholder={{
        color: 'gray.300',
      }}
      // _focus={{
      //   bgColor: 'gray.100',
      // }}
      // borderWidth="0"
      // bgColor="gray.100"
      variant="outline"
      color="gray.800"
      borderRadius="xl"
      type="search"
      value={searchString}
      onInput={handleInput}
      onKeyDown={onKeyDown}
      {...rest}
    />
  )
}

export const SearchBox = React.forwardRef(SearchBoxComponent)
