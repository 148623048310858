import { gql } from '@apollo/client'

export const fragments = {
  Role: gql`
    fragment RoleFragment on Role {
      id
      name
      isPublic
      isAdmin
      isLocked
      params
      createdAt
      updatedAt
    }
  `,
  User: gql`
    fragment UserFragment on User {
      id
      email
      firstName
      lastName
      picture {
        id
        urlSmall
      }
      roles {
        id
        name
        params
        isAdmin
      }
    }
  `,
  get Project() {
    return gql`
      fragment ProjectFragment on Project {
        id
        name
        startDate
        endDate
        color
        visibility
        pictureId
        picture {
          id
          name
          urlSmall
          urlMedium
        }
        createdAt
        updatedAt
      }
    `
  },
  TaskStatus: gql`
    fragment TaskStatusFragment on TaskStatus {
      id
      name
      order
      color
      projectId
      createdAt
      updatedAt
    }
  `,

  Task: gql`
    fragment TaskFragment on Task {
      id
      title
      description
      color
      startDate
      endDate
      completionDate
      order
      taskStatusId
      commentCount
      status {
        id
        name
        color
      }
      tags {
        id
        name
        color
      }
      project {
        id
        name
      }
      assignees {
        id
        firstName
        lastName
        picture {
          id
          urlSmall
        }
      }
      todos {
        id
        description
        isDone
        position
        taskId
      }
      attachments {
        id
        isCover
        picture {
          id
          urlMedium
        }
        url
      }
      createdAt
      updatedAt
    }
  `,
  TaskAttachment: gql`
    fragment TaskAttachmentFragment on TaskAttachment {
      id
      name
      url
      taskId
      mimeType
      createdAt
      updatedAt
    }
  `,
  TaskTodo: gql`
    fragment TaskTodoFragment on TaskTodo {
      id
      description
      isDone
      taskId
      position
      createdAt
      updatedAt
    }
  `,

  Interaction: gql`
    fragment InteractionFragment on Interaction {
      id
      type
      data
      createdAt
      user {
        id
        firstName
        lastName
        picture {
          id
          urlSmall
        }
      }
    }
  `,
  PermissionRole: gql`
    fragment PermissionRoleFragment on PermissionRole {
      permissionId
      roleId
      role {
        id
      }
      permission {
        id
      }
    }
  `,
  Permission: gql`
    fragment PermissionFragment on Permission {
      id
      name
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
      }
      roles {
        id
      }
    }
  `,
}

export const QUERY_GET_USER_ME = gql`
  ${fragments.User}

  query GetUserMe {
    userMe {
      ...UserFragment
    }
  }
`

export const MUTATION_LOGIN = gql`
  ${fragments.User}

  mutation Login($credentials: CredentialsInput!) {
    login(credentials: $credentials) {
      ...UserFragment
    }
  }
`

export const MUTATION_LOGOUT = gql`
  mutation Logout {
    logout
  }
`

export const QUERY_GET_ROLES = gql`
  ${fragments.Role}

  query GetRoles(
    $pagination: PaginationInputType
    $sort: RoleSortInput
    $filters: RoleFilterInput
  ) {
    roles(pagination: $pagination, sort: $sort, filters: $filters) {
      total
      data {
        ...RoleFragment
      }
    }
  }
`

export const QUERY_GET_ROLE = gql`
  ${fragments.Role}

  query GetRole($id: ID!) {
    role(id: $id) {
      ...RoleFragment
    }
  }
`

export const MUTATION_CREATE_ROLE = gql`
  ${fragments.Role}
  mutation CreateRole($data: RoleCreateInput!) {
    createRole(data: $data) {
      ...RoleFragment
    }
  }
`

export const MUTATION_UPDATE_ROLE = gql`
  ${fragments.Role}
  mutation EditRole($id: ID!, $data: RoleUpdateInput!) {
    updateRole(id: $id, data: $data) {
      ...RoleFragment
    }
  }
`

export const MUTATION_DELETE_ROLE = gql`
  mutation DeleteRole($id: ID!) {
    deleteRole(id: $id)
  }
`

export const QUERY_GET_USERS = gql`
  query GetUsers(
    $pagination: PaginationInputType
    $sort: UserSortInput
    $filters: UserFilterInput
  ) {
    users(pagination: $pagination, sort: $sort, filters: $filters) {
      total
      data {
        ...UserFragment
      }
    }
  }

  ${fragments.User}
`

export const QUERY_GET_USER = gql`
  ${fragments.User}

  query GetUser($id: ID!) {
    user(id: $id) {
      ...UserFragment
    }
  }
`

export const MUTATION_CREATE_USER = gql`
  ${fragments.User}
  mutation CreateUser($data: UserCreateInput!) {
    createUser(data: $data) {
      ...UserFragment
    }
  }
`

export const MUTATION_UPDATE_USER = gql`
  ${fragments.User}
  mutation EditUser($id: ID!, $data: UserUpdateInput!) {
    updateUser(id: $id, data: $data) {
      ...UserFragment
    }
  }
`

export const MUTATION_DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`

export const MUTATION_ADD_USER_TO_PROJECT = gql`
  mutation AddUserToProject($projectId: ID!, $userId: ID!) {
    addUserToProject(projectId: $projectId, userId: $userId) {
      id
    }
  }
`

export const MUTATION_REMOVE_USER_FROM_PROJECT = gql`
  mutation RemoveUserFromProject($projectId: ID!, $userId: ID!) {
    removeUserFromProject(projectId: $projectId, userId: $userId)
  }
`

// PROJECTS
export const QUERY_GET_PROJECTS = gql`
  query GetProjects(
    $pagination: PaginationInputType
    $sort: ProjectSortInput
    $filters: ProjectFilterInput
  ) {
    projects(pagination: $pagination, sort: $sort, filters: $filters) {
      total
      data {
        ...ProjectFragment
      }
    }
  }

  ${fragments.Project}
`

export const QUERY_GET_PROJECT = gql`
  ${fragments.Project}

  query GetProject($id: ID!) {
    project(id: $id) {
      ...ProjectFragment
    }
  }
`

export const MUTATION_CREATE_PROJECT = gql`
  ${fragments.Project}
  mutation CreateProject($data: ProjectCreateInput!) {
    createProject(data: $data) {
      ...ProjectFragment
    }
  }
`

export const MUTATION_UPDATE_PROJECT = gql`
  ${fragments.Project}
  mutation EditProject($id: ID!, $data: ProjectUpdateInput!) {
    updateProject(id: $id, data: $data) {
      ...ProjectFragment
    }
  }
`

export const MUTATION_DELETE_PROJECT = gql`
  mutation DeleteProject($id: ID!) {
    deleteProject(id: $id)
  }
`

// TASK STATUS
export const QUERY_GET_TASK_STATUSES = gql`
  query GetTaskStatuses(
    $pagination: PaginationInputType
    $sort: TaskStatusSortInput
    $filters: TaskStatusFilterInput
  ) {
    taskStatuses(pagination: $pagination, sort: $sort, filters: $filters) {
      total
      data {
        ...TaskStatusFragment
      }
    }
  }

  ${fragments.TaskStatus}
`

export const QUERY_GET_TASK_STATUS = gql`
  ${fragments.TaskStatus}

  query GetTaskStatus($id: ID!) {
    taskStatus(id: $id) {
      ...TaskStatusFragment
    }
  }
`

export const MUTATION_CREATE_TASK_STATUS = gql`
  ${fragments.TaskStatus}
  mutation CreateTaskStatus($data: TaskStatusCreateInput!) {
    createTaskStatus(data: $data) {
      ...TaskStatusFragment
    }
  }
`

export const MUTATION_UPDATE_TASK_STATUS = gql`
  ${fragments.TaskStatus}
  mutation EditTaskStatus($id: ID!, $data: TaskStatusUpdateInput!) {
    updateTaskStatus(id: $id, data: $data) {
      ...TaskStatusFragment
    }
  }
`

export const MUTATION_UPDATE_TASK_STATUSES_ORDER = gql`
  mutation EditTaskStatusesOrder($data: [TaskStatusesOrderUpdateInput!]!) {
    updateTaskStatusesOrder(data: $data)
  }
`

export const MUTATION_DELETE_TASK_STATUS = gql`
  mutation DeleteTaskStatus($id: ID!) {
    deleteTaskStatus(id: $id)
  }
`

export const MUTATION_ADD_ASSIGNEE_TO_TASK = gql`
  mutation AddAssigneeToTask($userId: ID!, $taskId: ID!) {
    addAssigneeToTask(taskId: $taskId, userId: $userId) {
      id
      assignees {
        id
        firstName
        lastName
        picture {
          urlSmall
        }
      }
    }
  }
`

export const MUTATION_REMOVE_ASSIGNEE_FROM_TASK = gql`
  mutation RemoveAssigneeFromTask($taskId: ID!, $userId: ID!) {
    removeAssigneeFromTask(taskId: $taskId, userId: $userId) {
      id
      assignees {
        id
        firstName
        lastName
        picture {
          urlSmall
        }
      }
    }
  }
`

// TASK
export const QUERY_GET_TASKS = gql`
  query GetTasks(
    $pagination: PaginationInputType
    $sort: TaskSortInput
    $filters: TaskFilterInput
  ) {
    tasks(pagination: $pagination, sort: $sort, filters: $filters) {
      total
      data {
        ...TaskFragment
      }
    }
  }

  ${fragments.Task}
`

export const QUERY_GET_TASK = gql`
  ${fragments.Task}

  query GetTask($id: ID!) {
    task(id: $id) {
      ...TaskFragment
      attachments {
        id
        name
        url
        createdAt
        updatedAt
        mimeType
      }
    }
  }
`

export const MUTATION_CREATE_TASK = gql`
  ${fragments.Task}
  mutation CreateTask($data: TaskCreateInput!) {
    createTask(data: $data) {
      ...TaskFragment
    }
  }
`

export const MUTATION_UPDATE_TASK = gql`
  ${fragments.Task}
  mutation EditTask($id: ID!, $data: TaskUpdateInput!) {
    updateTask(id: $id, data: $data) {
      ...TaskFragment
    }
  }
`

export const MUTATION_UPDATE_TASK_ORDER = gql`
  mutation EditTaskOrder($data: [TaskOrderUpdateInput!]!) {
    updateTaskOrder(data: $data)
  }
`

export const MUTATION_DELETE_TASK = gql`
  mutation DeleteTask($id: ID!) {
    deleteTask(id: $id)
  }
`

// TASK ATTACHMENT
export const QUERY_GET_TASK_ATTACHMENTS = gql`
  query GetTaskAttachments(
    $pagination: PaginationInputType
    $sort: TaskAttachmentSortInput
    $filters: TaskAttachmentFilterInput
  ) {
    taskAttachments(pagination: $pagination, sort: $sort, filters: $filters) {
      total
      data {
        ...TaskAttachmentFragment
      }
    }
  }

  ${fragments.TaskAttachment}
`

export const QUERY_GET_TASK_ATTACHMENT = gql`
  ${fragments.TaskAttachment}

  query GetTaskAttachment($id: ID!) {
    taskAttachment(id: $id) {
      ...TaskAttachmentFragment
    }
  }
`

export const MUTATION_CREATE_TASK_ATTACHMENT = gql`
  ${fragments.TaskAttachment}
  mutation CreateTaskAttachment($data: TaskAttachmentCreateInput!) {
    createTaskAttachment(data: $data) {
      ...TaskAttachmentFragment
    }
  }
`

export const MUTATION_UPDATE_TASK_ATTACHMENT = gql`
  ${fragments.TaskAttachment}
  mutation EditTaskAttachment($id: ID!, $data: TaskAttachmentUpdateInput!) {
    updateTaskAttachment(id: $id, data: $data) {
      ...TaskAttachmentFragment
    }
  }
`

export const MUTATION_DELETE_TASK_ATTACHMENT = gql`
  mutation DeleteTaskAttachment($id: ID!) {
    deleteTaskAttachment(id: $id)
  }
`

// TASK TODO

export const QUERY_GET_TASK_TODOS = gql`
  query GetTaskTodos(
    $taskId: ID!
    $pagination: PaginationInputType
    $sort: TaskTodoSortInput
    $filters: TaskTodoFilterInput
  ) {
    taskTodos(taskId: $taskId, pagination: $pagination, sort: $sort, filters: $filters) {
      total
      data {
        ...TaskTodoFragment
      }
    }
  }

  ${fragments.TaskTodo}
`

export const QUERY_GET_TASK_TODO = gql`
  ${fragments.TaskTodo}

  query GetTaskTodo($id: ID!) {
    taskTodo(id: $id) {
      ...TaskTodoFragment
    }
  }
`

export const MUTATION_CREATE_TASK_TODO = gql`
  ${fragments.TaskTodo}
  mutation CreateTaskTodo($data: TaskTodoCreateInput!) {
    createTaskTodo(data: $data) {
      ...TaskTodoFragment
    }
  }
`

export const MUTATION_UPDATE_TASK_TODO = gql`
  ${fragments.TaskTodo}
  mutation EditTaskTodo($id: ID!, $data: TaskTodoUpdateInput!) {
    updateTaskTodo(id: $id, data: $data) {
      ...TaskTodoFragment
    }
  }
`

export const MUTATION_DELETE_TASK_TODO = gql`
  mutation DeleteTaskTodo($id: ID!) {
    deleteTaskTodo(id: $id)
  }
`

export const MUTATION_UPDATE_TASK_TODO_POSITION = gql`
  mutation updateTaskTodoPosition($id: ID!, $over: ID!) {
    updateTaskTodoPosition(id: $id, over: $over) {
      id
      position
    }
  }
`

export const QUERY_GET_INTERACTIONS = gql`
  query GetInteractions(
    $on: InteractableInput!
    $pagination: PaginationInputType
    $sort: InteractionSortInput
    $filters: InteractionFilterInput
  ) {
    interactions(on: $on, pagination: $pagination, sort: $sort, filters: $filters) {
      total
      data {
        ...InteractionFragment
      }
    }
  }

  ${fragments.Interaction}
`

export const MUTATION_CREATE_COMMENT = gql`
  ${fragments.Interaction}
  mutation CreateComment($on: InteractableInput!, $comment: String!) {
    createComment(on: $on, comment: $comment) {
      ...InteractionFragment
    }
  }
`

export const MUTATION_UPDATE_COMMENT = gql`
  ${fragments.Interaction}
  mutation UpdateComment($id: ID!, $comment: String!) {
    updateComment(id: $id, comment: $comment) {
      ...InteractionFragment
    }
  }
`

export const MUTATION_DELETE_COMMENT = gql`
  mutation DeleteComment($id: ID!) {
    deleteComment(id: $id)
  }
`

export const MUTATION_CREATE_NOTIFICATION_TOKEN = gql`
  mutation createNotificationToken {
    createNotificationToken
  }
`

export const MUTATION_SUBSCRIBE = gql`
  mutation Subscribe($to: InteractableInput!) {
    subscribe(to: $to)
  }
`

export const MUTATION_UNSUBSCRIBE = gql`
  mutation Unsubscribe($to: InteractableInput!) {
    unsubscribe(to: $to)
  }
`

export const QUERY_IS_SUBSCRIBED = gql`
  query IsSubscribed($to: InteractableInput!) {
    isSubscribed(to: $to)
  }
`

export const QUERY_GET_PERMISSIONS = gql`
  ${fragments.Permission}
  query GetPermissions(
    $filters: PermissionFilterInput
    $pagination: PaginationInputType
    $sort: PermissionSortInput
  ) {
    permissions(pagination: $pagination, filters: $filters, sort: $sort) {
      total
      data {
        ...PermissionFragment
      }
    }
  }
`
