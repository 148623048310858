import React from 'react'
import DatePickerI, { ReactDatePickerProps } from 'react-datepicker'
import it from 'date-fns/locale/it'

import 'react-datepicker/dist/react-datepicker.css'
import { chakra, Input, InputProps } from '@chakra-ui/react'

const DatePicker: any = DatePickerI

interface DatetimePickerProps extends ReactDatePickerProps {
  inputProps?: Omit<InputProps, 'onChange' | 'value'>
}

const DatetimePickerComponent: React.ForwardRefRenderFunction<DatePickerI, DatetimePickerProps> = (
  { inputProps, ...props },
  ref
) => {
  return (
    <chakra.div>
      <DatePicker
        ref={ref}
        locale={it}
        dateFormat="dd/MM/yyyy"
        customInput={<Input autocomplete="off" {...inputProps} />}
        {...props}
      />
    </chakra.div>
  )
}

export const DatetimePicker = React.forwardRef(DatetimePickerComponent)
