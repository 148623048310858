import React, { useMemo } from 'react'
import { useTranslate } from 'chakra-admin'
import { Avatar, Box, ListItem, Stack, Text } from '@chakra-ui/react'
import { gql_InteractionType } from '../../graphql'
import { Time } from '../time/Time'
import { InteractionUpdates } from './InteractionUpdates'

interface InteractionItemProps {
  interaction: {
    id: string
    type: gql_InteractionType
    data?: any
    createdAt: string
    user: {
      id: string
      firstName?: string
      lastName?: string
      picture?: {
        id?: string
        urlSmall?: string
      }
    }
  }
}

export const InteractionItem: React.FC<InteractionItemProps> = ({ interaction }) => {
  const t = useTranslate()
  const name = `${interaction.user.firstName} ${interaction.user.lastName}`
  const userPicture = useMemo(
    () => interaction.user.picture?.urlSmall,
    [interaction.user.picture?.urlSmall]
  )

  return (
    <Stack as={ListItem} isInline alignItems="flex-start">
      <Avatar size="sm" name={name} src={userPicture} />
      <Box ml="2" w="100%">
        <Box>
          <Text fontSize="md" fontWeight="600" display="inline">
            {name}
          </Text>
          <Text display="inline" fontSize="sm">
            {' '}
            {t(`resources.Interaction.types.${interaction.type}`, {
              ...(interaction.data || {}),
            })}
          </Text>
        </Box>
        <InteractionUpdates interaction={interaction} />
        <Time fontSize="sm" lineHeight="short" color="gray.300">
          {interaction.createdAt}
        </Time>
      </Box>
    </Stack>
  )
}
