import React, { FC, useCallback, useState } from 'react'
import {
  Button,
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  EditableProps,
  EditableTextarea,
  IconButton,
  Input,
  Tooltip,
  useColorModeValue,
  useEditableControls,
  useEditableState,
  useToast,
} from '@chakra-ui/react'
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { useApolloClient } from '@apollo/client'
import { useVersion } from 'chakra-admin'
import { gql_EditTaskMutation, gql_EditTaskMutationVariables, gql_Task } from '../../graphql'
import { MUTATION_UPDATE_TASK } from '../../queries'
import ResizeTextarea from 'react-textarea-autosize'

export const EditableControls: FC = (props) => {
  const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } =
    useEditableControls()

  if (isEditing) {
    return (
      <ButtonGroup orientation="vertical" size="xs" ml={2} mr={4} mt={2.5}>
        <IconButton aria-label="conferma" icon={<CheckIcon />} {...getSubmitButtonProps()} />
        <IconButton
          aria-label="annulla"
          icon={<CloseIcon boxSize={3} />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    )
  } else {
    return null
  }
}

export const EditableTextareaComponent: FC = (props) => {
  const { onSubmit } = useEditableState()
  const [isShiftPressed, setIsShiftPressed] = useState(false)

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.code === 'ShiftRight' || event.code === 'ShiftLeft') {
      setIsShiftPressed(true)
    }
    if (event.code === 'Enter' && !isShiftPressed) {
      onSubmit()
    } else {
      return null
    }
  }
  const handleKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.code === 'ShiftRight' || event.code === 'ShiftLeft') {
      setIsShiftPressed(false)
    }
  }

  return (
    <EditableTextarea
      py={2}
      px={3}
      fontSize="3xl"
      fontWeight="600"
      border="1.5px solid"
      borderColor="gray.200"
      h="100%"
      maxRows={4}
      as={ResizeTextarea}
      onKeyDown={(event) => handleKeyDown(event)}
      onKeyUp={(event) => handleKeyUp(event)}
    />
  )
}

type TaskTitleEditableProps = EditableProps & { task?: gql_Task }

export const TaskTitleEditable: FC<TaskTitleEditableProps> = ({ task, ...props }) => {
  const client = useApolloClient()
  const toast = useToast()
  const nextVersion = useVersion()
  const handleSubmit = useCallback(
    async (value: string) => {
      try {
        const result = await client.mutate<gql_EditTaskMutation, gql_EditTaskMutationVariables>({
          mutation: MUTATION_UPDATE_TASK,
          variables: {
            id: task?.id!,
            data: {
              title: value,
            },
          },
        })

        if (result.data && !result.errors) {
          // toast({
          //   status: 'success',
          //   title: `Attivit.`,
          //   isClosable: true,
          // })

          nextVersion()
        }
      } catch (error) {
        toast({
          title: 'Error',
          description: (error as any).message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    },
    [task?.id, client, nextVersion, toast]
  )

  return (
    <Editable
      // isPreviewFocusable={true}
      selectAllOnFocus={false}
      placeholder="Attività senza nome"
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      defaultValue={task?.title}
      onSubmit={handleSubmit}
      w="100%"
      {...props}
    >
      <Tooltip label="Clicca per modificare" portalProps={{ appendToParentPortal: true }}>
        <EditablePreview
          py={1}
          px={1}
          cursor="pointer"
          fontSize="3xl"
          fontWeight="600"
          border="1px solid transparent"
          w="100%"
          noOfLines={4}
          // fontWeight="bold"
          _hover={{
            background: useColorModeValue('gray.100', 'gray.700'),
          }}
        />
      </Tooltip>
      <EditableTextareaComponent />
      {/*  <EditableTextarea
        py={2}
        px={3}
        fontSize="3xl"
        fontWeight="600"
        border="1.5px solid"
        borderColor="gray.200"
        h="100%"
        maxRows={4}
        as={ResizeTextarea}
      /> */}
      {/* <Input py={2} px={3} fontSize="3xl" fontWeight="600" minH="60px" as={EditableInput} /> */}
      <EditableControls />
    </Editable>
  )
}
