import { Box, Button, Flex, HStack, IconButton, useOutsideClick, VStack } from '@chakra-ui/react'
import React, { FC, useCallback, useRef } from 'react'
import { NewLaneTitleEditor } from './NewLaneTitleEditor'
import { v1 } from 'uuid'
import { useTranslate } from 'chakra-admin'
import { CloseIcon } from '@chakra-ui/icons'

type Props = {
  onCancel?: () => void
  onAdd?: (newLane: any) => void
}

export const NewLaneForm: FC<Props> = ({ onCancel = () => {}, onAdd = () => {}, ...props }) => {
  const t = useTranslate()
  const inputRef = useRef<HTMLTextAreaElement>()
  const formRef = useRef<HTMLFormElement>()
  useOutsideClick({
    ref: formRef as any,
    handler: () => onCancel(),
  })

  const getValue = useCallback(() => inputRef.current?.value, [])

  const handleSave = useCallback(() => {
    onAdd({
      id: v1(),
      title: getValue(),
    })
  }, [getValue, onAdd])

  return (
    <Box
      ref={formRef as any}
      w="300px"
      alignItems="flex-start"
      borderRadius="lg"
      bgColor="gray.100"
      p={2.5}
      m={1.5}
      mt={3.5}
    >
      <NewLaneTitleEditor
        ref={inputRef as any}
        onCancel={onCancel}
        onSave={handleSave}
        autoFocus
        resize="vertical"
        placeholder={t('components.ProjectBoard.createLanePlaceholder')}
        lineHeight="32px"
        _placeholder={{
          color: 'gray.300',
        }}
      />

      <HStack mt={4}>
        <Button size="sm" colorScheme="primary" onClick={handleSave}>
          {t('components.ProjectBoard.confirmCreateLane')}
        </Button>
        <IconButton
          icon={<CloseIcon />}
          aria-label={t('ca.action.cancel')}
          variant="ghost"
          size="sm"
          onClick={onCancel}
        />
      </HStack>
    </Box>
  )
}
