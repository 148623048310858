import React, { useCallback, useMemo } from 'react'
import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react'
import {
  gql_Task,
  useCreateTaskMutation,
  useDeleteTaskMutation,
  //   useDuplicateTaskMutation,
} from '../../graphql'
import { FiCalendar, FiMoreVertical, FiTrash } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { useTranslate, useDeleteWithConfirm, DeleteModal, useVersion } from 'chakra-admin'
import { MUTATION_DELETE_TASK } from '../../queries'
import { IoIosCopy } from 'react-icons/io'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useLocation, useParams } from 'react-router-dom'

interface TaskOptionsProps {
  task: gql_Task
  afterDelete?: () => void
}

const TaskOptionsComponent: React.FC<TaskOptionsProps> = ({ task, afterDelete }) => {
  const [deleteMutation] = useDeleteTaskMutation()
  //   const [duplicateTask] = useDuplicateTaskMutation()
  const location = useLocation()
  const { id } = useParams()
  const nextVersion = useVersion()
  // const calendarState = useCalendarStateValue()
  const t = useTranslate()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const calendarLink = useMemo(() => {
    return `/Project/${task.projectId}/show/calendar?date=${task.startDate}`
  }, [task])

  //   const handleDuplication = React.useCallback(async () => {
  //     const createdTask = await duplicateTask({
  //       variables: {
  //         id: activity.id,
  //       },
  //     })

  //     if (location.pathname.includes('/show/list')) {
  //       // we hare inside a project list
  //       navigate({
  //         pathname: `/Project/${id}/show/list/${createdTask.data?.duplicateTask?.id}`,
  //         search: searchParams.toString(),
  //       })
  //     } else if (location.pathname.includes('/show/calendar')) {
  //       // we hare inside a project calendar
  //       navigate({
  //         pathname: `/Project/${id}/show/calendar/${createdTask.data?.duplicateTask?.id}`,
  //         search: searchParams.toString(),
  //       })
  //     } else {
  //       // we hare inside the global calendar
  //       navigate({
  //         pathname: `/Calendar/${createdTask.data?.duplicateTask?.id}`,
  //         search: searchParams.toString(),
  //       })
  //     }
  //   }, [activity.id, duplicateTask, id, location.pathname, navigate, searchParams])

  const handleDelete = React.useCallback(() => {
    deleteMutation({
      variables: {
        id: task.id,
      },
      update: (cache) => {
        cache.evict({
          id: cache.identify(task),
        })

        nextVersion()
        afterDelete?.()
      },
    })
  }, [deleteMutation, task, nextVersion, afterDelete])

  return (
    <>
      {/* <GenericMoreMenuButton
        hasCalendar
        resource="Task"
        id={activity?.id}
        openEditAsModal
        hasDelete
        onDelete={handleDelete}
        deleteItemMutation={MUTATION_DELETE_TASK}
        calendarLink={calendarLink}
        icon={FiMoreVertical}
        bg="transparent"
        ml={1.5}
        minW={{base: 'unset', md: 8}}
      /> */}
      <Menu isLazy>
        <MenuButton
          as={IconButton}
          aria-label="actions options"
          icon={<Icon as={FiMoreVertical} fontSize="lg" />}
          size="sm"
          variant="ghost"
          colorScheme="blackAlpha"
        />
        <Portal>
          <MenuList>
            <MenuItem icon={<Icon as={IoIosCopy} />} fontSize="sm">
              Duplica
            </MenuItem>
            <MenuItem
              onClick={handleDelete}
              color="red.500"
              fontSize="sm"
              icon={<Icon color="red.400" as={FiTrash} />}
            >
              {t('ca.action.delete')}
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </>
  )
}

export const TaskOptions = React.memo(TaskOptionsComponent, (prev, next) => {
  return prev.task.id === next.task.id
})
