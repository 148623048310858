import React, { FC } from 'react'
import { MUTATION_CREATE_ROLE } from '../../queries'
import { Create } from '../../components/details/Create'
import { RoleForm } from './RoleForm'

export const RoleCreate: FC = (props) => {
  return (
    <Create mutation={MUTATION_CREATE_ROLE} {...props}>
      <RoleForm />
    </Create>
  )
}
