import React, { FC } from 'react'
import { Create } from '../../components/details/Create'
import { MUTATION_CREATE_PROJECT } from '../../queries'
import { ProjectForm } from './ProjectForm'

const redirect = (data: any) => `/Project/${data.createProject.id}/show`

export const ProjectCreate: FC<any> = (props) => {
  return (
    <Create mutation={MUTATION_CREATE_PROJECT} redirect={redirect} {...props}>
      <ProjectForm />
    </Create>
  )
}
