import React, { FC, isValidElement, useCallback, cloneElement } from 'react'
import { Box, chakra, Flex, Grid } from '@chakra-ui/react'
import {
  DataTableValueProps,
  ListProps,
  Pagination,
  useDataTable,
  useGlobalStrategy,
  UseListReturn,
} from 'chakra-admin'

export type DataTableProps<TItem> = Partial<UseListReturn> &
  Partial<ListProps> & {
    renderCard: (data: any) => React.ReactNode
    children?:
      | React.ReactElement<DataTableValueProps<TItem>>[]
      | React.ReactElement<DataTableValueProps<TItem>>
  }

export function CardsGrid<TItem = Record<string, any>>(props: DataTableProps<TItem>) {
  const { renderCard, filtersComponent } = props
  const strategy = useGlobalStrategy()

  const {
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    showBackToTop,
    backToTop,
    state: { pageIndex, pageSize },
  } = useDataTable<TItem>(props) as any

  const data: any[] = strategy?.list.getList(props.queryResult!, 'offset') || []

  // const handleNextPage = useCallback(() => {
  //   props.onPageChange?.({
  //     ...props,
  //     page: (props.page || 0) + 1,
  //   } as any)
  // }, [props])

  // const handlePrevPage = useCallback(() => {
  //   if ((props?.offset || 0) > 0) {
  //     props.onPaginationChange!({
  //       limit: props.limit || 10,
  //       offset: (props.offset || 0) - 1,
  //     })
  //   }
  // }, [props.limit, props.offset, props.onPaginationChange])

  return (
    <chakra.div>
      <Flex alignItems="center" justifyContent="space-between">
        {filtersComponent &&
          isValidElement(filtersComponent) &&
          cloneElement(filtersComponent, {
            ...props,
          })}

        <Box display="flex" justifyContent="flex-end" minW="160px">
          <Pagination
            page={page}
            paginationMode={props.paginationMode!}
            fetching={props.loading}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalRows={props.total}
            showBackToTop={showBackToTop}
            backToTop={backToTop}
          />
        </Box>
      </Flex>
      <chakra.div
        maxW="100%"
        mt={{ base: 2, lg: 5 }}
        mb={10}
        // mr={{ base: 6, lg: 8 }}
        // pl={{ base: 6, lg: 0 }}
      >
        <Grid
          templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
          gap="30px"
          gridAutoFlow="row-dense"
          mb={5}
        >
          {data?.map((i: any, index: any) => {
            return <Box key={index}> {renderCard(i)}</Box>
          })}
        </Grid>
        <Box display="flex" justifyContent="flex-end" minW="160px">
          <Pagination
            page={page}
            paginationMode={props.paginationMode!}
            fetching={props.loading}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalRows={props.total}
            showBackToTop={showBackToTop}
            backToTop={backToTop}
          />
        </Box>
      </chakra.div>
    </chakra.div>
  )
}
