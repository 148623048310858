import { format } from 'date-fns'

export const dateFormatter = Intl.DateTimeFormat(undefined, {
  dateStyle: 'short',
}).format

export const dayMonthFormatter = Intl.DateTimeFormat(undefined, {
  day: '2-digit',
  month: 'short',
}).format

export const currencyFormatter = Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'EUR',
}).format

export const dateOnly = (date?: Date | null) => {
  if (!date) {
    return date
  }
  return format(date, 'yyyy-MM-dd')
}

export const getName = (person: { firstName?: string; lastName?: string }) => {
  return `${person.firstName} ${person.lastName}`
}
