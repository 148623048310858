import React, { FC, useCallback } from 'react'
import { IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react'
import { useSlate } from 'slate-react'
import { isMarkActive, toggleMark } from './helpers'

export type MarkButtonProps = {
  format: string
  label: string
} & Omit<IconButtonProps, 'aria-label'>

export const MarkButton: FC<MarkButtonProps> = ({ format, label, ...props }) => {
  const editor = useSlate()

  const handleClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault()

      toggleMark(editor, format)
    },
    [editor, format]
  )

  return (
    <Tooltip label={label}>
      <IconButton
        size="xs"
        isActive={isMarkActive(editor, format)}
        aria-label={label}
        onClick={handleClick}
        {...props}
      ></IconButton>
    </Tooltip>
  )
}
