import React, { FC, useCallback, useState } from 'react'
import { Box, Button, ButtonProps, CircularProgress, useToast } from '@chakra-ui/react'
import { useTranslate, useVersion } from 'chakra-admin'
import { AddIcon } from '@chakra-ui/icons'
import { useDropzone } from 'react-dropzone'
import { useCreateTaskAttachmentMutation, useCreateTaskMutation } from '../../graphql'
import { useParams } from 'react-router-dom'

type Props = Pick<ButtonProps, 'onClick'> & {
  laneId: string
  t: any
}

export const AddCardLink: FC<Props> = ({ onClick, laneId, t: rtt, ...props }) => {
  const [isUploading, setIsUploading] = useState(false)

  const [createTask] = useCreateTaskMutation()
  const [uploadAttachmentMutation] = useCreateTaskAttachmentMutation()
  const t = useTranslate()
  const toast = useToast()
  const { id: projectId } = useParams()
  const nextVersion = useVersion()

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        try {
          setIsUploading(true)

          const { data: createTaskData } = await createTask({
            variables: {
              data: {
                title: acceptedFiles[0].name,
                projectId,
                order: 999,
                taskStatusId: laneId,
              },
            },
          })

          if (!createTaskData?.createTask) {
            throw new Error('Failed to create task')
          }

          const results = await Promise.all(
            acceptedFiles.map(async (item) => {
              const { data } = await uploadAttachmentMutation({
                variables: {
                  data: {
                    taskId: createTaskData?.createTask?.id!,
                    name: item.name,
                    file: item,
                  },
                },
              })

              if (data?.createTaskAttachment?.id) {
                return true
              } else {
                return false
              }
            })
          )

          if (results.filter((item) => item === false).length === 0) {
            nextVersion()
            toast({
              title: 'Allegati caricati',
              description: 'I file sono stati caricati correttamente',
              status: 'success',
              duration: 5000,
              isClosable: true,
            })
          } else {
            toast({
              title: 'Errore',
              description: 'Si è verificato un errore durante il caricamento dei file',
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          }
        } catch (e) {
          toast({
            title: 'Errore',
            // eslint-disable-next-line @typescript-eslint/quotes
            description: 'Errore nella creazione del task',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        } finally {
          setIsUploading(false)
        }
      }
    },
    [createTask, laneId, nextVersion, projectId, toast, uploadAttachmentMutation]
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: handleDrop,
    noClick: true,
  })

  return (
    <Box pos="sticky" bottom="0px" {...getRootProps()}>
      <Button
        variant="solid"
        onClick={onClick}
        leftIcon={<AddIcon />}
        mt={0}
        p={1}
        w="100%"
        // bgColor="white"
        color="gray.600"
        border="1px solid"
        borderColor="gray.200"
        // boxShadow="xl"
        {...props}
        flex="0 0 auto"
      >
        {t('components.ProjectBoard.addCardLink')}
      </Button>

      <input {...getInputProps()} />
      {isDragActive && (
        <Box
          pos="absolute"
          top="0"
          right="0"
          left="0"
          bottom="0"
          border="1px solid"
          borderColor="gray.200"
          boxShadow="md"
          borderRadius="md"
          // minH={isModal || isTablet ? '100vh' : '100%'}
          display="flex"
          bgColor="whiteAlpha.800"
          zIndex="10"
          alignItems="center"
          justifyContent="center"
          p={2}
          fontSize="sm"
          fontWeight="bold"
        >
          Crea nuova scheda con allegati.
        </Box>
      )}

      {isUploading && (
        <Box
          pos="absolute"
          top="0"
          right="0"
          left="0"
          bottom="0"
          border="1px solid"
          borderColor="gray.200"
          boxShadow="md"
          borderRadius="md"
          // minH={isModal || isTablet ? '100vh' : '100%'}
          display="flex"
          bgColor="whiteAlpha.800"
          zIndex="10"
          alignItems="center"
          justifyContent="center"
          p={2}
          fontSize="sm"
          fontWeight="bold"
        >
          <CircularProgress size="30px" isIndeterminate color="primary.500" /> Creazione in corso...
        </Box>
      )}
    </Box>
  )
}
