import { TreeRenderer } from 'chakra-admin'
import React, { FC, useMemo } from 'react'
import { useHasPermission } from '../../utils/auth/permissions'

type Props = {
  permission: string | string[]
  condition?: 'and' | 'or'
}

export const WithPermission: FC<Props> = ({ children, permission, condition, ...rest }) => {
  const hasPermissionFn = useHasPermission()
  const hasPermission = useMemo(
    () => hasPermissionFn(permission, condition),
    [condition, hasPermissionFn, permission]
  )

  if (!hasPermission) {
    return null
  }

  return <TreeRenderer children={children} propsOverride={rest} />
}
