import { AddIcon } from '@chakra-ui/icons'
import { Box, Button } from '@chakra-ui/react'
import { useTranslate } from 'chakra-admin'
import React, { FC } from 'react'

type Props = {
  onClick: () => void
}

export const NewLaneSection: FC<Props> = ({ onClick }) => {
  const t = useTranslate()

  return (
    <Box w="300px" mx={2}>
      <Button
        leftIcon={<AddIcon />}
        w="100%"
        fontSize="sm"
        fontWeight="normal"
        bgColor="gray.100"
        onClick={onClick}
        borderRadius="lg"
        mt={3.5}
        justifyContent="flex-start"
      >
        {t('components.ProjectBoard.createNewLane')}
      </Button>
    </Box>
  )
}
