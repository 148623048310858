import * as React from 'react'
import {
  Editable,
  EditablePreview,
  EditablePreviewProps,
  EditableProps,
  EditableTextarea,
  EditableTextareaProps,
} from '@chakra-ui/react'

interface EditableDescriptionProps {
  initialValue: string
  allowEmpty?: boolean
  onSubmit: (value: string) => void
  editableProps?: EditableProps
  editablePreviewProps?: EditablePreviewProps
  editableTextareaProps?: EditableTextareaProps
}

export const EditableDescription: React.FC<EditableDescriptionProps> = ({
  initialValue,
  onSubmit,
  allowEmpty = false,
  editableProps,
  editablePreviewProps,
  editableTextareaProps,
}) => {
  const originalValue = React.useRef(initialValue)
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    if (initialValue !== originalValue.current) {
      originalValue.current = initialValue
      setValue(initialValue)
    }
  }, [initialValue, setValue])

  return (
    <Editable
      value={value}
      onChange={(value) => setValue(value)}
      submitOnBlur
      onSubmit={(value) => {
        if (!allowEmpty && !value.trim()) {
          setValue(initialValue)
          return
        }

        if (value === initialValue) {
          return
        }

        onSubmit(value)
      }}
      {...editableProps}
    >
      <EditablePreview
        paddingLeft="2"
        color="gray.500"
        fontWeight="400"
        fontSize="md"
        {...editablePreviewProps}
      />
      <EditableTextarea
        paddingLeft="2"
        color="gray.600"
        fontWeight="400"
        fontSize="md"
        borderWidth="1px"
        required
        {...editableTextareaProps}
      />
    </Editable>
  )
}
