import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

export type TagViewMode = 'showLabel' | 'hideLabel'

export const tagViewModeState = atom<TagViewMode>({
  key: 'tagViewModeState',
  default: 'hideLabel',
})

export const useTagViewModeState = () => useRecoilState(tagViewModeState)

export const useSetTagViewModetState = () => {
  return useSetRecoilState(tagViewModeState)
}

export const useTagViewModeValue = () => {
  return useRecoilValue(tagViewModeState)
}
