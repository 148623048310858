import React, { FC } from 'react'
import {
  List,
  DataTableValue as Field,
  Input,
  DataTable,
  GenericMoreMenuButton,
  ListToolbar,
  OffsetSortDirection,
} from 'chakra-admin'
import { MUTATION_DELETE_PROJECT } from '../../queries'
import { gql_Project, gql_Query } from '../../graphql'
import { PageLayout } from '../../components/details/PageLayout'
import { PageTitle } from '../../components/details/PageTitle'
import { CardsGrid } from '../../components/list/CardsGrid'
import { ProjectCard } from '../../components/cards/ProjectCard'
import { useLocation } from 'react-router-dom'
import { CreateButton } from '../../components/buttons/CreateButton'
import { Flex, Icon, useBreakpointValue } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import { Filters } from '../../components/list/Filters'

const ProjectFilters: FC = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <Flex
      boxShadow={{
        base: 'none',
        lg: '0px 1px 4px rgba(0, 0, 0, 0.05), 0px 6px 24px rgba(0, 0, 0, 0.04), inset 0px 1px 1px rgba(0, 0, 0, 0.04)',
      }}
      borderRadius="xl"
      alignItems="center"
      w="280px"
      mt={{ base: 0, lg: 3 }}
    >
      <Icon as={SearchIcon} color="gray.300" ml={4} display={{ base: 'none', lg: 'inherit' }} />
      <Filters {...props} hideDrawer={isMobile ? false : true}>
        <Input
          source="name"
          label="Name"
          placeholder="Cerca bacheca..."
          bgColor="transparent"
          _hover={{ bgColor: 'transparent' }}
          border="none"
          boxShadow={{
            base: '0px 1px 4px rgba(0, 0, 0, 0.05), 0px 6px 24px rgba(0, 0, 0, 0.04), inset 0px 1px 1px rgba(0, 0, 0, 0.04)',
            lg: 'none',
          }}
          borderRadius="xl"
          alwaysOn={isMobile ? false : true}
        />
      </Filters>
    </Flex>
  )
}

export const ProjectList: FC<any> = (props) => {
  const location = useLocation()
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <List<gql_Query, gql_Project>
      paginationMode="offset"
      query="projects"
      queryOptions={{
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      }}
      fields={['name', 'color', 'picture.id', 'picture.urlMedium', 'visibility']}
      deleteItemMutation={MUTATION_DELETE_PROJECT}
      filtersComponent={<ProjectFilters />}
      defaultSorting={{ name: OffsetSortDirection.ASC }}
      layout={<PageLayout />}
      title={<PageTitle />}
      toolbarComponent={
        <ListToolbar>
          <CreateButton
            state={{
              defaultValues: {
                visibility: 'PRIVATE',
              },
              background: location,
            }}
            label={isMobile ? 'Crea' : true}
            openAsModal
          />
        </ListToolbar>
      }
      {...props}
    >
      {/* <DataTable rowClick="show" moreMenuComponent={<GenericMoreMenuButton openEditAsModal />}>
        <Field<gql_Project> source="id" />
        <Field<gql_Project> source="name" />
        <Field<gql_Project> source="startDate" />
        <Field<gql_Project> source="endDate" />
      </DataTable> */}
      <CardsGrid
        renderCard={(record: gql_Project) => {
          return <ProjectCard project={record} />
        }}
      />
    </List>
  )
}
