/* eslint-disable react-hooks/rules-of-hooks */
import { useApolloClient } from '@apollo/client'
import { useCallback, useEffect } from 'react'
import { atom, useRecoilState, useRecoilValue } from 'recoil'
import {
  gql_GetPermissionsQuery,
  gql_GetPermissionsQueryVariables,
  gql_GetUserMeQuery,
} from '../../graphql'
import { QUERY_GET_PERMISSIONS, QUERY_GET_USER_ME } from '../../queries'

type ActivePermissions = {
  initialized: boolean
  permissions: Record<string, boolean>
  params: Record<string, string | number | boolean>
}

export const activePermissionsState = atom<ActivePermissions>({
  key: 'activePermissions',
  default: {
    initialized: false,
    permissions: {},
    params: {},
  },
})

export const usePermissions = () => useRecoilValue(activePermissionsState)

export const useHasPermission = () => {
  const permissions = usePermissions()

  return useCallback(
    (permission: string | string[], condition: 'and' | 'or' = 'and') => {
      if (!permissions.initialized) {
        return false
      }

      if (typeof permission === 'string') {
        return permissions.permissions[permission]
      }

      if (condition === 'and') {
        return permission.every((p) => permissions.permissions[p])
      } else {
        return permission.some((p) => permissions.permissions[p])
      }
    },
    [permissions.initialized, permissions.permissions]
  )
}

export const useGetRoleParam = () => {
  const permissions = usePermissions()

  return useCallback(
    (param: string) => {
      if (!permissions.initialized) {
        return undefined
      }

      return permissions.params[param]
    },
    [permissions.initialized, permissions.params]
  )
}

export const useInitPermissions = () => {
  const client = useApolloClient()
  const [{ initialized, permissions }, setPermissions] = useRecoilState(activePermissionsState)

  useEffect(() => {
    const initPermissions = async () => {
      const userResult = await client.query<gql_GetUserMeQuery>({
        query: QUERY_GET_USER_ME,
        fetchPolicy: 'no-cache',
      })

      if (!userResult?.data?.userMe?.id) {
        setPermissions({
          initialized: true,
          permissions: {},
          params: {},
        })
        return
      }

      const permissionsResult = await client.query<
        gql_GetPermissionsQuery,
        gql_GetPermissionsQueryVariables
      >({
        query: QUERY_GET_PERMISSIONS,
        variables: {
          pagination: {
            disabled: true,
          },
          filters: {
            roleIds: (userResult?.data?.userMe?.roles || []).map((role) => `${role.id}`),
          },
        },
      })

      if (!permissionsResult?.data?.permissions?.data?.length) {
        setPermissions({
          initialized: true,
          permissions: {},
          params: userResult?.data?.userMe?.roles?.reduce<any>((acc, role) => {
            return {
              ...acc,
              ...role.params,
            }
          }, {}),
        })
        return
      }

      const permissionsMap = permissionsResult.data.permissions.data.reduce(
        (acc: any, permission: any) => {
          return {
            ...acc,
            [`${permission.name}`]: true,
          }
        },
        {}
      )

      if (JSON.stringify(permissionsMap) !== JSON.stringify(permissions)) {
        setPermissions({
          permissions: permissionsMap,
          params: userResult?.data?.userMe?.roles?.reduce<any>((acc, role) => {
            return {
              ...acc,
              ...role.params,
            }
          }, {}),
          initialized: true,
        })
      }
    }

    if (!initialized) {
      initPermissions()
    }

    return () => {
      setPermissions({
        initialized: false,
        permissions: {},
        params: {},
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
