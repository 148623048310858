import React, { FC } from 'react'
import { Text, Heading, OrderedList, UnorderedList, ListItem } from '@chakra-ui/react'
import { Mention } from './withMentions'

type ElementProps = {
  attributes?: any
  element?: any
}

export const Element: FC<ElementProps> = ({ element, children, attributes }) => {
  const style = { textAlign: element.align }

  switch (element.type) {
    case 'block-quote':
      return (
        <Text
          {...attributes}
          {...style}
          fontStyle="italic"
          fontWeight="semibold"
          paddingStart={4}
          my={0}
          borderStartWidth="4px"
          borderStartColor="gray.200"
        >
          {children}
        </Text>
      )

    case 'heading-one':
      return (
        <Heading as="h1" fontSize="3xl" mb={4} {...attributes} {...style} children={children} />
      )

    case 'heading-two':
      return <Heading as="h2" fontSize="xl" mb={2} {...attributes} {...style} children={children} />

    case 'bulleted-list':
      return (
        <UnorderedList {...style} {...attributes}>
          {children}
        </UnorderedList>
      )
    case 'list-item':
      return (
        <ListItem {...style} {...attributes}>
          {children}
        </ListItem>
      )
    case 'ordered-list':
      return (
        <OrderedList {...style} {...attributes}>
          {children}
        </OrderedList>
      )

    case 'mention':
      return <Mention {...{ element, children, attributes }} />

    default:
      return <Text {...attributes} {...style} children={children} />
  }
}
