import * as React from 'react'
import { FC, useCallback, useMemo } from 'react'
import {
  Box,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  StackDivider,
  Text,
  Link as ChakraLink,
  Icon,
  Stack,
  Wrap,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useSearchParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { FiLink } from 'react-icons/fi'
import { gql_InteractableType, useGetTaskQuery } from '../../graphql'
import { TaskAttachmentsDropzone } from './TaskAttachmentsDropzone'
import { TaskOptions } from './TaskOptions'
import { IoIosArrowForward } from 'react-icons/io'
import { TimeRangeInput } from './TimeRangeInput'
import { TaskTitleEditable } from './TaskTitleEditable'
import { UserSelect } from '../user-select'
import { TaskDescriptionEditable } from './TaskDescriptionEditable'
import { TaskTodoList } from './task-todo'
import { TaskAttachmentsList } from './TaskAttachmentsList'
import { Interactions } from '../interactions'
import { TagSelect } from '../tag-select'
import { CompletableDateInput } from './CompletableDateInput'
import { TaskStatusPicker } from './task-status-picker/TaskStatusPicker'
import { InteractionSubscription } from '../interactions/InteractionSubscription'

type TaskFormParams = {
  id: string
  taskId: string
}

type Props = {
  isMobile?: boolean
  isModal?: boolean
  isTablet?: boolean
  boardEventBus?: any
}

export const TaskForm: FC<Props> = ({ isMobile, isModal, isTablet, boardEventBus }) => {
  const navigate = useNavigate()
  const { taskId } = useParams<TaskFormParams>()
  const { data: dataTask } = useGetTaskQuery({
    variables: {
      id: taskId!,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !taskId,
  })

  /* const { data: dataLocations } = useGetLocationsQuery()
  console.log("location", dataLocations?.locations?.data) */

  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const isList = useMemo(() => pathname.includes('/list'), [pathname])

  if (!dataTask?.task) {
    return null
  }

  const { task } = dataTask

  return (
    <TaskAttachmentsDropzone task={task} isModal={isModal} isTablet={isTablet}>
      <Flex w="100%" pb={1} mt={2} alignItems="flex-start" flexDir="column" px={{ base: 4, lg: 8 }}>
        {isMobile && (
          <Flex justifyContent="space-between" alignItems="center" w="100%" mb={1}>
            <Flex>
              {/* <TaskCategoryChooser task={task} /> */}
              <TaskStatusPicker task={task} />
              {/* {!task?.isDone && (
                <Flex
                  bg="green.100"
                  borderRadius="xl"
                  alignItems="center"
                  py={0.5}
                  px={3}
                  h="fit-content"
                  ml={2}
                >
                  <Box w={2} h={2} bg="green.500" borderRadius="xl" mr={2} />
                  <Text color="green.500" fontSize="sm" fontWeight="500" m={0}>
                    Confermato
                  </Text>
                </Flex>
              )} */}
              <InteractionSubscription type="TASK" id={task.id} />
              <ModalCloseButton />
            </Flex>

            <Box mr={8}>
              <TaskOptions
                task={task as any}
                afterDelete={() => {
                  boardEventBus.current?.publish({
                    type: 'REMOVE_CARD',
                    laneId: task.taskStatusId,
                    cardId: task.id,
                  })
                  navigate(`/Project/${task.project?.id}/show`)
                }}
              />
            </Box>
          </Flex>
        )}
        <Flex w="100%" alignItems={{ base: 'center', lg: 'start' }} justifyContent="space-between">
          <Wrap
            w="100%"
            spacingX={{ base: 0, lg: 6 }}
            justify={{ base: 'space-between', lg: 'unset' }}
            // mr={{ base: 0, lg: 8 }}
          >
            <CompletableDateInput task={task} />
            <TagSelect projectId={task.project?.id!} taskId={task.id} selected={task.tags} />
          </Wrap>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" w="100%">
          <Stack
            isInline
            justifyContent="center"
            alignItems="flex-start"
            flex="1"
            mr={4}
            maxW="100%"
          >
            <Text fontSize="3xl" lineHeight="normal" fontWeight="600" mt={2}>
              #{task?.id}
            </Text>
            <TaskTitleEditable task={task as any} flex="1" />
          </Stack>
        </Flex>
      </Flex>
      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        justifyContent="space-between"
        alignItems={{ base: 'unset', lg: 'center' }}
        px={{ base: 4, lg: 8 }}
        mt={{ base: 1, lg: 0 }}
      >
        <Stack
          direction={{ base: 'row', xl: 'row' }}
          divider={<StackDivider />}
          spacing={4}
          /* marginRight="4" */
          maxW={{ base: '100%', lg: '75%' }}
        >
          <ChakraLink
            as={Link}
            to={{
              pathname: `/Project/${task.project?.id}/show/${isList ? 'list' : 'calendar'}`,
              search: searchParams.toString(),
            }}
            fontSize="md"
            textOverflow="ellipsis"
            noOfLines={2}
            overflow="hidden"
          >
            <Text as="span" fontWeight="700">
              Bacheca:{' '}
            </Text>
            {task.project?.name}
            <Icon as={FiLink} fontSize="xs" ml="2" />
          </ChakraLink>
        </Stack>

        <Flex alignItems="center" mt={{ base: 4, lg: 0 }}>
          <UserSelect taskId={task.id} users={task.assignees} />
          {/* <AvatarGroup size="sm" spacing={-2}>
            {task?.assignees?.map((user, index) => (
              <Avatar key={index} name={getUserName(user)} src={user.picture?.urlSmall} />
            ))}
          </AvatarGroup> */}
        </Flex>
      </Flex>
      <Box
        p={4}
        mt={4}
        boxShadow="0px 3px 10px rgba(0, 0, 0, 0.1)"
        // boxShadow="0px 24px 64px rgba(0, 0, 0, 0.1)"
        borderRadius="xl"
        mx={{ base: 4, lg: 8 }}
      >
        <TaskDescriptionEditable task={task as any} />
      </Box>
      <TaskTodoList taskId={task.id} containerProps={{ paddingY: '4' }} />
      <TaskAttachmentsList isMobile={isMobile} task={task as any} showAlways />
      {/* <TaskInteractions
        taskId={task.id}
        containerProps={{
          paddingY: '4',
        }}
      /> */}
      <Interactions type={gql_InteractableType.Task} id={task.id} py="4" mb={8} px={8} />
    </TaskAttachmentsDropzone>
  )
}

export const TaskFormModal: FC<Props & { onAfterClose?: () => void }> = ({
  isMobile = true,
  isModal = true,
  isTablet = true,
  boardEventBus,
  onAfterClose,
}) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const handleClose = useCallback(() => {
    const returnTo = searchParams.get('returnTo')

    if (returnTo) {
      const newParams = new URLSearchParams(searchParams)
      newParams.delete('returnTo')

      navigate({
        pathname: returnTo!,
        search: newParams.toString(),
      })
    } else {
      navigate(-1)
    }

    if (onAfterClose) {
      onAfterClose()
    }
  }, [navigate, onAfterClose, searchParams])

  return (
    <Modal
      motionPreset="none"
      trapFocus={false}
      scrollBehavior="outside"
      isOpen
      onClose={handleClose}
      closeOnEsc
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent bgColor="gray.50" /* pb={{ base: 0, md: 0 }} */>
        <TaskForm
          isMobile={isMobile}
          isModal={isModal}
          isTablet={isTablet}
          boardEventBus={boardEventBus}
        />
      </ModalContent>
    </Modal>
  )
}
