import { Box, Avatar, Heading, Divider, Text, Icon, HStack } from '@chakra-ui/react'
import React, { FC } from 'react'
import { FaUsers } from 'react-icons/fa'

type Props = {
  record?: any
}

const RoleDetailCard: FC<Props> = (props) => {
  return (
    <Box border="1px solid" borderColor="gray.100" borderRadius="lg">
      <HStack p={4} spacing={4}>
        <Box bgColor="gray.100" w="fit-content" p={3} px={4} borderRadius="lg">
          <Icon as={FaUsers} boxSize="30" color="gray.500" />
        </Box>
        <Box>
          <Heading fontSize="md">Role:</Heading>
          <Text fontSize="lg">{`${props.record?.name}`}</Text>
        </Box>
      </HStack>
      <Divider />
      <Box p={4}>
        <Box display="flex" flexDirection="column">
          <Heading fontSize="md">Is admin:</Heading>
          <Text>{props.record.isAdmin ? 'Yes' : 'No'}</Text>
        </Box>
      </Box>
      <Divider />
      <Box p={4}>
        <Box display="flex" flexDirection="column">
          <Heading fontSize="md">Is blocked:</Heading>
          <Text>{props.record.isBlocked ? 'Yes' : 'No'}</Text>
        </Box>
      </Box>
      <Divider />
      <Box p={4}>
        <Box display="flex" flexDirection="column">
          <Heading fontSize="md">Is Public:</Heading>
          <Text>{props.record.isPublic ? 'Yes' : 'No'}</Text>
        </Box>
      </Box>
    </Box>
  )
}

export default RoleDetailCard
