import React, { FC } from 'react'
import { useAuthUser } from 'chakra-admin'
import { useInitPermissions } from '../../utils/auth/permissions'

export const InitPermissions: FC = () => {
  const { initialized } = useAuthUser()
  if (!initialized) {
    return null
  }

  return <InitPermissionsInner />
}

export const InitPermissionsInner: FC = () => {
  useInitPermissions()

  return null
}
