import React, { FC } from 'react'
import {
  IconButton,
  Drawer,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  chakra,
  DrawerHeader,
  DrawerCloseButton,
  Icon,
  Link,
} from '@chakra-ui/react'
import { CgMenuLeft } from 'react-icons/cg'
import { NavMenu } from 'chakra-admin'
import { OfflineAlert } from 'chakra-admin'
import { SidebarTitle } from '../../components/layout/stable/sidebar/SidebarTitle'
import { Link as RouterLink } from 'react-router-dom'

type Props = {
  title?: any
  icon?: any
}
export const MobileTopBarLight: FC<Props> = ({ title, icon, children }) => {
  const { isOpen, onClose, onToggle } = useDisclosure()

  return (
    <>
      <chakra.nav
        position="fixed"
        as="header"
        role="header"
        top="0px"
        right="0px"
        left="0px"
        bgColor="white"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={4}
        pb={3}
        boxShadow="0px 2px 8px rgba(0, 0, 0, 0.06)"
        zIndex="3"
      >
        <Link
          boxSize="48px"
          borderRadius="md"
          as={RouterLink}
          to="/"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgColor="white"
          boxShadow="md"
          ml={5}
        >
          <chakra.img src={icon} w="34px" />
        </Link>

        <chakra.div display="flex">
          <IconButton
            w="45px"
            h="45px"
            ml={2}
            mr={3}
            aria-label="Show Menu"
            variant="outline"
            border="none"
            icon={<CgMenuLeft size="23px" />}
            onClick={onToggle}
          />

          <OfflineAlert bottom="-18px" />
        </chakra.div>
      </chakra.nav>

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              <SidebarTitle />
            </DrawerHeader>
            {/* <DrawerBody px={0}> */}
            <NavMenu onItemClick={onClose} />
            {children}
            {/* </DrawerBody> */}
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}
