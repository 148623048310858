import {
  Box,
  Image,
  Text,
  Flex,
  LinkOverlay,
  LinkBox,
  Icon,
  Tooltip,
  Portal,
} from '@chakra-ui/react'
import React, { FC } from 'react'
import { gql } from '@apollo/client'
import { Link as RouterLink } from 'react-router-dom'
import { FcAddImage } from 'react-icons/fc'
import { GenericMoreMenuButton } from 'chakra-admin'
import { gql_Project } from '../../graphql'
import { TbLock } from 'react-icons/tb'
import { BiLock } from 'react-icons/bi'

type ProjectCardProps = {
  project: gql_Project
}

export const ProjectCard: FC<ProjectCardProps> = ({ project, ...rest }) => {
  const DELETE_PROJECT = gql`
    mutation DeleteProjectMu($id: ID!) {
      deleteProject(id: $id)
    }
  `

  return (
    <Box
      position="relative"
      borderRadius="xl"
      boxShadow="0px 1px 4px rgba(0, 0, 0, 0.05), 0px 6px 24px rgba(0, 0, 0, 0.04), inset 0px 1px 1px rgba(0, 0, 0, 0.04)"
      minH="220px"
    >
      <LinkBox
        display="flex"
        flexDir="column"
        alignItems="center"
        minW="200px"
        position="relative"
        {...rest}
      >
        {project?.picture?.urlMedium ? (
          <Image
            src={project?.picture?.urlMedium}
            w="100%"
            h="153px"
            objectFit="cover"
            objectPosition="center"
            borderTopRightRadius="xl"
            borderTopLeftRadius="xl"
          />
        ) : (
          <Flex
            alignItems="center"
            justifyContent="center"
            w="100%"
            h="153px"
            borderTopRightRadius="xl"
            borderTopLeftRadius="xl"
            bg="gray.100"
          >
            <Icon as={FcAddImage} boxSize={20} />
          </Flex>
        )}
        <Flex
          w="100%"
          h="154px"
          position="absolute"
          borderTopLeftRadius="xl"
          borderTopRightRadius="xl"
          alignItems="flex-start"
          justifyContent="flex-end"
          p={4}
          bg="linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%)"
        ></Flex>
        <Box w="100%" p={4} pt={1}>
          <Text
            bg={project?.color ? `${project?.color}A1` : 'gray.100'}
            w={10}
            h={2}
            px={2}
            py={0.5}
            borderRadius="xl"
          />

          <LinkOverlay as={RouterLink} to={`/Project/${project.id}/show`}>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              minH="20px"
              position="absolute"
            >
              <Text color="gray.600" fontSize={16} mt={3} mb={1} noOfLines={1}>
                {project.visibility === 'PRIVATE' && (
                  <Tooltip label="Bacheca Privata">
                    <Box display="inline">
                      <Icon
                        as={BiLock}
                        boxSize="16px"
                        color="gray.600"
                        mr={2}
                        transform="translateY(2.5px)"
                        cursor="pointer"
                      />
                    </Box>
                  </Tooltip>
                )}
                {project?.name || 'Senza nome'}
              </Text>
            </Flex>
          </LinkOverlay>
        </Box>
      </LinkBox>
      <Box position="absolute" top={4} right={4} zIndex="2">
        <GenericMoreMenuButton
          deleteItemMutation={DELETE_PROJECT}
          resource="Project"
          id={project?.id}
          openEditAsModal
          hasEdit
        />
      </Box>
    </Box>
  )
}
