import React, { FC, useCallback } from 'react'
import { Box, useToast } from '@chakra-ui/react'
import { useDropzone } from 'react-dropzone'
import { useVersion } from 'chakra-admin'
import produce from 'immer'
import { MUTATION_CREATE_TASK_ATTACHMENT, QUERY_GET_TASK } from '../../queries'
import { useSetTaskUploadAttachmentState } from '../../utils/task/taskUploadAttachmentsState'
import { gql_GetTaskQuery, gql_Tag, gql_Task, useCreateTaskAttachmentMutation } from '../../graphql'

type Props = {
  task?: Omit<gql_Task, 'todos' | 'project' | 'tags'> & {
    tags?: Pick<gql_Tag, 'id' | 'name' | 'color'>[]
  }
  isModal?: boolean
  isTablet?: boolean
}

const TaskAttachmentsContext = React.createContext<() => void>(() => undefined)

export const TaskAttachmentsDropzone: FC<Props> = ({
  task,
  isModal,
  isTablet,
  children,
  ...rest
}) => {
  const setIsUploading = useSetTaskUploadAttachmentState()
  const toast = useToast()
  const nextVersion = useVersion()

  const [uploadAttachmentMutation] = useCreateTaskAttachmentMutation()

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        try {
          setIsUploading(true)

          const results = await Promise.all(
            acceptedFiles.map(async (item) => {
              const { data } = await uploadAttachmentMutation({
                mutation: MUTATION_CREATE_TASK_ATTACHMENT,
                variables: {
                  data: {
                    taskId: task?.id!,
                    name: item.name,
                    file: item,
                  },
                },
                update: (cache, { data }) => {
                  const attachment = data?.createTaskAttachment

                  if (!attachment) {
                    return
                  }

                  const currentData = cache.readQuery<gql_GetTaskQuery>({
                    query: QUERY_GET_TASK,
                    variables: { id: task?.id },
                  })

                  cache.writeQuery({
                    query: QUERY_GET_TASK,
                    variables: { id: task?.id },
                    data: produce(currentData, (draftState) => {
                      draftState?.task?.attachments?.push(attachment)
                    }),
                  })
                },
              })

              if (data?.createTaskAttachment?.id) {
                return true
              } else {
                return false
              }
            })
          )

          if (results.filter((item) => item === false).length === 0) {
            nextVersion()
            toast({
              title: 'Allegati caricati',
              description: 'I file sono stati caricati correttamente',
              status: 'success',
              duration: 5000,
              isClosable: true,
            })
          } else {
            toast({
              title: 'Errore',
              description: 'Si è verificato un errore durante il caricamento dei file',
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          }
        } catch (e) {
          toast({
            title: 'Errore',
            // eslint-disable-next-line @typescript-eslint/quotes
            description: "Errore nell'upload dei file",
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        } finally {
          setIsUploading(false)
        }
      }
    },
    [setIsUploading, uploadAttachmentMutation, task?.id, nextVersion, toast]
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: handleDrop,
    noClick: true,
  })

  return (
    <TaskAttachmentsContext.Provider value={open}>
      <Box
        {...getRootProps({ role: 'none' })}
        pos="relative"
        cursor="auto"
        // tabIndex={undefined}
        w="100%"
        // overflowY={isModal || isTablet ? 'unset' : 'auto'}
        overflowY="visible"
        id="form-box"
        {...rest}
      >
        <input {...getInputProps()} />
        {isDragActive && (
          <Box
            pos="fixed"
            top="0"
            right="0"
            left="0"
            bottom="0"
            // minH={isModal || isTablet ? '100vh' : '100%'}
            display="flex"
            bgColor="whiteAlpha.700"
            zIndex="10"
            alignItems="center"
            justifyContent="center"
            fontSize="2xl"
          >
            Rilascia i file per caricare.
          </Box>
        )}
        <Box
          pos="absolute"
          top="0"
          w="100%"
          /* h="100%" */
          bgColor={isModal || isTablet ? 'gray.50' : 'white'}
        />
        {children}
      </Box>
    </TaskAttachmentsContext.Provider>
  )
}

export const useTaskAttachments = () => {
  return React.useContext(TaskAttachmentsContext)
}
