import React from 'react'
import { HStack, Icon, Text, TextProps } from '@chakra-ui/react'
import { GoCommentDiscussion } from 'react-icons/go'

interface ChatBadgeProps {
  count: number
  size?: TextProps['size']
}

export const ChatBadge = ({ count, size = 'sm' }: ChatBadgeProps) => {
  if (!count) {
    return null
  }

  return (
    <HStack spacing={1}>
      <Icon as={GoCommentDiscussion} />
      <Text fontSize={size} as="span" color="blackAlpha.700" fontWeight="bold">
        {count}
      </Text>
    </HStack>
  )
}
