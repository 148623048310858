import { gql } from '@apollo/client'
import { fragments } from '../../queries'

export const QUERY_GET_PERMISSION_ROLES = gql`
  query GetRoleDetails {
    permissions(pagination: { disabled: true }, sort: { name: "ASC" }) {
      total
      data {
        id
        name
        roles {
          id
          name
        }
        category {
          id
          name
        }
      }
    }

    permissionCategories(pagination: { disabled: true }, sort: { name: "ASC" }) {
      data {
        id
        name
      }
    }
  }
`
export const MUTATION_UPDATE_PERMISSION = gql`
  ${fragments.Permission}
  mutation UpdatePermission($id: ID!, $data: PermissionUpdateInput!) {
    updatePermission(id: $id, data: $data) {
      ...PermissionFragment
    }
  }
`
