import React from 'react'
import { Button } from '@chakra-ui/react'
import { FaFileCsv } from 'react-icons/fa'
import CsvDownloader from 'react-csv-downloader'
import { Node } from 'slate'

import { client } from '../../apolloClient'
import {
  gql_GetTasksQuery,
  gql_GetTasksQueryVariables,
  gql_TaskFragmentFragment,
} from '../../graphql'
import { QUERY_GET_TASKS } from '../../queries'
import { useSearchParams } from 'react-router-dom'

const LIMIT_FOR_CSV = 100

const serialize = (text: string) => {
  try {
    const nodes = JSON.parse(text)
    return nodes.map((n: any) => Node.string(n)).join('\n')
  } catch {
    return ''
  }
}

const dateTimeFormatter = Intl.DateTimeFormat(undefined, {
  dateStyle: 'short',
  timeStyle: 'short',
}).format

const row = (record: gql_TaskFragmentFragment) => {
  return {
    id: record.id,
    Title: record.title,
    Description: record.description ? `"${serialize(record.description)}"` : '',
    'Expiration Date': record.endDate ? dateTimeFormatter(new Date(record.endDate)) : '',
    Completed: record.completionDate ? 'Yes' : 'No',
    'Completion Date': record.completionDate
      ? dateTimeFormatter(new Date(record.completionDate))
      : '',
    Status: record.status?.name,
    'Created At': dateTimeFormatter(new Date(record.createdAt)),
    'Updated At': dateTimeFormatter(new Date(record.updatedAt)),
    Tags: record.tags?.map((tag) => tag.name).join(', '),
    Project: record.project?.name,
  }
}

const fetch = async (
  page: number,
  sort: gql_GetTasksQueryVariables['sort'],
  filters: gql_GetTasksQueryVariables['filters']
) => {
  const { data } = await client.query<gql_GetTasksQuery, gql_GetTasksQueryVariables>({
    query: QUERY_GET_TASKS,
    variables: {
      sort,
      filters,
      pagination: {
        limit: LIMIT_FOR_CSV,
        offset: page,
      },
    },
    fetchPolicy: 'network-only',
  })

  return data.tasks || { data: [], total: 0 }
}

export const handleCSVDownload = async (props?: {
  sort?: gql_GetTasksQueryVariables['sort']
  filters?: gql_GetTasksQueryVariables['filters']
}) => {
  const { sort, filters } = props || {}

  const { data, total } = await fetch(0, sort, filters)

  const rows = [...data.map(row)]

  for (let i = 1; i < total / LIMIT_FOR_CSV; i++) {
    const { data: more } = await fetch(i, sort, filters)
    rows.push(...more.map(row))
  }

  return rows
}

export const useDownloadButtonData = (projectId: string, statusId?: string) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [searchParams] = useSearchParams()

  const handleDownload = async () => {
    let data = []

    const filteredUserIdsParam = searchParams.get('f_userIds')
    const userIds = filteredUserIdsParam
      ? filteredUserIdsParam.split(',').filter((item) => !!item)
      : undefined

    const filteredTagIdsParam = searchParams.get('f_tagIds')
    const tagIds = filteredTagIdsParam
      ? filteredTagIdsParam.split(',').filter((item) => !!item)
      : undefined

    try {
      setIsLoading(true)
      data = await handleCSVDownload({
        filters: {
          userIds,
          tagIds,
          projectId,
          taskStatusId: statusId,
        },
      })
    } finally {
      setIsLoading(false)
    }

    return data
  }

  return {
    isLoading,
    handleDownload,
  }
}

type DownloadButtonProps = {
  projectId: string
  statusId?: string
  filename?: string
}

export const DownloadButton = ({
  projectId,
  statusId,
  filename,
  ...props
}: DownloadButtonProps) => {
  const { isLoading, handleDownload } = useDownloadButtonData(projectId, statusId)

  if (!projectId) {
    return null
  }

  return (
    <Button
      leftIcon={<FaFileCsv />}
      variant="ghost"
      iconSpacing="0.8rem"
      fontWeight={500}
      as={CsvDownloader}
      datas={handleDownload}
      filename={filename || 'project'}
      separator=";"
      isLoading={isLoading}
      loadingText="Sto scaricando..."
      px="0.8rem"
      {...props}
    >
      Export CSV
    </Button>
  )
}
