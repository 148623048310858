import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, Grid, Heading, Icon, useToast } from '@chakra-ui/react'
import { useApolloClient, useQuery } from '@apollo/client'
import { FaSave } from 'react-icons/fa'
import { MUTATION_UPDATE_PERMISSION, QUERY_GET_PERMISSION_ROLES } from '../role.queries'
import PermissionItem from './PermissionItem'
import RoleDetailCard from './RoleDetailCard'
import Loading from '../../../components/base/Loading'
import {
  gql_UpdatePermissionMutation,
  gql_UpdatePermissionMutationVariables,
} from '../../../graphql'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'

type Props = {
  record?: any
}

const RoleDetails: FC<Props> = (props) => {
  const toast = useToast()
  const { loading, data, error, refetch } = useQuery(QUERY_GET_PERMISSION_ROLES, {
    fetchPolicy: 'network-only',
  })
  const client = useApolloClient()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [activePermissions, setActivePermissions] = useState<Record<string, boolean> | undefined>(
    undefined
  )

  const permissionHashTable = useMemo(() => {
    if (!loading && data && data.permissions && data.permissions.data) {
      return data.permissions.data.reduce((acc: any, item: any) => {
        return {
          ...acc,
          [item.id]: item,
        }
      }, {})
    }

    return {}
  }, [data, loading])

  const categoriesPermissions = useMemo(() => {
    if (!loading && data?.permissionCategories?.data && data?.permissions?.data) {
      const orphanedPermissions = data.permissions.data.filter(
        (permission: any) => !permission.category || permission.category.name.length === 0
      )

      return [
        ...data.permissionCategories.data.map((permissionCategory: any) => {
          return {
            ...permissionCategory,
            permissions: data.permissions.data.filter((permission: any) => {
              return !!(permission.category.id === permissionCategory.id)
            }),
          }
        }),
        {
          id: 'NO_CATEGORY',
          name: 'Senza Categoria',
          permissions: orphanedPermissions,
        },
      ]
    }
    return []
  }, [data, loading])

  const categoriesPermissionsAllSelected = useMemo(() => {
    return categoriesPermissions.reduce((acc: any, category: any) => {
      const foundedActivePermissions = category.permissions.filter(
        (item: any) => activePermissions?.[item.id]
      )

      return {
        ...acc,
        [category.id]: foundedActivePermissions.length === category.permissions.length,
      }
    }, {})
  }, [activePermissions, categoriesPermissions])

  const handlePermissionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setActivePermissions({
        ...activePermissions,
        [event.target.name]: event.target.checked,
      })
    },
    [activePermissions]
  )

  const handleToggleAllSelected = useCallback(
    (category: any) => {
      if (!categoriesPermissionsAllSelected[category.id]) {
        setActivePermissions((prevState: any) => ({
          ...prevState,
          ...category.permissions.reduce((acc: any, item: any) => {
            return {
              ...acc,
              [item.id]: true,
            }
          }, {}),
        }))
      } else {
        setActivePermissions((prevState: any) => ({
          ...prevState,
          ...category.permissions.reduce((acc: any, item: any) => {
            return {
              ...acc,
              [item.id]: false,
            }
          }, {}),
        }))
      }
    },
    [categoriesPermissionsAllSelected]
  )

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true)
      if (!activePermissions) {
        throw new Error('Active permissions not set')
      }

      const results = await Promise.all(
        Object.keys(activePermissions).map((item: any) => {
          const roleIds = permissionHashTable[item].roles
            .map((r: any) => r.id)
            .filter((r: any) => r !== props.record.id)

          if (activePermissions[item]) {
            roleIds.push(props.record.id)
          } else {
            console.log('dovrebbe togliere', item, roleIds)
          }

          return client.mutate<gql_UpdatePermissionMutation, gql_UpdatePermissionMutationVariables>(
            {
              mutation: MUTATION_UPDATE_PERMISSION,
              variables: {
                id: item,
                data: {
                  roleIds,
                },
              },
            }
          )
        })
      )

      refetch()
      toast({
        title: 'Permessi modificati',
        description: 'I permessi sono stati modificati correttamente',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } catch (e) {
      //   notify('ra.message.error', { type: 'warning' })
      console.error(e)
      toast({
        title: 'Errore',
        // eslint-disable-next-line prettier/prettier
        description: 'C\'è stato un errore, riprova più tardi',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setSubmitting(false)
    }
  }, [activePermissions, refetch, toast, permissionHashTable, client, props?.record?.id])

  useEffect(() => {
    if (
      !loading &&
      data.permissions &&
      data.permissions.data &&
      !activePermissions &&
      props.record
    ) {
      const activePermissions = data.permissions.data.filter((permission: any) => {
        return permission.roles && permission?.roles?.find((pR: any) => pR.id === props.record.id)
      })

      setActivePermissions(
        activePermissions.reduce((acc: any, item: any) => {
          return {
            ...acc,
            [item.id]: true,
          }
        }, {})
      )
    }
  }, [loading, data, activePermissions, props.record])

  if (loading || !activePermissions) {
    return <Loading />
  }

  return (
    <Grid
      templateColumns={{ base: 'unset', md: '325px 2fr' }}
      height="100%"
      columnGap={4}
      rowGap={8}
      mt={3}
      mb={10}
    >
      <Box position="sticky" top={4} h="fit-content">
        <RoleDetailCard record={props.record} />
        <Box display="flex" justifyContent="flex-end" my={3}>
          <Button
            isDisabled={submitting}
            isLoading={submitting}
            onClick={handleSubmit}
            color="white"
            variant="ghost"
            bgColor="primary.500"
            size="large"
            py={{ base: 3, lg: 4 }}
            px={{ base: 4, lg: 5 }}
            leftIcon={<Icon as={FaSave} />}
            borderRadius="xl"
            _hover={{ bgColor: 'primary.300' }}
          >
            Salva
          </Button>
        </Box>
      </Box>
      <Box>
        {categoriesPermissions.map((categoryPermission: any) => {
          return (
            <Box
              key={`category-permission-${categoryPermission.id}`}
              boxShadow={3}
              mb={3}
              p={8}
              border="1px solid"
              borderColor="gray.100"
              borderRadius="lg"
            >
              <Box>
                <Heading as="h2" fontSize="2xl">
                  {categoryPermission.name}

                  <Button
                    onClick={() => {
                      handleToggleAllSelected(categoryPermission)
                    }}
                    size="xs"
                    ml={4}
                    leftIcon={
                      <Icon
                        as={
                          categoriesPermissionsAllSelected[categoryPermission.id]
                            ? MdCheckBoxOutlineBlank
                            : MdCheckBox
                        }
                      />
                    }
                  >
                    {categoriesPermissionsAllSelected[categoryPermission.id]
                      ? 'Deseleziona tutti'
                      : 'Seleziona tutti'}
                  </Button>
                </Heading>
                <Box mt={categoryPermission.permissions.length > 0 ? 6 : 0}>
                  <Grid
                    templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
                    columnGap={4}
                    rowGap={6}
                    gridAutoFlow="row-dense"
                  >
                    {categoryPermission.permissions.map((permission: any) => {
                      return (
                        <Grid key={`permission-item-${permission.id}`} w="fit-content">
                          <PermissionItem
                            isDisabled={submitting}
                            permission={permission}
                            name={permission.id}
                            isChecked={activePermissions && activePermissions[permission.id]}
                            onChange={handlePermissionChange}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
              </Box>
            </Box>
          )
        })}
      </Box>
    </Grid>
  )
}

export default RoleDetails
