import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

export const boardHeightState = atom({
  key: 'boardHeightState',
  default: 0,
})

export const useBoardHeightState = () => {
  return useRecoilState(boardHeightState)
}

export const useSetBoardHeightState = () => {
  return useSetRecoilState(boardHeightState)
}

export const useBoardHeightValue = () => {
  return useRecoilValue(boardHeightState)
}
