import { DefaultStrategy } from 'chakra-admin'
import { CreateStrategy } from './CreateStrategy'
import { EditStrategy } from './EditStrategy'
import { ListStrategy } from './ListStrategy'

export class MashupStrategy extends DefaultStrategy {
  list = new ListStrategy()

  create = new CreateStrategy()

  edit = new EditStrategy()

  show = new EditStrategy()
}
