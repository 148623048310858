import React, { FC, useMemo } from 'react'
import { PageLayout } from '../../components/details/PageLayout'
import { PageTitle } from '../../components/details/PageTitle'
import { gql_Project, useGetProjectQuery } from '../../graphql'
import { ProjectBoard } from '../../components/board/ProjectBoard'
import { Box, Heading, HStack, Icon, IconButton, MenuItem, Skeleton } from '@chakra-ui/react'
import { PageTitleProps, useTranslate } from 'chakra-admin'
import { useNavigate } from 'react-router-dom'
import { BoardUsersFilter } from '../../components/filters/BoardUsersFilter'
import { MUTATION_DELETE_PROJECT } from '../../queries'
import { Breadcrumbs } from '../../components/layout/Breadcrumbs'
import { BiLock } from 'react-icons/bi'
import { BoardTagsFilter } from '../../components/filters/BoardTagsFilter'
import { useHasPermission } from '../../utils/auth/permissions'
import { WithPermission } from '../../components/auth/WithPermission'
import { InteractionSubscription } from '../../components/interactions/InteractionSubscription'
import { DownloadButton } from './DownloadButton'
import { GenericMoreMenuButton } from '../../components/GenericMoreMenuButton'

type Props = {
  id: string
}

export const ProjectShow: FC<Props> = ({ id, ...props }) => {
  const { data, loading, error } = useGetProjectQuery({
    variables: {
      id,
    },
    skip: !id,
  })

  const bgImage = useMemo(
    () => data?.project?.picture?.urlMedium,
    [data?.project?.picture?.urlMedium]
  )

  return (
    <PageLayout
      title={(<ProjectShowTitle loading={loading} record={data?.project} />) as any}
      pr={0}
      pl={0}
      h="100vh"
      overflow="hidden"
      display="flex"
      flexDir="column"
      bgImage={bgImage}
      bgRepeat="no-repeat"
      bgSize="cover"
      topContainerProps={{
        pt: 0,
        pb: 0,
        pr: 0,
      }}
    >
      {!loading && data?.project && (
        <ProjectBoard id={id} loading={loading} project={data?.project} />
      )}
    </PageLayout>
  )
}

const ProjectShowTitle: FC<PageTitleProps & { record?: gql_Project }> = ({
  record,
  loading,
  ...props
}) => {
  const navigate = useNavigate()
  const t = useTranslate()
  const hasPermissionFn = useHasPermission()
  return (
    <Box
      display="flex"
      w="100%"
      alignItems="flex-start"
      justifyContent="space-between"
      bgColor="whiteAlpha.800"
      backdropFilter="auto"
      backdropBlur="3px"
      px={6}
      pb={2}
      pt={{ base: 0, lg: 3 }}
    >
      <Box display="flex" flexDir="column" alignItems="flex-start" justifyContent="center" mr={2}>
        <Breadcrumbs />
        <Skeleton isLoaded={!loading}>
          <HStack>
            <Heading as="h1" fontSize={{ base: 'xl', lg: '4xl' }}>
              {record?.name || t('ca.message.loading')}
              {record?.visibility === 'PRIVATE' && (
                <Icon
                  as={BiLock}
                  boxSize="30px"
                  color="gray.700"
                  ml={2}
                  transform="translateY(3px)"
                />
              )}
            </Heading>
            <WithPermission permission={['updateProject', 'deleteProject']} condition="or">
              <GenericMoreMenuButton
                deleteItemMutation={MUTATION_DELETE_PROJECT}
                resource="Project"
                onDeleteCompleted={() => {
                  navigate('/Project')
                }}
                id={record?.id}
                openEditAsModal
                hasEdit={hasPermissionFn('updateProject')}
                hasDelete={hasPermissionFn('deleteProject')}
                ml={2}
                borderRadius="full"
                alignSelf="center"
                top={0.5}
                {...({ size: 'sm' } as any)}
              >
                <MenuItem
                  as={DownloadButton}
                  projectId={record?.id}
                  filename={`Project ${record?.name}`}
                  w="100%"
                  justifyContent="flex-start"
                  borderRadius="none"
                  _hover={{ cursor: 'pointer' }}
                />
              </GenericMoreMenuButton>
            </WithPermission>
            <InteractionSubscription type="PROJECT" id={record?.id} />
          </HStack>
        </Skeleton>
      </Box>

      <Box display="flex" h="100%" alignItems="flex-end">
        <BoardTagsFilter record={record} />
        <BoardUsersFilter record={record} />
      </Box>
    </Box>
  )
}
