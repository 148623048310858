import * as React from 'react'
import { Box, BoxProps, Button, Flex, Heading, Icon, IconButton, List } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'

import orderBy from 'lodash.orderby'

import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'

import {
  gql_TaskTodo,
  useGetTaskTodosQuery,
  useUpdateTaskTodoPositionMutation,
} from '../../../graphql'

import { TaskTodoCreate } from './TaskTodoCreate'
import { useTaskTodo } from './TaskTodoContext'
import { TaskTodoItem } from './TaskTodoItem'
import { FiRefreshCw } from 'react-icons/fi'
import { NetworkStatus } from '@apollo/client'

interface TaskTodoListProps {
  containerProps?: BoxProps
}

export const TaskTodoListComponent: React.FC<TaskTodoListProps> = ({ containerProps }) => {
  const { taskId, setSearchString, variables, done } = useTaskTodo()
  const [todoItems, setTodoItems] = React.useState<gql_TaskTodo[]>([])
  const [doneItems, setDoneItems] = React.useState<gql_TaskTodo[]>([])
  const { data, refetch, networkStatus } = useGetTaskTodosQuery({
    variables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    skip: !taskId,
  })

  React.useEffect(() => {
    if (!data?.taskTodos?.data) {
      return
    }

    setTodoItems(
      orderBy(
        data.taskTodos.data.filter((todo) => !todo.isDone),
        ['position', 'createdAt'],
        ['asc', 'asc']
      )
    )
    setDoneItems(
      orderBy(
        data.taskTodos.data.filter((todo) => todo.isDone),
        ['position', 'createdAt'],
        ['asc', 'asc']
      )
    )
  }, [data?.taskTodos?.data, setTodoItems, setDoneItems])

  const [updatePositionMutation] = useUpdateTaskTodoPositionMutation()

  const handleDragEnd = React.useCallback(
    async (event: DragEndEvent, setState: typeof setTodoItems) => {
      const { active, over } = event

      if (!!over && active.id !== over.id) {
        setState((items) => {
          const oldIndex = items.findIndex((todo) => todo.id === active.id)
          const newIndex = items.findIndex((todo) => todo.id === over.id)

          return arrayMove(items, oldIndex, newIndex)
        })

        try {
          await updatePositionMutation({
            variables: {
              id: active.id as any,
              over: over.id as any,
            },
          })
        } catch {
          setState((items) => {
            const oldIndex = items.findIndex((todo) => todo.id === over.id)
            const newIndex = items.findIndex((todo) => todo.id === active.id)

            return arrayMove(items, oldIndex, newIndex)
          })
        }
      }
    },
    [updatePositionMutation]
  )

  return (
    <Box {...containerProps} px={{ base: 4, lg: 8 }}>
      <Flex justifyContent="flex-end">
        {/*  <Flex
          w="fit-content"
          p={1}
          borderRadius="xl"
          bg="white"
          mb={{ lg: 6, xl: 0 }}
          alignItems="center"
          boxShadow="0px 1px 4px rgba(0, 0, 0, 0.05), 0px 6px 24px rgba(0, 0, 0, 0.04), inset 0px 1px 1px rgba(0, 0, 0, 0.04)"
        >
          <SearchBox
            doSearch={setSearchString}
            m={0}
            variant="unstyled"
            borderRadius="0"
            size="sm"
            boxShadow="none"
            bgColor="transparent"
          />
          <Flex
            bg="gray.100"
            color="gray.700"
            p={2}
            pl={3}
            fontSize="sm"
            fontWeight="500"
            borderRadius="lg"
          >
            Fatto
            <Checkbox
              size="lg"
              colorScheme="purple"
              ml={2}
              isChecked={done.value}
              onChange={() => done.set(!done.value)}
            />
          </Flex>
        </Flex> */}
      </Flex>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex>
          <Heading size="md" color="gray.600" fontWeight="700" ml={2}>
            Pianificazione
          </Heading>
          <IconButton
            bg="white"
            size="xs"
            marginLeft="2"
            aria-label="Refresh todos list"
            onClick={() => refetch()}
            icon={<Icon as={FiRefreshCw} />}
            isLoading={networkStatus === NetworkStatus.refetch}
          />
        </Flex>
        <Button
          bg="white"
          color="gray.600"
          fontSize="sm"
          fontWeight="500"
          borderRadius="lg"
          _active={{ bg: 'gray.200' }}
          onClick={() => done.set(!done.value)}
          w="100px"
          boxShadow="0px 1px 4px rgba(0, 0, 0, 0.05), 0px 6px 24px rgba(0, 0, 0, 0.04), inset 0px 1px 1px rgba(0, 0, 0, 0.04);"
        >
          {done.value ? 'Nascondi' : 'Mostra'}
        </Button>
      </Flex>

      <List mt={4} ml={{ base: 0, lg: 2 }}>
        <DndContext onDragEnd={(ev) => handleDragEnd(ev, setTodoItems)}>
          <SortableContext items={todoItems}>
            <AnimatePresence initial={false}>
              {todoItems.map((todo) => (
                <motion.div
                  key={todo.id}
                  initial={{ opacity: 0, height: 0 }}
                  exit={{ opacity: 0, height: 0, transition: { duration: 0.5 } }}
                  animate={{ opacity: 1, height: 'auto', transition: { duration: 0.5 } }}
                >
                  <TaskTodoItem key={todo.id} todo={todo} sortable />
                </motion.div>
              ))}
            </AnimatePresence>
          </SortableContext>
        </DndContext>

        <AnimatePresence initial={false}>
          {doneItems.map((todo) => (
            <motion.div
              key={todo.id}
              initial={{ opacity: 0, height: 0 }}
              exit={{ opacity: 0, height: 0, transition: { duration: 0.5 } }}
              animate={{ opacity: 1, height: 'auto', transition: { duration: 0.5 } }}
            >
              <TaskTodoItem key={todo.id} todo={todo} />
            </motion.div>
          ))}
        </AnimatePresence>

        <TaskTodoCreate containerProps={{ marginX: 2 }} />
      </List>
    </Box>
  )
}
