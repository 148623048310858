import { Box, Button, Flex, IconButton } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useFieldArray } from 'react-hook-form'
import { ca, SelectInput } from 'chakra-admin'
import { Icon } from '@chakra-ui/react'
import { IoIosAddCircle, IoIosRemoveCircle } from 'react-icons/io'
import { RiAddCircleFill } from 'react-icons/ri'

export const RolesInput: FC = (props) => {
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control: (props as any).control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'roleIds', // unique name for your Field Array
  })

  return (
    <Box w="100%" py={4}>
      {fields.map((field, index) => (
        <Flex key={field.id} justifyContent="space-between" alignItems="center">
          <SelectInput
            {...props}
            showEmpty
            resource="Role"
            source={`roleIds.${index}`}
            query="roles"
            fields={['id', 'name']}
            getOption={(record) => ({
              value: `${record.id}`,
              label: `${record.name}`,
            })}
            borderRadius="lg"
          />

          <IconButton
            _hover={{ bg: 'white' }}
            _active={{ bg: 'white' }}
            color="red.500"
            aria-label="Remove"
            size="lg"
            ml={3}
            // alignSelf="flex-end"
            borderRadius="full"
            variant="ghost"
            onClick={() => remove(index)}
            icon={<Icon as={IoIosRemoveCircle} />}
          />
        </Flex>
      ))}
      <Button
        mt={4}
        w="100%"
        size="sm"
        bg="white"
        _hover={{
          bg: 'green.50',
        }}
        borderRadius="lg"
        color="gray.600"
        leftIcon={<Icon as={RiAddCircleFill} color="green.400" fontSize="lg" />}
        justifyContent="flex-start"
        onClick={() => append('')}
      >
        Aggiungi ruolo
      </Button>
    </Box>
  )
}
