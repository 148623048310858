import { Box, FormControl, FormHelperText, FormLabel, Grid, Input, Stack } from '@chakra-ui/react'
import { useTranslate } from 'chakra-admin'
import React, { FC } from 'react'
import { Helpers } from 'react-scroll'
import { SingleImageInput } from '../../components/inputs/SingleImageInput'
import { RolesInput } from './RolesInput'

export const UserForm: FC<any> = (props) => {
  const { isRequired = true } = props
  const t = useTranslate()
  return (
    <Stack spacing="4" mt={3} mb={20}>
      <Grid
        as={FormControl}
        templateColumns={{ base: 'unset', md: '1fr 2fr' }}
        templateRows={{ base: '1fr 3fr', md: 'unset' }}
        alignItems="baseline"
        columnGap="2"
        isRequired
      >
        <FormLabel htmlFor="picture.file">Immagine</FormLabel>
        <SingleImageInput {...props} maxW="100%" source="picture.file" />
      </Grid>
      <Grid
        as={FormControl}
        templateColumns={{ base: 'unset', md: '1fr 2fr' }}
        templateRows={{ base: '1fr 1fr', md: 'unset' }}
        alignItems="baseline"
        columnGap="2"
        isRequired
      >
        <FormLabel htmlFor="firstName">{t(`resources.User.fields.firstName`)}</FormLabel>
        <Input
          {...props.register('firstName', {
            required: t('ca.message.required_field'),
          })}
          variant="outline"
          placeholder={t(`resources.User.placeholders.firstName`)}
          borderRadius="lg"
        />
      </Grid>
      <Grid
        as={FormControl}
        templateColumns={{ base: 'unset', md: '1fr 2fr' }}
        templateRows={{ base: '1fr 1fr', md: 'unset' }}
        alignItems="baseline"
        columnGap="2"
        isRequired
      >
        <FormLabel htmlFor="lastName">{t(`resources.User.fields.lastName`)}</FormLabel>
        <Input
          variant="outline"
          {...props.register('lastName')}
          placeholder={t(`resources.User.placeholders.lastName`)}
          borderRadius="lg"
        />
      </Grid>
      <Grid
        as={FormControl}
        templateColumns={{ base: 'unset', md: '1fr 2fr' }}
        templateRows={{ base: '1fr 1fr', md: 'unset' }}
        alignItems="baseline"
        columnGap="2"
        isRequired
      >
        <FormLabel htmlFor="email">{t(`resources.User.fields.email`)}</FormLabel>
        <Input
          variant="outline"
          {...props.register('email')}
          placeholder={t(`resources.User.placeholders.email`)}
          borderRadius="lg"
        />
      </Grid>
      <Grid
        as={FormControl}
        templateColumns={{ base: 'unset', md: '1fr 2fr' }}
        templateRows={{ base: '1fr 1fr', md: 'unset' }}
        alignItems="baseline"
        columnGap="2"
        isRequired={isRequired}
      >
        <FormLabel htmlFor="password">{t(`resources.User.fields.password`)}</FormLabel>
        <Box>
          <Input
            variant="outline"
            {...props.register('password')}
            placeholder={t(`resources.User.placeholders.password`)}
            borderRadius="lg"
          />
          {!isRequired && (
            <FormHelperText ml={2}>
              Da riempire solamente se si vuole modificare la password
            </FormHelperText>
          )}
        </Box>
      </Grid>
      <RolesInput {...props} />
    </Stack>
  )
}
