import React, { FC } from 'react'
import { Box, BoxProps, Heading, Link, Image, Icon, Flex, IconButton } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import hypertingLogo from '../../../../assets/icons/hyperting.png'
import { CgMenuRight } from 'react-icons/cg'
import { AnimatePresence, motion } from 'framer-motion'

type Props = {
  onToggleClick?: () => void
  isCollapsed?: boolean
} & BoxProps

export const SidebarTitle: FC<Props> = ({ onToggleClick, isCollapsed, ...props }) => {
  return (
    <Box
      w="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      pb={5}
      {...props}
    >
      <Flex alignItems="center" flex="1">
        <Link
          boxSize="48px"
          borderRadius="md"
          as={RouterLink}
          to="/"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgColor="white"
          boxShadow="md"
        >
          <Image boxSize="34px" src={hypertingLogo} />
        </Link>
        <AnimatePresence>
          {!isCollapsed && (
            <motion.span initial={{ opacity: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Heading pt={1} as="h3" size="sm" color="gray.900" fontWeight={600} ml={4}>
                Focus
                <br />
                Board
              </Heading>
            </motion.span>
          )}
        </AnimatePresence>
      </Flex>
      {/* <AnimatePresence>
        {!isCollapsed && (
          <motion.span initial={{ opacity: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <IconButton
              borderRadius="full"
              variant="ghost"
              onClick={onToggleClick}
              aria-label="toggle sidebar"
              icon={<Icon boxSize="24px" as={CgMenuRight} />}
              _hover={{
                bgColor: 'primary.100',
                color: 'primary.500',
              }}
            />
          </motion.span>
        )}
      </AnimatePresence> */}
    </Box>
  )
}
