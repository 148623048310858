import React from 'react'
import { Text, Code, Link } from '@chakra-ui/react'
import { RenderLeafProps, useReadOnly } from 'slate-react'

export const Leaf: (props: RenderLeafProps) => JSX.Element = ({ attributes, children, leaf }) => {
  const readOnly = useReadOnly()

  if (leaf.decoration === 'link') {
    children = (
      <Link
        color="blue.500"
        style={{ cursor: 'pointer' }}
        href={leaf.text}
        target="_blank"
        rel="noopener"
        onClick={(e) => {
          if (!readOnly && e.metaKey) {
            window.open(leaf.text, '_blank', 'noopener')
          }
        }}
      >
        {children}
      </Link>
    )
  }

  if (leaf.bold) {
    children = (
      <Text as="strong" fontWeight="bold">
        {children}
      </Text>
    )
  }

  if (leaf.code) {
    children = <Code>{children}</Code>
  }

  if (leaf.italic) {
    children = <Text as="i">{children}</Text>
  }

  if (leaf.underline) {
    children = <Text as="u">{children}</Text>
  }

  return (
    <Text as="span" {...attributes}>
      {children}
    </Text>
  )
}
