import {
  DocumentNode,
  gql,
  OperationVariables,
  QueryResult,
  TypedDocumentNode,
} from '@apollo/client'
import { ListGetVariablesParams, DefaultListStrategy, generateFields } from 'chakra-admin'
import { query } from 'gql-query-builder'

export class ListStrategy extends DefaultListStrategy {
  getQuery(
    resource: string,
    operation: string,
    variables?: OperationVariables,
    fields?: string[]
  ): DocumentNode | TypedDocumentNode<any, OperationVariables> {
    const result = query({
      operation,
      variables: {
        pagination: {
          type: 'PaginationInputType',
        },
        filters: { type: `${resource}FilterInput` },
        sort: { type: `${resource}SortInput` },
      },
      fields: ['total', 'offset', 'error', { data: generateFields(fields) }],
    })

    return gql`
      ${result.query}
    `
  }

  getList({ data }: QueryResult<any, OperationVariables>): Record<string, any>[] {
    return data && Object.keys(data).length > 0 && (data as any)[Object.keys(data)[0]]
      ? ((data as any)[Object.keys(data)[0]].data as any[])
      : []
  }

  getTotal(result: QueryResult<any, OperationVariables>) {
    const dataKeys = Object.keys(result.data)
    if (
      dataKeys.length > 0 &&
      (result.data as any)[dataKeys[0]] &&
      (result.data as any)[dataKeys[0]].total
    ) {
      return (result.data as any)[dataKeys[0]].total as number
    }

    return 0
  }

  private paginate(
    paginationMode: 'offset' | 'cursor',
    pagination?: { page: number; perPage: number }
  ) {
    if (!pagination) {
      return
    }

    if (paginationMode === 'offset') {
      return {
        offset: pagination.page - 1,
        limit: pagination.perPage,
      }
    }
  }

  getVariables(params: ListGetVariablesParams<Record<string, any>>) {
    const { filters = {}, paginationMode, resource, ...rest } = params
    const { __UNSTABLE_additionalVariables__, ...restFilters } = filters as any

    if (paginationMode !== 'offset') {
      alert(`Only offset pagination is supported \n${JSON.stringify(params, null, 2)}`)
    }

    const pagination = this.paginate(paginationMode, (params as any).pagination)

    return {
      filters: restFilters,
      ...rest,
      pagination,
      ...(__UNSTABLE_additionalVariables__ || {}),
    }
  }
}
