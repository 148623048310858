import * as React from 'react'

import { Admin, defaultI18n, en, getDefaultI18nOptions, Resource } from 'chakra-admin'
// import { theme } from 'ca-theme'
import { theme } from './theme'
import { ChakraProvider } from '@chakra-ui/react'
import { MashupAuthProvider } from './MashupAuthProvider'
import { client } from './apolloClient'
import { HNSProvider } from 'hns-react'

import { it } from './it'
import { RecoilRoot } from 'recoil'

import './App.css'
import 'react-calendar-timeline/lib/Timeline.css'
import { Global } from '@emotion/react'
import { RouteLayout } from './components/layout/stable/RouteLayout'

import '@fontsource/dm-sans'
import { Login } from './components/login/Login'

import Role from './resources/Role'
import User from './resources/User'
import Project from './resources/Project'

import { MashupStrategy } from './strategies/MashupStrategy'
import { useCreateNotificationTokenMutation } from './graphql'

export const App = () => {
  const isWindows = React.useMemo(() => {
    return navigator.userAgent.indexOf('Windows') !== -1
  }, [])

  const [createNotificationToken] = useCreateNotificationTokenMutation({ client })

  const getUserToken = React.useCallback(async () => {
    const { data } = await createNotificationToken()

    return data?.createNotificationToken!
  }, [createNotificationToken])

  return (
    <RecoilRoot>
      {isWindows && (
        <Global
          styles={{
            'div::-webkit-scrollbar': {
              width: '10px',
              height: '10px',
            },
            'div::-webkit-scrollbar-track': {
              background: 'transparent',
            },

            'div::-webkit-scrollbar-thumb': {
              backgroundColor: 'var(--chakra-colors-blackAlpha-200)',
              borderRadius: '20px',
            },

            'div::-webkit-scrollbar-thumb:hover': {
              backgroundColor: 'var(--chakra-colors-blackAlpha-400)',
            },
          }}
        />
      )}
      <HNSProvider
        serviceWorkerPath="/service-worker.js"
        publicKey={process.env.REACT_APP_HNS_VAPID_KEY}
        getUserToken={getUserToken}
      >
        <ChakraProvider theme={theme} portalZIndex={10}>
          {/* <ThemeEditorProvider> */}
          <Admin
            client={client}
            loginComponent={<Login />}
            strategy={MashupStrategy}
            authProvider={MashupAuthProvider}
            layoutComponent={<RouteLayout />}
            i18nProviderProps={{
              i18n: defaultI18n,
              options: {
                ...getDefaultI18nOptions({ en, it }),
                fallbackLng: 'it',
              },
            }}
          >
            <Resource {...Project} />
            <Resource {...Role} />
            <Resource {...User} />
            {/* <Route index element={<div>Eh si!</div>} /> */}
          </Admin>
          {/* <HyperThemeEditor pos="fixed" bottom={4} right={4} />
        </ThemeEditorProvider> */}
        </ChakraProvider>
      </HNSProvider>
    </RecoilRoot>
  )
}
