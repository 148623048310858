import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

export const collapsedState = atom({
  key: 'collapsedState',
  default: false,
})

export const useCollapsedState = () => {
  return useRecoilState(collapsedState)
}

export const useSetCollapsedState = () => {
  return useSetRecoilState(collapsedState)
}

export const useCollapsedValue = () => {
  return useRecoilValue(collapsedState)
}
