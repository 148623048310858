import React from 'react'
import { Text, TextProps } from '@chakra-ui/react'
import { formatDistanceToNow, formatISO } from 'date-fns'
import it from 'date-fns/locale/it'

interface TimeProps extends TextProps {
  children?: Date | string | number
}

const dateFormatter = Intl.DateTimeFormat('it', {
  dateStyle: 'full',
  timeStyle: 'long',
}).format

export const TimeComponent: React.FC<TimeProps> = ({ children: unknownDateType, ...props }) => {
  const date = unknownDateType && new Date(unknownDateType)

  if (!date) {
    return null
  }

  const relative = formatDistanceToNow(date, { locale: it, addSuffix: true })
  const title = dateFormatter(date)
  const iso = formatISO(date)

  return (
    <Text as="time" {...props} title={title} dateTime={iso}>
      {relative}
    </Text>
  )
}

export const Time = React.memo(TimeComponent)
