import {
  Menu,
  MenuButton,
  IconButton,
  Icon,
  Portal,
  MenuList,
  MenuItem,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react'
import { DefaultUserIdentity, DeleteModal, useAuthProvider, useTranslate } from 'chakra-admin'
import React, { useCallback, useState } from 'react'
import { FC } from 'react'
import { FaTrash } from 'react-icons/fa'
import { FiMoreVertical } from 'react-icons/fi'

type Props = {
  user: DefaultUserIdentity | undefined
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  isCollapsed: boolean
}

export const LogoutMenu: FC<Props> = ({ user, isOpen, onClose, onOpen, isCollapsed, ...props }) => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })
  const t = useTranslate()
  const authProvider = useAuthProvider()
  const toast = useToast()
  const [loading, setLoading] = useState<boolean>(false)

  const onLogout = useCallback(async () => {
    try {
      setLoading(true)
      await authProvider?.logout()
      window.location.reload()
    } catch (error) {
      toast({
        title: 'Errore',
        description: 'Errore durante il logout',
        status: 'error',
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }, [authProvider, toast])

  return (
    <>
      <DeleteModal
        resource="User"
        id={user?.id}
        deleting={false}
        isOpen={isOpen}
        onClose={onClose}
        onDeleteItem={onLogout}
        title="Logout"
        description="Sei sicuro di voler effettuare il logout?"
        confirmDeleteButtonLabel={t('ca.action.logout')}
      />
      {isMobile || !isCollapsed ? (
        <Menu isLazy>
          <MenuButton
            as={IconButton}
            size="sm"
            fontSize={20}
            variant="ghost"
            aria-label="Gantt settings"
            icon={<Icon as={FiMoreVertical} />}
            color="gray.300"
            {...props}
          />
          <Portal>
            <MenuList fontSize="sm">
              <MenuItem
                onClick={onOpen}
                color="red.500"
                icon={<Icon color="red.400" as={FaTrash} />}
              >
                {t('ca.action.logout')}
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      ) : null}
    </>
  )
}
