import React from 'react'
import {
  Box,
  IconButton,
  Icon,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Button,
  Stack,
  List,
  PopoverFooter,
  Flex,
  Text,
  PopoverCloseButton,
} from '@chakra-ui/react'

import { SearchBox, SearchBoxHandle } from '../search-box/SearchBox'
import { gql_Tag, useGetTagsQuery } from '../../graphql'
import { FaChevronLeft } from 'react-icons/fa'
import { Item } from './Item'
import { TagForm } from './TagForm'

interface TagListSelectProps {
  selected: Array<{ id: string }>
  onSelect: (userId: string, isIn: boolean) => void
  projectId: string
}

export const TagListSelect: React.FC<TagListSelectProps> = ({ projectId, selected, onSelect }) => {
  const [searchString, setSearchString] = React.useState<string>()
  const searchBoxRef = React.useRef<SearchBoxHandle>(null)
  const { data } = useGetTagsQuery({
    variables: {
      projectId,
      filters: {
        q: searchString || undefined,
      },
      pagination: {
        limit: 100,
      },
    },
    fetchPolicy: 'cache-and-network',
  })
  const [editing, setEditing] = React.useState<Partial<gql_Tag> | undefined>(undefined)

  return (
    <Box as={PopoverContent} onClick={(event) => event.stopPropagation}>
      <PopoverHeader>
        <Flex justifyContent="space-between" alignItems="center">
          <IconButton
            aria-label="Ritorna"
            icon={<Icon as={FaChevronLeft} color={!editing ? 'transparent' : undefined} />}
            variant="ghost"
            size="xs"
            onClick={() => setEditing(undefined)}
            isDisabled={!editing}
          />
          <Text>{!editing ? 'Tags' : 'Crea nuova tag'}</Text>
          <PopoverCloseButton position="unset" />
        </Flex>
      </PopoverHeader>
      <PopoverBody p="0">
        {editing ? (
          <TagForm
            projectId={projectId}
            onComplete={(newId) => {
              searchBoxRef.current?.clear()
              newId && onSelect(newId, false)
              setEditing(undefined)
            }}
            tag={editing}
          />
        ) : (
          <>
            <Stack isInline pt="2" paddingX={2}>
              <SearchBox ref={searchBoxRef} size="sm" m="0" doSearch={setSearchString} />
            </Stack>
            <List spacing="1" m="2" maxH="20rem" overflowY="auto">
              {data?.tags?.data?.map((item) => (
                <Item
                  key={item.id}
                  record={item}
                  isSelected={selected.some((selectedItem) => selectedItem.id === item.id)}
                  onClick={onSelect}
                  onEdit={() => setEditing(item)}
                />
              ))}
            </List>
          </>
        )}
      </PopoverBody>
      <PopoverFooter borderTop="none" p="2">
        {!editing && (
          <Button
            borderRadius="xl"
            size="sm"
            w="100%"
            height="auto"
            py="0.5rem"
            fontWeight="500"
            noOfLines={1}
            style={{
              whiteSpace: 'normal',
              wordWrap: 'break-word',
            }}
            onClick={() => setEditing({ name: searchString })}
          >
            Aggingi nuova{' '}
            {!data?.tags?.data.length && searchString ? `"${searchString.trim()}"` : ''} tag
          </Button>
        )}
      </PopoverFooter>
    </Box>
  )
}
