import React, { FC } from 'react'
import { IconButton, IconButtonProps } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { AnimatePresence } from 'framer-motion'

type Props = {
  onHover: () => void
  onLeave: () => void
  isCollapsed: boolean
  isHover: boolean
} & Omit<IconButtonProps, 'aria-label'>

export const ToggleSizeButton: FC<Props> = ({
  onHover,
  onLeave,
  isCollapsed,
  isHover,
  ...props
}) => {
  return (
    <AnimatePresence>
      {isHover && (
        <IconButton
          aria-label="Toggle sidebar size"
          pos="absolute"
          right="-12px"
          top="32px"
          w="24px"
          minW="24px"
          h="24px"
          minH="24px"
          bgColor="white"
          border="1px solid"
          borderColor="gray.200"
          _hover={{
            bgColor: 'primary.400',
            borderColor: 'primary.400',
            color: 'white',
          }}
          p={0}
          variant="ghost"
          borderRadius="full"
          boxShadow="md"
          icon={
            isCollapsed ? <ChevronRightIcon boxSize="18px" /> : <ChevronLeftIcon boxSize="18px" />
          }
          zIndex="100"
          onMouseEnter={onHover}
          onMouseLeave={onLeave}
          {...props}
        />
      )}
    </AnimatePresence>
  )
}
