export const resolvePicture = (picture: any) =>
  picture && (picture as any)?.file
    ? {
        file: (picture as any)?.file,
        name: picture?.name,
        mediaType: (picture as any)?.mediaType,
      }
    : undefined

const leftPad =
  (nb = 2) =>
  (value: any) =>
    ('0'.repeat(nb) + value).slice(-nb)
const leftPad4 = leftPad(4)
const leftPad2 = leftPad(2)

export const formatDate = (inputValue: string) => {
  const value = new Date(inputValue)
  if (!(value instanceof Date) || isNaN(value.getDate())) return ''
  const yyyy = leftPad4(value.getFullYear())
  const MM = leftPad2(value.getMonth() + 1)
  const dd = leftPad2(value.getDate())
  const hh = leftPad2(value.getHours())
  const mm = leftPad2(value.getMinutes())
  return `${yyyy}-${MM}-${dd}T${hh}:${mm}`
}
