import React, { FC, forwardRef, useCallback, useState } from 'react'
import {
  Box,
  BoxProps,
  useBreakpointValue,
  Icon,
  Stack,
  Flex,
  Badge,
  Button,
  Divider,
  chakra,
} from '@chakra-ui/react'
import { MotionBox, useGetResourceLabel, useLocalStorage } from 'chakra-admin'
import { SidebarTitle } from './SidebarTitle'
import { ToggleSizeButton } from './ToggleSizeButton'
import { ToggleSizeHoverBorder } from './ToggleSizeHoverBorder'
import { MenuItemLink } from './MenuItemLink'
import { RiListCheck2 } from 'react-icons/ri'
import { FiUser, FiUsers } from 'react-icons/fi'
import { HiBell } from 'react-icons/hi'
import { AccountBox } from './AccountBox'
import { useSetCollapsedState } from '../../../../utils/sidebar'
import { WithPermission } from '../../../auth/WithPermission'
import { NotificationsPopover } from 'hns-react'
import { useNavigate } from 'react-router-dom'

type Props = {} & BoxProps

export const Sidebar: FC<Props> = ({ children, ...rest }) => {
  const getResourceLabel = useGetResourceLabel()
  const navigate = useNavigate()
  const [collapsed, setCollapsed] = useLocalStorage<boolean>('ca-default-sidebar-collapsed', false)
  const [isHover, setIsHover] = useState(false)
  const [isExpanderHover, setIsExpanderHover] = useState(false)
  const setCollapsedState = useSetCollapsedState()

  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })

  const handleToggleSize = useCallback(() => {
    setCollapsed((oldCollapsed) => !oldCollapsed)
    setCollapsedState((oldCollapsed) => !oldCollapsed)
    setIsExpanderHover(false)
  }, [setCollapsed, setCollapsedState])

  const handleMouseEnter = useCallback(() => {
    setIsHover(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsHover(false)
  }, [])

  const handleExpanderMouseEnter = useCallback(() => {
    setIsExpanderHover(true)
  }, [])

  const handleExpanderMouseLeave = useCallback(() => {
    setIsExpanderHover(false)
  }, [])

  const handleNotificationClick = useCallback(
    (id: string, clickUrl?: string) => {
      if (clickUrl) {
        navigate(clickUrl.replace(window.location.origin, ''))
      }
    },
    [navigate]
  )

  return (
    <Box
      pos="relative"
      bgColor="white"
      borderRight="1px solid"
      borderRightColor="gray.200"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
    >
      <ToggleSizeHoverBorder isExpanderHover={isExpanderHover} />
      {!isMobile && (
        <ToggleSizeButton
          onHover={handleExpanderMouseEnter}
          onLeave={handleExpanderMouseLeave}
          onClick={handleToggleSize}
          isHover={isHover}
          isCollapsed={collapsed}
        />
      )}
      <MotionBox
        bgColor="white"
        initial={false}
        animate={{ width: isMobile ? 300 : collapsed ? 88 : 300 }}
        pt={{ base: 0, lg: '30px' }}
        pl={5}
        h="100%"
        display="flex"
        flexDir="column"
      >
        {!isMobile && <SidebarTitle onToggleClick={handleToggleSize} isCollapsed={collapsed} />}

        <Flex
          flex="1"
          mt={{ base: 0, lg: 2 }}
          w="100%"
          h="100%"
          flexDir="column"
          overflow="hidden"
          alignItems="center"
        >
          <Box overflowY="auto" w="100%" pr={{ base: 0, lg: 5 }} mb={20}>
            <Stack as="ul" w="100%">
              <NotificationsPopover
                onNotificationClick={handleNotificationClick}
                buttonComponent={<NotificationsTriggerButton isCollapsed={collapsed} />}
              />
              <Divider />

              <WithPermission permission="projects">
                <MenuItemLink
                  icon={<Icon as={RiListCheck2} />}
                  to="/Project"
                  label={getResourceLabel('Project')}
                  isCollapsed={collapsed}
                />
              </WithPermission>
              <WithPermission permission={['createUser', 'updateUser', 'deleteUser']}>
                <MenuItemLink
                  icon={<Icon as={FiUser} />}
                  to="/User"
                  label="Utenti"
                  isCollapsed={collapsed}
                />
              </WithPermission>
              <WithPermission permission={['createRole', 'updateRole', 'deleteRole']}>
                <MenuItemLink
                  icon={<Icon as={FiUsers} />}
                  to="/Role"
                  label="Ruoli"
                  isCollapsed={collapsed}
                />
              </WithPermission>
            </Stack>
          </Box>
        </Flex>
        <AccountBox
          isCollapsed={collapsed}
          position="fixed"
          bottom={0}
          minW={{ base: '280px', lg: '260px' }}
          maxW={{ base: '100%', lg: '260px' }}
        />
        {/* <AccountBox
          position="fixed"
          pb={6}
          bottom={0}
          zIndex="1"
          minW="280px"
          maxW={isMobile ? '100%' : '280px'}
        /> */}
      </MotionBox>
    </Box>
  )
}

const NotificationsTriggerButton = forwardRef(({ unreadCount, isCollapsed, ...rest }: any, ref) => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })

  return (
    <chakra.div as="li" userSelect="none" listStyleType="none" pt={2}>
      <Button
        ref={ref as any}
        h="48px"
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        variant="unstyled"
        color="gray.900"
        fontWeight="normal"
        _hover={{
          bgColor: 'primary.100',
          color: 'primary.500',
        }}
        _active={{
          bgColor: 'primary.100',
          color: 'primary.500',
        }}
        pl={3.5}
        pr={3.5}
        borderRadius="xl"
        {...rest}
      >
        <Icon w="20px" mr={3} h="20px" pointerEvents="none" as={HiBell} />
        {(!isCollapsed || isMobile) && 'Notifiche'}
        {unreadCount > 0 && (
          <Badge
            ml={2}
            bgColor="primary.200"
            color="white"
            px={2}
            borderRadius="lg"
            position="absolute"
            right={3}
            {...(isCollapsed && {
              top: -2,
              right: -2,
            })}
          >
            {unreadCount}
          </Badge>
        )}
      </Button>
    </chakra.div>
  )
})
