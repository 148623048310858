import { OperationVariables } from '@apollo/client'
import { CreateStrategy as DefaultCreateStrategy } from 'chakra-admin'
import omit from 'lodash.omit'
import pick from 'lodash.pick'
import { gql_Task, gql_Project } from '../graphql'

import { resolvePicture } from './utils'

export class CreateStrategy implements DefaultCreateStrategy {
  getMutationVariables(values: Record<string, any>): OperationVariables {
    switch (values.__typename) {
      case 'Project': {
        const { pictureId, picture, __typename, ...data } = values as gql_Project
        return {
          data: {
            ...data,
            picture: resolvePicture(picture),
          },
        }
      }

      case 'Task': {
        const { __typename, ...rest } = values as gql_Task
        return {
          data: {
            ...rest,
            startDate: new Date(rest.startDate || '').toISOString(),
            endDate: new Date(rest.endDate || '').toISOString(),
          },
        }
      }

      default: {
        const { __typename, ...data } = values as any
        return {
          data,
        }
      }
    }
  }
}
