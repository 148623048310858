import React, { FC, useCallback, useMemo } from 'react'
import { Badge, BadgeProps } from '@chakra-ui/react'
import { useTagViewModeState } from '../../utils/task/tagViewModeState'
import { isDark as isDarkFn } from '@chakra-ui/theme-tools'

type Props = {
  value: string
} & Omit<BadgeProps, 'children'>

export const CardTag: FC<Props> = ({ ...props }) => {
  const [viewMode, setViewMode] = useTagViewModeState()
  const isDark = useMemo(() => isDarkFn(props.bgColor as string)({}), [props.bgColor])
  const color = useMemo(() => (isDark ? 'white' : 'gray.800'), [isDark])

  const handleClick = useCallback<React.MouseEventHandler<HTMLSpanElement>>(
    (e) => {
      e.stopPropagation()
      setViewMode((prevMode) => (prevMode === 'hideLabel' ? 'showLabel' : 'hideLabel'))
    },
    [setViewMode]
  )

  return (
    <Badge
      colorScheme="gray"
      textTransform="none"
      px={2}
      borderRadius="full"
      fontSize="xx-small"
      minH="8px"
      minW="40px"
      {...props}
      color={props.bgColor ? color : undefined}
      onClick={handleClick}
    >
      {viewMode === 'showLabel' ? props.value : null}
    </Badge>
  )
}
