import React, { FC, useCallback } from 'react'
import { Box, chakra, Flex, Grid, HStack } from '@chakra-ui/react'
import { LaneHeaderMenu } from './LaneHeaderMenu'
import { InlineInput } from './InlineInput'

type Props = {
  updateTitle?: (value: string) => void
  editLaneTitle?: boolean
  canAddLanes?: boolean
  laneDraggable?: boolean
  label?: string
  title?: string
  onDelete?: () => void
  onDoubleClick?: () => void
  t: () => void
}

export const LaneHeader: FC<Props> = ({
  title,
  onDelete = () => {},
  updateTitle = () => {},
  canAddLanes,
  ...props
}) => {
  return (
    <Flex
      as="header"
      textOverflow="ellipsis"
      flexWrap="nowrap"
      w="100%"
      bgColor="gray.100"
      borderTopRadius="lg"
      flex="0 0 auto"
      p={1}
    >
      <Box
        flex="1"
        flexBasis="250px"
        boxSizing="border-box"
        fontWeight="bold"
        fontSize="20px"
        color="gray.600"
        // pl={2}
        mr={2}
      >
        <InlineInput
          value={title}
          resize="vertical"
          onSave={updateTitle}
          borderRadius="lg"
          h="32px"
          flex="0"
          minW="32px"
          boxSizing="border-box"
          pl={2}
          pr={2}
          cursor="pointer"
          _focus={{
            cursor: 'text',
          }}
        />
      </Box>
      {canAddLanes && <LaneHeaderMenu {...props} title={title} onDelete={onDelete} />}
    </Flex>
  )
}
