import React from 'react'
import { Text, ListItem, Avatar, ListItemProps } from '@chakra-ui/react'
import { gql_User } from '../../graphql'
import { getName } from '../../utils/formatters'

export const UserItem = ({
  user,
  isSelected,
  ...props
}: { user: gql_User; isSelected: boolean } & ListItemProps) => {
  const fullName = getName(user)
  return (
    <ListItem
      display="flex"
      flexDirection="row"
      as={ListItem}
      isInline
      py={1}
      px={2}
      borderRadius="md"
      backgroundColor={isSelected ? 'blue.100' : undefined}
      {...props}
    >
      <Avatar size="xs" name={fullName} src={user.picture?.urlSmall || user.picture?.urlMedium} />
      <Text fontSize="md" ml="2">
        {fullName}
      </Text>
    </ListItem>
  )
}
