import { NodeEntry, Range } from 'slate'
import { findUrlsInText } from './helpers'

export const decorator: (entry: NodeEntry) => Range[] = ([node, path]) => {
  if ('text' in node) {
    const nodeText = node.text
    if (!nodeText) return []

    const urls = findUrlsInText(nodeText)

    return urls.map(([url, index]) => {
      return {
        anchor: {
          path,
          offset: index,
        },
        focus: {
          path,
          offset: index + url.length,
        },
        decoration: 'link',
      }
    })
  }

  return []
}
