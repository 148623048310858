import React from 'react'
import produce from 'immer'
import { randomColor } from '@chakra-ui/theme-tools'
import {
  gql_GetTagsQuery,
  gql_GetTagsQueryVariables,
  gql_Tag,
  useCreateTagMutation,
  useDeleteTagMutation,
  useUpdateTagMutation,
} from '../../graphql'
import { QUERY_GET_TAGS } from './tag.queries'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Button, Flex, FormControl, FormLabel, Input, Stack, chakra } from '@chakra-ui/react'
import pick from 'lodash.pick'

interface TagFormProps {
  tag?: Partial<gql_Tag>
  onComplete: (id?: string) => void
  projectId: string
}

interface FormValues {
  name: string
  color: string
}

export const TagForm: React.FC<TagFormProps> = ({ projectId, onComplete, tag = {} }) => {
  const [create, createResult] = useCreateTagMutation()
  const [update, updateResult] = useUpdateTagMutation()
  const [remove, removeResult] = useDeleteTagMutation()

  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      ...tag,
      color: tag?.color || randomColor({ string: tag?.name }),
    },
  })

  const isEditing = !!tag.id

  const onSubmit = React.useCallback<SubmitHandler<FormValues>>(
    (values) => {
      if (tag.id) {
        update({
          variables: {
            id: tag.id,
            data: pick(values, 'name', 'color'),
          },
        })

        return onComplete()
      }

      create({
        variables: {
          data: {
            name: values.name,
            color: values.color || randomColor({ string: values.name }),
            projectId,
          },
        },
        update: (cache, { data }) => {
          const created = data?.createTag

          if (!created) {
            return
          }

          const currentData = cache.readQuery<gql_GetTagsQuery, gql_GetTagsQueryVariables>({
            query: QUERY_GET_TAGS,
            variables: {
              projectId,
              filters: {},
              pagination: {
                limit: 100,
              },
            },
          })

          if (currentData) {
            cache.writeQuery({
              query: QUERY_GET_TAGS,
              variables: {
                projectId,
                filters: {},
                pagination: {
                  limit: 100,
                },
              },
              data: produce(currentData, (draftState) => {
                draftState?.tags?.data.unshift(created)
              }),
            })
          }

          onComplete(created.id)
        },
      })
    },
    [create, onComplete, projectId, tag.id, update]
  )

  const handleRemove = React.useCallback(() => {
    if (!tag.id) {
      return
    }

    remove({
      variables: {
        id: tag.id,
      },
      update: (cache) => {
        cache.evict({
          id: cache.identify(tag),
        })
        onComplete()
      },
    })
  }, [onComplete, remove, tag])

  return (
    <Stack p="3" spacing="4" as={chakra.form} onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
        <FormLabel htmlFor="name">Name</FormLabel>
        <Input size="sm" {...register('name', { required: true })} placeholder="nome" />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="color">Seleziona un colore</FormLabel>
        <Input
          size="sm"
          type="color"
          placeholder="color"
          {...register('color', { required: true })}
        />
      </FormControl>

      <Flex justifyContent="space-between">
        <Button
          colorScheme="purple"
          type="submit"
          size="sm"
          borderRadius="xl"
          isDisabled={removeResult.loading}
          isLoading={createResult.loading || updateResult.loading}
          loadingText={isEditing ? 'Aggiornando' : 'Creando'}
        >
          {isEditing ? 'Aggiorna' : 'Crea'}
        </Button>

        {isEditing && (
          <Button
            colorScheme="red"
            size="sm"
            borderRadius="xl"
            isLoading={removeResult.loading}
            loadingText="Eliminando"
            onClick={handleRemove}
          >
            Elimina
          </Button>
        )}
      </Flex>
    </Stack>
  )
}
