import React, { FC } from 'react'
import { Box, Heading, Icon, Text } from '@chakra-ui/react'
import { PageTitleProps, ShadowedBox } from 'chakra-admin'
import { ModalPageTitle } from '../../components/details/ModalPageTitle'

type Props = PageTitleProps & {
  subtitle?: string
}

export const DetailsPageTitle: FC<Props> = ({ subtitle, ...rest }) => {
  if (rest.renderingInModal) {
    return <ModalPageTitle {...rest} />
  }

  return (
    <Box display="flex" alignItems="center" mr={2} {...rest}>
      {rest.boxComponent ||
        (rest.icon ? (
          <ShadowedBox bgColor="gray.100" w="55px" h="55px">
            <Icon as={rest.icon} fontSize="2xl" />
          </ShadowedBox>
        ) : null)}
      <Box ml={{ base: 4, lg: 6 }}>
        {subtitle && <Text color="blackAlpha.700">{subtitle}</Text>}
        <Heading as="h1" fontSize={{ base: 'xl', lg: '2xl' }}>
          {rest.label}
        </Heading>
      </Box>
    </Box>
  )
}
