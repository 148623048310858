import React, { FC, useCallback, useRef } from 'react'
import { Box, Button, chakra, HStack, IconButton, useOutsideClick } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useTranslate } from 'chakra-admin'
import { CardTitleEditable } from './CardTitleEditable'
import isHotkey from 'is-hotkey'
import { CloseIcon } from '@chakra-ui/icons'

type Props = {
  onCancel: () => void
  onAdd: (newCard: any) => void
}

export const NewCardForm: FC<Props> = ({ onCancel, onAdd }) => {
  const formRef = useRef<HTMLFormElement>()
  useOutsideClick({
    ref: formRef as any,
    handler: () => onCancel(),
  })
  const t = useTranslate()
  const { register, handleSubmit } = useForm()

  const onSubmit = useCallback(
    (data) => {
      onAdd(data)
    },
    [onAdd]
  )

  const handleKeyDown = useCallback<React.KeyboardEventHandler<HTMLTextAreaElement>>(
    (e) => {
      if (isHotkey('esc')(e)) {
        onCancel()
        e.preventDefault()
        return
      }

      if (isHotkey('enter')(e)) {
        handleSubmit(onSubmit)(e)
        e.preventDefault()
        return
      }
    },
    [handleSubmit, onCancel, onSubmit]
  )

  return (
    <Box px={1} pb={2}>
      <chakra.form
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDir="column"
        maxW="250px"
        flexWrap="nowrap"
        ref={formRef as any}
      >
        <CardTitleEditable
          autoFocus
          onKeyDown={handleKeyDown}
          resize="vertical"
          boxShadow="md"
          fontWeight="bold"
          lineHeight="32px"
          placeholder={t('components.ProjectBoard.cardTitlePlaceholder')}
          _placeholder={{
            fontWeight: 'normal',
            color: 'gray.400',
          }}
          {...register('title', { required: true })}
        />
        <HStack mt={2}>
          <Button size="sm" type="button" onClick={handleSubmit(onSubmit)} colorScheme="primary">
            {t('components.ProjectBoard.confirmAddCard')}
          </Button>
          <IconButton
            variant="ghost"
            icon={<CloseIcon />}
            aria-label={t('ca.action.cancel')}
            size="sm"
            onClick={onCancel}
          />
        </HStack>
      </chakra.form>
    </Box>
  )
}
