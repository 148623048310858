import React, { FC, useCallback, useState } from 'react'
import { Box, useToast, Heading, Icon, Stack, Button } from '@chakra-ui/react'
import { useVersion } from 'chakra-admin'
import debounce from 'lodash.debounce'
import { gql_Task, useEditTaskDescriptionMutation } from '../../../graphql'
import { MdSave } from 'react-icons/md'
import { RichText } from '../../rich-text/RichText'

export type TaskDescriptionEditableProps = {
  task?: gql_Task
  loading?: boolean
}

export const TaskDescriptionEditable: FC<TaskDescriptionEditableProps> = ({ task, loading }) => {
  const toast = useToast()
  const nextVersion = useVersion()
  const [dirtyValue, setDirtyValue] = useState<string>()
  const [updateDescription, updateResult] = useEditTaskDescriptionMutation()

  const handleSubmit = useCallback(
    async (value: string) => {
      try {
        const result = await updateDescription({
          variables: {
            id: task?.id!,
            data: {
              description: value,
            },
          },
        })

        if (result.data && !result.errors) {
          nextVersion()
          setDirtyValue(undefined)
        }
      } catch (error) {
        toast({
          title: 'Error',
          description: (error as any).message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    },
    [updateDescription, task?.id, nextVersion, toast]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = React.useCallback(
    debounce((value: string) => {
      handleSubmit(value)
    }, 3000),
    [handleSubmit]
  )

  return (
    <Box>
      <Stack isInline alignItems="center" justifyContent="space-between" mb={4} h="2rem">
        <Heading fontSize="md">Descrizione</Heading>

        <Button
          w="5rem"
          size="xs"
          colorScheme="blue"
          isDisabled={!dirtyValue}
          isLoading={updateResult.loading}
          loadingText="Salvando"
          leftIcon={<Icon as={MdSave} />}
          onClick={() => dirtyValue && handleSubmit(dirtyValue)}
        >
          {dirtyValue ? 'Salvare' : 'Salvato'}
        </Button>
      </Stack>

      <RichText
        placeholder="Inserisci una descrizione più dettagliata..."
        initialValue={task?.description}
        onChange={(value) => {
          setDirtyValue(value)
          debouncedSubmit(value)
        }}
        mentions={false}
      />
    </Box>
  )
}
