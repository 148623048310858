import { useLocalStorage } from 'chakra-admin'
import React, { createContext, ReactNode, useContext, useMemo } from 'react'
import {
  gql_TaskTodo,
  gql_GetTaskTodosQueryVariables,
  gql_SortDirection,
  useDeleteTaskTodoMutation,
  useEditTaskTodoMutation,
} from '../../../graphql'

interface TaskTodoContextData {
  variables: gql_GetTaskTodosQueryVariables
  setSearchString: (searchString?: string) => void
  taskId: string
  handleDelete: (todo: gql_TaskTodo) => void
  handleDone: (todo: gql_TaskTodo) => void
  done: {
    value: boolean
    set: (value: boolean) => void
  }
  handleUpdateDescription: (id: string, description: string) => void
}

interface TaskTodoProviderProps {
  children: ReactNode
  taskId: string
}

const TaskTodoContext = createContext({} as TaskTodoContextData)

export const taskTodoSort = {
  createdAt: gql_SortDirection.Asc,
  isDone: gql_SortDirection.Asc,
  position: gql_SortDirection.Asc,
}

export const TaskTodoProvider = ({ taskId, children }: TaskTodoProviderProps) => {
  const [searchString, setSearchString] = React.useState<string | undefined>()
  const [showDone, setShowDone] = useLocalStorage<boolean>('task-todo-done-filter', true)

  const variables = useMemo(() => {
    return {
      taskId,
      filters: {
        description: searchString,
        isDone: showDone ? undefined : false,
      },
      sort: taskTodoSort,
    }
  }, [searchString, showDone, taskId])

  const [deleteMutation] = useDeleteTaskTodoMutation()
  const [updateMutation] = useEditTaskTodoMutation()

  const handleDelete = React.useCallback(
    (todo: gql_TaskTodo) => {
      deleteMutation({
        variables: {
          id: todo.id,
        },
        update: (cache) => {
          cache.evict({
            id: cache.identify(todo),
          })
        },
      })
    },
    [deleteMutation]
  )

  const handleDone = React.useCallback(
    (todo: gql_TaskTodo) => {
      updateMutation({
        variables: {
          id: todo.id,
          data: {
            isDone: !todo.isDone,
          },
        },
      })
    },
    [updateMutation]
  )

  const handleUpdateDescription = React.useCallback(
    (id: string, description: string) => {
      updateMutation({
        variables: {
          id,
          data: {
            description,
          },
        },
      })
    },
    [updateMutation]
  )

  return (
    <TaskTodoContext.Provider
      value={{
        taskId,
        variables,
        setSearchString,
        handleDelete,
        handleDone,
        done: {
          value: showDone,
          set: setShowDone,
        },
        handleUpdateDescription,
      }}
    >
      {children}
    </TaskTodoContext.Provider>
  )
}

export const useTaskTodo = () => {
  return useContext(TaskTodoContext)
}
