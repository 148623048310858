import React, { FC } from 'react'
import { Edit } from '../../components/details/Edit'
import { MUTATION_UPDATE_PROJECT, QUERY_GET_PROJECT } from '../../queries'
import { ProjectForm } from './ProjectForm'

export const ProjectEdit: FC = (props) => {
  return (
    <Edit query={QUERY_GET_PROJECT} mutation={MUTATION_UPDATE_PROJECT} {...props}>
      <ProjectForm {...props} />
    </Edit>
  )
}
