import React, { FC, useMemo } from 'react'
import { Box, chakra } from '@chakra-ui/react'
import { useBoardHeightValue } from '../../utils/board'

export const SECTION_DEFAULT_HEIGHT_OFFSET = 30

type Props = {
  boardId: string
  index: number
  labelStyle: any
  title: string
  currentPage: number
  draggable: boolean
  cardDragClass: string
  actions: any
  titleStyle: any
}

export const Section: FC<Props> = ({
  boardId,
  index,
  labelStyle,
  title,
  currentPage,
  draggable,
  cardDragClass,
  actions,
  titleStyle,
  ...props
}) => {
  const boardHeight = useBoardHeightValue()
  const maxHeight = useMemo(() => `${boardHeight - SECTION_DEFAULT_HEIGHT_OFFSET}px`, [boardHeight])

  return (
    <Box pos="relative">
      <chakra.section
        data-testid={`section-${index}`}
        borderRadius="3px"
        margin="15px 4px"
        // px="10px"
        boxSizing="border-box"
        display="flex"
        h="auto"
        w="272px"
        maxH={maxHeight}
        overflowY="hidden"
        flexDir="column"
        {...props}
      />
    </Box>
  )
}
