import { ElapsedPeriod } from './components/filters/DateRangeFilter'
import { gql_InteractionType } from './graphql'

export const it = {
  translation: {
    ca: {
      action: {
        create: 'Nuovo %{name}',
        close: 'Chiudi',
        edit: 'Modifica',
        show: 'Visualizza',
        delete: 'Elimina',
        cancel: 'Annulla',
        confirm_delete: 'Elimina %{name}',
        submit: 'Invia',
        logout: 'Logout',
      },
      page: {
        list: '%{name}',
        create: 'Nuovo %{name}',
        edit: 'Modifica %{name} #%{id}',
        show: '%{name} #%{id}',
      },
      pagination: {
        page_info: '<strong>%{from}-%{to}</strong> di <strong>%{total}</strong>',
      },
      message: {
        delete_title: 'Elimina %{name} #%{id}',
        delete_content: 'Sei sicuro di voler eliminare questa risorsa?',
        required_field: 'Questo campo è obbligatorio',
        // eslint-disable-next-line prettier/prettier
        delete_error: "Errore nell' eliminazione",
        loading: 'Caricamento in corso...',
      },
      input: {
        single_image: {
          label: 'Seleziona o rilascia immagine',
          // eslint-disable-next-line prettier/prettier
          while_dragging: "Rilascia qui l'immagine",
        },
      },
    },
    components: {
      ProjectBoard: {
        laneMenuAriaLabel: 'Opzioni Lista',
        deleteLane: 'Elimina lista',
        deleteLaneConfirm:
          'Sei sicuro di voler eliminare questa lista? Questa azione non è reversibile.',
        // eslint-disable-next-line @typescript-eslint/quotes
        createNewLane: "Aggiungi un'altra lista",
        createLanePlaceholder: 'Inserisci un nome alla lista...',
        confirmCreateLane: 'Crea lista',
        addCardLink: 'Aggiungi una scheda',
        confirmAddCard: 'Aggiungi scheda',
        cardTitlePlaceholder: 'Inserisci un titolo per questa scheda...',
      },
      DateRangeFilter: {
        periods: {
          [ElapsedPeriod.today]: 'Oggi',
          [ElapsedPeriod.yesterday]: 'Ieri',
          [ElapsedPeriod.tomorrow]: 'Domani',
          [ElapsedPeriod.this_month]: 'Questo mese',
          [ElapsedPeriod.next_month]: 'Il prossimo mese',
          [ElapsedPeriod.this_week]: 'Questa settimana',
          [ElapsedPeriod.previous_week]: 'La settimana scorsa',
          [ElapsedPeriod.next_week]: 'La settimana prossima',
          [ElapsedPeriod.this_quarter]: 'Questo trimestre',
          [ElapsedPeriod.next_quarter]: 'Il prossimo trimestre',
          [ElapsedPeriod.this_halfyear]: 'Questo semestre',
          [ElapsedPeriod.next_halfyear]: 'Il prossimo semestre',
          [ElapsedPeriod.this_year]: "Quest'anno",
          [ElapsedPeriod.next_year]: "L'anno prossimo",
        },
      },
    },
    resources: {
      Role: {
        label: 'Ruolo |||| Ruoli',
        fields: {
          name: 'Nome',
          isAdmin: 'Super Amministratore',
          isPublic: 'Pubblico',
          isLocked: 'Modifiche Abilitate',
        },
      },
      User: {
        label: 'Utente |||| Utenti',
        fields: {
          email: 'E-mail',
          password: 'Password',
          firstName: 'Nome',
          lastName: 'Cognome',
          picture: 'Immagine',
        },
        placeholders: {
          email: "Inserisci l'email dell'utente",
          password: "Inserisci la password dell'utente",
          firstName: "Inserisci il nome dell'utente",
          lastName: "Inserisci il cognome dell'utente",
        },
      },

      Organization: {
        label: 'Organizzazione |||| Organizzazioni',
        fields: {
          name: 'Nome',
        },
        placeholders: {
          // eslint-disable-next-line prettier/prettier
          name: "Inserisci il nome dell'organizzazione",
        },
      },
      Project: {
        label: 'Bacheca |||| Bacheche',
        fields: {
          name: 'Nome',
          description: 'Descrizione',
          picture: 'Immagine',
          color: 'Colore',
        },
        placeholders: {
          name: 'Inserisci il nome della bacheca',
          description: 'Descrizione della bacheca...',
          color: 'Colore di Default della bacheca',
        },
      },

      ProjectCategory: {
        label: 'Categoria Progetto |||| Categorie Progetto',
        fields: {
          name: 'Nome',
          color: 'Colore',
        },
        placeholders: {
          name: 'Inserisci il nome della categoria',
          color: 'Seleziona un colore',
        },
      },
      ProjectRole: {
        label: 'Ruolo Progetto |||| Ruoli Progetti',
        fields: {
          name: 'Nome',
        },
        placeholders: {
          name: 'Inserisci il nome del ruolo',
        },
      },
      ProjectDocument: {
        label: 'Documento |||| Documenti',
        fields: {
          name: 'Nome',
          file: 'file',
        },
        placeholders: {
          name: 'Inserisci il nome del documento',
        },
      },
      EventCategory: {
        label: 'Categoria Attività |||| Categorie Attività',
        fields: {
          name: 'Nome',
          color: 'Colore',
        },
        placeholders: {
          name: 'Inserisci il nome della categoria',
          color: 'Seleziona un colore',
        },
      },
      Supplier: {
        label: 'Fornitore |||| Fornitori',
        fields: {
          name: 'Nome',
        },
        placeholders: {
          name: 'Inserisci il nome del fornitore',
        },
      },
      TeamMember: {
        label: 'Risorsa |||| Risorse',
        fields: {
          name: 'Nome',
        },
      },
      Location: {
        label: 'Luogo |||| Luoghi',
        fields: {
          name: 'Nome',
        },
      },
      Activity: {
        label: 'Attività |||| Attività',
        fields: {
          actionsCount: 'Azioni',
          title: 'Nome Attività',
          description: 'Descrizione',
          startDate: 'Data/Ora Inizio',
          endDate: 'Data/Ora Fine',
          projectId: 'Progetto',
        },
        placeholders: {
          title: 'Inserisci un nome',
          // eslint-disable-next-line @typescript-eslint/quotes
          description: "Descrizione dell'attività...",
        },
      },
      Action: {
        label: 'Azione |||| Azioni',
        fields: {
          title: 'Nome Azione',
          description: 'Descrizione',
          startDate: 'Data/Ora Inizio',
          endDate: 'Data/Ora Fine',
        },
        placeholders: {
          title: 'Inserisci un nome',
          // eslint-disable-next-line @typescript-eslint/quotes
          description: "Descrizione dell'azione...",
        },
      },
      Document: {
        label: 'Documento |||| Documenti',
      },
      Task: {
        label: 'Task |||| Task',
        fields: {
          title: 'Titolo',
          description: 'Descrizione',
          endDate: 'Scadenza',
        },
      },
      Todo: {
        label: 'Todo |||| Todos',
        fields: {
          description: 'Descrizione',
        },
      },
      Interaction: {
        label: 'Interazione |||| Interazioni',
        types: {
          [gql_InteractionType.TaskAdded]: 'ha creato la task %{title}',
          [gql_InteractionType.TaskStatusChanged]:
            'ha aggiornato lo stato da %{beforeName} a %{afterName}',
          [gql_InteractionType.TaskAssigneeAdded]:
            'ha assegnato il task a: %{firstName} %{lastName}',
          [gql_InteractionType.TaskAssigneeRemoved]: 'ha rimosso %{firstName} %{lastName} dal task',
          [gql_InteractionType.TaskUpdated]: 'ha aggiornato il task',
          [gql_InteractionType.TaskCompleted]: 'ha completato il task',
          [gql_InteractionType.TaskUncompleted]: 'ha riaperto il task',
          [gql_InteractionType.TaskDeleted]: 'ha eliminato il task',
          [gql_InteractionType.TaskTodoAdded]: 'ha aggiunto todo %{todo}',
          [gql_InteractionType.TaskTodoUpdated]: 'ha aggiornato il todo %{todo}',
          [gql_InteractionType.TaskTodoCompleted]: 'ha completato todo %{todo}',
          [gql_InteractionType.TaskTodoUncompleted]: 'ha riaperto todo %{todo}',
          [gql_InteractionType.TaskTodoDeleted]: 'ha eliminato todo %{todo}',
        },
      },
    },
  },
}
