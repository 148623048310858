import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { Textarea, TextareaProps } from '@chakra-ui/react'
import autosize from 'autosize'
import isHotkey from 'is-hotkey'

type Props = {
  onSave?: () => void
  onCancel?: () => void
  border?: boolean
  value?: string
  autoFocus?: boolean
  resize?: 'none' | 'vertical' | 'horizontal'
  autoResize?: boolean
} & TextareaProps

export const NewLaneTitleEditor = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      onSave = () => {},
      onCancel = () => {},
      value = '',
      border = false,
      autoFocus = false,
      resize = 'none',
      autoResize,
      ...props
    },
    ref
  ) => {
    const inputRef = useRef<HTMLTextAreaElement>()
    useImperativeHandle(ref, () => {
      if (resize !== 'none') {
        autosize(inputRef.current!)
      }

      return inputRef.current!
    })

    const getValue = useCallback(() => {
      return inputRef.current?.value
    }, [])

    const setValue = useCallback((value: string) => {
      if (inputRef.current) {
        inputRef.current.value = value
      }
    }, [])

    const cancel = useCallback(() => {
      setValue('')
      onCancel()
      inputRef.current?.blur()
    }, [onCancel, setValue])

    const handleKeyDown = useCallback<React.KeyboardEventHandler<HTMLTextAreaElement>>(
      (e) => {
        if (isHotkey('shift+enter', e)) {
          e.preventDefault()
          if (inputRef.current) {
            inputRef.current.value = `${getValue()}\n`
            autosize.update(inputRef.current!)
          }
          return
        }

        if (isHotkey('enter')(e)) {
          inputRef.current?.blur()
          onSave()
          e.preventDefault()
          return
        }

        if (isHotkey('esc')(e)) {
          cancel()
          e.preventDefault()
          return
        }

        if (isHotkey('tab')(e)) {
          if (getValue()?.length === 0) {
            onCancel()
          }
          onSave()
          e.preventDefault()
          return
        }
      },
      [cancel, getValue, onCancel, onSave]
    )

    useEffect(() => {
      setValue(value)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return (
      <Textarea
        ref={inputRef as any}
        onKeyDown={handleKeyDown}
        defaultValue={value}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        dataGramm="false"
        rows={1}
        autoFocus={autoFocus}
        autoResize={autoResize}
        overflowX="hidden" /* for Firefox (issue #5) */
        wordWrap="break-word"
        minH="18px"
        maxH="112px" /* optional, but recommended */
        resize={resize}
        width="100%"
        height="18px"
        fontSize="inherit"
        fontWeight="inherit"
        lineHeight="inherit"
        textAlign="inherit"
        bgColor="transparent"
        boxShadow="none"
        boxSizing="border-box"
        border="0"
        padding="0"
        outline="0"
        pl={2}
        pr={2}
        _focus={{
          bgColor: 'white',
        }}
        {...props}
      />
    )
  }
)
