import React from 'react'
import { BoxProps } from '@chakra-ui/react'
import { InteractionProvider } from './InteractionContext'
import { InteractionList } from './InteractionList'
import { gql_InteractableType } from '../../graphql'

interface InteractionsProps extends BoxProps {
  type: gql_InteractableType
  id: string
  subscribable?: boolean
}

export const Interactions: React.FC<InteractionsProps> = ({ type, id, subscribable, ...props }) => {
  return (
    <InteractionProvider {...{ type, id }}>
      <InteractionList {...props} subscribable={subscribable} />
    </InteractionProvider>
  )
}
