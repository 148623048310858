import React, { FC } from 'react'
import { Checkbox, CheckboxProps, FormLabel, FormControl } from '@chakra-ui/react'

type Props = CheckboxProps & {
  permission: {
    id: string
    name: string
  }
}

const PermissionItem: FC<Props> = ({ permission, ...rest }) => {
  return (
    <FormControl display="flex" alignItems="center">
      <Checkbox color="primary" {...rest} />
      <FormLabel m={0} ml={3}>
        {permission.name}
      </FormLabel>
    </FormControl>
  )
}

export default PermissionItem
