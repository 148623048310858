import React, { FC } from 'react'
import { FormControl, FormLabel, Grid, Input, Stack, VStack } from '@chakra-ui/react'
import { ca, SingleImageInput, useTranslate } from 'chakra-admin'
import { MUTATION_CREATE_USER } from '../../queries'
import { RolesInput } from './RolesInput'
import { BaseForm } from '../../components/forms/BaseForm'
import { UserForm } from './UserForm'
import { Create } from '../../components/details/Create'

export const UserCreate: FC = (props) => {
  const t = useTranslate()
  return (
    <Create mutation={MUTATION_CREATE_USER} {...props}>
      <BaseForm
        defaultValues={{
          roleIds: [''],
        }}
      >
        <UserForm {...props} />

        {/** TODO: Fix me */}
        {/* <VStack pt={4} spacing={4}>
          <SingleImageInput
            bg="gray.50"
            boxShadow="sm"
            color="gray.500"
            borderRadius="full"
            maxW="130px"
            h="100%"
            source="picture.file"
          />
          <ca.FormControl source="firstName">
            <ca.FormLabel />
            <ca.Input source="firstName" />
          </ca.FormControl>
          <ca.FormControl source="lastName">
            <ca.FormLabel />
            <ca.Input source="lastName" />
          </ca.FormControl>
          <ca.FormControl source="email">
            <ca.FormLabel />
            <ca.Input source="email" />
          </ca.FormControl>
          <ca.FormControl source="password">
            <ca.FormLabel />
            <ca.Input type="password" source="password" required />
          </ca.FormControl>
          <RolesInput />
        </VStack> */}
      </BaseForm>
    </Create>
  )
}
