import React, { FC } from 'react'
import {
  Box,
  Text,
  VStack,
  BoxProps,
  IconButton,
  Icon,
  Button,
  PopoverContent,
  PopoverHeader,
  PopoverFooter,
  PopoverBody,
  Avatar,
  HStack,
} from '@chakra-ui/react'

import { SearchBox } from '../search-box/SearchBox'
import { User } from '.'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { useGetUsersQuery } from '../../graphql'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { getUserName } from '../../utils/user/getUserName'

type UserItemProps = {
  user: User
  isSelected: boolean
  onClick: (userId: string, isIn: boolean) => void
} & Omit<BoxProps, 'onClick'>

const UserItem: FC<UserItemProps> = ({ onClick, user, isSelected, ...props }) => {
  const userName = getUserName(user)

  return (
    <HStack
      px={3}
      py={1}
      w="100%"
      alignItems="center"
      justifyContent="space-between"
      bgColor={isSelected ? 'green.100' : undefined}
    >
      <Avatar size="sm" name={userName} src={user.picture?.urlSmall} />
      <Box flex={1}>
        <Text fontSize="sm">{userName}</Text>
        <Text fontSize="xs">{user.id}</Text>
      </Box>
      <IconButton
        colorScheme={isSelected ? 'red' : 'blue'}
        aria-label="select user"
        icon={<Icon as={isSelected ? FiTrash : FiPlus} fontSize="md" />}
        size="sm"
        borderRadius="50%"
        onClick={() => onClick(user.id, isSelected)}
      />
    </HStack>
  )
}

interface UserListSelectProps {
  selected: Array<{ id: string }>
  taskId: string
  onSelect: (userId: string, isIn: boolean) => void
}

export const UserListSelect: React.FC<UserListSelectProps> = ({ selected, taskId, onSelect }) => {
  const location = useLocation()
  const [searchString, setSearchString] = React.useState<string>()
  const { data } = useGetUsersQuery({
    variables: {
      filters: {
        q: searchString,
      },
    },
  })

  return (
    <Box as={PopoverContent} onClick={(event) => event.stopPropagation}>
      <PopoverHeader>Assegnatari</PopoverHeader>
      <PopoverBody p="0">
        <Box pt="2" paddingX={3}>
          <SearchBox size="sm" doSearch={setSearchString} m="0" />
        </Box>
        <VStack my={2} alignItems="flex-start" spacing={0} maxHeight="300px" overflowY="scroll">
          {data?.users?.data?.map((item) => {
            return (
              <UserItem
                key={item.id}
                user={item}
                isSelected={selected.some((selectedItem) => selectedItem.id === item.id)}
                onClick={onSelect}
              />
            )
          })}
        </VStack>
      </PopoverBody>
      <PopoverFooter>
        <Button
          size="sm"
          leftIcon={<Icon as={FiPlus} />}
          as={Link}
          to={`/User/create?linkToActivity=${taskId}`}
          state={{ background: location }}
        >
          Crea nuovo utente
        </Button>
      </PopoverFooter>
    </Box>
  )
}
