import React, { FC } from 'react'
import { Box, useBreakpointValue } from '@chakra-ui/react'
import { OfflineAlert } from 'chakra-admin'
import { Outlet } from 'react-router-dom'
import { Sidebar } from './sidebar/Sidebar'
import { MobileTopBarLight } from '../MobileTopBarLight'
import logo from '../../../assets/icons/hyperting.png'
import { InitPermissions } from '../../auth/InitPermissions'

export type RouteLayoutProps = {
  sidebar?: React.ReactNode
  showOfflineAlert?: boolean
}
export const RouteLayout: FC<RouteLayoutProps> = ({
  sidebar = <Sidebar />,
  showOfflineAlert = true,
  children,
}) => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })
  return (
    <>
      <InitPermissions />
      <Box display="flex" h="100vh" w="100vw" maxW="100%" overflow="hidden">
        {showOfflineAlert && <OfflineAlert />}
        {isMobile ? (
          <MobileTopBarLight title={`Focus ${(<br />)} Board`} icon={logo}>
            {sidebar}
          </MobileTopBarLight>
        ) : (
          sidebar
        )}
        <Box flex="1" overflowY="auto" id="route-page-content">
          <Outlet />
        </Box>
      </Box>
    </>
  )
}
