import React, { FC, useCallback, useEffect, useRef } from 'react'
import { Textarea, TextareaProps } from '@chakra-ui/react'
import autosize from 'autosize'
import isHotkey from 'is-hotkey'

type Props = {
  onSave?: (value: any) => void
  onCancel?: () => void
  border?: boolean
  placeholder?: string
  value?: string
  autoFocus?: boolean
  resize?: 'none' | 'vertical' | 'horizontal'
} & TextareaProps

export const InlineInput: FC<Props> = ({
  onSave = () => {},
  onCancel = () => {},
  placeholder = '',
  value = '',
  border = false,
  autoFocus = false,
  resize = 'none',
  ...props
}) => {
  const inputRef = useRef<HTMLTextAreaElement | undefined>()

  const getValue = useCallback(() => {
    return inputRef.current?.value
  }, [])

  const setValue = useCallback((value: string) => {
    if (inputRef.current) {
      inputRef.current.value = value
    }
  }, [])

  const updateValue = useCallback(() => {
    if (getValue() !== value) {
      onSave(getValue())
    }
  }, [getValue, onSave, value])

  const handleRef = useCallback(
    (ref: any) => {
      inputRef.current = ref
      if (resize !== 'none') {
        autosize(inputRef.current!)
      }
    },
    [resize]
  )

  const handleMouseDown = useCallback<React.MouseEventHandler<HTMLTextAreaElement>>((e) => {
    if (document.activeElement != e.target) {
      e.preventDefault()
      inputRef.current?.focus()
    }
  }, [])

  const handleFocus = useCallback<React.FocusEventHandler<HTMLTextAreaElement>>((e) => {
    e.target.select()
  }, [])

  const handleBlur = useCallback<React.FocusEventHandler<HTMLTextAreaElement>>(
    (e) => {
      updateValue()
      autosize.update(inputRef.current!)
    },
    [updateValue]
  )

  const handleKeyDown = useCallback<React.KeyboardEventHandler<HTMLTextAreaElement>>(
    (e) => {
      if (isHotkey('shift+enter', e)) {
        e.preventDefault()
        if (inputRef.current) {
          inputRef.current.value = `${getValue()}\n`
          autosize.update(inputRef.current!)
        }
        return
      }

      if (isHotkey('enter')(e)) {
        inputRef.current?.blur()
        e.preventDefault()
        return
      }

      if (isHotkey('esc')(e)) {
        setValue(value)
        inputRef.current?.blur()
        e.preventDefault()
        return
      }

      if (isHotkey('tab')(e)) {
        if (getValue()?.length === 0) {
          onCancel()
        }
        inputRef.current?.blur()
        e.preventDefault()
        return
      }
    },
    [getValue, onCancel, setValue, value]
  )

  useEffect(() => {
    setValue(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Textarea
      ref={handleRef}
      onMouseDown={handleMouseDown}
      // onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      placeholder={value.length == 0 ? undefined : placeholder}
      defaultValue={value}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      dataGramm="false"
      rows={1}
      autoFocus={autoFocus}
      overflowX="hidden" /* for Firefox (issue #5) */
      wordWrap="break-word"
      minH="18px"
      maxH="112px" /* optional, but recommended */
      // resize={resize}
      width="100%"
      height="18px"
      fontSize="inherit"
      fontWeight="inherit"
      lineHeight="inherit"
      textAlign="inherit"
      bgColor="transparent"
      boxShadow="none"
      boxSizing="border-box"
      border="0"
      padding="0"
      outline="0"
      _focus={{
        bgColor: 'white',
      }}
      {...props}
    />
  )
}
