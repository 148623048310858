import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { UnorderedList, Portal, Spinner, Box, BoxProps } from '@chakra-ui/react'
import { createEditor, Descendant, Transforms, Editor, Range } from 'slate'
import { Slate, Editable, withReact, ReactEditor } from 'slate-react'
import { withHistory } from 'slate-history'
import isHotkey from 'is-hotkey'
import { useGetUsersQuery } from '../../graphql'
import { Toolbar } from './Toolbar'
import { decorator } from './decorators'
import { UserItem } from './UserItem'
import { Leaf } from './Leaf'
import { insertMention, withMentions } from './withMentions'
import { Element } from './Element'
import { BLOCK_HOTKEYS, MARK_HOTKEYS } from './constants'
import { toggleBlock, toggleMark } from './helpers'

const defaultInitialValue = (text = ''): Descendant[] => [
  {
    type: 'paragraph',
    children: [{ text }],
  },
]

export type RichTextProps = {
  hideToolbar?: boolean
  initialValue?: string // JSON
  readonly?: boolean
  onChange?: (value: string) => void
  onSubmit?: (value: string) => void
  keepValueOnSubmit?: boolean
  boxContainer?: boolean
  placeholder?: string
  mentions?: boolean
  containerProps?: BoxProps
}

export const RichText: FC<RichTextProps> = ({
  hideToolbar,
  initialValue: value,
  readonly,
  onChange,
  onSubmit,
  keepValueOnSubmit,
  placeholder,
  boxContainer,
  mentions = true,
  containerProps,
}) => {
  const editor = useMemo(() => withMentions(withReact(withHistory(createEditor()))), [])
  const initialValue = useMemo(
    () => {
      if (!value) {
        return defaultInitialValue()
      }
      try {
        return JSON.parse(value)
      } catch (e) {
        return defaultInitialValue(value)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const [target, setTarget] = useState<Range | undefined>()
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')
  const ref = useRef<HTMLUListElement>(null)

  const renderElement = useCallback((props) => <Element {...props} />, [])
  const renderLeaf = useCallback((props) => <Leaf {...props} />, [])

  const userQuery = useGetUsersQuery({
    variables: { filters: { q: search }, pagination: { limit: 10 } },
    skip: !search,
  })

  const users = useMemo(() => userQuery.data?.users?.data || [], [userQuery.data?.users?.data])

  const handleKeyDown = useCallback<React.KeyboardEventHandler<HTMLDivElement>>(
    (event) => {
      if (target) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault()
            const prevIndex = index >= users.length - 1 ? 0 : index + 1
            setIndex(prevIndex)
            break
          case 'ArrowUp':
            event.preventDefault()
            const nextIndex = index <= 0 ? users.length - 1 : index - 1
            setIndex(nextIndex)
            break
          case 'Tab':
          case 'Enter':
            event.preventDefault()
            Transforms.select(editor, target)
            insertMention(editor, users[index])
            setTarget(undefined)
            break
          case 'Escape':
            event.preventDefault()
            setTarget(undefined)
            break
        }
      } else {
        for (const hotkey in MARK_HOTKEYS) {
          if (isHotkey(hotkey, event as any)) {
            event.preventDefault()
            const mark = MARK_HOTKEYS[hotkey as keyof typeof MARK_HOTKEYS]
            toggleMark(editor, mark)
          }
        }
        for (const hotkey in BLOCK_HOTKEYS) {
          if (isHotkey(hotkey, event as any)) {
            event.preventDefault()
            const block = BLOCK_HOTKEYS[hotkey as keyof typeof BLOCK_HOTKEYS]
            toggleBlock(editor, block)
          }
        }

        if (onSubmit && event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault()
          onSubmit(JSON.stringify(editor.children))
          if (!keepValueOnSubmit) {
            editor.history = { redos: [], undos: [] }

            Transforms.select(editor, Editor.start(editor, []))
            editor.children = defaultInitialValue()
          }
        }
      }
    },
    [target, index, users, editor, onSubmit, keepValueOnSubmit]
  )

  useEffect(() => {
    if (readonly === false) {
      setTimeout(() => {
        Editor.withoutNormalizing(editor, () => {
          Transforms.deselect(editor)
          Transforms.select(editor, Editor.end(editor, []))
        })
        ReactEditor.focus(editor)
      }, 300)
    }
  }, [editor, readonly])

  const handleChange = useCallback(
    (value: Descendant[], onValueChange) => {
      const selection = editor.selection

      if (mentions && selection && Range.isCollapsed(selection)) {
        const [start] = Range.edges(selection)
        const wordBefore = Editor.before(editor, start, { unit: 'word' })
        const before = wordBefore && Editor.before(editor, wordBefore)
        const beforeRange = before && Editor.range(editor, before, start)
        const beforeText = beforeRange && Editor.string(editor, beforeRange)
        const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
        const after = Editor.after(editor, start)
        const afterRange = Editor.range(editor, start, after)
        const afterText = Editor.string(editor, afterRange)
        const afterMatch = afterText.match(/^(\s|$)/)

        if (beforeMatch && afterMatch) {
          setTarget(beforeRange)
          setSearch(beforeMatch[1])
          setIndex(0)
          return
        }
      }

      setTarget(undefined)

      if (onValueChange) {
        const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type)
        if (isAstChange) {
          onValueChange(JSON.stringify(value))
        }
      }
    },
    [editor, mentions]
  )

  useEffect(() => {
    if (target && users.length > 0) {
      const timeout = setTimeout(() => {
        try {
          const el = ref.current
          const domRange = ReactEditor.toDOMRange(editor, target)
          const rect = domRange.getBoundingClientRect()
          if (el) {
            el.style!.top = `${rect.top + window.pageYOffset + 24}px`
            el.style!.left = `${rect.left + window.pageXOffset}px`
          }
        } catch (e) {
          console.log(e)
        }
      }, 10)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [editor, index, search, target, users])

  return (
    <Box
      w="100%"
      __css={
        boxContainer
          ? {
              borderColor: 'gray.200',
              borderWidth: '1px',
              pt: '1',
              pb: '2',
              px: '2',
              borderRadius: 'md',
            }
          : undefined
      }
      {...containerProps}
    >
      <Slate
        editor={editor}
        value={initialValue}
        onChange={(value) => handleChange(value, onChange)}
      >
        <Editable
          placeholder={placeholder ?? 'Use @ to mention someone'}
          style={{ fontSize: '14px', color: 'gray.500', marginTop: '4px' }}
          onKeyDown={handleKeyDown}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          decorate={decorator}
          readOnly={readonly}
          onBlur={() => {
            setTimeout(() => setTarget(undefined), 500)
          }}
        />

        {!hideToolbar && !readonly && <Toolbar />}

        {target && users.length > 0 && (
          <Portal>
            <UnorderedList
              ref={ref}
              position="absolute"
              zIndex={1}
              p={2}
              borderRadius="md"
              backgroundColor="white"
              boxShadow="base"
              // need use style to override with the right position
              style={{
                top: '-9999px',
                left: '-9999px',
              }}
              data-cy="mentions-portal"
            >
              {userQuery.loading && <Spinner size="sm" />}
              {users.map((user, i) => (
                <UserItem
                  key={user.id}
                  isSelected={i === index}
                  user={user}
                  onMouseEnter={() => setIndex(i)}
                  onClick={() => {
                    Transforms.select(editor, target)
                    insertMention(editor, user)
                    setTarget(undefined)
                  }}
                />
              ))}
            </UnorderedList>
          </Portal>
        )}
      </Slate>
    </Box>
  )
}
