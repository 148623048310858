import React, { FC } from 'react'
import {
  Box,
  VStack,
  BoxProps,
  IconButton,
  Icon,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Flex,
  Heading,
} from '@chakra-ui/react'

import { FiRefreshCw } from 'react-icons/fi'
import invert from 'invert-color'
import { BsCheck } from 'react-icons/bs'
import { NetworkStatus } from '@apollo/client'
import { SearchBox } from '../../search-box/SearchBox'
import { gql_TaskStatus, useGetTaskStatusesQuery } from '../../../graphql'

type ItemData = Pick<gql_TaskStatus, 'id' | 'name' | 'color'>

type ItemProps = {
  item: ItemData
  isSelected: boolean
} & BoxProps

const Item: FC<ItemProps> = ({ item, isSelected, ...props }) => {
  return (
    <Flex paddingX={3} w="100%">
      <Flex
        flexGrow={1}
        px={3}
        py={2}
        opacity={0.9}
        alignItems="center"
        _hover={{
          opacity: 1,
        }}
        cursor="pointer"
        borderRadius="md"
        bgColor={item?.color ? `${item?.color}BF` : undefined}
        color={item?.color ? invert(item.color, true) : undefined}
        fontWeight="bold"
        textTransform="uppercase"
        justifyContent="space-between"
        fontSize="xs"
        {...props}
      >
        {item.name}

        {isSelected && (
          <Icon
            fontSize="xl"
            color={item?.color ? invert(item.color, true) : undefined}
            as={BsCheck}
          />
        )}
      </Flex>
    </Flex>
  )
}

interface TaskStatusListProps {
  projectId?: string
  selected?: ItemData
  onSelect: (item: ItemData) => void
}

export const TaskStatusList = ({ selected, onSelect, projectId }: TaskStatusListProps) => {
  const [searchString, setSearchString] = React.useState<string>()
  const { data, refetch, networkStatus } = useGetTaskStatusesQuery({
    variables: {
      filters: {
        projectId,
        name: searchString,
      },
    },
  })

  return (
    <Box as={PopoverContent}>
      <PopoverHeader>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="sm">Categorie</Heading>
          <IconButton
            size="xs"
            marginLeft="2"
            aria-label="Refresh todos list"
            onClick={() => refetch()}
            icon={<Icon as={FiRefreshCw} />}
            isLoading={networkStatus === NetworkStatus.refetch}
          />
        </Flex>
      </PopoverHeader>
      <PopoverBody p="0">
        <Box pt="2" paddingX={3}>
          <SearchBox size="sm" doSearch={setSearchString} m="0" />
        </Box>
        <VStack my={3} alignItems="flex-start" spacing={1} maxHeight="300px" overflowY="auto">
          {data?.taskStatuses?.data?.map((item) => {
            return (
              <Item
                key={item.id}
                item={item}
                isSelected={selected?.id === item.id}
                onClick={() => onSelect(item)}
              />
            )
          })}
        </VStack>
      </PopoverBody>
    </Box>
  )
}
