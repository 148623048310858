import { ApolloClient, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { StrictTypedTypePolicies } from './apollo-helpers'

const typePolicies: StrictTypedTypePolicies = {
  Task: {
    fields: {
      tags: {
        merge: false,
      },
      assignees: {
        merge: false,
      },
    },
  },
}

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies,
  }),
  link: createUploadLink({
    uri: process.env.REACT_APP_API_URI,
    credentials: 'include',
  }),
})
