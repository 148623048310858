import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateOnly: any;
  DateTime: any;
  JSONObject: any;
  Upload: any;
};

export type gql_CredentialsInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe?: InputMaybe<Scalars['Boolean']>;
};

export type gql_DateOnlyRange = {
  end?: InputMaybe<Scalars['DateOnly']>;
  start?: InputMaybe<Scalars['DateOnly']>;
};

export type gql_InteractableInput = {
  id: Scalars['ID'];
  type: gql_InteractableType;
};

/** Type of an interactable entity */
export enum gql_InteractableType {
  Project = 'PROJECT',
  Task = 'TASK'
}

export type gql_Interaction = {
  __typename?: 'Interaction';
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['JSONObject']>;
  id: Scalars['ID'];
  type: gql_InteractionType;
  user: gql_User;
};

export type gql_InteractionFilterInput = {
  comment?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  types?: InputMaybe<Array<gql_InteractionType>>;
};

export type gql_InteractionSortInput = {
  createdAt?: InputMaybe<gql_SortDirection>;
  id?: InputMaybe<gql_SortDirection>;
  updatedAt?: InputMaybe<gql_SortDirection>;
};

/** Type of an interaction */
export enum gql_InteractionType {
  Comment = 'COMMENT',
  TaskAdded = 'TASK_ADDED',
  TaskAssigneeAdded = 'TASK_ASSIGNEE_ADDED',
  TaskAssigneeRemoved = 'TASK_ASSIGNEE_REMOVED',
  TaskCompleted = 'TASK_COMPLETED',
  TaskDeleted = 'TASK_DELETED',
  TaskStatusChanged = 'TASK_STATUS_CHANGED',
  TaskTodoAdded = 'TASK_TODO_ADDED',
  TaskTodoCompleted = 'TASK_TODO_COMPLETED',
  TaskTodoDeleted = 'TASK_TODO_DELETED',
  TaskTodoUncompleted = 'TASK_TODO_UNCOMPLETED',
  TaskTodoUpdated = 'TASK_TODO_UPDATED',
  TaskUncompleted = 'TASK_UNCOMPLETED',
  TaskUpdated = 'TASK_UPDATED'
}

export type gql_ListInteractionResponse = {
  __typename?: 'ListInteractionResponse';
  data: Array<gql_Interaction>;
  error?: Maybe<Scalars['String']>;
  isError: Scalars['Boolean'];
  isPaginated: Scalars['Boolean'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type gql_ListPermissionCategoryryResponse = {
  __typename?: 'ListPermissionCategoryryResponse';
  data: Array<gql_PermissionCategory>;
  error?: Maybe<Scalars['String']>;
  isError: Scalars['Boolean'];
  isPaginated: Scalars['Boolean'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type gql_ListPermissionRoleryResponse = {
  __typename?: 'ListPermissionRoleryResponse';
  data: Array<gql_PermissionRole>;
  error?: Maybe<Scalars['String']>;
  isError: Scalars['Boolean'];
  isPaginated: Scalars['Boolean'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type gql_ListPermissionryResponse = {
  __typename?: 'ListPermissionryResponse';
  data: Array<gql_Permission>;
  error?: Maybe<Scalars['String']>;
  isError: Scalars['Boolean'];
  isPaginated: Scalars['Boolean'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type gql_ListPictureResponse = {
  __typename?: 'ListPictureResponse';
  data: Array<gql_Picture>;
  error?: Maybe<Scalars['String']>;
  isError: Scalars['Boolean'];
  isPaginated: Scalars['Boolean'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type gql_ListProjectResponse = {
  __typename?: 'ListProjectResponse';
  data: Array<gql_Project>;
  error?: Maybe<Scalars['String']>;
  isError: Scalars['Boolean'];
  isPaginated: Scalars['Boolean'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type gql_ListRoleResponse = {
  __typename?: 'ListRoleResponse';
  data: Array<gql_Role>;
  error?: Maybe<Scalars['String']>;
  isError: Scalars['Boolean'];
  isPaginated: Scalars['Boolean'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type gql_ListTagResponse = {
  __typename?: 'ListTagResponse';
  data: Array<gql_Tag>;
  error?: Maybe<Scalars['String']>;
  isError: Scalars['Boolean'];
  isPaginated: Scalars['Boolean'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type gql_ListTaskAttachmentResponse = {
  __typename?: 'ListTaskAttachmentResponse';
  data: Array<gql_TaskAttachment>;
  error?: Maybe<Scalars['String']>;
  isError: Scalars['Boolean'];
  isPaginated: Scalars['Boolean'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type gql_ListTaskResponse = {
  __typename?: 'ListTaskResponse';
  data: Array<gql_Task>;
  error?: Maybe<Scalars['String']>;
  isError: Scalars['Boolean'];
  isPaginated: Scalars['Boolean'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type gql_ListTaskStatusResponse = {
  __typename?: 'ListTaskStatusResponse';
  data: Array<gql_TaskStatus>;
  error?: Maybe<Scalars['String']>;
  isError: Scalars['Boolean'];
  isPaginated: Scalars['Boolean'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type gql_ListTaskTodoResponse = {
  __typename?: 'ListTaskTodoResponse';
  data: Array<gql_TaskTodo>;
  error?: Maybe<Scalars['String']>;
  isError: Scalars['Boolean'];
  isPaginated: Scalars['Boolean'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type gql_ListUserResponse = {
  __typename?: 'ListUserResponse';
  data: Array<gql_User>;
  error?: Maybe<Scalars['String']>;
  isError: Scalars['Boolean'];
  isPaginated: Scalars['Boolean'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type gql_Mutation = {
  __typename?: 'Mutation';
  addAssigneeToTask: gql_Task;
  addTagToTask: gql_Task;
  addUserToProject: gql_User;
  createComment: gql_Interaction;
  createNotificationToken: Scalars['String'];
  createPermissionCategory: gql_PermissionCategory;
  createPermissionRole: gql_PermissionRole;
  createPicture: gql_Picture;
  createProject: gql_Project;
  createRole: gql_Role;
  createTag: gql_Tag;
  createTask: gql_Task;
  createTaskAttachment: gql_TaskAttachment;
  createTaskStatus: gql_TaskStatus;
  createTaskTodo: gql_TaskTodo;
  createUser: gql_User;
  deleteComment: Scalars['Boolean'];
  deletePermissionCategory: Scalars['Boolean'];
  deletePermissionRole: Scalars['Boolean'];
  deletePicture: Scalars['Boolean'];
  deleteProject: Scalars['Boolean'];
  deleteRole: Scalars['Boolean'];
  deleteTag: Scalars['Boolean'];
  deleteTask: Scalars['Boolean'];
  deleteTaskAttachment: Scalars['Boolean'];
  deleteTaskStatus: Scalars['Boolean'];
  deleteTaskTodo: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  login: gql_User;
  logout: Scalars['Boolean'];
  removeAssigneeFromTask: gql_Task;
  removeTagFromTask: gql_Task;
  removeUserFromProject: Scalars['Boolean'];
  subscribe: Scalars['Boolean'];
  unsubscribe: Scalars['Boolean'];
  updateComment: gql_Interaction;
  updatePermission?: Maybe<gql_Permission>;
  updatePermissionCategory: gql_PermissionCategory;
  updatePermissionRole: gql_PermissionRole;
  updatePicture: gql_Picture;
  updateProject: gql_Project;
  updateRole: gql_Role;
  updateTag: gql_Tag;
  updateTask: gql_Task;
  updateTaskAttachment: gql_TaskAttachment;
  updateTaskOrder: Scalars['Boolean'];
  updateTaskStatus: gql_TaskStatus;
  updateTaskStatusesOrder: Scalars['Boolean'];
  updateTaskTodo: gql_TaskTodo;
  updateTaskTodoPosition: Array<gql_TaskTodo>;
  updateUser: gql_User;
};


export type gql_MutationAddAssigneeToTaskArgs = {
  taskId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type gql_MutationAddTagToTaskArgs = {
  tagId: Scalars['ID'];
  taskId: Scalars['ID'];
};


export type gql_MutationAddUserToProjectArgs = {
  projectId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type gql_MutationCreateCommentArgs = {
  comment: Scalars['String'];
  on: gql_InteractableInput;
};


export type gql_MutationCreatePermissionCategoryArgs = {
  data: gql_PermissionCategoryCreateInput;
};


export type gql_MutationCreatePermissionRoleArgs = {
  data: gql_PermissionRoleCreateInput;
};


export type gql_MutationCreatePictureArgs = {
  data: gql_PictureCreateInput;
};


export type gql_MutationCreateProjectArgs = {
  data: gql_ProjectCreateInput;
};


export type gql_MutationCreateRoleArgs = {
  data: gql_RoleCreateInput;
};


export type gql_MutationCreateTagArgs = {
  data: gql_TagCreateInput;
};


export type gql_MutationCreateTaskArgs = {
  data: gql_TaskCreateInput;
};


export type gql_MutationCreateTaskAttachmentArgs = {
  data: gql_TaskAttachmentCreateInput;
};


export type gql_MutationCreateTaskStatusArgs = {
  data: gql_TaskStatusCreateInput;
};


export type gql_MutationCreateTaskTodoArgs = {
  data: gql_TaskTodoCreateInput;
};


export type gql_MutationCreateUserArgs = {
  data: gql_UserCreateInput;
};


export type gql_MutationDeleteCommentArgs = {
  id: Scalars['ID'];
};


export type gql_MutationDeletePermissionCategoryArgs = {
  id: Scalars['ID'];
};


export type gql_MutationDeletePermissionRoleArgs = {
  id: Scalars['ID'];
};


export type gql_MutationDeletePictureArgs = {
  id: Scalars['ID'];
};


export type gql_MutationDeleteProjectArgs = {
  id: Scalars['ID'];
};


export type gql_MutationDeleteRoleArgs = {
  id: Scalars['ID'];
};


export type gql_MutationDeleteTagArgs = {
  id: Scalars['ID'];
};


export type gql_MutationDeleteTaskArgs = {
  id: Scalars['ID'];
};


export type gql_MutationDeleteTaskAttachmentArgs = {
  id: Scalars['ID'];
};


export type gql_MutationDeleteTaskStatusArgs = {
  id: Scalars['ID'];
};


export type gql_MutationDeleteTaskTodoArgs = {
  id: Scalars['ID'];
};


export type gql_MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type gql_MutationLoginArgs = {
  credentials: gql_CredentialsInput;
};


export type gql_MutationRemoveAssigneeFromTaskArgs = {
  taskId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type gql_MutationRemoveTagFromTaskArgs = {
  tagId: Scalars['ID'];
  taskId: Scalars['ID'];
};


export type gql_MutationRemoveUserFromProjectArgs = {
  projectId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type gql_MutationSubscribeArgs = {
  to: gql_InteractableInput;
};


export type gql_MutationUnsubscribeArgs = {
  to: gql_InteractableInput;
};


export type gql_MutationUpdateCommentArgs = {
  comment: Scalars['String'];
  id: Scalars['ID'];
};


export type gql_MutationUpdatePermissionArgs = {
  data: gql_PermissionUpdateInput;
  id: Scalars['ID'];
};


export type gql_MutationUpdatePermissionCategoryArgs = {
  data: gql_PermissionCategoryUpdateInput;
  id: Scalars['ID'];
};


export type gql_MutationUpdatePermissionRoleArgs = {
  data: gql_PermissionRoleUpdateInput;
  id: Scalars['ID'];
};


export type gql_MutationUpdatePictureArgs = {
  data: gql_PictureUpdateInput;
  id: Scalars['ID'];
};


export type gql_MutationUpdateProjectArgs = {
  data: gql_ProjectUpdateInput;
  id: Scalars['ID'];
};


export type gql_MutationUpdateRoleArgs = {
  data: gql_RoleUpdateInput;
  id: Scalars['ID'];
};


export type gql_MutationUpdateTagArgs = {
  data: gql_TagUpdateInput;
  id: Scalars['ID'];
};


export type gql_MutationUpdateTaskArgs = {
  data: gql_TaskUpdateInput;
  id: Scalars['ID'];
};


export type gql_MutationUpdateTaskAttachmentArgs = {
  data: gql_TaskAttachmentUpdateInput;
  id: Scalars['ID'];
};


export type gql_MutationUpdateTaskOrderArgs = {
  data: Array<gql_TaskOrderUpdateInput>;
};


export type gql_MutationUpdateTaskStatusArgs = {
  data: gql_TaskStatusUpdateInput;
  id: Scalars['ID'];
};


export type gql_MutationUpdateTaskStatusesOrderArgs = {
  data: Array<gql_TaskStatusesOrderUpdateInput>;
};


export type gql_MutationUpdateTaskTodoArgs = {
  data: gql_TaskTodoUpdateInput;
  id: Scalars['ID'];
};


export type gql_MutationUpdateTaskTodoPositionArgs = {
  id: Scalars['ID'];
  over: Scalars['ID'];
};


export type gql_MutationUpdateUserArgs = {
  data: gql_UserUpdateInput;
  id: Scalars['ID'];
};

export type gql_PaginationInputType = {
  disabled?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  /** Number of pages (offset * limit) to skip */
  offset?: InputMaybe<Scalars['Int']>;
  /** Number of records to skip */
  skip?: InputMaybe<Scalars['Int']>;
};

export type gql_Permission = {
  __typename?: 'Permission';
  category?: Maybe<gql_PermissionCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<gql_Role>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type gql_PermissionCategory = {
  __typename?: 'PermissionCategory';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type gql_PermissionCategoryCreateInput = {
  name: Scalars['String'];
};

export type gql_PermissionCategoryFilterInput = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  q?: InputMaybe<Scalars['String']>;
};

export type gql_PermissionCategorySortInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type gql_PermissionCategoryUpdateInput = {
  name: Scalars['String'];
};

export type gql_PermissionFilterInput = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  q?: InputMaybe<Scalars['String']>;
  roleIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type gql_PermissionRole = {
  __typename?: 'PermissionRole';
  permission?: Maybe<gql_Permission>;
  permissionId: Scalars['Float'];
  role?: Maybe<gql_Role>;
  roleId: Scalars['Float'];
};

export type gql_PermissionRoleCreateInput = {
  permissionId: Scalars['ID'];
  roleId: Scalars['ID'];
};

export type gql_PermissionRoleFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type gql_PermissionRoleSortInput = {
  categoryId?: InputMaybe<Scalars['ID']>;
  permissionName?: InputMaybe<Scalars['String']>;
};

export type gql_PermissionRoleUpdateInput = {
  permissionId?: InputMaybe<Scalars['ID']>;
  roleId?: InputMaybe<Scalars['ID']>;
};

export type gql_PermissionSortInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type gql_PermissionUpdateInput = {
  name?: InputMaybe<Scalars['String']>;
  permissionCategoryId?: InputMaybe<Scalars['ID']>;
  roleIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type gql_Picture = {
  __typename?: 'Picture';
  id?: Maybe<Scalars['ID']>;
  largeHeight?: Maybe<Scalars['Float']>;
  largeWidth?: Maybe<Scalars['Float']>;
  mediumHeight?: Maybe<Scalars['Float']>;
  mediumWidth?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  smallHeight?: Maybe<Scalars['Float']>;
  smallWidth?: Maybe<Scalars['Float']>;
  urlLarge?: Maybe<Scalars['String']>;
  urlMedium?: Maybe<Scalars['String']>;
  urlSmall?: Maybe<Scalars['String']>;
};

export type gql_PictureCreateInput = {
  file: Scalars['Upload'];
  mediaType?: InputMaybe<gql_PictureMediaType>;
  name?: InputMaybe<Scalars['String']>;
};

export type gql_PictureFilterInput = {
  featured?: InputMaybe<Scalars['Boolean']>;
  galleryId?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
};

/** Media Type */
export enum gql_PictureMediaType {
  Jpeg = 'jpeg',
  Png = 'png'
}

export type gql_PictureSortInput = {
  createdAt?: InputMaybe<gql_SortDirection>;
  id?: InputMaybe<gql_SortDirection>;
  updatedAt?: InputMaybe<gql_SortDirection>;
};

export type gql_PictureUpdateInput = {
  file?: InputMaybe<Scalars['Upload']>;
  mediaType?: InputMaybe<gql_PictureMediaType>;
  name?: InputMaybe<Scalars['String']>;
};

export type gql_Project = {
  __typename?: 'Project';
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<gql_Picture>;
  pictureId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['DateTime']>;
  tasksCount?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visibility: gql_ProjectVisibility;
};

export type gql_ProjectCreateInput = {
  color?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  picture?: InputMaybe<gql_PictureUpdateInput>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  visibility: gql_ProjectVisibility;
};

export type gql_ProjectFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type gql_ProjectSortInput = {
  createdAt?: InputMaybe<gql_SortDirection>;
  id?: InputMaybe<gql_SortDirection>;
  name?: InputMaybe<gql_SortDirection>;
  updatedAt?: InputMaybe<gql_SortDirection>;
};

export type gql_ProjectUpdateInput = {
  color?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<gql_PictureUpdateInput>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  visibility?: InputMaybe<gql_ProjectVisibility>;
};

/** Visibility of a project */
export enum gql_ProjectVisibility {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type gql_Query = {
  __typename?: 'Query';
  interaction?: Maybe<gql_Interaction>;
  interactions?: Maybe<gql_ListInteractionResponse>;
  isSubscribed: Scalars['Boolean'];
  permission?: Maybe<gql_Permission>;
  permissionCategories?: Maybe<gql_ListPermissionCategoryryResponse>;
  permissionCategory?: Maybe<gql_PermissionCategory>;
  permissionRole?: Maybe<gql_PermissionRole>;
  permissionRoles?: Maybe<gql_ListPermissionRoleryResponse>;
  permissions?: Maybe<gql_ListPermissionryResponse>;
  picture?: Maybe<gql_Picture>;
  pictures?: Maybe<gql_ListPictureResponse>;
  project?: Maybe<gql_Project>;
  projects?: Maybe<gql_ListProjectResponse>;
  role?: Maybe<gql_Role>;
  roles?: Maybe<gql_ListRoleResponse>;
  tag?: Maybe<gql_Tag>;
  tags?: Maybe<gql_ListTagResponse>;
  task?: Maybe<gql_Task>;
  taskAttachment?: Maybe<gql_TaskAttachment>;
  taskAttachments?: Maybe<gql_ListTaskAttachmentResponse>;
  taskStatus?: Maybe<gql_TaskStatus>;
  taskStatuses?: Maybe<gql_ListTaskStatusResponse>;
  taskTodo?: Maybe<gql_TaskTodo>;
  taskTodos?: Maybe<gql_ListTaskTodoResponse>;
  tasks?: Maybe<gql_ListTaskResponse>;
  user?: Maybe<gql_User>;
  userMe?: Maybe<gql_User>;
  users?: Maybe<gql_ListUserResponse>;
};


export type gql_QueryInteractionArgs = {
  id: Scalars['ID'];
};


export type gql_QueryInteractionsArgs = {
  filters?: InputMaybe<gql_InteractionFilterInput>;
  on: gql_InteractableInput;
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_InteractionSortInput>;
};


export type gql_QueryIsSubscribedArgs = {
  to: gql_InteractableInput;
};


export type gql_QueryPermissionArgs = {
  id: Scalars['ID'];
};


export type gql_QueryPermissionCategoriesArgs = {
  filters?: InputMaybe<gql_PermissionCategoryFilterInput>;
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_PermissionCategorySortInput>;
};


export type gql_QueryPermissionCategoryArgs = {
  id: Scalars['ID'];
};


export type gql_QueryPermissionRoleArgs = {
  id: Scalars['ID'];
};


export type gql_QueryPermissionRolesArgs = {
  filters?: InputMaybe<gql_PermissionRoleFilterInput>;
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_PermissionRoleSortInput>;
};


export type gql_QueryPermissionsArgs = {
  filters?: InputMaybe<gql_PermissionFilterInput>;
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_PermissionSortInput>;
};


export type gql_QueryPictureArgs = {
  id: Scalars['ID'];
};


export type gql_QueryPicturesArgs = {
  filters?: InputMaybe<gql_PictureFilterInput>;
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_PictureSortInput>;
};


export type gql_QueryProjectArgs = {
  id: Scalars['ID'];
};


export type gql_QueryProjectsArgs = {
  filters?: InputMaybe<gql_ProjectFilterInput>;
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_ProjectSortInput>;
};


export type gql_QueryRoleArgs = {
  id: Scalars['ID'];
};


export type gql_QueryRolesArgs = {
  filters?: InputMaybe<gql_RoleFilterInput>;
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_RoleSortInput>;
};


export type gql_QueryTagArgs = {
  id: Scalars['ID'];
};


export type gql_QueryTagsArgs = {
  filters?: InputMaybe<gql_TagFilterInput>;
  pagination?: InputMaybe<gql_PaginationInputType>;
  projectId: Scalars['ID'];
  sort?: InputMaybe<gql_TagSortInput>;
};


export type gql_QueryTaskArgs = {
  id: Scalars['ID'];
};


export type gql_QueryTaskAttachmentArgs = {
  id: Scalars['ID'];
};


export type gql_QueryTaskAttachmentsArgs = {
  filters?: InputMaybe<gql_TaskAttachmentFilterInput>;
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_TaskAttachmentSortInput>;
};


export type gql_QueryTaskStatusArgs = {
  id: Scalars['ID'];
};


export type gql_QueryTaskStatusesArgs = {
  filters?: InputMaybe<gql_TaskStatusFilterInput>;
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_TaskStatusSortInput>;
};


export type gql_QueryTaskTodoArgs = {
  id: Scalars['ID'];
};


export type gql_QueryTaskTodosArgs = {
  filters?: InputMaybe<gql_TaskTodoFilterInput>;
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_TaskTodoSortInput>;
  taskId: Scalars['ID'];
};


export type gql_QueryTasksArgs = {
  filters?: InputMaybe<gql_TaskFilterInput>;
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_TaskSortInput>;
};


export type gql_QueryUserArgs = {
  id: Scalars['ID'];
};


export type gql_QueryUsersArgs = {
  filters?: InputMaybe<gql_UserFilterInput>;
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_UserSortInput>;
};

export type gql_Role = {
  __typename?: 'Role';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  params?: Maybe<Scalars['JSONObject']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<Array<gql_User>>;
};

export type gql_RoleCreateInput = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type gql_RoleFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type gql_RoleSortInput = {
  createdAt?: InputMaybe<gql_SortDirection>;
  id?: InputMaybe<gql_SortDirection>;
  isAdmin?: InputMaybe<gql_SortDirection>;
  isLocked?: InputMaybe<gql_SortDirection>;
  isPublic?: InputMaybe<gql_SortDirection>;
  name?: InputMaybe<gql_SortDirection>;
  updatedAt?: InputMaybe<gql_SortDirection>;
};

export type gql_RoleUpdateInput = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Sort Directions */
export enum gql_SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type gql_Tag = {
  __typename?: 'Tag';
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
};

export type gql_TagCreateInput = {
  color: Scalars['String'];
  name: Scalars['String'];
  projectId: Scalars['ID'];
};

export type gql_TagFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  q?: InputMaybe<Scalars['String']>;
};

export type gql_TagSortInput = {
  createdAt?: InputMaybe<gql_SortDirection>;
  id?: InputMaybe<gql_SortDirection>;
  name?: InputMaybe<gql_SortDirection>;
  updatedAt?: InputMaybe<gql_SortDirection>;
};

export type gql_TagUpdateInput = {
  color?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type gql_Task = {
  __typename?: 'Task';
  assignees?: Maybe<Array<gql_User>>;
  attachments?: Maybe<Array<gql_TaskAttachment>>;
  color?: Maybe<Scalars['String']>;
  commentCount: Scalars['Int'];
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  order: Scalars['Int'];
  project?: Maybe<gql_Project>;
  projectId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<gql_TaskStatus>;
  tags?: Maybe<Array<gql_Tag>>;
  taskStatusId: Scalars['ID'];
  title: Scalars['String'];
  todos?: Maybe<Array<gql_TaskTodo>>;
  updatedAt: Scalars['DateTime'];
};

export type gql_TaskAttachment = {
  __typename?: 'TaskAttachment';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  isCover?: Maybe<Scalars['Boolean']>;
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<gql_Picture>;
  pictureId?: Maybe<Scalars['ID']>;
  taskId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type gql_TaskAttachmentCreateInput = {
  file: Scalars['Upload'];
  name?: InputMaybe<Scalars['String']>;
  taskId: Scalars['ID'];
};

export type gql_TaskAttachmentFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  taskId?: InputMaybe<Scalars['ID']>;
};

export type gql_TaskAttachmentSortInput = {
  createdAt?: InputMaybe<gql_SortDirection>;
  id?: InputMaybe<gql_SortDirection>;
  name?: InputMaybe<gql_SortDirection>;
  updatedAt?: InputMaybe<gql_SortDirection>;
};

export type gql_TaskAttachmentUpdateInput = {
  file?: InputMaybe<Scalars['Upload']>;
  name?: InputMaybe<Scalars['String']>;
};

export type gql_TaskCreateInput = {
  assigneeIds?: InputMaybe<Array<Scalars['ID']>>;
  color?: InputMaybe<Scalars['String']>;
  completionDate?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  order: Scalars['Int'];
  projectId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  taskStatusId: Scalars['ID'];
  title: Scalars['String'];
  todos?: InputMaybe<Array<gql_TaskTodoUpsertInput>>;
};

export type gql_TaskFilterInput = {
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<gql_DateOnlyRange>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  projectId?: InputMaybe<Scalars['ID']>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  taskStatusId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type gql_TaskOrderUpdateInput = {
  id: Scalars['ID'];
  order: Scalars['Int'];
};

export type gql_TaskSortInput = {
  createdAt?: InputMaybe<gql_SortDirection>;
  endDate?: InputMaybe<gql_SortDirection>;
  id?: InputMaybe<gql_SortDirection>;
  order?: InputMaybe<gql_SortDirection>;
  startDate?: InputMaybe<gql_SortDirection>;
  updatedAt?: InputMaybe<gql_SortDirection>;
};

export type gql_TaskStatus = {
  __typename?: 'TaskStatus';
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type gql_TaskStatusCreateInput = {
  color?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  projectId: Scalars['ID'];
};

export type gql_TaskStatusFilterInput = {
  color?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['ID']>;
};

export type gql_TaskStatusSortInput = {
  createdAt?: InputMaybe<gql_SortDirection>;
  id?: InputMaybe<gql_SortDirection>;
  name?: InputMaybe<gql_SortDirection>;
  order?: InputMaybe<gql_SortDirection>;
  updatedAt?: InputMaybe<gql_SortDirection>;
};

export type gql_TaskStatusUpdateInput = {
  color?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['ID']>;
};

export type gql_TaskStatusesOrderUpdateInput = {
  id: Scalars['ID'];
  order: Scalars['Int'];
};

export type gql_TaskTodo = {
  __typename?: 'TaskTodo';
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  isDone: Scalars['Boolean'];
  position: Scalars['Int'];
  taskId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type gql_TaskTodoCreateInput = {
  description: Scalars['String'];
  taskId: Scalars['ID'];
};

export type gql_TaskTodoFilterInput = {
  description?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isDone?: InputMaybe<Scalars['Boolean']>;
};

export type gql_TaskTodoSortInput = {
  createdAt?: InputMaybe<gql_SortDirection>;
  description?: InputMaybe<gql_SortDirection>;
  id?: InputMaybe<gql_SortDirection>;
  isDone?: InputMaybe<gql_SortDirection>;
  position?: InputMaybe<gql_SortDirection>;
  updatedAt?: InputMaybe<gql_SortDirection>;
};

export type gql_TaskTodoUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  isDone?: InputMaybe<Scalars['Boolean']>;
};

export type gql_TaskTodoUpsertInput = {
  description: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  isDone: Scalars['Boolean'];
  position: Scalars['Int'];
};

export type gql_TaskUpdateInput = {
  assigneeIds?: InputMaybe<Array<Scalars['ID']>>;
  color?: InputMaybe<Scalars['String']>;
  completionDate?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  order?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  taskStatusId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  todos?: InputMaybe<Array<gql_TaskTodoUpsertInput>>;
};

export type gql_User = {
  __typename?: 'User';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  picture?: Maybe<gql_Picture>;
  pictureId?: Maybe<Scalars['ID']>;
  roles?: Maybe<Array<gql_Role>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type gql_UserCreateInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  picture?: InputMaybe<gql_PictureUpdateInput>;
  roleIds: Array<Scalars['ID']>;
};

export type gql_UserFilterInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  lastName?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['ID']>;
  q?: InputMaybe<Scalars['String']>;
};

export type gql_UserSortInput = {
  createdAt?: InputMaybe<gql_SortDirection>;
  email?: InputMaybe<gql_SortDirection>;
  firstName?: InputMaybe<gql_SortDirection>;
  id?: InputMaybe<gql_SortDirection>;
  lastName?: InputMaybe<gql_SortDirection>;
  updatedAt?: InputMaybe<gql_SortDirection>;
};

export type gql_UserUpdateInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<gql_PictureUpdateInput>;
  roleIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type gql_DeleteProjectMuMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_DeleteProjectMuMutation = { __typename?: 'Mutation', deleteProject: boolean };

export type gql_GetTaskAssigneesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_GetTaskAssigneesQuery = { __typename?: 'Query', task?: { __typename?: 'Task', id: string, assignees?: Array<{ __typename?: 'User', id: string, email?: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', id?: string, urlSmall?: string }, roles?: Array<{ __typename?: 'Role', id?: string, name?: string, params?: any, isAdmin?: boolean }> }> } };

export type gql_TagFragmentFragment = { __typename?: 'Tag', id: string, name: string, color: string };

export type gql_GetTagsQueryVariables = Exact<{
  projectId: Scalars['ID'];
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_TagSortInput>;
  filters?: InputMaybe<gql_TagFilterInput>;
}>;


export type gql_GetTagsQuery = { __typename?: 'Query', tags?: { __typename?: 'ListTagResponse', total: number, data: Array<{ __typename?: 'Tag', id: string, name: string, color: string }> } };

export type gql_AddTagToTaskMutationVariables = Exact<{
  tagId: Scalars['ID'];
  taskId: Scalars['ID'];
}>;


export type gql_AddTagToTaskMutation = { __typename?: 'Mutation', addTagToTask: { __typename?: 'Task', id: string, tags?: Array<{ __typename?: 'Tag', id: string, name: string, color: string }> } };

export type gql_RemoveTagFromTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
  tagId: Scalars['ID'];
}>;


export type gql_RemoveTagFromTaskMutation = { __typename?: 'Mutation', removeTagFromTask: { __typename?: 'Task', id: string, tags?: Array<{ __typename?: 'Tag', id: string, name: string, color: string }> } };

export type gql_CreateTagMutationVariables = Exact<{
  data: gql_TagCreateInput;
}>;


export type gql_CreateTagMutation = { __typename?: 'Mutation', createTag: { __typename?: 'Tag', id: string, name: string, color: string } };

export type gql_UpdateTagMutationVariables = Exact<{
  id: Scalars['ID'];
  data: gql_TagUpdateInput;
}>;


export type gql_UpdateTagMutation = { __typename?: 'Mutation', updateTag: { __typename?: 'Tag', id: string, name: string, color: string } };

export type gql_DeleteTagMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_DeleteTagMutation = { __typename?: 'Mutation', deleteTag: boolean };

export type gql_EditTaskCompletionDateMutationVariables = Exact<{
  id: Scalars['ID'];
  data: gql_TaskUpdateInput;
}>;


export type gql_EditTaskCompletionDateMutation = { __typename?: 'Mutation', updateTask: { __typename?: 'Task', id: string, completionDate?: any } };

export type gql_EditTaskDescriptionMutationVariables = Exact<{
  id: Scalars['ID'];
  data: gql_TaskUpdateInput;
}>;


export type gql_EditTaskDescriptionMutation = { __typename?: 'Mutation', updateTask: { __typename?: 'Task', id: string, description?: string } };

export type gql_ChangeTaskStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  data: gql_TaskUpdateInput;
}>;


export type gql_ChangeTaskStatusMutation = { __typename?: 'Mutation', updateTask: { __typename?: 'Task', id: string, taskStatusId: string, status?: { __typename?: 'TaskStatus', id: string, name: string, color?: string } } };

export type gql_RoleFragmentFragment = { __typename?: 'Role', id?: string, name?: string, isPublic?: boolean, isAdmin?: boolean, isLocked?: boolean, params?: any, createdAt?: any, updatedAt?: any };

export type gql_UserFragmentFragment = { __typename?: 'User', id: string, email?: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', id?: string, urlSmall?: string }, roles?: Array<{ __typename?: 'Role', id?: string, name?: string, params?: any, isAdmin?: boolean }> };

export type gql_ProjectFragmentFragment = { __typename?: 'Project', id?: string, name?: string, startDate?: any, endDate?: any, color?: string, visibility: gql_ProjectVisibility, pictureId?: string, createdAt?: any, updatedAt?: any, picture?: { __typename?: 'Picture', id?: string, name?: string, urlSmall?: string, urlMedium?: string } };

export type gql_TaskStatusFragmentFragment = { __typename?: 'TaskStatus', id: string, name: string, order?: number, color?: string, projectId?: string, createdAt?: any, updatedAt?: any };

export type gql_TaskFragmentFragment = { __typename?: 'Task', id: string, title: string, description?: string, color?: string, startDate?: any, endDate?: any, completionDate?: any, order: number, taskStatusId: string, commentCount: number, createdAt: any, updatedAt: any, status?: { __typename?: 'TaskStatus', id: string, name: string, color?: string }, tags?: Array<{ __typename?: 'Tag', id: string, name: string, color: string }>, project?: { __typename?: 'Project', id?: string, name?: string }, assignees?: Array<{ __typename?: 'User', id: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', id?: string, urlSmall?: string } }>, todos?: Array<{ __typename?: 'TaskTodo', id: string, description: string, isDone: boolean, position: number, taskId: string }>, attachments?: Array<{ __typename?: 'TaskAttachment', id?: string, isCover?: boolean, url?: string, picture?: { __typename?: 'Picture', id?: string, urlMedium?: string } }> };

export type gql_TaskAttachmentFragmentFragment = { __typename?: 'TaskAttachment', id?: string, name?: string, url?: string, taskId?: string, mimeType?: string, createdAt?: any, updatedAt?: any };

export type gql_TaskTodoFragmentFragment = { __typename?: 'TaskTodo', id: string, description: string, isDone: boolean, taskId: string, position: number, createdAt?: any, updatedAt?: any };

export type gql_InteractionFragmentFragment = { __typename?: 'Interaction', id: string, type: gql_InteractionType, data?: any, createdAt: any, user: { __typename?: 'User', id: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', id?: string, urlSmall?: string } } };

export type gql_PermissionRoleFragmentFragment = { __typename?: 'PermissionRole', permissionId: number, roleId: number, role?: { __typename?: 'Role', id?: string }, permission?: { __typename?: 'Permission', id?: string } };

export type gql_PermissionFragmentFragment = { __typename?: 'Permission', id?: string, name?: string, createdAt?: any, updatedAt?: any, category?: { __typename?: 'PermissionCategory', id?: string, name?: string, createdAt?: any, updatedAt?: any }, roles?: Array<{ __typename?: 'Role', id?: string }> };

export type gql_GetUserMeQueryVariables = Exact<{ [key: string]: never; }>;


export type gql_GetUserMeQuery = { __typename?: 'Query', userMe?: { __typename?: 'User', id: string, email?: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', id?: string, urlSmall?: string }, roles?: Array<{ __typename?: 'Role', id?: string, name?: string, params?: any, isAdmin?: boolean }> } };

export type gql_LoginMutationVariables = Exact<{
  credentials: gql_CredentialsInput;
}>;


export type gql_LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'User', id: string, email?: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', id?: string, urlSmall?: string }, roles?: Array<{ __typename?: 'Role', id?: string, name?: string, params?: any, isAdmin?: boolean }> } };

export type gql_LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type gql_LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type gql_GetRolesQueryVariables = Exact<{
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_RoleSortInput>;
  filters?: InputMaybe<gql_RoleFilterInput>;
}>;


export type gql_GetRolesQuery = { __typename?: 'Query', roles?: { __typename?: 'ListRoleResponse', total: number, data: Array<{ __typename?: 'Role', id?: string, name?: string, isPublic?: boolean, isAdmin?: boolean, isLocked?: boolean, params?: any, createdAt?: any, updatedAt?: any }> } };

export type gql_GetRoleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_GetRoleQuery = { __typename?: 'Query', role?: { __typename?: 'Role', id?: string, name?: string, isPublic?: boolean, isAdmin?: boolean, isLocked?: boolean, params?: any, createdAt?: any, updatedAt?: any } };

export type gql_CreateRoleMutationVariables = Exact<{
  data: gql_RoleCreateInput;
}>;


export type gql_CreateRoleMutation = { __typename?: 'Mutation', createRole: { __typename?: 'Role', id?: string, name?: string, isPublic?: boolean, isAdmin?: boolean, isLocked?: boolean, params?: any, createdAt?: any, updatedAt?: any } };

export type gql_EditRoleMutationVariables = Exact<{
  id: Scalars['ID'];
  data: gql_RoleUpdateInput;
}>;


export type gql_EditRoleMutation = { __typename?: 'Mutation', updateRole: { __typename?: 'Role', id?: string, name?: string, isPublic?: boolean, isAdmin?: boolean, isLocked?: boolean, params?: any, createdAt?: any, updatedAt?: any } };

export type gql_DeleteRoleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_DeleteRoleMutation = { __typename?: 'Mutation', deleteRole: boolean };

export type gql_GetUsersQueryVariables = Exact<{
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_UserSortInput>;
  filters?: InputMaybe<gql_UserFilterInput>;
}>;


export type gql_GetUsersQuery = { __typename?: 'Query', users?: { __typename?: 'ListUserResponse', total: number, data: Array<{ __typename?: 'User', id: string, email?: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', id?: string, urlSmall?: string }, roles?: Array<{ __typename?: 'Role', id?: string, name?: string, params?: any, isAdmin?: boolean }> }> } };

export type gql_GetUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_GetUserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, email?: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', id?: string, urlSmall?: string }, roles?: Array<{ __typename?: 'Role', id?: string, name?: string, params?: any, isAdmin?: boolean }> } };

export type gql_CreateUserMutationVariables = Exact<{
  data: gql_UserCreateInput;
}>;


export type gql_CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: string, email?: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', id?: string, urlSmall?: string }, roles?: Array<{ __typename?: 'Role', id?: string, name?: string, params?: any, isAdmin?: boolean }> } };

export type gql_EditUserMutationVariables = Exact<{
  id: Scalars['ID'];
  data: gql_UserUpdateInput;
}>;


export type gql_EditUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, email?: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', id?: string, urlSmall?: string }, roles?: Array<{ __typename?: 'Role', id?: string, name?: string, params?: any, isAdmin?: boolean }> } };

export type gql_DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type gql_AddUserToProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type gql_AddUserToProjectMutation = { __typename?: 'Mutation', addUserToProject: { __typename?: 'User', id: string } };

export type gql_RemoveUserFromProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type gql_RemoveUserFromProjectMutation = { __typename?: 'Mutation', removeUserFromProject: boolean };

export type gql_GetProjectsQueryVariables = Exact<{
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_ProjectSortInput>;
  filters?: InputMaybe<gql_ProjectFilterInput>;
}>;


export type gql_GetProjectsQuery = { __typename?: 'Query', projects?: { __typename?: 'ListProjectResponse', total: number, data: Array<{ __typename?: 'Project', id?: string, name?: string, startDate?: any, endDate?: any, color?: string, visibility: gql_ProjectVisibility, pictureId?: string, createdAt?: any, updatedAt?: any, picture?: { __typename?: 'Picture', id?: string, name?: string, urlSmall?: string, urlMedium?: string } }> } };

export type gql_GetProjectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_GetProjectQuery = { __typename?: 'Query', project?: { __typename?: 'Project', id?: string, name?: string, startDate?: any, endDate?: any, color?: string, visibility: gql_ProjectVisibility, pictureId?: string, createdAt?: any, updatedAt?: any, picture?: { __typename?: 'Picture', id?: string, name?: string, urlSmall?: string, urlMedium?: string } } };

export type gql_CreateProjectMutationVariables = Exact<{
  data: gql_ProjectCreateInput;
}>;


export type gql_CreateProjectMutation = { __typename?: 'Mutation', createProject: { __typename?: 'Project', id?: string, name?: string, startDate?: any, endDate?: any, color?: string, visibility: gql_ProjectVisibility, pictureId?: string, createdAt?: any, updatedAt?: any, picture?: { __typename?: 'Picture', id?: string, name?: string, urlSmall?: string, urlMedium?: string } } };

export type gql_EditProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  data: gql_ProjectUpdateInput;
}>;


export type gql_EditProjectMutation = { __typename?: 'Mutation', updateProject: { __typename?: 'Project', id?: string, name?: string, startDate?: any, endDate?: any, color?: string, visibility: gql_ProjectVisibility, pictureId?: string, createdAt?: any, updatedAt?: any, picture?: { __typename?: 'Picture', id?: string, name?: string, urlSmall?: string, urlMedium?: string } } };

export type gql_DeleteProjectMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_DeleteProjectMutation = { __typename?: 'Mutation', deleteProject: boolean };

export type gql_GetTaskStatusesQueryVariables = Exact<{
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_TaskStatusSortInput>;
  filters?: InputMaybe<gql_TaskStatusFilterInput>;
}>;


export type gql_GetTaskStatusesQuery = { __typename?: 'Query', taskStatuses?: { __typename?: 'ListTaskStatusResponse', total: number, data: Array<{ __typename?: 'TaskStatus', id: string, name: string, order?: number, color?: string, projectId?: string, createdAt?: any, updatedAt?: any }> } };

export type gql_GetTaskStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_GetTaskStatusQuery = { __typename?: 'Query', taskStatus?: { __typename?: 'TaskStatus', id: string, name: string, order?: number, color?: string, projectId?: string, createdAt?: any, updatedAt?: any } };

export type gql_CreateTaskStatusMutationVariables = Exact<{
  data: gql_TaskStatusCreateInput;
}>;


export type gql_CreateTaskStatusMutation = { __typename?: 'Mutation', createTaskStatus: { __typename?: 'TaskStatus', id: string, name: string, order?: number, color?: string, projectId?: string, createdAt?: any, updatedAt?: any } };

export type gql_EditTaskStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  data: gql_TaskStatusUpdateInput;
}>;


export type gql_EditTaskStatusMutation = { __typename?: 'Mutation', updateTaskStatus: { __typename?: 'TaskStatus', id: string, name: string, order?: number, color?: string, projectId?: string, createdAt?: any, updatedAt?: any } };

export type gql_EditTaskStatusesOrderMutationVariables = Exact<{
  data: Array<gql_TaskStatusesOrderUpdateInput> | gql_TaskStatusesOrderUpdateInput;
}>;


export type gql_EditTaskStatusesOrderMutation = { __typename?: 'Mutation', updateTaskStatusesOrder: boolean };

export type gql_DeleteTaskStatusMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_DeleteTaskStatusMutation = { __typename?: 'Mutation', deleteTaskStatus: boolean };

export type gql_AddAssigneeToTaskMutationVariables = Exact<{
  userId: Scalars['ID'];
  taskId: Scalars['ID'];
}>;


export type gql_AddAssigneeToTaskMutation = { __typename?: 'Mutation', addAssigneeToTask: { __typename?: 'Task', id: string, assignees?: Array<{ __typename?: 'User', id: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', urlSmall?: string } }> } };

export type gql_RemoveAssigneeFromTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type gql_RemoveAssigneeFromTaskMutation = { __typename?: 'Mutation', removeAssigneeFromTask: { __typename?: 'Task', id: string, assignees?: Array<{ __typename?: 'User', id: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', urlSmall?: string } }> } };

export type gql_GetTasksQueryVariables = Exact<{
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_TaskSortInput>;
  filters?: InputMaybe<gql_TaskFilterInput>;
}>;


export type gql_GetTasksQuery = { __typename?: 'Query', tasks?: { __typename?: 'ListTaskResponse', total: number, data: Array<{ __typename?: 'Task', id: string, title: string, description?: string, color?: string, startDate?: any, endDate?: any, completionDate?: any, order: number, taskStatusId: string, commentCount: number, createdAt: any, updatedAt: any, status?: { __typename?: 'TaskStatus', id: string, name: string, color?: string }, tags?: Array<{ __typename?: 'Tag', id: string, name: string, color: string }>, project?: { __typename?: 'Project', id?: string, name?: string }, assignees?: Array<{ __typename?: 'User', id: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', id?: string, urlSmall?: string } }>, todos?: Array<{ __typename?: 'TaskTodo', id: string, description: string, isDone: boolean, position: number, taskId: string }>, attachments?: Array<{ __typename?: 'TaskAttachment', id?: string, isCover?: boolean, url?: string, picture?: { __typename?: 'Picture', id?: string, urlMedium?: string } }> }> } };

export type gql_GetTaskQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_GetTaskQuery = { __typename?: 'Query', task?: { __typename?: 'Task', id: string, title: string, description?: string, color?: string, startDate?: any, endDate?: any, completionDate?: any, order: number, taskStatusId: string, commentCount: number, createdAt: any, updatedAt: any, attachments?: Array<{ __typename?: 'TaskAttachment', id?: string, name?: string, url?: string, createdAt?: any, updatedAt?: any, mimeType?: string, isCover?: boolean, picture?: { __typename?: 'Picture', id?: string, urlMedium?: string } }>, status?: { __typename?: 'TaskStatus', id: string, name: string, color?: string }, tags?: Array<{ __typename?: 'Tag', id: string, name: string, color: string }>, project?: { __typename?: 'Project', id?: string, name?: string }, assignees?: Array<{ __typename?: 'User', id: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', id?: string, urlSmall?: string } }>, todos?: Array<{ __typename?: 'TaskTodo', id: string, description: string, isDone: boolean, position: number, taskId: string }> } };

export type gql_CreateTaskMutationVariables = Exact<{
  data: gql_TaskCreateInput;
}>;


export type gql_CreateTaskMutation = { __typename?: 'Mutation', createTask: { __typename?: 'Task', id: string, title: string, description?: string, color?: string, startDate?: any, endDate?: any, completionDate?: any, order: number, taskStatusId: string, commentCount: number, createdAt: any, updatedAt: any, status?: { __typename?: 'TaskStatus', id: string, name: string, color?: string }, tags?: Array<{ __typename?: 'Tag', id: string, name: string, color: string }>, project?: { __typename?: 'Project', id?: string, name?: string }, assignees?: Array<{ __typename?: 'User', id: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', id?: string, urlSmall?: string } }>, todos?: Array<{ __typename?: 'TaskTodo', id: string, description: string, isDone: boolean, position: number, taskId: string }>, attachments?: Array<{ __typename?: 'TaskAttachment', id?: string, isCover?: boolean, url?: string, picture?: { __typename?: 'Picture', id?: string, urlMedium?: string } }> } };

export type gql_EditTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  data: gql_TaskUpdateInput;
}>;


export type gql_EditTaskMutation = { __typename?: 'Mutation', updateTask: { __typename?: 'Task', id: string, title: string, description?: string, color?: string, startDate?: any, endDate?: any, completionDate?: any, order: number, taskStatusId: string, commentCount: number, createdAt: any, updatedAt: any, status?: { __typename?: 'TaskStatus', id: string, name: string, color?: string }, tags?: Array<{ __typename?: 'Tag', id: string, name: string, color: string }>, project?: { __typename?: 'Project', id?: string, name?: string }, assignees?: Array<{ __typename?: 'User', id: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', id?: string, urlSmall?: string } }>, todos?: Array<{ __typename?: 'TaskTodo', id: string, description: string, isDone: boolean, position: number, taskId: string }>, attachments?: Array<{ __typename?: 'TaskAttachment', id?: string, isCover?: boolean, url?: string, picture?: { __typename?: 'Picture', id?: string, urlMedium?: string } }> } };

export type gql_EditTaskOrderMutationVariables = Exact<{
  data: Array<gql_TaskOrderUpdateInput> | gql_TaskOrderUpdateInput;
}>;


export type gql_EditTaskOrderMutation = { __typename?: 'Mutation', updateTaskOrder: boolean };

export type gql_DeleteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_DeleteTaskMutation = { __typename?: 'Mutation', deleteTask: boolean };

export type gql_GetTaskAttachmentsQueryVariables = Exact<{
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_TaskAttachmentSortInput>;
  filters?: InputMaybe<gql_TaskAttachmentFilterInput>;
}>;


export type gql_GetTaskAttachmentsQuery = { __typename?: 'Query', taskAttachments?: { __typename?: 'ListTaskAttachmentResponse', total: number, data: Array<{ __typename?: 'TaskAttachment', id?: string, name?: string, url?: string, taskId?: string, mimeType?: string, createdAt?: any, updatedAt?: any }> } };

export type gql_GetTaskAttachmentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_GetTaskAttachmentQuery = { __typename?: 'Query', taskAttachment?: { __typename?: 'TaskAttachment', id?: string, name?: string, url?: string, taskId?: string, mimeType?: string, createdAt?: any, updatedAt?: any } };

export type gql_CreateTaskAttachmentMutationVariables = Exact<{
  data: gql_TaskAttachmentCreateInput;
}>;


export type gql_CreateTaskAttachmentMutation = { __typename?: 'Mutation', createTaskAttachment: { __typename?: 'TaskAttachment', id?: string, name?: string, url?: string, taskId?: string, mimeType?: string, createdAt?: any, updatedAt?: any } };

export type gql_EditTaskAttachmentMutationVariables = Exact<{
  id: Scalars['ID'];
  data: gql_TaskAttachmentUpdateInput;
}>;


export type gql_EditTaskAttachmentMutation = { __typename?: 'Mutation', updateTaskAttachment: { __typename?: 'TaskAttachment', id?: string, name?: string, url?: string, taskId?: string, mimeType?: string, createdAt?: any, updatedAt?: any } };

export type gql_DeleteTaskAttachmentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_DeleteTaskAttachmentMutation = { __typename?: 'Mutation', deleteTaskAttachment: boolean };

export type gql_GetTaskTodosQueryVariables = Exact<{
  taskId: Scalars['ID'];
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_TaskTodoSortInput>;
  filters?: InputMaybe<gql_TaskTodoFilterInput>;
}>;


export type gql_GetTaskTodosQuery = { __typename?: 'Query', taskTodos?: { __typename?: 'ListTaskTodoResponse', total: number, data: Array<{ __typename?: 'TaskTodo', id: string, description: string, isDone: boolean, taskId: string, position: number, createdAt?: any, updatedAt?: any }> } };

export type gql_GetTaskTodoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_GetTaskTodoQuery = { __typename?: 'Query', taskTodo?: { __typename?: 'TaskTodo', id: string, description: string, isDone: boolean, taskId: string, position: number, createdAt?: any, updatedAt?: any } };

export type gql_CreateTaskTodoMutationVariables = Exact<{
  data: gql_TaskTodoCreateInput;
}>;


export type gql_CreateTaskTodoMutation = { __typename?: 'Mutation', createTaskTodo: { __typename?: 'TaskTodo', id: string, description: string, isDone: boolean, taskId: string, position: number, createdAt?: any, updatedAt?: any } };

export type gql_EditTaskTodoMutationVariables = Exact<{
  id: Scalars['ID'];
  data: gql_TaskTodoUpdateInput;
}>;


export type gql_EditTaskTodoMutation = { __typename?: 'Mutation', updateTaskTodo: { __typename?: 'TaskTodo', id: string, description: string, isDone: boolean, taskId: string, position: number, createdAt?: any, updatedAt?: any } };

export type gql_DeleteTaskTodoMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_DeleteTaskTodoMutation = { __typename?: 'Mutation', deleteTaskTodo: boolean };

export type gql_UpdateTaskTodoPositionMutationVariables = Exact<{
  id: Scalars['ID'];
  over: Scalars['ID'];
}>;


export type gql_UpdateTaskTodoPositionMutation = { __typename?: 'Mutation', updateTaskTodoPosition: Array<{ __typename?: 'TaskTodo', id: string, position: number }> };

export type gql_GetInteractionsQueryVariables = Exact<{
  on: gql_InteractableInput;
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_InteractionSortInput>;
  filters?: InputMaybe<gql_InteractionFilterInput>;
}>;


export type gql_GetInteractionsQuery = { __typename?: 'Query', interactions?: { __typename?: 'ListInteractionResponse', total: number, data: Array<{ __typename?: 'Interaction', id: string, type: gql_InteractionType, data?: any, createdAt: any, user: { __typename?: 'User', id: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', id?: string, urlSmall?: string } } }> } };

export type gql_CreateCommentMutationVariables = Exact<{
  on: gql_InteractableInput;
  comment: Scalars['String'];
}>;


export type gql_CreateCommentMutation = { __typename?: 'Mutation', createComment: { __typename?: 'Interaction', id: string, type: gql_InteractionType, data?: any, createdAt: any, user: { __typename?: 'User', id: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', id?: string, urlSmall?: string } } } };

export type gql_UpdateCommentMutationVariables = Exact<{
  id: Scalars['ID'];
  comment: Scalars['String'];
}>;


export type gql_UpdateCommentMutation = { __typename?: 'Mutation', updateComment: { __typename?: 'Interaction', id: string, type: gql_InteractionType, data?: any, createdAt: any, user: { __typename?: 'User', id: string, firstName?: string, lastName?: string, picture?: { __typename?: 'Picture', id?: string, urlSmall?: string } } } };

export type gql_DeleteCommentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_DeleteCommentMutation = { __typename?: 'Mutation', deleteComment: boolean };

export type gql_CreateNotificationTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type gql_CreateNotificationTokenMutation = { __typename?: 'Mutation', createNotificationToken: string };

export type gql_SubscribeMutationVariables = Exact<{
  to: gql_InteractableInput;
}>;


export type gql_SubscribeMutation = { __typename?: 'Mutation', subscribe: boolean };

export type gql_UnsubscribeMutationVariables = Exact<{
  to: gql_InteractableInput;
}>;


export type gql_UnsubscribeMutation = { __typename?: 'Mutation', unsubscribe: boolean };

export type gql_IsSubscribedQueryVariables = Exact<{
  to: gql_InteractableInput;
}>;


export type gql_IsSubscribedQuery = { __typename?: 'Query', isSubscribed: boolean };

export type gql_GetPermissionsQueryVariables = Exact<{
  filters?: InputMaybe<gql_PermissionFilterInput>;
  pagination?: InputMaybe<gql_PaginationInputType>;
  sort?: InputMaybe<gql_PermissionSortInput>;
}>;


export type gql_GetPermissionsQuery = { __typename?: 'Query', permissions?: { __typename?: 'ListPermissionryResponse', total: number, data: Array<{ __typename?: 'Permission', id?: string, name?: string, createdAt?: any, updatedAt?: any, category?: { __typename?: 'PermissionCategory', id?: string, name?: string, createdAt?: any, updatedAt?: any }, roles?: Array<{ __typename?: 'Role', id?: string }> }> } };

export type gql_GetRoleDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type gql_GetRoleDetailsQuery = { __typename?: 'Query', permissions?: { __typename?: 'ListPermissionryResponse', total: number, data: Array<{ __typename?: 'Permission', id?: string, name?: string, roles?: Array<{ __typename?: 'Role', id?: string, name?: string }>, category?: { __typename?: 'PermissionCategory', id?: string, name?: string } }> }, permissionCategories?: { __typename?: 'ListPermissionCategoryryResponse', data: Array<{ __typename?: 'PermissionCategory', id?: string, name?: string }> } };

export type gql_UpdatePermissionMutationVariables = Exact<{
  id: Scalars['ID'];
  data: gql_PermissionUpdateInput;
}>;


export type gql_UpdatePermissionMutation = { __typename?: 'Mutation', updatePermission?: { __typename?: 'Permission', id?: string, name?: string, createdAt?: any, updatedAt?: any, category?: { __typename?: 'PermissionCategory', id?: string, name?: string, createdAt?: any, updatedAt?: any }, roles?: Array<{ __typename?: 'Role', id?: string }> } };

export const TagFragmentFragmentDoc = gql`
    fragment TagFragment on Tag {
  id
  name
  color
}
    `;
export const RoleFragmentFragmentDoc = gql`
    fragment RoleFragment on Role {
  id
  name
  isPublic
  isAdmin
  isLocked
  params
  createdAt
  updatedAt
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  email
  firstName
  lastName
  picture {
    id
    urlSmall
  }
  roles {
    id
    name
    params
    isAdmin
  }
}
    `;
export const ProjectFragmentFragmentDoc = gql`
    fragment ProjectFragment on Project {
  id
  name
  startDate
  endDate
  color
  visibility
  pictureId
  picture {
    id
    name
    urlSmall
    urlMedium
  }
  createdAt
  updatedAt
}
    `;
export const TaskStatusFragmentFragmentDoc = gql`
    fragment TaskStatusFragment on TaskStatus {
  id
  name
  order
  color
  projectId
  createdAt
  updatedAt
}
    `;
export const TaskFragmentFragmentDoc = gql`
    fragment TaskFragment on Task {
  id
  title
  description
  color
  startDate
  endDate
  completionDate
  order
  taskStatusId
  commentCount
  status {
    id
    name
    color
  }
  tags {
    id
    name
    color
  }
  project {
    id
    name
  }
  assignees {
    id
    firstName
    lastName
    picture {
      id
      urlSmall
    }
  }
  todos {
    id
    description
    isDone
    position
    taskId
  }
  attachments {
    id
    isCover
    picture {
      id
      urlMedium
    }
    url
  }
  createdAt
  updatedAt
}
    `;
export const TaskAttachmentFragmentFragmentDoc = gql`
    fragment TaskAttachmentFragment on TaskAttachment {
  id
  name
  url
  taskId
  mimeType
  createdAt
  updatedAt
}
    `;
export const TaskTodoFragmentFragmentDoc = gql`
    fragment TaskTodoFragment on TaskTodo {
  id
  description
  isDone
  taskId
  position
  createdAt
  updatedAt
}
    `;
export const InteractionFragmentFragmentDoc = gql`
    fragment InteractionFragment on Interaction {
  id
  type
  data
  createdAt
  user {
    id
    firstName
    lastName
    picture {
      id
      urlSmall
    }
  }
}
    `;
export const PermissionRoleFragmentFragmentDoc = gql`
    fragment PermissionRoleFragment on PermissionRole {
  permissionId
  roleId
  role {
    id
  }
  permission {
    id
  }
}
    `;
export const PermissionFragmentFragmentDoc = gql`
    fragment PermissionFragment on Permission {
  id
  name
  createdAt
  updatedAt
  category {
    id
    name
    createdAt
    updatedAt
  }
  roles {
    id
  }
}
    `;
export const DeleteProjectMuDocument = gql`
    mutation DeleteProjectMu($id: ID!) {
  deleteProject(id: $id)
}
    `;
export type gql_DeleteProjectMuMutationFn = Apollo.MutationFunction<gql_DeleteProjectMuMutation, gql_DeleteProjectMuMutationVariables>;

/**
 * __useDeleteProjectMuMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMuMutation, { data, loading, error }] = useDeleteProjectMuMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMuMutation(baseOptions?: Apollo.MutationHookOptions<gql_DeleteProjectMuMutation, gql_DeleteProjectMuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_DeleteProjectMuMutation, gql_DeleteProjectMuMutationVariables>(DeleteProjectMuDocument, options);
      }
export type DeleteProjectMuMutationHookResult = ReturnType<typeof useDeleteProjectMuMutation>;
export type DeleteProjectMuMutationResult = Apollo.MutationResult<gql_DeleteProjectMuMutation>;
export type DeleteProjectMuMutationOptions = Apollo.BaseMutationOptions<gql_DeleteProjectMuMutation, gql_DeleteProjectMuMutationVariables>;
export const GetTaskAssigneesDocument = gql`
    query GetTaskAssignees($id: ID!) {
  task(id: $id) {
    id
    assignees {
      id
      email
      firstName
      lastName
      picture {
        id
        urlSmall
      }
      roles {
        id
        name
        params
        isAdmin
      }
    }
  }
}
    `;

/**
 * __useGetTaskAssigneesQuery__
 *
 * To run a query within a React component, call `useGetTaskAssigneesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskAssigneesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskAssigneesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskAssigneesQuery(baseOptions: Apollo.QueryHookOptions<gql_GetTaskAssigneesQuery, gql_GetTaskAssigneesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetTaskAssigneesQuery, gql_GetTaskAssigneesQueryVariables>(GetTaskAssigneesDocument, options);
      }
export function useGetTaskAssigneesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetTaskAssigneesQuery, gql_GetTaskAssigneesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetTaskAssigneesQuery, gql_GetTaskAssigneesQueryVariables>(GetTaskAssigneesDocument, options);
        }
export type GetTaskAssigneesQueryHookResult = ReturnType<typeof useGetTaskAssigneesQuery>;
export type GetTaskAssigneesLazyQueryHookResult = ReturnType<typeof useGetTaskAssigneesLazyQuery>;
export type GetTaskAssigneesQueryResult = Apollo.QueryResult<gql_GetTaskAssigneesQuery, gql_GetTaskAssigneesQueryVariables>;
export const GetTagsDocument = gql`
    query GetTags($projectId: ID!, $pagination: PaginationInputType, $sort: TagSortInput, $filters: TagFilterInput) {
  tags(
    projectId: $projectId
    pagination: $pagination
    sort: $sort
    filters: $filters
  ) {
    total
    data {
      ...TagFragment
    }
  }
}
    ${TagFragmentFragmentDoc}`;

/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetTagsQuery(baseOptions: Apollo.QueryHookOptions<gql_GetTagsQuery, gql_GetTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetTagsQuery, gql_GetTagsQueryVariables>(GetTagsDocument, options);
      }
export function useGetTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetTagsQuery, gql_GetTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetTagsQuery, gql_GetTagsQueryVariables>(GetTagsDocument, options);
        }
export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>;
export type GetTagsLazyQueryHookResult = ReturnType<typeof useGetTagsLazyQuery>;
export type GetTagsQueryResult = Apollo.QueryResult<gql_GetTagsQuery, gql_GetTagsQueryVariables>;
export const AddTagToTaskDocument = gql`
    mutation AddTagToTask($tagId: ID!, $taskId: ID!) {
  addTagToTask(taskId: $taskId, tagId: $tagId) {
    id
    tags {
      ...TagFragment
    }
  }
}
    ${TagFragmentFragmentDoc}`;
export type gql_AddTagToTaskMutationFn = Apollo.MutationFunction<gql_AddTagToTaskMutation, gql_AddTagToTaskMutationVariables>;

/**
 * __useAddTagToTaskMutation__
 *
 * To run a mutation, you first call `useAddTagToTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTagToTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTagToTaskMutation, { data, loading, error }] = useAddTagToTaskMutation({
 *   variables: {
 *      tagId: // value for 'tagId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useAddTagToTaskMutation(baseOptions?: Apollo.MutationHookOptions<gql_AddTagToTaskMutation, gql_AddTagToTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_AddTagToTaskMutation, gql_AddTagToTaskMutationVariables>(AddTagToTaskDocument, options);
      }
export type AddTagToTaskMutationHookResult = ReturnType<typeof useAddTagToTaskMutation>;
export type AddTagToTaskMutationResult = Apollo.MutationResult<gql_AddTagToTaskMutation>;
export type AddTagToTaskMutationOptions = Apollo.BaseMutationOptions<gql_AddTagToTaskMutation, gql_AddTagToTaskMutationVariables>;
export const RemoveTagFromTaskDocument = gql`
    mutation RemoveTagFromTask($taskId: ID!, $tagId: ID!) {
  removeTagFromTask(taskId: $taskId, tagId: $tagId) {
    id
    tags {
      ...TagFragment
    }
  }
}
    ${TagFragmentFragmentDoc}`;
export type gql_RemoveTagFromTaskMutationFn = Apollo.MutationFunction<gql_RemoveTagFromTaskMutation, gql_RemoveTagFromTaskMutationVariables>;

/**
 * __useRemoveTagFromTaskMutation__
 *
 * To run a mutation, you first call `useRemoveTagFromTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagFromTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagFromTaskMutation, { data, loading, error }] = useRemoveTagFromTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useRemoveTagFromTaskMutation(baseOptions?: Apollo.MutationHookOptions<gql_RemoveTagFromTaskMutation, gql_RemoveTagFromTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_RemoveTagFromTaskMutation, gql_RemoveTagFromTaskMutationVariables>(RemoveTagFromTaskDocument, options);
      }
export type RemoveTagFromTaskMutationHookResult = ReturnType<typeof useRemoveTagFromTaskMutation>;
export type RemoveTagFromTaskMutationResult = Apollo.MutationResult<gql_RemoveTagFromTaskMutation>;
export type RemoveTagFromTaskMutationOptions = Apollo.BaseMutationOptions<gql_RemoveTagFromTaskMutation, gql_RemoveTagFromTaskMutationVariables>;
export const CreateTagDocument = gql`
    mutation CreateTag($data: TagCreateInput!) {
  createTag(data: $data) {
    ...TagFragment
  }
}
    ${TagFragmentFragmentDoc}`;
export type gql_CreateTagMutationFn = Apollo.MutationFunction<gql_CreateTagMutation, gql_CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<gql_CreateTagMutation, gql_CreateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_CreateTagMutation, gql_CreateTagMutationVariables>(CreateTagDocument, options);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<gql_CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<gql_CreateTagMutation, gql_CreateTagMutationVariables>;
export const UpdateTagDocument = gql`
    mutation UpdateTag($id: ID!, $data: TagUpdateInput!) {
  updateTag(id: $id, data: $data) {
    ...TagFragment
  }
}
    ${TagFragmentFragmentDoc}`;
export type gql_UpdateTagMutationFn = Apollo.MutationFunction<gql_UpdateTagMutation, gql_UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTagMutation(baseOptions?: Apollo.MutationHookOptions<gql_UpdateTagMutation, gql_UpdateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_UpdateTagMutation, gql_UpdateTagMutationVariables>(UpdateTagDocument, options);
      }
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<gql_UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<gql_UpdateTagMutation, gql_UpdateTagMutationVariables>;
export const DeleteTagDocument = gql`
    mutation DeleteTag($id: ID!) {
  deleteTag(id: $id)
}
    `;
export type gql_DeleteTagMutationFn = Apollo.MutationFunction<gql_DeleteTagMutation, gql_DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<gql_DeleteTagMutation, gql_DeleteTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_DeleteTagMutation, gql_DeleteTagMutationVariables>(DeleteTagDocument, options);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<gql_DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<gql_DeleteTagMutation, gql_DeleteTagMutationVariables>;
export const EditTaskCompletionDateDocument = gql`
    mutation EditTaskCompletionDate($id: ID!, $data: TaskUpdateInput!) {
  updateTask(id: $id, data: $data) {
    id
    completionDate
  }
}
    `;
export type gql_EditTaskCompletionDateMutationFn = Apollo.MutationFunction<gql_EditTaskCompletionDateMutation, gql_EditTaskCompletionDateMutationVariables>;

/**
 * __useEditTaskCompletionDateMutation__
 *
 * To run a mutation, you first call `useEditTaskCompletionDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaskCompletionDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaskCompletionDateMutation, { data, loading, error }] = useEditTaskCompletionDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditTaskCompletionDateMutation(baseOptions?: Apollo.MutationHookOptions<gql_EditTaskCompletionDateMutation, gql_EditTaskCompletionDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_EditTaskCompletionDateMutation, gql_EditTaskCompletionDateMutationVariables>(EditTaskCompletionDateDocument, options);
      }
export type EditTaskCompletionDateMutationHookResult = ReturnType<typeof useEditTaskCompletionDateMutation>;
export type EditTaskCompletionDateMutationResult = Apollo.MutationResult<gql_EditTaskCompletionDateMutation>;
export type EditTaskCompletionDateMutationOptions = Apollo.BaseMutationOptions<gql_EditTaskCompletionDateMutation, gql_EditTaskCompletionDateMutationVariables>;
export const EditTaskDescriptionDocument = gql`
    mutation EditTaskDescription($id: ID!, $data: TaskUpdateInput!) {
  updateTask(id: $id, data: $data) {
    id
    description
  }
}
    `;
export type gql_EditTaskDescriptionMutationFn = Apollo.MutationFunction<gql_EditTaskDescriptionMutation, gql_EditTaskDescriptionMutationVariables>;

/**
 * __useEditTaskDescriptionMutation__
 *
 * To run a mutation, you first call `useEditTaskDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaskDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaskDescriptionMutation, { data, loading, error }] = useEditTaskDescriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditTaskDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<gql_EditTaskDescriptionMutation, gql_EditTaskDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_EditTaskDescriptionMutation, gql_EditTaskDescriptionMutationVariables>(EditTaskDescriptionDocument, options);
      }
export type EditTaskDescriptionMutationHookResult = ReturnType<typeof useEditTaskDescriptionMutation>;
export type EditTaskDescriptionMutationResult = Apollo.MutationResult<gql_EditTaskDescriptionMutation>;
export type EditTaskDescriptionMutationOptions = Apollo.BaseMutationOptions<gql_EditTaskDescriptionMutation, gql_EditTaskDescriptionMutationVariables>;
export const ChangeTaskStatusDocument = gql`
    mutation ChangeTaskStatus($id: ID!, $data: TaskUpdateInput!) {
  updateTask(id: $id, data: $data) {
    id
    taskStatusId
    status {
      id
      name
      color
    }
  }
}
    `;
export type gql_ChangeTaskStatusMutationFn = Apollo.MutationFunction<gql_ChangeTaskStatusMutation, gql_ChangeTaskStatusMutationVariables>;

/**
 * __useChangeTaskStatusMutation__
 *
 * To run a mutation, you first call `useChangeTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTaskStatusMutation, { data, loading, error }] = useChangeTaskStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangeTaskStatusMutation(baseOptions?: Apollo.MutationHookOptions<gql_ChangeTaskStatusMutation, gql_ChangeTaskStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_ChangeTaskStatusMutation, gql_ChangeTaskStatusMutationVariables>(ChangeTaskStatusDocument, options);
      }
export type ChangeTaskStatusMutationHookResult = ReturnType<typeof useChangeTaskStatusMutation>;
export type ChangeTaskStatusMutationResult = Apollo.MutationResult<gql_ChangeTaskStatusMutation>;
export type ChangeTaskStatusMutationOptions = Apollo.BaseMutationOptions<gql_ChangeTaskStatusMutation, gql_ChangeTaskStatusMutationVariables>;
export const GetUserMeDocument = gql`
    query GetUserMe {
  userMe {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useGetUserMeQuery__
 *
 * To run a query within a React component, call `useGetUserMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserMeQuery(baseOptions?: Apollo.QueryHookOptions<gql_GetUserMeQuery, gql_GetUserMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetUserMeQuery, gql_GetUserMeQueryVariables>(GetUserMeDocument, options);
      }
export function useGetUserMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetUserMeQuery, gql_GetUserMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetUserMeQuery, gql_GetUserMeQueryVariables>(GetUserMeDocument, options);
        }
export type GetUserMeQueryHookResult = ReturnType<typeof useGetUserMeQuery>;
export type GetUserMeLazyQueryHookResult = ReturnType<typeof useGetUserMeLazyQuery>;
export type GetUserMeQueryResult = Apollo.QueryResult<gql_GetUserMeQuery, gql_GetUserMeQueryVariables>;
export const LoginDocument = gql`
    mutation Login($credentials: CredentialsInput!) {
  login(credentials: $credentials) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type gql_LoginMutationFn = Apollo.MutationFunction<gql_LoginMutation, gql_LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      credentials: // value for 'credentials'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<gql_LoginMutation, gql_LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_LoginMutation, gql_LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<gql_LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<gql_LoginMutation, gql_LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type gql_LogoutMutationFn = Apollo.MutationFunction<gql_LogoutMutation, gql_LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<gql_LogoutMutation, gql_LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_LogoutMutation, gql_LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<gql_LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<gql_LogoutMutation, gql_LogoutMutationVariables>;
export const GetRolesDocument = gql`
    query GetRoles($pagination: PaginationInputType, $sort: RoleSortInput, $filters: RoleFilterInput) {
  roles(pagination: $pagination, sort: $sort, filters: $filters) {
    total
    data {
      ...RoleFragment
    }
  }
}
    ${RoleFragmentFragmentDoc}`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<gql_GetRolesQuery, gql_GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetRolesQuery, gql_GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetRolesQuery, gql_GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetRolesQuery, gql_GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<gql_GetRolesQuery, gql_GetRolesQueryVariables>;
export const GetRoleDocument = gql`
    query GetRole($id: ID!) {
  role(id: $id) {
    ...RoleFragment
  }
}
    ${RoleFragmentFragmentDoc}`;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions: Apollo.QueryHookOptions<gql_GetRoleQuery, gql_GetRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetRoleQuery, gql_GetRoleQueryVariables>(GetRoleDocument, options);
      }
export function useGetRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetRoleQuery, gql_GetRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetRoleQuery, gql_GetRoleQueryVariables>(GetRoleDocument, options);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = Apollo.QueryResult<gql_GetRoleQuery, gql_GetRoleQueryVariables>;
export const CreateRoleDocument = gql`
    mutation CreateRole($data: RoleCreateInput!) {
  createRole(data: $data) {
    ...RoleFragment
  }
}
    ${RoleFragmentFragmentDoc}`;
export type gql_CreateRoleMutationFn = Apollo.MutationFunction<gql_CreateRoleMutation, gql_CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<gql_CreateRoleMutation, gql_CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_CreateRoleMutation, gql_CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<gql_CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<gql_CreateRoleMutation, gql_CreateRoleMutationVariables>;
export const EditRoleDocument = gql`
    mutation EditRole($id: ID!, $data: RoleUpdateInput!) {
  updateRole(id: $id, data: $data) {
    ...RoleFragment
  }
}
    ${RoleFragmentFragmentDoc}`;
export type gql_EditRoleMutationFn = Apollo.MutationFunction<gql_EditRoleMutation, gql_EditRoleMutationVariables>;

/**
 * __useEditRoleMutation__
 *
 * To run a mutation, you first call `useEditRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRoleMutation, { data, loading, error }] = useEditRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditRoleMutation(baseOptions?: Apollo.MutationHookOptions<gql_EditRoleMutation, gql_EditRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_EditRoleMutation, gql_EditRoleMutationVariables>(EditRoleDocument, options);
      }
export type EditRoleMutationHookResult = ReturnType<typeof useEditRoleMutation>;
export type EditRoleMutationResult = Apollo.MutationResult<gql_EditRoleMutation>;
export type EditRoleMutationOptions = Apollo.BaseMutationOptions<gql_EditRoleMutation, gql_EditRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($id: ID!) {
  deleteRole(id: $id)
}
    `;
export type gql_DeleteRoleMutationFn = Apollo.MutationFunction<gql_DeleteRoleMutation, gql_DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<gql_DeleteRoleMutation, gql_DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_DeleteRoleMutation, gql_DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<gql_DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<gql_DeleteRoleMutation, gql_DeleteRoleMutationVariables>;
export const GetUsersDocument = gql`
    query GetUsers($pagination: PaginationInputType, $sort: UserSortInput, $filters: UserFilterInput) {
  users(pagination: $pagination, sort: $sort, filters: $filters) {
    total
    data {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<gql_GetUsersQuery, gql_GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetUsersQuery, gql_GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetUsersQuery, gql_GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetUsersQuery, gql_GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<gql_GetUsersQuery, gql_GetUsersQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: ID!) {
  user(id: $id) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<gql_GetUserQuery, gql_GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetUserQuery, gql_GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetUserQuery, gql_GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetUserQuery, gql_GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<gql_GetUserQuery, gql_GetUserQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($data: UserCreateInput!) {
  createUser(data: $data) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type gql_CreateUserMutationFn = Apollo.MutationFunction<gql_CreateUserMutation, gql_CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<gql_CreateUserMutation, gql_CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_CreateUserMutation, gql_CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<gql_CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<gql_CreateUserMutation, gql_CreateUserMutationVariables>;
export const EditUserDocument = gql`
    mutation EditUser($id: ID!, $data: UserUpdateInput!) {
  updateUser(id: $id, data: $data) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type gql_EditUserMutationFn = Apollo.MutationFunction<gql_EditUserMutation, gql_EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: Apollo.MutationHookOptions<gql_EditUserMutation, gql_EditUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_EditUserMutation, gql_EditUserMutationVariables>(EditUserDocument, options);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<gql_EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<gql_EditUserMutation, gql_EditUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  deleteUser(id: $id)
}
    `;
export type gql_DeleteUserMutationFn = Apollo.MutationFunction<gql_DeleteUserMutation, gql_DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<gql_DeleteUserMutation, gql_DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_DeleteUserMutation, gql_DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<gql_DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<gql_DeleteUserMutation, gql_DeleteUserMutationVariables>;
export const AddUserToProjectDocument = gql`
    mutation AddUserToProject($projectId: ID!, $userId: ID!) {
  addUserToProject(projectId: $projectId, userId: $userId) {
    id
  }
}
    `;
export type gql_AddUserToProjectMutationFn = Apollo.MutationFunction<gql_AddUserToProjectMutation, gql_AddUserToProjectMutationVariables>;

/**
 * __useAddUserToProjectMutation__
 *
 * To run a mutation, you first call `useAddUserToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToProjectMutation, { data, loading, error }] = useAddUserToProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddUserToProjectMutation(baseOptions?: Apollo.MutationHookOptions<gql_AddUserToProjectMutation, gql_AddUserToProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_AddUserToProjectMutation, gql_AddUserToProjectMutationVariables>(AddUserToProjectDocument, options);
      }
export type AddUserToProjectMutationHookResult = ReturnType<typeof useAddUserToProjectMutation>;
export type AddUserToProjectMutationResult = Apollo.MutationResult<gql_AddUserToProjectMutation>;
export type AddUserToProjectMutationOptions = Apollo.BaseMutationOptions<gql_AddUserToProjectMutation, gql_AddUserToProjectMutationVariables>;
export const RemoveUserFromProjectDocument = gql`
    mutation RemoveUserFromProject($projectId: ID!, $userId: ID!) {
  removeUserFromProject(projectId: $projectId, userId: $userId)
}
    `;
export type gql_RemoveUserFromProjectMutationFn = Apollo.MutationFunction<gql_RemoveUserFromProjectMutation, gql_RemoveUserFromProjectMutationVariables>;

/**
 * __useRemoveUserFromProjectMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromProjectMutation, { data, loading, error }] = useRemoveUserFromProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveUserFromProjectMutation(baseOptions?: Apollo.MutationHookOptions<gql_RemoveUserFromProjectMutation, gql_RemoveUserFromProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_RemoveUserFromProjectMutation, gql_RemoveUserFromProjectMutationVariables>(RemoveUserFromProjectDocument, options);
      }
export type RemoveUserFromProjectMutationHookResult = ReturnType<typeof useRemoveUserFromProjectMutation>;
export type RemoveUserFromProjectMutationResult = Apollo.MutationResult<gql_RemoveUserFromProjectMutation>;
export type RemoveUserFromProjectMutationOptions = Apollo.BaseMutationOptions<gql_RemoveUserFromProjectMutation, gql_RemoveUserFromProjectMutationVariables>;
export const GetProjectsDocument = gql`
    query GetProjects($pagination: PaginationInputType, $sort: ProjectSortInput, $filters: ProjectFilterInput) {
  projects(pagination: $pagination, sort: $sort, filters: $filters) {
    total
    data {
      ...ProjectFragment
    }
  }
}
    ${ProjectFragmentFragmentDoc}`;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetProjectsQuery(baseOptions?: Apollo.QueryHookOptions<gql_GetProjectsQuery, gql_GetProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetProjectsQuery, gql_GetProjectsQueryVariables>(GetProjectsDocument, options);
      }
export function useGetProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetProjectsQuery, gql_GetProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetProjectsQuery, gql_GetProjectsQueryVariables>(GetProjectsDocument, options);
        }
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsQueryResult = Apollo.QueryResult<gql_GetProjectsQuery, gql_GetProjectsQueryVariables>;
export const GetProjectDocument = gql`
    query GetProject($id: ID!) {
  project(id: $id) {
    ...ProjectFragment
  }
}
    ${ProjectFragmentFragmentDoc}`;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectQuery(baseOptions: Apollo.QueryHookOptions<gql_GetProjectQuery, gql_GetProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetProjectQuery, gql_GetProjectQueryVariables>(GetProjectDocument, options);
      }
export function useGetProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetProjectQuery, gql_GetProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetProjectQuery, gql_GetProjectQueryVariables>(GetProjectDocument, options);
        }
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = Apollo.QueryResult<gql_GetProjectQuery, gql_GetProjectQueryVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($data: ProjectCreateInput!) {
  createProject(data: $data) {
    ...ProjectFragment
  }
}
    ${ProjectFragmentFragmentDoc}`;
export type gql_CreateProjectMutationFn = Apollo.MutationFunction<gql_CreateProjectMutation, gql_CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<gql_CreateProjectMutation, gql_CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_CreateProjectMutation, gql_CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<gql_CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<gql_CreateProjectMutation, gql_CreateProjectMutationVariables>;
export const EditProjectDocument = gql`
    mutation EditProject($id: ID!, $data: ProjectUpdateInput!) {
  updateProject(id: $id, data: $data) {
    ...ProjectFragment
  }
}
    ${ProjectFragmentFragmentDoc}`;
export type gql_EditProjectMutationFn = Apollo.MutationFunction<gql_EditProjectMutation, gql_EditProjectMutationVariables>;

/**
 * __useEditProjectMutation__
 *
 * To run a mutation, you first call `useEditProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectMutation, { data, loading, error }] = useEditProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditProjectMutation(baseOptions?: Apollo.MutationHookOptions<gql_EditProjectMutation, gql_EditProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_EditProjectMutation, gql_EditProjectMutationVariables>(EditProjectDocument, options);
      }
export type EditProjectMutationHookResult = ReturnType<typeof useEditProjectMutation>;
export type EditProjectMutationResult = Apollo.MutationResult<gql_EditProjectMutation>;
export type EditProjectMutationOptions = Apollo.BaseMutationOptions<gql_EditProjectMutation, gql_EditProjectMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation DeleteProject($id: ID!) {
  deleteProject(id: $id)
}
    `;
export type gql_DeleteProjectMutationFn = Apollo.MutationFunction<gql_DeleteProjectMutation, gql_DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<gql_DeleteProjectMutation, gql_DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_DeleteProjectMutation, gql_DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<gql_DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<gql_DeleteProjectMutation, gql_DeleteProjectMutationVariables>;
export const GetTaskStatusesDocument = gql`
    query GetTaskStatuses($pagination: PaginationInputType, $sort: TaskStatusSortInput, $filters: TaskStatusFilterInput) {
  taskStatuses(pagination: $pagination, sort: $sort, filters: $filters) {
    total
    data {
      ...TaskStatusFragment
    }
  }
}
    ${TaskStatusFragmentFragmentDoc}`;

/**
 * __useGetTaskStatusesQuery__
 *
 * To run a query within a React component, call `useGetTaskStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskStatusesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetTaskStatusesQuery(baseOptions?: Apollo.QueryHookOptions<gql_GetTaskStatusesQuery, gql_GetTaskStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetTaskStatusesQuery, gql_GetTaskStatusesQueryVariables>(GetTaskStatusesDocument, options);
      }
export function useGetTaskStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetTaskStatusesQuery, gql_GetTaskStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetTaskStatusesQuery, gql_GetTaskStatusesQueryVariables>(GetTaskStatusesDocument, options);
        }
export type GetTaskStatusesQueryHookResult = ReturnType<typeof useGetTaskStatusesQuery>;
export type GetTaskStatusesLazyQueryHookResult = ReturnType<typeof useGetTaskStatusesLazyQuery>;
export type GetTaskStatusesQueryResult = Apollo.QueryResult<gql_GetTaskStatusesQuery, gql_GetTaskStatusesQueryVariables>;
export const GetTaskStatusDocument = gql`
    query GetTaskStatus($id: ID!) {
  taskStatus(id: $id) {
    ...TaskStatusFragment
  }
}
    ${TaskStatusFragmentFragmentDoc}`;

/**
 * __useGetTaskStatusQuery__
 *
 * To run a query within a React component, call `useGetTaskStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskStatusQuery(baseOptions: Apollo.QueryHookOptions<gql_GetTaskStatusQuery, gql_GetTaskStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetTaskStatusQuery, gql_GetTaskStatusQueryVariables>(GetTaskStatusDocument, options);
      }
export function useGetTaskStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetTaskStatusQuery, gql_GetTaskStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetTaskStatusQuery, gql_GetTaskStatusQueryVariables>(GetTaskStatusDocument, options);
        }
export type GetTaskStatusQueryHookResult = ReturnType<typeof useGetTaskStatusQuery>;
export type GetTaskStatusLazyQueryHookResult = ReturnType<typeof useGetTaskStatusLazyQuery>;
export type GetTaskStatusQueryResult = Apollo.QueryResult<gql_GetTaskStatusQuery, gql_GetTaskStatusQueryVariables>;
export const CreateTaskStatusDocument = gql`
    mutation CreateTaskStatus($data: TaskStatusCreateInput!) {
  createTaskStatus(data: $data) {
    ...TaskStatusFragment
  }
}
    ${TaskStatusFragmentFragmentDoc}`;
export type gql_CreateTaskStatusMutationFn = Apollo.MutationFunction<gql_CreateTaskStatusMutation, gql_CreateTaskStatusMutationVariables>;

/**
 * __useCreateTaskStatusMutation__
 *
 * To run a mutation, you first call `useCreateTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskStatusMutation, { data, loading, error }] = useCreateTaskStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTaskStatusMutation(baseOptions?: Apollo.MutationHookOptions<gql_CreateTaskStatusMutation, gql_CreateTaskStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_CreateTaskStatusMutation, gql_CreateTaskStatusMutationVariables>(CreateTaskStatusDocument, options);
      }
export type CreateTaskStatusMutationHookResult = ReturnType<typeof useCreateTaskStatusMutation>;
export type CreateTaskStatusMutationResult = Apollo.MutationResult<gql_CreateTaskStatusMutation>;
export type CreateTaskStatusMutationOptions = Apollo.BaseMutationOptions<gql_CreateTaskStatusMutation, gql_CreateTaskStatusMutationVariables>;
export const EditTaskStatusDocument = gql`
    mutation EditTaskStatus($id: ID!, $data: TaskStatusUpdateInput!) {
  updateTaskStatus(id: $id, data: $data) {
    ...TaskStatusFragment
  }
}
    ${TaskStatusFragmentFragmentDoc}`;
export type gql_EditTaskStatusMutationFn = Apollo.MutationFunction<gql_EditTaskStatusMutation, gql_EditTaskStatusMutationVariables>;

/**
 * __useEditTaskStatusMutation__
 *
 * To run a mutation, you first call `useEditTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaskStatusMutation, { data, loading, error }] = useEditTaskStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditTaskStatusMutation(baseOptions?: Apollo.MutationHookOptions<gql_EditTaskStatusMutation, gql_EditTaskStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_EditTaskStatusMutation, gql_EditTaskStatusMutationVariables>(EditTaskStatusDocument, options);
      }
export type EditTaskStatusMutationHookResult = ReturnType<typeof useEditTaskStatusMutation>;
export type EditTaskStatusMutationResult = Apollo.MutationResult<gql_EditTaskStatusMutation>;
export type EditTaskStatusMutationOptions = Apollo.BaseMutationOptions<gql_EditTaskStatusMutation, gql_EditTaskStatusMutationVariables>;
export const EditTaskStatusesOrderDocument = gql`
    mutation EditTaskStatusesOrder($data: [TaskStatusesOrderUpdateInput!]!) {
  updateTaskStatusesOrder(data: $data)
}
    `;
export type gql_EditTaskStatusesOrderMutationFn = Apollo.MutationFunction<gql_EditTaskStatusesOrderMutation, gql_EditTaskStatusesOrderMutationVariables>;

/**
 * __useEditTaskStatusesOrderMutation__
 *
 * To run a mutation, you first call `useEditTaskStatusesOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaskStatusesOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaskStatusesOrderMutation, { data, loading, error }] = useEditTaskStatusesOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditTaskStatusesOrderMutation(baseOptions?: Apollo.MutationHookOptions<gql_EditTaskStatusesOrderMutation, gql_EditTaskStatusesOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_EditTaskStatusesOrderMutation, gql_EditTaskStatusesOrderMutationVariables>(EditTaskStatusesOrderDocument, options);
      }
export type EditTaskStatusesOrderMutationHookResult = ReturnType<typeof useEditTaskStatusesOrderMutation>;
export type EditTaskStatusesOrderMutationResult = Apollo.MutationResult<gql_EditTaskStatusesOrderMutation>;
export type EditTaskStatusesOrderMutationOptions = Apollo.BaseMutationOptions<gql_EditTaskStatusesOrderMutation, gql_EditTaskStatusesOrderMutationVariables>;
export const DeleteTaskStatusDocument = gql`
    mutation DeleteTaskStatus($id: ID!) {
  deleteTaskStatus(id: $id)
}
    `;
export type gql_DeleteTaskStatusMutationFn = Apollo.MutationFunction<gql_DeleteTaskStatusMutation, gql_DeleteTaskStatusMutationVariables>;

/**
 * __useDeleteTaskStatusMutation__
 *
 * To run a mutation, you first call `useDeleteTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskStatusMutation, { data, loading, error }] = useDeleteTaskStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskStatusMutation(baseOptions?: Apollo.MutationHookOptions<gql_DeleteTaskStatusMutation, gql_DeleteTaskStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_DeleteTaskStatusMutation, gql_DeleteTaskStatusMutationVariables>(DeleteTaskStatusDocument, options);
      }
export type DeleteTaskStatusMutationHookResult = ReturnType<typeof useDeleteTaskStatusMutation>;
export type DeleteTaskStatusMutationResult = Apollo.MutationResult<gql_DeleteTaskStatusMutation>;
export type DeleteTaskStatusMutationOptions = Apollo.BaseMutationOptions<gql_DeleteTaskStatusMutation, gql_DeleteTaskStatusMutationVariables>;
export const AddAssigneeToTaskDocument = gql`
    mutation AddAssigneeToTask($userId: ID!, $taskId: ID!) {
  addAssigneeToTask(taskId: $taskId, userId: $userId) {
    id
    assignees {
      id
      firstName
      lastName
      picture {
        urlSmall
      }
    }
  }
}
    `;
export type gql_AddAssigneeToTaskMutationFn = Apollo.MutationFunction<gql_AddAssigneeToTaskMutation, gql_AddAssigneeToTaskMutationVariables>;

/**
 * __useAddAssigneeToTaskMutation__
 *
 * To run a mutation, you first call `useAddAssigneeToTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssigneeToTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssigneeToTaskMutation, { data, loading, error }] = useAddAssigneeToTaskMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useAddAssigneeToTaskMutation(baseOptions?: Apollo.MutationHookOptions<gql_AddAssigneeToTaskMutation, gql_AddAssigneeToTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_AddAssigneeToTaskMutation, gql_AddAssigneeToTaskMutationVariables>(AddAssigneeToTaskDocument, options);
      }
export type AddAssigneeToTaskMutationHookResult = ReturnType<typeof useAddAssigneeToTaskMutation>;
export type AddAssigneeToTaskMutationResult = Apollo.MutationResult<gql_AddAssigneeToTaskMutation>;
export type AddAssigneeToTaskMutationOptions = Apollo.BaseMutationOptions<gql_AddAssigneeToTaskMutation, gql_AddAssigneeToTaskMutationVariables>;
export const RemoveAssigneeFromTaskDocument = gql`
    mutation RemoveAssigneeFromTask($taskId: ID!, $userId: ID!) {
  removeAssigneeFromTask(taskId: $taskId, userId: $userId) {
    id
    assignees {
      id
      firstName
      lastName
      picture {
        urlSmall
      }
    }
  }
}
    `;
export type gql_RemoveAssigneeFromTaskMutationFn = Apollo.MutationFunction<gql_RemoveAssigneeFromTaskMutation, gql_RemoveAssigneeFromTaskMutationVariables>;

/**
 * __useRemoveAssigneeFromTaskMutation__
 *
 * To run a mutation, you first call `useRemoveAssigneeFromTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssigneeFromTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssigneeFromTaskMutation, { data, loading, error }] = useRemoveAssigneeFromTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveAssigneeFromTaskMutation(baseOptions?: Apollo.MutationHookOptions<gql_RemoveAssigneeFromTaskMutation, gql_RemoveAssigneeFromTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_RemoveAssigneeFromTaskMutation, gql_RemoveAssigneeFromTaskMutationVariables>(RemoveAssigneeFromTaskDocument, options);
      }
export type RemoveAssigneeFromTaskMutationHookResult = ReturnType<typeof useRemoveAssigneeFromTaskMutation>;
export type RemoveAssigneeFromTaskMutationResult = Apollo.MutationResult<gql_RemoveAssigneeFromTaskMutation>;
export type RemoveAssigneeFromTaskMutationOptions = Apollo.BaseMutationOptions<gql_RemoveAssigneeFromTaskMutation, gql_RemoveAssigneeFromTaskMutationVariables>;
export const GetTasksDocument = gql`
    query GetTasks($pagination: PaginationInputType, $sort: TaskSortInput, $filters: TaskFilterInput) {
  tasks(pagination: $pagination, sort: $sort, filters: $filters) {
    total
    data {
      ...TaskFragment
    }
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useGetTasksQuery__
 *
 * To run a query within a React component, call `useGetTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetTasksQuery(baseOptions?: Apollo.QueryHookOptions<gql_GetTasksQuery, gql_GetTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetTasksQuery, gql_GetTasksQueryVariables>(GetTasksDocument, options);
      }
export function useGetTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetTasksQuery, gql_GetTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetTasksQuery, gql_GetTasksQueryVariables>(GetTasksDocument, options);
        }
export type GetTasksQueryHookResult = ReturnType<typeof useGetTasksQuery>;
export type GetTasksLazyQueryHookResult = ReturnType<typeof useGetTasksLazyQuery>;
export type GetTasksQueryResult = Apollo.QueryResult<gql_GetTasksQuery, gql_GetTasksQueryVariables>;
export const GetTaskDocument = gql`
    query GetTask($id: ID!) {
  task(id: $id) {
    ...TaskFragment
    attachments {
      id
      name
      url
      createdAt
      updatedAt
      mimeType
    }
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useGetTaskQuery__
 *
 * To run a query within a React component, call `useGetTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskQuery(baseOptions: Apollo.QueryHookOptions<gql_GetTaskQuery, gql_GetTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetTaskQuery, gql_GetTaskQueryVariables>(GetTaskDocument, options);
      }
export function useGetTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetTaskQuery, gql_GetTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetTaskQuery, gql_GetTaskQueryVariables>(GetTaskDocument, options);
        }
export type GetTaskQueryHookResult = ReturnType<typeof useGetTaskQuery>;
export type GetTaskLazyQueryHookResult = ReturnType<typeof useGetTaskLazyQuery>;
export type GetTaskQueryResult = Apollo.QueryResult<gql_GetTaskQuery, gql_GetTaskQueryVariables>;
export const CreateTaskDocument = gql`
    mutation CreateTask($data: TaskCreateInput!) {
  createTask(data: $data) {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;
export type gql_CreateTaskMutationFn = Apollo.MutationFunction<gql_CreateTaskMutation, gql_CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<gql_CreateTaskMutation, gql_CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_CreateTaskMutation, gql_CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<gql_CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<gql_CreateTaskMutation, gql_CreateTaskMutationVariables>;
export const EditTaskDocument = gql`
    mutation EditTask($id: ID!, $data: TaskUpdateInput!) {
  updateTask(id: $id, data: $data) {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;
export type gql_EditTaskMutationFn = Apollo.MutationFunction<gql_EditTaskMutation, gql_EditTaskMutationVariables>;

/**
 * __useEditTaskMutation__
 *
 * To run a mutation, you first call `useEditTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaskMutation, { data, loading, error }] = useEditTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditTaskMutation(baseOptions?: Apollo.MutationHookOptions<gql_EditTaskMutation, gql_EditTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_EditTaskMutation, gql_EditTaskMutationVariables>(EditTaskDocument, options);
      }
export type EditTaskMutationHookResult = ReturnType<typeof useEditTaskMutation>;
export type EditTaskMutationResult = Apollo.MutationResult<gql_EditTaskMutation>;
export type EditTaskMutationOptions = Apollo.BaseMutationOptions<gql_EditTaskMutation, gql_EditTaskMutationVariables>;
export const EditTaskOrderDocument = gql`
    mutation EditTaskOrder($data: [TaskOrderUpdateInput!]!) {
  updateTaskOrder(data: $data)
}
    `;
export type gql_EditTaskOrderMutationFn = Apollo.MutationFunction<gql_EditTaskOrderMutation, gql_EditTaskOrderMutationVariables>;

/**
 * __useEditTaskOrderMutation__
 *
 * To run a mutation, you first call `useEditTaskOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaskOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaskOrderMutation, { data, loading, error }] = useEditTaskOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditTaskOrderMutation(baseOptions?: Apollo.MutationHookOptions<gql_EditTaskOrderMutation, gql_EditTaskOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_EditTaskOrderMutation, gql_EditTaskOrderMutationVariables>(EditTaskOrderDocument, options);
      }
export type EditTaskOrderMutationHookResult = ReturnType<typeof useEditTaskOrderMutation>;
export type EditTaskOrderMutationResult = Apollo.MutationResult<gql_EditTaskOrderMutation>;
export type EditTaskOrderMutationOptions = Apollo.BaseMutationOptions<gql_EditTaskOrderMutation, gql_EditTaskOrderMutationVariables>;
export const DeleteTaskDocument = gql`
    mutation DeleteTask($id: ID!) {
  deleteTask(id: $id)
}
    `;
export type gql_DeleteTaskMutationFn = Apollo.MutationFunction<gql_DeleteTaskMutation, gql_DeleteTaskMutationVariables>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<gql_DeleteTaskMutation, gql_DeleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_DeleteTaskMutation, gql_DeleteTaskMutationVariables>(DeleteTaskDocument, options);
      }
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<gql_DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<gql_DeleteTaskMutation, gql_DeleteTaskMutationVariables>;
export const GetTaskAttachmentsDocument = gql`
    query GetTaskAttachments($pagination: PaginationInputType, $sort: TaskAttachmentSortInput, $filters: TaskAttachmentFilterInput) {
  taskAttachments(pagination: $pagination, sort: $sort, filters: $filters) {
    total
    data {
      ...TaskAttachmentFragment
    }
  }
}
    ${TaskAttachmentFragmentFragmentDoc}`;

/**
 * __useGetTaskAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetTaskAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskAttachmentsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetTaskAttachmentsQuery(baseOptions?: Apollo.QueryHookOptions<gql_GetTaskAttachmentsQuery, gql_GetTaskAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetTaskAttachmentsQuery, gql_GetTaskAttachmentsQueryVariables>(GetTaskAttachmentsDocument, options);
      }
export function useGetTaskAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetTaskAttachmentsQuery, gql_GetTaskAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetTaskAttachmentsQuery, gql_GetTaskAttachmentsQueryVariables>(GetTaskAttachmentsDocument, options);
        }
export type GetTaskAttachmentsQueryHookResult = ReturnType<typeof useGetTaskAttachmentsQuery>;
export type GetTaskAttachmentsLazyQueryHookResult = ReturnType<typeof useGetTaskAttachmentsLazyQuery>;
export type GetTaskAttachmentsQueryResult = Apollo.QueryResult<gql_GetTaskAttachmentsQuery, gql_GetTaskAttachmentsQueryVariables>;
export const GetTaskAttachmentDocument = gql`
    query GetTaskAttachment($id: ID!) {
  taskAttachment(id: $id) {
    ...TaskAttachmentFragment
  }
}
    ${TaskAttachmentFragmentFragmentDoc}`;

/**
 * __useGetTaskAttachmentQuery__
 *
 * To run a query within a React component, call `useGetTaskAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskAttachmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskAttachmentQuery(baseOptions: Apollo.QueryHookOptions<gql_GetTaskAttachmentQuery, gql_GetTaskAttachmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetTaskAttachmentQuery, gql_GetTaskAttachmentQueryVariables>(GetTaskAttachmentDocument, options);
      }
export function useGetTaskAttachmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetTaskAttachmentQuery, gql_GetTaskAttachmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetTaskAttachmentQuery, gql_GetTaskAttachmentQueryVariables>(GetTaskAttachmentDocument, options);
        }
export type GetTaskAttachmentQueryHookResult = ReturnType<typeof useGetTaskAttachmentQuery>;
export type GetTaskAttachmentLazyQueryHookResult = ReturnType<typeof useGetTaskAttachmentLazyQuery>;
export type GetTaskAttachmentQueryResult = Apollo.QueryResult<gql_GetTaskAttachmentQuery, gql_GetTaskAttachmentQueryVariables>;
export const CreateTaskAttachmentDocument = gql`
    mutation CreateTaskAttachment($data: TaskAttachmentCreateInput!) {
  createTaskAttachment(data: $data) {
    ...TaskAttachmentFragment
  }
}
    ${TaskAttachmentFragmentFragmentDoc}`;
export type gql_CreateTaskAttachmentMutationFn = Apollo.MutationFunction<gql_CreateTaskAttachmentMutation, gql_CreateTaskAttachmentMutationVariables>;

/**
 * __useCreateTaskAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateTaskAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskAttachmentMutation, { data, loading, error }] = useCreateTaskAttachmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTaskAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<gql_CreateTaskAttachmentMutation, gql_CreateTaskAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_CreateTaskAttachmentMutation, gql_CreateTaskAttachmentMutationVariables>(CreateTaskAttachmentDocument, options);
      }
export type CreateTaskAttachmentMutationHookResult = ReturnType<typeof useCreateTaskAttachmentMutation>;
export type CreateTaskAttachmentMutationResult = Apollo.MutationResult<gql_CreateTaskAttachmentMutation>;
export type CreateTaskAttachmentMutationOptions = Apollo.BaseMutationOptions<gql_CreateTaskAttachmentMutation, gql_CreateTaskAttachmentMutationVariables>;
export const EditTaskAttachmentDocument = gql`
    mutation EditTaskAttachment($id: ID!, $data: TaskAttachmentUpdateInput!) {
  updateTaskAttachment(id: $id, data: $data) {
    ...TaskAttachmentFragment
  }
}
    ${TaskAttachmentFragmentFragmentDoc}`;
export type gql_EditTaskAttachmentMutationFn = Apollo.MutationFunction<gql_EditTaskAttachmentMutation, gql_EditTaskAttachmentMutationVariables>;

/**
 * __useEditTaskAttachmentMutation__
 *
 * To run a mutation, you first call `useEditTaskAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaskAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaskAttachmentMutation, { data, loading, error }] = useEditTaskAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditTaskAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<gql_EditTaskAttachmentMutation, gql_EditTaskAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_EditTaskAttachmentMutation, gql_EditTaskAttachmentMutationVariables>(EditTaskAttachmentDocument, options);
      }
export type EditTaskAttachmentMutationHookResult = ReturnType<typeof useEditTaskAttachmentMutation>;
export type EditTaskAttachmentMutationResult = Apollo.MutationResult<gql_EditTaskAttachmentMutation>;
export type EditTaskAttachmentMutationOptions = Apollo.BaseMutationOptions<gql_EditTaskAttachmentMutation, gql_EditTaskAttachmentMutationVariables>;
export const DeleteTaskAttachmentDocument = gql`
    mutation DeleteTaskAttachment($id: ID!) {
  deleteTaskAttachment(id: $id)
}
    `;
export type gql_DeleteTaskAttachmentMutationFn = Apollo.MutationFunction<gql_DeleteTaskAttachmentMutation, gql_DeleteTaskAttachmentMutationVariables>;

/**
 * __useDeleteTaskAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteTaskAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskAttachmentMutation, { data, loading, error }] = useDeleteTaskAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<gql_DeleteTaskAttachmentMutation, gql_DeleteTaskAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_DeleteTaskAttachmentMutation, gql_DeleteTaskAttachmentMutationVariables>(DeleteTaskAttachmentDocument, options);
      }
export type DeleteTaskAttachmentMutationHookResult = ReturnType<typeof useDeleteTaskAttachmentMutation>;
export type DeleteTaskAttachmentMutationResult = Apollo.MutationResult<gql_DeleteTaskAttachmentMutation>;
export type DeleteTaskAttachmentMutationOptions = Apollo.BaseMutationOptions<gql_DeleteTaskAttachmentMutation, gql_DeleteTaskAttachmentMutationVariables>;
export const GetTaskTodosDocument = gql`
    query GetTaskTodos($taskId: ID!, $pagination: PaginationInputType, $sort: TaskTodoSortInput, $filters: TaskTodoFilterInput) {
  taskTodos(
    taskId: $taskId
    pagination: $pagination
    sort: $sort
    filters: $filters
  ) {
    total
    data {
      ...TaskTodoFragment
    }
  }
}
    ${TaskTodoFragmentFragmentDoc}`;

/**
 * __useGetTaskTodosQuery__
 *
 * To run a query within a React component, call `useGetTaskTodosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskTodosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskTodosQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetTaskTodosQuery(baseOptions: Apollo.QueryHookOptions<gql_GetTaskTodosQuery, gql_GetTaskTodosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetTaskTodosQuery, gql_GetTaskTodosQueryVariables>(GetTaskTodosDocument, options);
      }
export function useGetTaskTodosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetTaskTodosQuery, gql_GetTaskTodosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetTaskTodosQuery, gql_GetTaskTodosQueryVariables>(GetTaskTodosDocument, options);
        }
export type GetTaskTodosQueryHookResult = ReturnType<typeof useGetTaskTodosQuery>;
export type GetTaskTodosLazyQueryHookResult = ReturnType<typeof useGetTaskTodosLazyQuery>;
export type GetTaskTodosQueryResult = Apollo.QueryResult<gql_GetTaskTodosQuery, gql_GetTaskTodosQueryVariables>;
export const GetTaskTodoDocument = gql`
    query GetTaskTodo($id: ID!) {
  taskTodo(id: $id) {
    ...TaskTodoFragment
  }
}
    ${TaskTodoFragmentFragmentDoc}`;

/**
 * __useGetTaskTodoQuery__
 *
 * To run a query within a React component, call `useGetTaskTodoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskTodoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskTodoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskTodoQuery(baseOptions: Apollo.QueryHookOptions<gql_GetTaskTodoQuery, gql_GetTaskTodoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetTaskTodoQuery, gql_GetTaskTodoQueryVariables>(GetTaskTodoDocument, options);
      }
export function useGetTaskTodoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetTaskTodoQuery, gql_GetTaskTodoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetTaskTodoQuery, gql_GetTaskTodoQueryVariables>(GetTaskTodoDocument, options);
        }
export type GetTaskTodoQueryHookResult = ReturnType<typeof useGetTaskTodoQuery>;
export type GetTaskTodoLazyQueryHookResult = ReturnType<typeof useGetTaskTodoLazyQuery>;
export type GetTaskTodoQueryResult = Apollo.QueryResult<gql_GetTaskTodoQuery, gql_GetTaskTodoQueryVariables>;
export const CreateTaskTodoDocument = gql`
    mutation CreateTaskTodo($data: TaskTodoCreateInput!) {
  createTaskTodo(data: $data) {
    ...TaskTodoFragment
  }
}
    ${TaskTodoFragmentFragmentDoc}`;
export type gql_CreateTaskTodoMutationFn = Apollo.MutationFunction<gql_CreateTaskTodoMutation, gql_CreateTaskTodoMutationVariables>;

/**
 * __useCreateTaskTodoMutation__
 *
 * To run a mutation, you first call `useCreateTaskTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskTodoMutation, { data, loading, error }] = useCreateTaskTodoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTaskTodoMutation(baseOptions?: Apollo.MutationHookOptions<gql_CreateTaskTodoMutation, gql_CreateTaskTodoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_CreateTaskTodoMutation, gql_CreateTaskTodoMutationVariables>(CreateTaskTodoDocument, options);
      }
export type CreateTaskTodoMutationHookResult = ReturnType<typeof useCreateTaskTodoMutation>;
export type CreateTaskTodoMutationResult = Apollo.MutationResult<gql_CreateTaskTodoMutation>;
export type CreateTaskTodoMutationOptions = Apollo.BaseMutationOptions<gql_CreateTaskTodoMutation, gql_CreateTaskTodoMutationVariables>;
export const EditTaskTodoDocument = gql`
    mutation EditTaskTodo($id: ID!, $data: TaskTodoUpdateInput!) {
  updateTaskTodo(id: $id, data: $data) {
    ...TaskTodoFragment
  }
}
    ${TaskTodoFragmentFragmentDoc}`;
export type gql_EditTaskTodoMutationFn = Apollo.MutationFunction<gql_EditTaskTodoMutation, gql_EditTaskTodoMutationVariables>;

/**
 * __useEditTaskTodoMutation__
 *
 * To run a mutation, you first call `useEditTaskTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaskTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaskTodoMutation, { data, loading, error }] = useEditTaskTodoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditTaskTodoMutation(baseOptions?: Apollo.MutationHookOptions<gql_EditTaskTodoMutation, gql_EditTaskTodoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_EditTaskTodoMutation, gql_EditTaskTodoMutationVariables>(EditTaskTodoDocument, options);
      }
export type EditTaskTodoMutationHookResult = ReturnType<typeof useEditTaskTodoMutation>;
export type EditTaskTodoMutationResult = Apollo.MutationResult<gql_EditTaskTodoMutation>;
export type EditTaskTodoMutationOptions = Apollo.BaseMutationOptions<gql_EditTaskTodoMutation, gql_EditTaskTodoMutationVariables>;
export const DeleteTaskTodoDocument = gql`
    mutation DeleteTaskTodo($id: ID!) {
  deleteTaskTodo(id: $id)
}
    `;
export type gql_DeleteTaskTodoMutationFn = Apollo.MutationFunction<gql_DeleteTaskTodoMutation, gql_DeleteTaskTodoMutationVariables>;

/**
 * __useDeleteTaskTodoMutation__
 *
 * To run a mutation, you first call `useDeleteTaskTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskTodoMutation, { data, loading, error }] = useDeleteTaskTodoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskTodoMutation(baseOptions?: Apollo.MutationHookOptions<gql_DeleteTaskTodoMutation, gql_DeleteTaskTodoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_DeleteTaskTodoMutation, gql_DeleteTaskTodoMutationVariables>(DeleteTaskTodoDocument, options);
      }
export type DeleteTaskTodoMutationHookResult = ReturnType<typeof useDeleteTaskTodoMutation>;
export type DeleteTaskTodoMutationResult = Apollo.MutationResult<gql_DeleteTaskTodoMutation>;
export type DeleteTaskTodoMutationOptions = Apollo.BaseMutationOptions<gql_DeleteTaskTodoMutation, gql_DeleteTaskTodoMutationVariables>;
export const UpdateTaskTodoPositionDocument = gql`
    mutation updateTaskTodoPosition($id: ID!, $over: ID!) {
  updateTaskTodoPosition(id: $id, over: $over) {
    id
    position
  }
}
    `;
export type gql_UpdateTaskTodoPositionMutationFn = Apollo.MutationFunction<gql_UpdateTaskTodoPositionMutation, gql_UpdateTaskTodoPositionMutationVariables>;

/**
 * __useUpdateTaskTodoPositionMutation__
 *
 * To run a mutation, you first call `useUpdateTaskTodoPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskTodoPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskTodoPositionMutation, { data, loading, error }] = useUpdateTaskTodoPositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      over: // value for 'over'
 *   },
 * });
 */
export function useUpdateTaskTodoPositionMutation(baseOptions?: Apollo.MutationHookOptions<gql_UpdateTaskTodoPositionMutation, gql_UpdateTaskTodoPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_UpdateTaskTodoPositionMutation, gql_UpdateTaskTodoPositionMutationVariables>(UpdateTaskTodoPositionDocument, options);
      }
export type UpdateTaskTodoPositionMutationHookResult = ReturnType<typeof useUpdateTaskTodoPositionMutation>;
export type UpdateTaskTodoPositionMutationResult = Apollo.MutationResult<gql_UpdateTaskTodoPositionMutation>;
export type UpdateTaskTodoPositionMutationOptions = Apollo.BaseMutationOptions<gql_UpdateTaskTodoPositionMutation, gql_UpdateTaskTodoPositionMutationVariables>;
export const GetInteractionsDocument = gql`
    query GetInteractions($on: InteractableInput!, $pagination: PaginationInputType, $sort: InteractionSortInput, $filters: InteractionFilterInput) {
  interactions(on: $on, pagination: $pagination, sort: $sort, filters: $filters) {
    total
    data {
      ...InteractionFragment
    }
  }
}
    ${InteractionFragmentFragmentDoc}`;

/**
 * __useGetInteractionsQuery__
 *
 * To run a query within a React component, call `useGetInteractionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInteractionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInteractionsQuery({
 *   variables: {
 *      on: // value for 'on'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetInteractionsQuery(baseOptions: Apollo.QueryHookOptions<gql_GetInteractionsQuery, gql_GetInteractionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetInteractionsQuery, gql_GetInteractionsQueryVariables>(GetInteractionsDocument, options);
      }
export function useGetInteractionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetInteractionsQuery, gql_GetInteractionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetInteractionsQuery, gql_GetInteractionsQueryVariables>(GetInteractionsDocument, options);
        }
export type GetInteractionsQueryHookResult = ReturnType<typeof useGetInteractionsQuery>;
export type GetInteractionsLazyQueryHookResult = ReturnType<typeof useGetInteractionsLazyQuery>;
export type GetInteractionsQueryResult = Apollo.QueryResult<gql_GetInteractionsQuery, gql_GetInteractionsQueryVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($on: InteractableInput!, $comment: String!) {
  createComment(on: $on, comment: $comment) {
    ...InteractionFragment
  }
}
    ${InteractionFragmentFragmentDoc}`;
export type gql_CreateCommentMutationFn = Apollo.MutationFunction<gql_CreateCommentMutation, gql_CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      on: // value for 'on'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<gql_CreateCommentMutation, gql_CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_CreateCommentMutation, gql_CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<gql_CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<gql_CreateCommentMutation, gql_CreateCommentMutationVariables>;
export const UpdateCommentDocument = gql`
    mutation UpdateComment($id: ID!, $comment: String!) {
  updateComment(id: $id, comment: $comment) {
    ...InteractionFragment
  }
}
    ${InteractionFragmentFragmentDoc}`;
export type gql_UpdateCommentMutationFn = Apollo.MutationFunction<gql_UpdateCommentMutation, gql_UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<gql_UpdateCommentMutation, gql_UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_UpdateCommentMutation, gql_UpdateCommentMutationVariables>(UpdateCommentDocument, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<gql_UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<gql_UpdateCommentMutation, gql_UpdateCommentMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($id: ID!) {
  deleteComment(id: $id)
}
    `;
export type gql_DeleteCommentMutationFn = Apollo.MutationFunction<gql_DeleteCommentMutation, gql_DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<gql_DeleteCommentMutation, gql_DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_DeleteCommentMutation, gql_DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<gql_DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<gql_DeleteCommentMutation, gql_DeleteCommentMutationVariables>;
export const CreateNotificationTokenDocument = gql`
    mutation createNotificationToken {
  createNotificationToken
}
    `;
export type gql_CreateNotificationTokenMutationFn = Apollo.MutationFunction<gql_CreateNotificationTokenMutation, gql_CreateNotificationTokenMutationVariables>;

/**
 * __useCreateNotificationTokenMutation__
 *
 * To run a mutation, you first call `useCreateNotificationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationTokenMutation, { data, loading, error }] = useCreateNotificationTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateNotificationTokenMutation(baseOptions?: Apollo.MutationHookOptions<gql_CreateNotificationTokenMutation, gql_CreateNotificationTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_CreateNotificationTokenMutation, gql_CreateNotificationTokenMutationVariables>(CreateNotificationTokenDocument, options);
      }
export type CreateNotificationTokenMutationHookResult = ReturnType<typeof useCreateNotificationTokenMutation>;
export type CreateNotificationTokenMutationResult = Apollo.MutationResult<gql_CreateNotificationTokenMutation>;
export type CreateNotificationTokenMutationOptions = Apollo.BaseMutationOptions<gql_CreateNotificationTokenMutation, gql_CreateNotificationTokenMutationVariables>;
export const SubscribeDocument = gql`
    mutation Subscribe($to: InteractableInput!) {
  subscribe(to: $to)
}
    `;
export type gql_SubscribeMutationFn = Apollo.MutationFunction<gql_SubscribeMutation, gql_SubscribeMutationVariables>;

/**
 * __useSubscribeMutation__
 *
 * To run a mutation, you first call `useSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeMutation, { data, loading, error }] = useSubscribeMutation({
 *   variables: {
 *      to: // value for 'to'
 *   },
 * });
 */
export function useSubscribeMutation(baseOptions?: Apollo.MutationHookOptions<gql_SubscribeMutation, gql_SubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_SubscribeMutation, gql_SubscribeMutationVariables>(SubscribeDocument, options);
      }
export type SubscribeMutationHookResult = ReturnType<typeof useSubscribeMutation>;
export type SubscribeMutationResult = Apollo.MutationResult<gql_SubscribeMutation>;
export type SubscribeMutationOptions = Apollo.BaseMutationOptions<gql_SubscribeMutation, gql_SubscribeMutationVariables>;
export const UnsubscribeDocument = gql`
    mutation Unsubscribe($to: InteractableInput!) {
  unsubscribe(to: $to)
}
    `;
export type gql_UnsubscribeMutationFn = Apollo.MutationFunction<gql_UnsubscribeMutation, gql_UnsubscribeMutationVariables>;

/**
 * __useUnsubscribeMutation__
 *
 * To run a mutation, you first call `useUnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeMutation, { data, loading, error }] = useUnsubscribeMutation({
 *   variables: {
 *      to: // value for 'to'
 *   },
 * });
 */
export function useUnsubscribeMutation(baseOptions?: Apollo.MutationHookOptions<gql_UnsubscribeMutation, gql_UnsubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_UnsubscribeMutation, gql_UnsubscribeMutationVariables>(UnsubscribeDocument, options);
      }
export type UnsubscribeMutationHookResult = ReturnType<typeof useUnsubscribeMutation>;
export type UnsubscribeMutationResult = Apollo.MutationResult<gql_UnsubscribeMutation>;
export type UnsubscribeMutationOptions = Apollo.BaseMutationOptions<gql_UnsubscribeMutation, gql_UnsubscribeMutationVariables>;
export const IsSubscribedDocument = gql`
    query IsSubscribed($to: InteractableInput!) {
  isSubscribed(to: $to)
}
    `;

/**
 * __useIsSubscribedQuery__
 *
 * To run a query within a React component, call `useIsSubscribedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsSubscribedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsSubscribedQuery({
 *   variables: {
 *      to: // value for 'to'
 *   },
 * });
 */
export function useIsSubscribedQuery(baseOptions: Apollo.QueryHookOptions<gql_IsSubscribedQuery, gql_IsSubscribedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_IsSubscribedQuery, gql_IsSubscribedQueryVariables>(IsSubscribedDocument, options);
      }
export function useIsSubscribedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_IsSubscribedQuery, gql_IsSubscribedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_IsSubscribedQuery, gql_IsSubscribedQueryVariables>(IsSubscribedDocument, options);
        }
export type IsSubscribedQueryHookResult = ReturnType<typeof useIsSubscribedQuery>;
export type IsSubscribedLazyQueryHookResult = ReturnType<typeof useIsSubscribedLazyQuery>;
export type IsSubscribedQueryResult = Apollo.QueryResult<gql_IsSubscribedQuery, gql_IsSubscribedQueryVariables>;
export const GetPermissionsDocument = gql`
    query GetPermissions($filters: PermissionFilterInput, $pagination: PaginationInputType, $sort: PermissionSortInput) {
  permissions(pagination: $pagination, filters: $filters, sort: $sort) {
    total
    data {
      ...PermissionFragment
    }
  }
}
    ${PermissionFragmentFragmentDoc}`;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<gql_GetPermissionsQuery, gql_GetPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetPermissionsQuery, gql_GetPermissionsQueryVariables>(GetPermissionsDocument, options);
      }
export function useGetPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetPermissionsQuery, gql_GetPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetPermissionsQuery, gql_GetPermissionsQueryVariables>(GetPermissionsDocument, options);
        }
export type GetPermissionsQueryHookResult = ReturnType<typeof useGetPermissionsQuery>;
export type GetPermissionsLazyQueryHookResult = ReturnType<typeof useGetPermissionsLazyQuery>;
export type GetPermissionsQueryResult = Apollo.QueryResult<gql_GetPermissionsQuery, gql_GetPermissionsQueryVariables>;
export const GetRoleDetailsDocument = gql`
    query GetRoleDetails {
  permissions(pagination: {disabled: true}, sort: {name: "ASC"}) {
    total
    data {
      id
      name
      roles {
        id
        name
      }
      category {
        id
        name
      }
    }
  }
  permissionCategories(pagination: {disabled: true}, sort: {name: "ASC"}) {
    data {
      id
      name
    }
  }
}
    `;

/**
 * __useGetRoleDetailsQuery__
 *
 * To run a query within a React component, call `useGetRoleDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoleDetailsQuery(baseOptions?: Apollo.QueryHookOptions<gql_GetRoleDetailsQuery, gql_GetRoleDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetRoleDetailsQuery, gql_GetRoleDetailsQueryVariables>(GetRoleDetailsDocument, options);
      }
export function useGetRoleDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetRoleDetailsQuery, gql_GetRoleDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetRoleDetailsQuery, gql_GetRoleDetailsQueryVariables>(GetRoleDetailsDocument, options);
        }
export type GetRoleDetailsQueryHookResult = ReturnType<typeof useGetRoleDetailsQuery>;
export type GetRoleDetailsLazyQueryHookResult = ReturnType<typeof useGetRoleDetailsLazyQuery>;
export type GetRoleDetailsQueryResult = Apollo.QueryResult<gql_GetRoleDetailsQuery, gql_GetRoleDetailsQueryVariables>;
export const UpdatePermissionDocument = gql`
    mutation UpdatePermission($id: ID!, $data: PermissionUpdateInput!) {
  updatePermission(id: $id, data: $data) {
    ...PermissionFragment
  }
}
    ${PermissionFragmentFragmentDoc}`;
export type gql_UpdatePermissionMutationFn = Apollo.MutationFunction<gql_UpdatePermissionMutation, gql_UpdatePermissionMutationVariables>;

/**
 * __useUpdatePermissionMutation__
 *
 * To run a mutation, you first call `useUpdatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePermissionMutation, { data, loading, error }] = useUpdatePermissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePermissionMutation(baseOptions?: Apollo.MutationHookOptions<gql_UpdatePermissionMutation, gql_UpdatePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_UpdatePermissionMutation, gql_UpdatePermissionMutationVariables>(UpdatePermissionDocument, options);
      }
export type UpdatePermissionMutationHookResult = ReturnType<typeof useUpdatePermissionMutation>;
export type UpdatePermissionMutationResult = Apollo.MutationResult<gql_UpdatePermissionMutation>;
export type UpdatePermissionMutationOptions = Apollo.BaseMutationOptions<gql_UpdatePermissionMutation, gql_UpdatePermissionMutationVariables>;