import React from 'react'
import { Text } from '@chakra-ui/react'
import { BaseEditor, Editor, Transforms } from 'slate'
import { useSelected } from 'slate-react'
import { gql_User } from '../../graphql'
import { MentionElement } from './types'
import { getName } from '../../utils/formatters'

import produce from 'immer'

export const withMentions: <T extends BaseEditor>(editor: T) => T = (editor) => {
  const { isInline, isVoid, insertFragment } = editor

  editor.isInline = (element) => {
    return element.type === 'mention' ? true : isInline(element)
  }

  editor.isVoid = (element) => {
    return element.type === 'mention' ? true : isVoid(element)
  }

  editor.insertFragment = (element) => {
    try {
      const fixed = produce((draft) => {
        draft.forEach((node: any) => {
          if (node.type === 'paragraph') {
            node.children.forEach((child: any) => {
              if (child.type === 'mention') {
                child.timestamp = Date.now()
              }
            })
          }
        })
      })(element)

      insertFragment(fixed)
    } catch (error) {
      insertFragment(element)
    }
  }

  return editor
}

type ElementProps = {
  attributes?: any
  element?: any
}

export const Mention: React.FC<ElementProps> = ({ attributes, children, element }) => {
  const selected = useSelected()

  return (
    <Text
      {...attributes}
      as="span"
      contentEditable={false}
      data-cy={`mention-${element.id}`}
      paddingX={1}
      paddingY={0.3}
      margin="0 1px"
      verticalAlign="baseline"
      display="inline-block"
      borderRadius="lg"
      backgroundColor={selected ? 'gray.400' : 'gray.200'}
    >
      {children}@{element.name}
    </Text>
  )
}

export const insertMention = (
  editor: Editor,
  user: Pick<gql_User, 'id' | 'firstName' | 'lastName'>
) => {
  const mention: MentionElement = {
    type: 'mention',
    id: user.id,
    name: getName(user),
    timestamp: Date.now(),
    children: [{ text: '' }],
  }
  Transforms.insertNodes(editor, mention)
  Transforms.move(editor)
}
