/* eslint-disable import/no-anonymous-default-export */
import { RoleCreate } from './RoleCreate'
import { RoleEdit } from './RoleEdit'
import { RoleList } from './RoleList'
import { BsFillShieldLockFill } from 'react-icons/bs'
import { RoleShow } from './RoleShow'

export default {
  name: 'Role',
  icon: BsFillShieldLockFill,
  list: RoleList,
  create: RoleCreate,
  edit: RoleEdit,
  show: RoleShow,
}
