import React, { FC } from 'react'
import { ca, useTranslate } from 'chakra-admin'
import { BaseForm } from '../../components/forms/BaseForm'
import { gql_Role } from '../../graphql'
import { Box, Stack, VStack, Text, HStack } from '@chakra-ui/react'

type Role = Omit<gql_Role, 'users'>

export const RoleForm: FC<any> = (props) => {
  const t = useTranslate()
  return (
    <BaseForm {...props}>
      <Stack spacing="6" mt={3} mb={20}>
        <ca.FormControl source="name" isRequired>
          <ca.FormLabel htmlFor="role">{t(`resources.Role.fields.name`)}</ca.FormLabel>
          <ca.Input<Role> source="name" placeholder={t(`resources.Role.placeholders.name`)} />
        </ca.FormControl>

        <VStack spacing="6" border="1px solid" borderColor="gray.100" p={4} borderRadius="lg">
          <ca.FormControl source="isAdmin">
            <ca.FormLabel htmlFor="isAdmin">{t(`resources.Role.fields.isAdmin`)}</ca.FormLabel>
            <HStack align="flex-start" spacing={4}>
              <ca.Switch<Role> source="isAdmin" colorScheme="blue" mt={0.5} />
              <Text fontSize="sm">questo ruolo riceve accesso automatico a tutti i permessi</Text>
            </HStack>
          </ca.FormControl>
          <ca.FormControl source="isPublic">
            <ca.FormLabel htmlFor="isPublic">{t(`resources.Role.fields.isPublic`)}</ca.FormLabel>
            <HStack align="flex-start" spacing={4}>
              <ca.Switch<Role> source="isPublic" colorScheme="blue" mt={0.5} />
              <Text fontSize="sm">
                questo ruolo viene assegnato automaticamente agli utenti non loggati
              </Text>
            </HStack>
          </ca.FormControl>
          <ca.FormControl source="isLocked">
            <ca.FormLabel htmlFor="isLocked">{t(`resources.Role.fields.isLocked`)}</ca.FormLabel>
            <HStack align="flex-start" spacing={4}>
              <ca.Switch<Role> source="isLocked" colorScheme="blue" mt={0.5} />
              <Text fontSize="sm"> questo ruolo non può essere eliminato</Text>
            </HStack>
          </ca.FormControl>
        </VStack>
      </Stack>
    </BaseForm>
  )
}
