import React, { FC } from 'react'
import {
  List,
  DataTableValue as Field,
  Input,
  OffsetSortDirection,
  StatusField,
  GenericMoreMenuButton,
  ListToolbar,
} from 'chakra-admin'
import { MUTATION_DELETE_ROLE, QUERY_GET_ROLES } from '../../queries'

import { gql_Role } from '../../graphql'
import { Filters } from '../../components/list/Filters'
import { CreateButton } from '../../components/buttons/CreateButton'
import { DataTable } from '../../components/list/DataTable'
import { PageLayout } from '../../components/details/PageLayout'
import { PageTitle } from '../../components/details/PageTitle'
import { Flex, Icon, useBreakpointValue } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import { WithPermission } from '../../components/auth/WithPermission'

const RoleFilters: FC = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <Flex
      boxShadow={{
        base: 'none',
        lg: '0px 1px 4px rgba(0, 0, 0, 0.05), 0px 6px 24px rgba(0, 0, 0, 0.04), inset 0px 1px 1px rgba(0, 0, 0, 0.04)',
      }}
      borderRadius="xl"
      alignItems="center"
      w={{ base: 'unset', lg: '280px' }}
      my={{ base: 0, lg: 3 }}
    >
      <Icon as={SearchIcon} color="gray.300" ml={4} display={{ base: 'none', lg: 'inherit' }} />
      <Filters {...props}>
        <Input
          source="name"
          label="Name"
          placeholder="Cerca cliente..."
          bg="transparent"
          border="none"
          boxShadow={{
            base: '0px 1px 4px rgba(0, 0, 0, 0.05), 0px 6px 24px rgba(0, 0, 0, 0.04), inset 0px 1px 1px rgba(0, 0, 0, 0.04)',
            lg: 'none',
          }}
          borderRadius="xl"
          alwaysOn={isMobile ? false : true}
          _hover={{ bg: 'transparent' }}
        />
      </Filters>
    </Flex>
  )
}

export const RoleList: FC<any> = ({ rowClick = 'show', ...props }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <List
      {...props}
      paginationMode="offset"
      layout={<PageLayout />}
      title={(<PageTitle />) as any}
      query={QUERY_GET_ROLES}
      deleteItemMutation={MUTATION_DELETE_ROLE}
      filtersComponent={<RoleFilters />}
      defaultSorting={{ name: OffsetSortDirection.ASC }}
      toolbarComponent={
        <ListToolbar>
          <WithPermission permission="createRole">
            <CreateButton openAsModal label={isMobile ? 'Crea' : true} />
          </WithPermission>
        </ListToolbar>
      }
    >
      <DataTable
        moreMenuComponent={<GenericMoreMenuButton openEditAsModal />}
        // renderCard={(data: any) => (
        // <UserCard
        //   {...mockUser}
        //   avatar={
        //     <Avatar h={'72px'} w={'72px'}>
        //       <AvatarBadge bg="#4AC1A2" mb="3px" mr="5px" boxSize={'1.20em'} />
        //     </Avatar>
        //   }
        // />
        //   <ProjectsCard
        //     title="DSFjsidfjdsiofjsdoifdsjfoisdjfoisdfjo"
        //     image={
        //       'url("https://media.istockphoto.com/photos/round-podium-or-pedestal-with-blue-background-picture-id1226478932?b=1&k=20&m=1226478932&s=170667a&w=0&h=hXsvxQilNanpKx7QC_tIsFUOKPl8fdIDfJ_QSKXesGE=")'
        //     }
        //     button={<GenericMoreMenuButton />}
        //   />
        // )}
        rowClick={{ asModal: false, redirect: 'show' }}
      >
        <Field<gql_Role> source="id" />
        <Field<gql_Role> source="name" />

        <StatusField<gql_Role>
          label="resources.Role.fields.isAdmin"
          source={(record: any) => (record?.isAdmin ? 'isAdmin' : 'notAdmin') as any}
          statuses={{
            isAdmin: {
              label: 'Si',
              statusColor: 'green.300',
            },
            notAdmin: {
              label: 'No',
              statusColor: 'gray.300',
            },
          }}
        />
        <StatusField<gql_Role>
          label="resources.Role.fields.isPublic"
          source={(record: any) => (record?.isPublic ? 'public' : 'private') as any}
          statuses={{
            public: {
              label: 'Pubblico',
              statusColor: 'red.500',
            },
            private: {
              label: 'Ruolo privato',
              statusColor: 'green.300',
            },
          }}
        />
        <StatusField<gql_Role>
          label="resources.Role.fields.isLocked"
          source={(record: any) => (record?.isLocked ? 'locked' : 'notLocked') as any}
          statuses={{
            locked: {
              label: 'Modifiche bloccate',
              statusColor: 'orange.400',
            },
            notLocked: {
              label: 'Modifiche abilitate',
              statusColor: 'green.300',
            },
          }}
        />
      </DataTable>
    </List>
  )
}
