import React, { FC } from 'react'
import { FormHelperText, VStack } from '@chakra-ui/react'
import { MUTATION_UPDATE_USER, QUERY_GET_USER } from '../../queries'
import { RolesInput } from './RolesInput'
import { UserForm } from './UserForm'
import { Edit } from '../../components/details/Edit'
import { BaseForm } from '../../components/forms/BaseForm'
import { ca, SingleImageInput } from 'chakra-admin'

export const UserEdit: FC = (props) => {
  return (
    <Edit query={QUERY_GET_USER} mutation={MUTATION_UPDATE_USER} {...props}>
      <BaseForm {...props}>
        <UserForm isRequired={false} />

        {/* <VStack spacing={4} mt={4}>
          <SingleImageInput
            borderRadius="full"
            maxW="130px"
            h="100%"
            source="picture.file"
            bg="gray.50"
            boxShadow="sm"
            color="gray.500"
          />
          <ca.FormControl source="firstName">
            <ca.FormLabel />
            <ca.Input source="firstName" />
          </ca.FormControl>
          <ca.FormControl source="lastName">
            <ca.FormLabel />
            <ca.Input source="lastName" />
          </ca.FormControl>
          <ca.FormControl source="email">
            <ca.FormLabel />
            <ca.Input source="email" />
          </ca.FormControl>
          <ca.FormControl source="password">
            <ca.FormLabel />
            <ca.Input type="password" source="password" />
            <FormHelperText ml={1}>Lascia vuoto per non modificare</FormHelperText>
          </ca.FormControl>

          <RolesInput />
        </VStack> */}
      </BaseForm>
    </Edit>
  )
}
