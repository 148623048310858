import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { useBoardHeightValue } from '../../utils/board'

type Props = {
  //
}

const OVERFLOW_DEFAULT_OFFSET = 65

export const ScrollableLane: FC<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null)
  const boardHeight = useBoardHeightValue()
  const [laneHeight, setLaneHeight] = useState(0)

  const isOverflowed = useMemo(() => {
    return laneHeight > boardHeight - OVERFLOW_DEFAULT_OFFSET
  }, [laneHeight, boardHeight])

  useEffect(() => {
    if (ref.current) {
      setLaneHeight(ref.current.scrollHeight)
    }
  }, [props.children])

  return (
    <Box
      ref={ref}
      data-testid="scrollable-lane"
      flex="0 1 auto"
      overflowY="auto"
      overflowX="hidden"
      w="100%"
      boxSizing="border-box"
      px={1}
      pr={isOverflowed ? '11px' : undefined}
      pos="relative"
      alignSelf="center"
      bgColor="gray.100"
      borderBottomRadius="lg"
      pt={2}
      //   maxH="100%"
      flexDir="column"
      justifyContent="space-between"
      {...props}
    />
  )
}
