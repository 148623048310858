/* eslint-disable react/jsx-key */
import React, { FC } from 'react'
import {
  DataTableValue as Field,
  GenericMoreMenuButton,
  Input,
  List,
  ListToolbar,
  OffsetSortDirection,
} from 'chakra-admin'
import { MUTATION_DELETE_USER, QUERY_GET_USERS } from '../../queries'
import { gql_Query, gql_User } from '../../graphql'
import { Avatar, useBreakpointValue } from '@chakra-ui/react'
import { Filters } from '../../components/list/Filters'
import { CreateButton } from '../../components/buttons/CreateButton'
import { DataTable } from '../../components/list/DataTable'
import { PageLayout } from '../../components/details/PageLayout'
import { PageTitle } from '../../components/details/PageTitle'
import { WithPermission } from '../../components/auth/WithPermission'

const UserFilters: FC = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <Filters {...props}>
      <Input
        source="email"
        label="Email"
        placeholder="Cerca email..."
        alwaysOn={isMobile ? false : true}
        border="none"
        bgColor="transparent"
        _hover={{ bgColor: 'transparent' }}
        borderRadius="xl"
        boxShadow="0px 1px 4px rgba(0, 0, 0, 0.05), 0px 6px 24px rgba(0, 0, 0, 0.04), inset 0px 1px 1px rgba(0, 0, 0, 0.04)"
      />

      <Input source="firstName" label="Nome" />
      <Input source="lastName" label="Cognome" />
    </Filters>
  )
}

export const UserList: FC = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <List<gql_Query, gql_User>
      paginationMode="offset"
      layout={<PageLayout />}
      title={(<PageTitle />) as any}
      query={QUERY_GET_USERS}
      deleteItemMutation={MUTATION_DELETE_USER}
      filtersComponent={<UserFilters />}
      defaultSorting={{ id: OffsetSortDirection.ASC } as any}
      toolbarComponent={
        <ListToolbar>
          <WithPermission permission="createUser">
            <CreateButton openAsModal label={isMobile ? 'Crea' : true} />
          </WithPermission>
        </ListToolbar>
      }
      {...props}
    >
      <DataTable
        rowClick={{ asModal: true }}
        moreMenuComponent={<GenericMoreMenuButton openEditAsModal />}
      >
        <Field<gql_User> source="id" />
        <Field<gql_User>
          source="picture"
          render={({ record }: any) => {
            return (
              <Avatar
                src={record?.picture?.urlSmall}
                name={`${record?.firstName} ${record?.lastName}`}
              />
            )
          }}
        />
        <Field<gql_User> source="email" />
        <Field<gql_User> source="firstName" />
        <Field<gql_User> source="lastName" />
      </DataTable>
    </List>
  )
}
