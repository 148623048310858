import React, { FC } from 'react'
import { Show, ca, TreeRenderer, ShowToolbar, EditButton } from 'chakra-admin'
import { Box, Text } from '@chakra-ui/react'
import { MUTATION_UPDATE_ROLE, QUERY_GET_ROLE } from '../../queries'
import { gql_Query, gql_Role } from '../../graphql'
import RoleDetails from './components/RoleDetails'
import { PageLayout } from '../../components/details/PageLayout'
import { PageTitle } from '../../components/details/PageTitle'

export const RoleShow: FC = (props) => {
  return (
    <Show<gql_Query, gql_Role>
      query={QUERY_GET_ROLE}
      mutation={MUTATION_UPDATE_ROLE}
      layout={<PageLayout pr="5" pl="5" overflow="visible" />}
      title={(<PageTitle />) as any}
      toolbarComponent={
        <ShowToolbar>
          <EditButton
            openAsModal
            color="white"
            bg="primary.500"
            pr={{ base: 3.5, lg: 4 }}
            h={{ base: '30px', lg: '40px' }}
            w={{ base: '70px', lg: 'fit-content' }}
            borderRadius="xl"
            _hover={{ bg: 'primary.300' }}
            _active={{ bg: 'primary.300' }}
            fontWeight="600"
          />
        </ShowToolbar>
      }
      {...props}
    >
      <RoleDetails {...props} />
    </Show>
  )
}
