import React, { useMemo } from 'react'
import { Spinner, Icon, Stack, Tag, TagProps, useDisclosure, TagLeftIcon } from '@chakra-ui/react'
import { differenceInDays, isPast, isSameYear } from 'date-fns'
import { gql_Task, useEditTaskCompletionDateMutation } from '../../graphql'

import 'react-datepicker/dist/react-datepicker.css'
import { dateFormatter, dayMonthFormatter } from '../../utils/formatters'
import { RiCheckboxBlankLine, RiCheckboxLine, RiTimeLine } from 'react-icons/ri'
import { useVersion } from 'chakra-admin'

interface CompletableDateProps {
  task: Pick<gql_Task, 'id' | 'endDate' | 'completionDate'>
}

export const CompletableDate: React.FC<CompletableDateProps> = ({ task }) => {
  const [complete, completeResult] = useEditTaskCompletionDateMutation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const nextVersion = useVersion()

  const handleComplete: React.MouseEventHandler<HTMLSpanElement> = React.useCallback(
    async (ev) => {
      ev.stopPropagation()

      if (completeResult.loading) {
        return
      }

      await complete({
        variables: {
          id: task.id,
          data: {
            completionDate: !task.completionDate ? new Date().toISOString() : null,
          },
        },
      })

      nextVersion()
    },
    [complete, completeResult.loading, nextVersion, task.completionDate, task.id]
  )

  const colorScheme = React.useMemo<TagProps['colorScheme']>(() => {
    const isCompleted = !!task.completionDate

    if (isCompleted) {
      return 'green'
    }

    const isOverdue = isPast(new Date(task.endDate))

    if (isOverdue) {
      return 'red'
    }

    const closingSoon = differenceInDays(new Date(task.endDate), new Date()) === 0

    if (closingSoon) {
      return 'yellow'
    }

    return 'gray'
  }, [task.completionDate, task.endDate])

  const dateFormatted = useMemo(() => {
    const date = new Date(task.endDate)
    if (isSameYear(new Date(), date)) {
      return dayMonthFormatter(date)
    }
    return dateFormatter(date)
  }, [task.endDate])

  const isCompleted = !!task.completionDate

  return (
    <Tag
      colorScheme={colorScheme}
      size="sm"
      px="1"
      onMouseEnter={() => onOpen()}
      onMouseLeave={() => onClose()}
      onClick={handleComplete}
      cursor="pointer"
      borderRadius="lg"
    >
      {completeResult.loading ? (
        <Spinner size="sm" />
      ) : (
        <TagLeftIcon
          as={isOpen ? (isCompleted ? RiCheckboxLine : RiCheckboxBlankLine) : RiTimeLine}
          mr="1"
        />
      )}
      {dateFormatted}
    </Tag>
  )
}
