import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  gql_CreateTaskStatusMutation,
  gql_CreateTaskStatusMutationVariables,
  gql_DeleteTaskStatusMutation,
  gql_DeleteTaskStatusMutationVariables,
  gql_GetTaskStatusesQuery,
  gql_GetTaskStatusesQueryVariables,
  gql_Project,
  gql_SortDirection,
  gql_TaskStatus,
  gql_EditTaskStatusesOrderMutationVariables,
  gql_EditTaskStatusesOrderMutation,
  gql_EditTaskStatusMutation,
  gql_EditTaskStatusMutationVariables,
  gql_GetTasksQuery,
  gql_GetTasksQueryVariables,
  gql_CreateTaskMutation,
  gql_CreateTaskMutationVariables,
  gql_EditTaskMutationVariables,
  gql_EditTaskMutation,
  gql_EditTaskOrderMutation,
  gql_EditTaskOrderMutationVariables,
  gql_DeleteTaskMutationVariables,
  gql_DeleteTaskMutation,
} from '../../graphql'
import { useToast } from '@chakra-ui/react'
import { useApolloClient } from '@apollo/client'
import {
  MUTATION_CREATE_TASK,
  MUTATION_CREATE_TASK_STATUS,
  MUTATION_DELETE_TASK,
  MUTATION_DELETE_TASK_STATUS,
  MUTATION_UPDATE_TASK,
  MUTATION_UPDATE_TASK_ORDER,
  MUTATION_UPDATE_TASK_STATUS,
  MUTATION_UPDATE_TASK_STATUSES_ORDER,
  QUERY_GET_TASKS,
  QUERY_GET_TASK_STATUSES,
} from '../../queries'
// @ts-ignore
import Board from 'react-trello'
import { GlobalStyle } from './GlobalStyle'
import { BoardWrapper } from './BoardWrapper'
import { Loader } from './Loader'
import { ScrollableLane } from './ScrollableLane'
import { Section } from './Section'
import { LaneHeader } from './LaneHeader'
import { LaneFooter } from './LaneFooter'
import { NewLaneSection } from './NewLaneSection'
import { NewLaneForm } from './NewLaneForm'
import { AddCardLink } from './AddCardLink'
import { NewCardForm } from './NewCardForm'
import { TaskCard } from './TaskCard'
import { randomColor } from '../../utils/randomColor'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { TaskFormModal } from '../task/TaskForm'
import { useVersion, useVersionStateValue } from 'chakra-admin'
import { useSearchParams } from 'react-router-dom'
import { ProjectIdContext } from './ProjectIdContext'

type Props = {
  id: string
  project?: gql_Project
  loading?: boolean
}

const components = {
  GlobalStyle,
  BoardWrapper,
  Loader,
  Section,
  ScrollableLane,
  LaneHeader,
  LaneFooter,
  NewLaneSection,
  NewLaneForm,
  AddCardLink,
  NewCardForm,
  Card: TaskCard,
}

interface Task {
  id: string
  title: string
  description?: string
  taskStatusId: string
}

export const ProjectBoard: FC<Props> = ({ id, project, loading: loadingProject, ...props }) => {
  const [statuses, setStatuses] = useState<gql_TaskStatus[]>([])
  const [tasks, setTasks] = useState<Task[]>([])
  const [board, setBoard] = useState<Record<string, any>>({ lanes: [] })
  const client = useApolloClient()
  const toast = useToast()
  const navigate = useNavigate()
  const version = useVersionStateValue()
  const nextVersion = useVersion()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const filteredUserIdsParam = searchParams.get('f_userIds')
  const filteredUserIds = useMemo(
    () => (filteredUserIdsParam ? filteredUserIdsParam.split(',').filter((item) => !!item) : []),
    [filteredUserIdsParam]
  )

  const filteredTagIdsParam = searchParams.get('f_tagIds')
  const filteredTagIds = useMemo(
    () => (filteredTagIdsParam ? filteredTagIdsParam.split(',').filter((item) => !!item) : []),
    [filteredTagIdsParam]
  )

  const eventBus = useRef<any>(undefined)

  const fetchStatuses = useCallback(async () => {
    const { data } = await client.query<
      gql_GetTaskStatusesQuery,
      gql_GetTaskStatusesQueryVariables
    >({
      query: QUERY_GET_TASK_STATUSES,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          projectId: id,
        },
        sort: {
          order: gql_SortDirection.Asc,
        },
        pagination: {
          limit: 1000,
          offset: 0,
        },
      },
    })

    return data?.taskStatuses
  }, [client, id])

  const fetchTasks = useCallback(async () => {
    const { data } = await client.query<gql_GetTasksQuery, gql_GetTasksQueryVariables>({
      query: QUERY_GET_TASKS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          projectId: id,
          ...(filteredUserIds.length > 0 && { userIds: filteredUserIds }),
          ...(filteredTagIds.length > 0 && { tagIds: filteredTagIds }),
        },
        pagination: {
          limit: 1000,
          offset: 0,
        },
        sort: {
          order: gql_SortDirection.Asc,
        },
      },
    })

    return data?.tasks
  }, [client, filteredTagIds, filteredUserIds, id])

  const handleLaneAdd = useCallback(
    async (params: { id: string; title: string }) => {
      try {
        const lastOrder = statuses?.[statuses.length - 1]?.order || 0

        const { data } = await client.mutate<
          gql_CreateTaskStatusMutation,
          gql_CreateTaskStatusMutationVariables
        >({
          mutation: MUTATION_CREATE_TASK_STATUS,
          variables: {
            data: {
              projectId: id,
              name: params.title,
              order: lastOrder + 1,
            },
          },
        })
        if (!data?.createTaskStatus) {
          throw new Error('Error creating status')
        }

        setBoard((prevBoard) => ({
          ...prevBoard,
          lanes: [
            ...prevBoard.lanes,
            {
              id: data?.createTaskStatus?.id,
              title: params.title,
              order: lastOrder + 1,
              cards: [],
            },
          ],
        }))

        setStatuses((prevStatuses) => [...prevStatuses, data?.createTaskStatus])
      } catch (error) {
        console.error(error)
        toast({
          title: 'Errore durante la creazione della lista',
          status: 'error',
          isClosable: true,
          duration: 5000,
        })
        eventBus.current?.publish({
          type: 'REFRESH_BOARD',
          data: board,
        })
      }
    },
    [board, client, id, statuses, toast]
  )

  const handleLaneDelete = useCallback(
    async (id: string) => {
      try {
        const { data } = await client.mutate<
          gql_DeleteTaskStatusMutation,
          gql_DeleteTaskStatusMutationVariables
        >({
          mutation: MUTATION_DELETE_TASK_STATUS,
          variables: {
            id,
          },
        })
        if (!data?.deleteTaskStatus) {
          throw new Error('Error deleting status')
        }

        setStatuses((prevStatuses) => prevStatuses.filter((status) => status.id !== id))
        setBoard((prevBoard) => ({
          ...prevBoard,
          lanes: prevBoard.lanes.filter((lane: any) => lane.id !== id),
        }))
      } catch (error) {
        toast({
          // eslint-disable-next-line @typescript-eslint/quotes
          title: "Errore durante l'eliminazione della lista",
          status: 'error',
          isClosable: true,
          duration: 5000,
        })
        eventBus.current?.publish({
          type: 'REFRESH_BOARD',
          data: board,
        })
      }
    },
    [board, client, toast]
  )

  const handleLaneDragEnd = useCallback(
    async (removedIndex: number, addedIndex: number, payload: any) => {
      const newLanes = [...board.lanes]

      const [removedLane] = newLanes.splice(removedIndex, 1)
      newLanes.splice(addedIndex, 0, removedLane)

      let newStatuses = [...statuses]

      const [removedStatus] = newStatuses.splice(removedIndex, 1)
      newStatuses.splice(addedIndex, 0, removedStatus)

      newStatuses = newStatuses.map((item, index) => ({
        ...item,
        order: index + 1,
      }))

      try {
        const { data } = await client.mutate<
          gql_EditTaskStatusesOrderMutation,
          gql_EditTaskStatusesOrderMutationVariables
        >({
          mutation: MUTATION_UPDATE_TASK_STATUSES_ORDER,
          variables: {
            data: newStatuses.map((item) => ({
              id: item.id!,
              order: item.order!,
            })),
          },
        })

        setStatuses(newStatuses)
        setBoard((prevBoard) => ({
          ...prevBoard,
          lanes: newLanes,
        }))
      } catch (error) {
        console.error(error)
        toast({
          title: 'Errore durante la modifica della lista',
          status: 'error',
          isClosable: true,
          duration: 5000,
        })
        eventBus.current?.publish({
          type: 'REFRESH_BOARD',
          data: board,
        })
      }
    },
    [board, client, statuses, toast]
  )

  const handleLaneUpdate = useCallback(
    async (id: string, data: any) => {
      try {
        if (data.title) {
          const { data: result } = await client.mutate<
            gql_EditTaskStatusMutation,
            gql_EditTaskStatusMutationVariables
          >({
            mutation: MUTATION_UPDATE_TASK_STATUS,
            variables: {
              id,
              data: {
                name: data.title,
              },
            },
          })

          if (result?.updateTaskStatus) {
            setStatuses((prevStatuses) =>
              prevStatuses.map((status) => (status.id === id ? result.updateTaskStatus : status))
            )
            setBoard((prevBoard) => ({
              ...prevBoard,
              lanes: prevBoard.lanes.map((lane: any) =>
                lane.id === id ? { ...lane, ...data } : lane
              ),
            }))
          }
        }
      } catch (error) {
        console.error(error)
        toast({
          title: 'Errore durante la modifica della lista',
          status: 'error',
          isClosable: true,
          duration: 5000,
        })
        eventBus.current?.publish({
          type: 'REFRESH_BOARD',
          data: board,
        })
      }
    },
    [board, client, toast]
  )

  const handleCardAdd = useCallback(
    async (card: any, laneid: string) => {
      try {
        const foundedLane = board.lanes.find((lane: any) => lane.id === laneid)
        const lastOrder = foundedLane?.cards?.[foundedLane.cards.length - 1]?.order || 0

        const { data } = await client.mutate<
          gql_CreateTaskMutation,
          gql_CreateTaskMutationVariables
        >({
          mutation: MUTATION_CREATE_TASK,
          variables: {
            data: {
              projectId: id,
              title: card.title,
              description: card.description,
              taskStatusId: laneid,
              order: lastOrder + 1,
              color: randomColor(),
            },
          },
        })

        if (data?.createTask) {
          setBoard((prevBoard) => ({
            ...prevBoard,
            lanes: prevBoard.lanes.map((lane: any) => {
              if (lane.id === laneid) {
                return {
                  ...lane,
                  cards: [
                    ...(lane.cards || []),
                    {
                      id: data?.createTask?.id,
                      title: data?.createTask?.title || '',
                      description: data?.createTask?.description || '',
                      order: data?.createTask?.order,
                      metadata: data?.createTask,
                    },
                  ],
                }
              }
              return lane
            }),
          }))

          setTasks((prevTasks) => [...prevTasks, data.createTask])
        }
      } catch (error) {
        console.error(error)
        toast({
          // eslint-disable-next-line prettier/prettier
          title: "Errore durante la creazione dell'attività",
          status: 'error',
          isClosable: true,
          duration: 5000,
        })
        eventBus.current?.publish({
          type: 'REFRESH_BOARD',
          data: board,
        })
      }
    },
    [board, client, id, toast]
  )

  const handleCardUpdate = useCallback(
    async (laneId: any, data: any) => {
      try {
        if (data.title || data.description) {
          const { data: result } = await client.mutate<
            gql_EditTaskMutation,
            gql_EditTaskMutationVariables
          >({
            mutation: MUTATION_UPDATE_TASK,
            variables: {
              id: data.id,
              data: {
                title: data.title || undefined,
                description: data.description || undefined,
              },
            },
          })

          if (result?.updateTask) {
            setTasks((prevTasks) =>
              prevTasks.map((task) => (task.id === result.updateTask.id ? result.updateTask : task))
            )
            setBoard((prevBoard) => ({
              ...prevBoard,
              lanes: prevBoard.lanes.map((lane: any) =>
                lane.id === id ? { ...lane, ...data } : lane
              ),
            }))
          }
        }
      } catch (error) {
        console.error(error)
        toast({
          // eslint-disable-next-line prettier/prettier
          title: "Errore durante la modifica dell'attività",
          status: 'error',
          isClosable: true,
          duration: 5000,
        })
        eventBus.current?.publish({
          type: 'REFRESH_BOARD',
          data: board,
        })
      }
    },
    [board, client, id, toast]
  )

  const handleCardDragEnd = useCallback(
    async (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
      try {
        // UPDATE CARD
        const foundedTask = tasks.find((task) => task.id === cardId)
        let newCardStatus = foundedTask?.taskStatusId

        if (sourceLaneId !== targetLaneId) {
          newCardStatus = targetLaneId
        }

        const { data: cardUpdated } = await client.mutate<
          gql_EditTaskMutation,
          gql_EditTaskMutationVariables
        >({
          mutation: MUTATION_UPDATE_TASK,
          variables: {
            id: cardId,
            data: {
              taskStatusId: newCardStatus,
              order: position + 1,
            },
          },
        })

        // UPDATE CARDS ORDER
        const foundedTargetLane = board.lanes.find((lane: any) => lane.id === targetLaneId)
        let targetLaneCards = [...(foundedTargetLane?.cards || [])]

        const newCard = cardUpdated?.updateTask
          ? {
              id: cardUpdated?.updateTask.id,
              title: cardUpdated?.updateTask.title,
              description: cardUpdated?.updateTask.description,
              order: cardUpdated?.updateTask.order,
              metadata: cardUpdated?.updateTask,
            }
          : undefined
        if (targetLaneCards.length > 0) {
          const foundedCardIndex = targetLaneCards.findIndex((card: any) => card.id === cardId)

          if (foundedCardIndex > -1) {
            targetLaneCards.splice(foundedCardIndex, 1)
          }

          if (position === 0) {
            targetLaneCards.unshift(newCard)
          } else {
            targetLaneCards.splice(position, 0, newCard)
          }
        } else {
          targetLaneCards = [newCard]
        }

        targetLaneCards = targetLaneCards.map((card: any, index: number) => ({
          ...card,
          order: index + 1,
        }))

        let foundedSourceLaneCards: any = []
        if (sourceLaneId !== targetLaneId) {
          const foundedSourceLane = board.lanes.find((lane: any) => lane.id === sourceLaneId)
          foundedSourceLaneCards = [...(foundedSourceLane?.cards || [])]
            .filter((item) => item.id !== cardId)
            .map((item, index) => {
              return {
                ...item,
                order: index + 1,
              }
            })
        }

        const { data } = await client.mutate<
          gql_EditTaskOrderMutation,
          gql_EditTaskOrderMutationVariables
        >({
          mutation: MUTATION_UPDATE_TASK_ORDER,
          variables: {
            data: [
              ...(targetLaneCards || []).map((item) => {
                return {
                  id: item.id,
                  order: item.order,
                }
              }),
              ...(sourceLaneId !== targetLaneId
                ? [...(foundedSourceLaneCards || [])].map((item) => {
                    return {
                      id: item.id,
                      order: item.order,
                    }
                  })
                : []),
            ],
          },
        })

        if (data?.updateTaskOrder) {
          setBoard((prevBoard) => ({
            ...prevBoard,
            lanes: prevBoard.lanes.map((lane: any) => {
              if (lane.id === targetLaneId) {
                return {
                  ...lane,
                  cards: targetLaneCards.map((card: any) => ({
                    ...card,
                    id: card.id,
                    title: card.title || '',
                    description: card.description || '',
                    order: card.order,
                  })),
                }
              }
              if (lane.id === sourceLaneId && sourceLaneId !== targetLaneId) {
                return {
                  ...lane,
                  cards: foundedSourceLaneCards.map((card: any) => ({
                    ...card,
                    id: card.id,
                    title: card.title || '',
                    description: card.description || '',
                    order: card.order,
                  })),
                }
              }
              return lane
            }),
          }))
        }
      } catch (e) {
        console.error(e)
        toast({
          // eslint-disable-next-line prettier/prettier
          title: "Errore durante la modifica dell'attività",
          status: 'error',
          isClosable: true,
          duration: 5000,
        })
        eventBus.current?.publish({
          type: 'REFRESH_BOARD',
          data: board,
        })
      }
    },
    [board, client, tasks, toast]
  )

  const handleCardDelete = useCallback(
    async (id: string, laneId: string) => {
      try {
        const { data } = await client.mutate<
          gql_DeleteTaskMutation,
          gql_DeleteTaskMutationVariables
        >({
          mutation: MUTATION_DELETE_TASK,
          variables: {
            id,
          },
        })
        if (!data?.deleteTask) {
          throw new Error('Error deleting task')
        }

        setTasks((prevTasks) => prevTasks.filter((task) => task.id !== id))
        setBoard((prevBoard) => ({
          ...prevBoard,
          lanes: prevBoard.lanes.map((lane: any) => {
            if (lane.id === laneId) {
              return {
                ...lane,
                cards: lane.cards.filter((card: any) => card.id !== id),
              }
            }
            return lane
          }),
        }))
      } catch (error) {
        console.error(error)
        toast({
          // eslint-disable-next-line prettier/prettier
          title: "Errore durante l' eliminazione dell'attività",
          status: 'error',
          isClosable: true,
          duration: 5000,
        })
        eventBus.current?.publish({
          type: 'REFRESH_BOARD',
          data: board,
        })
      }
    },
    [board, client, toast]
  )

  const handleEventBus = useCallback((handler) => {
    eventBus.current = handler
  }, [])

  const handleCardClick = useCallback(
    (cardId, metadata, laneId) => {
      const newSP = new URLSearchParams(searchParams)
      newSP.set('returnTo', location.pathname)
      navigate({
        pathname: `${cardId}`,
        search: newSP.toString(),
      })
    },
    [location.pathname, navigate, searchParams]
  )

  useEffect(() => {
    const init = async () => {
      try {
        const statuses = await fetchStatuses()
        const tasks = await fetchTasks()
        setTasks(tasks?.data || [])
        setStatuses(statuses?.data || [])

        setBoard({
          project: { ...project },
          lanes: (statuses?.data || [])?.map(({ id, name, order }) => ({
            id,
            title: name,
            order,
            cards: (tasks?.data || [])
              ?.filter((task) => task.status?.id === id)
              .map((task) => {
                return {
                  id: task.id,
                  title: task.title || '',
                  description: task.description || '',
                  order: task.order,
                  metadata: task,
                }
              }),
            //   .sort((a, b) => a.order - b.order),
          })),
        })
      } catch (error) {}
    }

    if (id) {
      init()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, version, filteredUserIds, filteredTagIds])

  return (
    <ProjectIdContext.Provider value={id}>
      {/* <Box flex="1" w="100%" h="100%"> */}
      {/* <pre>{JSON.stringify(statusesData, null, 2)}</pre> */}
      <Board
        draggable
        editable
        canAddLanes
        editLaneTitle
        onLaneAdd={handleLaneAdd}
        onLaneDelete={handleLaneDelete}
        handleLaneDragEnd={handleLaneDragEnd}
        onLaneUpdate={handleLaneUpdate}
        onCardAdd={handleCardAdd}
        onCardUpdate={handleCardUpdate}
        handleDragEnd={handleCardDragEnd}
        onCardDelete={handleCardDelete}
        onCardClick={handleCardClick}
        data={board}
        components={components}
        eventBusHandle={handleEventBus}
      />
      {/* </Box> */}

      <Routes>
        <Route
          path=":taskId"
          element={<TaskFormModal boardEventBus={eventBus} onAfterClose={() => nextVersion()} />}
        />
      </Routes>
    </ProjectIdContext.Provider>
  )
}
