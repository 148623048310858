import { Badge, BadgeProps, Box, Button, Icon, Popover, PopoverTrigger } from '@chakra-ui/react'
import React from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { gql_Project, gql_TaskStatus, useChangeTaskStatusMutation } from '../../../graphql'
import { TaskStatusList } from './TaskStatusList'

type ItemData = Pick<gql_TaskStatus, 'id' | 'name' | 'color'>

interface TaskStatusPickerProps {
  task: {
    id: string
    project?: Pick<gql_Project, 'id'>
    status?: ItemData
  }
  isInvalid?: boolean
}

export const TaskStatusPicker: React.FC<TaskStatusPickerProps> = ({
  task,
  isInvalid,
  ...props
}) => {
  const [updateStatus] = useChangeTaskStatusMutation()
  const invalidStyle: BadgeProps = isInvalid
    ? {
        borderColor: 'red.500',
        borderWidth: '2px',
      }
    : {}

  const handleSelect = React.useCallback(
    (item: ItemData) => {
      updateStatus({
        variables: {
          id: task.id,
          data: {
            taskStatusId: item.id,
          },
        },
      })
    },
    [task.id, updateStatus]
  )

  return (
    <Box>
      <Popover>
        <PopoverTrigger>
          <Badge
            as={Button}
            px={3}
            py={2}
            h="auto"
            fontSize="xs"
            borderRadius="md"
            alignItems="center"
            display="flex"
            boxSizing="border-box"
            {...invalidStyle}
          >
            {task.status?.name || 'Cat. N/D'}
            <Icon ml={1} as={FiChevronDown} />
          </Badge>
        </PopoverTrigger>
        <TaskStatusList
          selected={task.status}
          onSelect={handleSelect}
          projectId={task.project?.id}
        />
      </Popover>
    </Box>
  )
}
