import React, { useCallback } from 'react'
import { useTranslate } from 'chakra-admin'
import { Badge, Icon, List, ListIcon, ListItem, Stack, Text } from '@chakra-ui/react'
import { gql_InteractionType } from '../../graphql'
import { currencyFormatter, dateFormatter } from '../../utils/formatters'
import { RiEdit2Fill } from 'react-icons/ri'
import { MdOutlineArrowRightAlt } from 'react-icons/md'

const DIFF_DELETE = -1
const DIFF_INSERT = 1
const DIFF_EQUAL = 0

interface InteractionUpdatesProps {
  interaction: {
    id: string
    type: gql_InteractionType
    data?: {
      changes?: Log
    }
  }
}

type ConversionTypes = 'currency' | 'date'
type SimpleChange = {
  before: number | string
  after: number | string
  type: ConversionTypes
}

type RichTextChange = {
  diff: [number, string][]
  type: 'rich-text'
}

type Log = Record<string, SimpleChange | RichTextChange>

const hasUpdatedColumns: {
  [K in gql_InteractionType]?: string
} = {
  [gql_InteractionType.TaskUpdated]: 'Task',
  [gql_InteractionType.TaskTodoUpdated]: 'Todo',
}

const format = (value: number | string, to: ConversionTypes): string => {
  switch (to) {
    case 'currency':
      return currencyFormatter(+value / 100)
    case 'date':
      return dateFormatter(new Date(value as string))
    default:
      return String(value)
  }
}

export const InteractionUpdates: React.FC<InteractionUpdatesProps> = React.memo(
  ({ interaction }) => {
    const t = useTranslate()
    const resource = hasUpdatedColumns[interaction.type]

    const renderSimpleChange = useCallback(({ after, before, type }: SimpleChange) => {
      return (
        <>
          {before && (
            <Badge
              colorScheme="gray"
              fontSize="smaller"
              borderRadius="lg"
              textDecoration={!after ? 'line-through' : undefined}
            >
              {format(before, type)}
            </Badge>
          )}
          {after && before && <Icon as={MdOutlineArrowRightAlt} mx="1" />}
          {after && (
            <Badge colorScheme="green" fontSize="smaller" borderRadius="lg">
              {format(after, type)}
            </Badge>
          )}
        </>
      )
    }, [])

    const renderRichTextChange = useCallback(({ diff }: RichTextChange) => {
      const html = []
      const pattern_amp = /&/g
      const pattern_lt = /</g
      const pattern_gt = />/g
      const pattern_para = /\n/g
      for (let x = 0; x < diff.length; x++) {
        var op = diff[x][0] // Operation (insert, delete, equal)
        var data = diff[x][1] // Text of change.
        var text = data
          .replace(pattern_amp, '&amp;')
          .replace(pattern_lt, '&lt;')
          .replace(pattern_gt, '&gt;')
          .replace(pattern_para, '<br>')

        switch (op) {
          case DIFF_INSERT:
            html[x] = `<ins style="background:#e6ffe6;">${text}</ins>`
            break
          case DIFF_DELETE:
            html[x] = `<del style="background:#ffe6e6;">${text}</del>`
            break
          case DIFF_EQUAL:
            html[x] = `<span>${text}</span>`
            break
        }
      }

      return (
        <Text p="1" fontSize="sm" bgColor="gray.100">
          <div dangerouslySetInnerHTML={{ __html: html.join('') }} />
        </Text>
      )
    }, [])
    if (!resource || !interaction.data?.changes) {
      return null
    }

    const data = interaction.data.changes

    try {
      return (
        <List>
          {Object.entries(data).map(([key, change]) => (
            <ListItem
              key={key}
              display="flex"
              flexDirection={change.type === 'rich-text' ? 'column' : 'row'}
            >
              <Stack isInline spacing="1" marginRight="2">
                <ListIcon as={RiEdit2Fill} color="gray" fontSize="sm" />
                <Text fontSize="smaller" display="inline">
                  {t(`resources.${resource}.fields.${key}`, {
                    defaultValue: key,
                  })}
                  :
                </Text>
              </Stack>
              {change.type === 'rich-text'
                ? renderRichTextChange(change)
                : renderSimpleChange(change)}
            </ListItem>
          ))}
        </List>
      )
    } catch (e) {
      return null
    }
  },
  (prev, next) => prev.interaction.id === next.interaction.id
)
