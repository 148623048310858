import * as React from 'react'
import {
  Avatar,
  Heading,
  Text,
  Flex,
  IconButton,
  Icon,
  usePopoverContext,
  CloseButton,
  PopoverContent,
  HStack,
} from '@chakra-ui/react'
import { User } from '.'
import { FiEdit2, FiTrash } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { getUserName } from '../../utils/user/getUserName'

interface UserCardProps {
  user?: User
  onDelete: (userId: string) => void
  hasEdit?: boolean
  hasRemove?: boolean
}

export const UserCard: React.FC<UserCardProps> = ({ user, onDelete, hasEdit, hasRemove }) => {
  const { onClose } = usePopoverContext()
  const location = useLocation()

  if (!user) {
    return null
  }

  const userName = getUserName(user)

  return (
    <Flex
      as={PopoverContent}
      direction="column"
      flex={1}
      alignItems="center"
      padding="4"
      paddingTop="4"
      position="relative"
      borderRadius="10px"
      overflow="hidden"
    >
      <Flex
        position="absolute"
        top="0"
        left="0"
        right="0"
        height="50px"
        bgColor="blue.600"
        alignItems="center"
        justifyContent="space-between"
        paddingX="2"
      >
        <HStack>
          {hasRemove && (
            <IconButton
              size="sm"
              aria-label="Rimuove contato"
              onClick={() => onDelete(user.id)}
              icon={<Icon as={FiTrash} fontSize="md" />}
              borderRadius="full"
              colorScheme="red"
            />
          )}
          {hasEdit && (
            <IconButton
              size="sm"
              aria-label="Aggiorna contato"
              icon={<Icon as={FiEdit2} fontSize="md" />}
              borderRadius="full"
              colorScheme="whiteAlpha"
              as={Link}
              to={`/User/${user.id}`}
              state={{
                background: location,
              }}
            />
          )}
        </HStack>
        <CloseButton color="white" size="md" onClick={onClose} />
      </Flex>
      <Avatar size="lg" name={userName} src={user.picture?.urlSmall} />
      <Heading pt="4" size="md">
        {userName}
      </Heading>
      {/* <Text>{user.}</Text> */}
    </Flex>
  )
}
