import React, { FC } from 'react'
import { Global, css } from '@emotion/react'

export const GlobalStyle: FC = () => {
  /*
   * Without "!important" on lines 21 and 25 the cursor style doesn't work.
   * That's because the cursor style is decided directly on the Card component.
   */
  return (
    <Global
      styles={css`
        .comPlainTextContentEditable {
          -webkit-user-modify: read-write-plaintext-only;
          cursor: text;
        }
        .comPlainTextContentEditable--has-placeholder::before {
          content: attr(placeholder);
          opacity: 0.5;
          color: inherit;
          cursor: text;
        }
        .react_trello_dragClass {
          transform: rotate(3deg);
          cursor: grabbing !important;
        }
        .react_trello_dragLaneClass {
          transform: rotate(3deg);
          cursor: grabbing !important;
        }
        .icon-overflow-menu-horizontal:before {
          content: '\\E91F';
        }
        .icon-lg,
        .icon-sm {
          color: #798d99;
        }
        .icon-lg {
          height: 32px;
          font-size: 16px;
          line-height: 32px;
          width: 32px;
        }
      `}
    />
  )
}
