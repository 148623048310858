import { ApolloCache } from '@apollo/client'
import { Button, ButtonProps, IconButton, useToast } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri'
import {
  useUnsubscribeMutation,
  useSubscribeMutation,
  useIsSubscribedQuery,
  gql_InteractableType,
  gql_IsSubscribedQuery,
} from '../../graphql'
import { QUERY_IS_SUBSCRIBED } from '../../queries'

type InteractableType = `${gql_InteractableType}`

interface InteractionSubscriptionProps extends Omit<ButtonProps, 'type' | 'onClick'> {
  type: InteractableType | gql_InteractableType
  id: string
  minimal?: boolean
}

export function InteractionSubscription({
  type,
  id,
  minimal,
  ...props
}: InteractionSubscriptionProps) {
  const [subscribe, subscribeResult] = useSubscribeMutation()
  const [unsubscribe, unsubscribeResult] = useUnsubscribeMutation()
  const toast = useToast()

  const variables = useMemo(
    () => ({
      to: {
        type: type as gql_InteractableType,
        id,
      },
    }),
    [type, id]
  )

  const { data, loading } = useIsSubscribedQuery({
    variables,
    skip: !id,
  })

  const updateCache = (cache: ApolloCache<any>, isSubscribed?: boolean) => {
    if (typeof isSubscribed !== 'boolean') {
      return
    }

    cache.writeQuery<gql_IsSubscribedQuery>({
      query: QUERY_IS_SUBSCRIBED,
      variables,
      data: {
        isSubscribed,
      },
    })
  }

  async function handleSubscription() {
    try {
      if (data?.isSubscribed) {
        await unsubscribe({
          variables,
          update: (cache, { data }) => updateCache(cache, data?.unsubscribe),
        })
        toast({
          description: 'Ti sei cancellato da questo articolo',
          status: 'info',
        })
      } else {
        await subscribe({
          variables,
          update: (cache, { data }) => updateCache(cache, data?.subscribe),
        })

        toast({
          description: 'Ti sei iscritto a questo articolo',
          status: 'success',
        })
      }
    } catch {
      toast({
        description: 'Errore durante il salvataggio',
        status: 'error',
      })
    }
  }

  const isLoading = subscribeResult.loading || unsubscribeResult.loading || loading

  if (!data) return null

  if (minimal) {
    return (
      <IconButton
        size="sm"
        aria-label="Segui"
        icon={data?.isSubscribed ? <RiEyeOffLine size="20" /> : <RiEyeLine size="20" />}
        colorScheme="gray"
        variant="ghost"
        disabled={isLoading}
        isLoading={isLoading}
        {...props}
        onClick={handleSubscription}
      />
    )
  }

  return (
    <Button
      size="sm"
      leftIcon={data?.isSubscribed ? <RiEyeOffLine size="20" /> : <RiEyeLine size="20" />}
      colorScheme="gray"
      variant="ghost"
      disabled={isLoading}
      isLoading={isLoading}
      loadingText="Loading..."
      {...props}
      onClick={handleSubscription}
    >
      {data?.isSubscribed ? 'Non Segui' : 'Segui'}
    </Button>
  )
}
