import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { Textarea, TextareaProps } from '@chakra-ui/react'
import autosize from 'autosize'
import isHotkey from 'is-hotkey'

type Props = {
  resize?: 'none' | 'vertical' | 'horizontal'
} & TextareaProps

export const CardTitleEditable = forwardRef<HTMLTextAreaElement, Props>(
  ({ resize = 'none', onKeyDown, ...props }, ref) => {
    const inputRef = useRef<HTMLTextAreaElement>()
    useImperativeHandle(ref, () => {
      if (resize !== 'none') {
        autosize(inputRef.current!)
      }

      return inputRef.current!
    })

    const getValue = useCallback(() => {
      return inputRef.current?.value
    }, [])

    const handleKeyDown = useCallback<React.KeyboardEventHandler<HTMLTextAreaElement>>(
      (e) => {
        if (onKeyDown) {
          onKeyDown(e)
        }

        if (isHotkey('shift+enter', e)) {
          e.preventDefault()
          if (inputRef.current) {
            inputRef.current.value = `${getValue()}\n`
            autosize.update(inputRef.current!)
          }
          return
        }
      },
      [getValue, onKeyDown]
    )

    return (
      <Textarea
        ref={inputRef as any}
        onKeyDown={handleKeyDown}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        dataGramm="false"
        rows={1}
        overflowX="hidden" /* for Firefox (issue #5) */
        wordWrap="break-word"
        minH="80px"
        maxH="112px" /* optional, but recommended */
        resize={resize}
        width="100%"
        height="18px"
        fontSize="inherit"
        fontWeight="inherit"
        lineHeight="inherit"
        textAlign="inherit"
        boxShadow="none"
        boxSizing="border-box"
        border="1px solid"
        borderColor="gray.200"
        // padding="0"
        outline="0"
        p={2}
        bgColor="white"
        _focus={{
          borderWidth: '1px',
          borderColor: 'gray.200',
        }}
        {...props}
      />
    )
  }
)
