/* eslint-disable import/no-anonymous-default-export */
import { ProjectList } from './ProjectList'
import { RiListCheck2 } from 'react-icons/ri'
import { ProjectEdit } from './ProjectEdit'
import { ProjectCreate } from './ProjectCreate'
import { ProjectShow } from './ProjectShow'

export default {
  name: 'Project',
  icon: RiListCheck2,
  list: ProjectList,
  edit: ProjectEdit,
  create: ProjectCreate,
  show: ProjectShow,
}
