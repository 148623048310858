import React, { FC, useEffect, useMemo, useRef } from 'react'
import { Box } from '@chakra-ui/react'
import { useSetBoardHeightState } from '../../utils/board'

type Props = {
  actions: any
  cardDragClass: string
  data: any
  draggable: boolean
}

export const BoardWrapper: FC<Props> = ({ actions, data, cardDragClass, draggable, ...props }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const setHeight = useSetBoardHeightState()

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setHeight(ref.current.clientHeight)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return (): void => {
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      ref={ref}
      data-testid="board-wrapper"
      backgroundColor={
        data?.project?.color && !data?.project?.picture?.id ? data?.project?.color : 'transparent'
      }
      overflowY="hidden"
      // padding="5px"
      // color="#393939"
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      flex="1"
      {...props}
    />
  )
}
