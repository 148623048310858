import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Flex,
  Icon,
  IconButton,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Text,
  useOutsideClick,
} from '@chakra-ui/react'
import { gql_InteractionType } from '../../graphql'
import { Time } from '../time/Time'
import { FiEdit, FiMoreVertical, FiTrash } from 'react-icons/fi'
import { useInteractions } from './InteractionContext'
import { RichText } from '../rich-text/RichText'

interface ItemProps {
  interaction: {
    id: string
    type: gql_InteractionType
    data?: {
      comment: string
    }
    createdAt: string
    user: {
      id: string
      firstName?: string
      lastName?: string
    }
  }
}

export const CommentItem: React.FC<ItemProps> = ({ interaction }) => {
  const { handleDelete, handleUpdate, handleClickOutside, user } = useInteractions()
  const name = `${interaction.user.firstName} ${interaction.user.lastName}`
  const isMine = user?.id === interaction.user.id
  const [isEditing, setIsEditing] = useState(false)

  const isTarget = window.location.hash === `#comment-${interaction.id}`
  const commentRef = React.useRef<HTMLDivElement>(null)

  useOutsideClick({
    ref: commentRef,
    handler: () => handleClickOutside(interaction.id),
  })

  return (
    <Stack
      ref={commentRef}
      as={ListItem}
      isInline
      alignItems="flex-start"
      id={`comment-${interaction.id}`}
    >
      <Avatar size="sm" name={name} />
      <Box ml="2" w="full">
        <Flex justifyContent="space-between">
          <Box>
            <Text fontSize="md" fontWeight="600" display="inline">
              {name}
            </Text>

            <Time ml="2" fontSize="sm" lineHeight="short" color="gray.300">
              {interaction.createdAt}
            </Time>
          </Box>
          {isMine && (
            <Menu isLazy>
              <MenuButton
                as={IconButton}
                aria-label="actions options"
                icon={<Icon as={FiMoreVertical} fontSize="lg" />}
                size="xs"
                variant="ghost"
                colorScheme="blackAlpha"
                mb={2}
              />
              <Portal>
                <MenuList>
                  <MenuItem onClick={() => setIsEditing(true)} icon={<Icon as={FiEdit} />}>
                    Modifica
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleDelete(interaction)}
                    color="red.500"
                    icon={<Icon color="red.400" as={FiTrash} />}
                  >
                    Elimina
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          )}
        </Flex>
        <Box w="100%" pr="2px">
          <RichText
            initialValue={interaction.data?.comment as string}
            readonly={!isMine || !isEditing}
            hideToolbar
            boxContainer
            containerProps={
              isTarget
                ? {
                    outline: '2px solid',
                    outlineColor: 'blue.500',
                  }
                : undefined
            }
            onSubmit={(value) => {
              handleUpdate(interaction.id, value)
              setIsEditing(false)
            }}
            keepValueOnSubmit
          />
        </Box>
      </Box>
    </Stack>
  )
}
